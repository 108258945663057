import React from 'react'
import { Modal, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { app } from '../../../../config';

const ModalRemove = (props) => {
  
  const { isOpen, buyer, representative, remove, loading, close, personType } = props.properties;

  const onRemoveClick = async () => {

    if(personType === app.personType.buyerRepresentative){
      await remove.removeActualRepresentative(representative.index);
    }

    if(personType === app.personType.buyer){
      await remove.removeActualBuyer(buyer.index);
    }
    close();
  }

  return (
    <Modal isOpen={isOpen} toggle={close} size='md' centered>
      <div className="modal-content modal-remove-representative">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">{`Eliminar ${personType === app.personType.buyer? 'comprador' : personType === app.personType.buyerRepresentative? 'representante' : ''}`}</h2>
          <button type="button" className="close white-circle-close" onClick={() => close()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <FontAwesomeIcon icon="exclamation-circle" className='orange-color'/>
          <h1 className="modal-title orange-color">
            {`¿Deseas eliminar este ${personType === app.personType.buyer? 'comprador' : personType === app.personType.buyerRepresentative? 'representante' : ''}?`}
          </h1>
          <p className='text-secondary mt-1 text-16'>Esta acción no se puede deshacer.</p>
        </div>
        <div className="modal-footer justify-content-center mt-2">
          <Button className="btn-outline-primary-dark" size="sm" onClick={close} >CANCELAR</Button>{' '}
          <Button color="primary-dark" size="sm" onClick={() => onRemoveClick()} >
          {loading.loading? [
                    <span key='span-k-1' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key='span-k-2' className='ml-2'> Cargando...</span>
                  ] :
                    <span>{`SI, ELIMINAR ${personType === app.personType.buyer? 'COMPRADOR' : personType === app.personType.buyerRepresentative? 'REPRESENTANTE' : ''}`}</span>
                  }
            
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalRemove