import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router';  
import { Modal } from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BackendApiService from '../../../services/BackendApiService';

const RemoveSignatureModal = ({
  data,
  isOpen,
  methods,
  match
}) => {

  const { rut, role, code } = data;
  const { closeThis, getTransference } = methods;
  const [roleString, setRoleString] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const openErrorAlert = () => { setShowError(true) };
  const closeErrorAlert = () => { setShowError(false) };

  useEffect(() => {
    switch (role) {
      case 0:
        setRoleString('buyer');
        break;
      case 1:
        setRoleString('seller');
        break;
      default:
        setRoleString('');
        break;
    }
  }, [data, role])

  const onRemoveClick = async () => {
    try {
      closeErrorAlert();
      setLoading(true);
      const response = await BackendApiService.removeSignature(code, {rut: rut, rol: roleString});
      if(response.data?.status){
        await getTransference(match.params.code);
        setLoading(false);
        closeThis();
      } else {
        throw new Error('no se pudo eliminar la firma');
      }
      
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      openErrorAlert();
    }
  }

  return (

    <Modal isOpen={isOpen} toggle={() => (loading ? null : closeThis())} className='modal-contract-remove' centered>
      <div className="modal-content">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Eliminar firma</h2>
          <button type="button" className="close white-circle-close" onClick={() => closeThis()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className='modal-body text-center'>
        {showError && (
          <div className={`alert alert-danger alert-dismissible fade show`} role="alert">
            No se pudo eliminar la firma.
            <button type="button" class="close" onClick={() => closeErrorAlert()} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
          <FontAwesomeIcon icon="exclamation-circle" className='orange-color'/>
          <h1 className="modal-title orange-color">¿Deseas eliminar esta firma?</h1>
          <p className='text-secondary mt-1 text-16'>Esta acción no se puede deshacer.</p>
        </div>
        <div className="modal-footer justify-content-center mt-2">
          <button className="btn btn-outline-primary-dark" onClick={() => closeThis()} disabled={loading}>CANCELAR</button>
          <button className="btn btn-primary-dark" onClick={() => onRemoveClick()} disabled={loading}>
          {loading? [
                    <span key='span-k-1' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key='span-k-2' className='ml-2'> Eliminando firma...</span>
                  ] :
                    <span>SI, ELIMINAR</span>
                  }
            
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(RemoveSignatureModal);