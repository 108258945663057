import React, { useState } from 'react'
import { Modal, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotificationModal = () => {

  const [contractOkOpen, setContractOkOpen] = useState(true);

  const closeModalContractOk = () => setContractOkOpen(false);

  return (
    <Modal isOpen={contractOkOpen} toggle={() => closeModalContractOk()} className='modal-contract-ok' centered>
      <div className="modal-content">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Creación de contrato</h2>
          <button type="button" className="close white-circle-close" onClick={() => closeModalContractOk()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <div className="alert alert alert-outline-success p-3 mb-4" role="alert">
            <span className='bold text-14'><FontAwesomeIcon icon='check-circle' className='green-ok-color'/> El contrato se ha creado exitosamente</span> 
          </div>
          <p className='text-16'> <span className='bold'>Importante</span>: Antes de iniciar el proceso de firmas electrónicas asegúrate de que todos los firmantes revisen íntegramente el contrato.</p>
        </div>
        <div className="modal-footer justify-content-center mb-3">
          <Button className="btn-primary-dark bold" size="md" onClick={() => closeModalContractOk()} >OK, CONTINUAR</Button>{' '}
        </div>
      </div>
    </Modal>
  )
}

export default NotificationModal