import React, {Component} from 'react';
import {withRouter} from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TransferenceMassiveSignForm from "../../components/transference-massive-sign-form/TransferenceMassiveSignForm";

class TransferenceMassiveSign extends Component {
    render() {
        return (
            <div>
                <div className="page-title">
                    <FontAwesomeIcon icon="list-alt" alt="Icon" className="icon-title" />
                    <h2 className="text-title">Firma masiva de contratos</h2>
                </div>
                <div className="main-wrapper-page">
                    <TransferenceMassiveSignForm operationId={this.props.match.params.operationId} />
                </div>
            </div>);
    }
}

export default withRouter(TransferenceMassiveSign);
