import React from 'react'
import { Modal } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalSaveContractForm = ({data, func}) => {

  const { modalIsOpen, status } = data;
  const { closeModal } = func;

  const closeModalAction = () => {
    closeModal();
  }

  return (
    <Modal isOpen={modalIsOpen} className='save-form-modal' toggle={() => closeModalAction()}>
      <div className="modal-content text-center">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Guardar datos</h2>
          <button type="button" className="close white-circle-close" onClick={() => closeModalAction()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    
          <div className="modal-body">
            {status === 'ok' && (
              <div className='alert alert-outline-success m-4' role='alert'>
                <FontAwesomeIcon icon='check-circle' className='green-ok-color mr-2' />
                <span className='bold text-8'>Los datos fueron guardados exitosamente.</span>
              </div>
            )}
            {status === 'error' && (
              <div className='alert alert-outline-danger m-4' role='alert'>
                <FontAwesomeIcon icon='times-circle' className='error-color mr-2' />
                <span className='bold text-8'>Ocurrió un error al guardar, inténtalo de nuevo en unos minutos por favor.</span>
              </div>
            )}
          </div>
        <div className="modal-footer justify-content-center mb-2">
          <button className="btn btn-lg btn-primary-dark px-5" onClick={() => closeModalAction()}> OK, CONTINUAR </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSaveContractForm