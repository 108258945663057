import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import moment from 'moment';
import {TransferenceStatus, TransferenceType, TransferenceStatusForNotary, NotaryTag} from '@autofact/operation-lib-model-notary-platform';
import DateRangePicker from 'react-bootstrap-daterangepicker';

class TransferenceListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilters: {
                PLATE: '',
                TYPE: '',
                REPERTORY: '',
                STATUS: '',
                DATE_FROM: '',
                DATE_TO: '',
                DATE_RANGE: '',
                PREADMISSION: '',
                PREADMISSION_DATE_START: '',
                PREADMISSION_DATE_END: '',
                WITH_OUT_REQUEST_UP:'',
                TO_ENTER:''
            },
            activeTab: '',
            defaultFilters: props.defaultFilters,
            buttonStatus: true
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.onDateRangeSelected = this.onDateRangeSelected.bind(this);
        this.onDatePreadmissionRangeSelected = this.onDatePreadmissionRangeSelected.bind(this);
        this.renderDateComponent = this.renderDateComponent.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({defaultFilters: props.defaultFilters, activeTab: props.activeTab});
    }

    handleFilterChange(event) {
        console.log(event);
        let newFilterData = {};
        newFilterData[event.target.id] = event.target.value;
        newFilterData = Object.assign(this.state.formFilters, newFilterData);

        const keys = Object.keys(newFilterData);
        let buttonStatus= true;

        for (let i = 0; i < keys.length; i += 1) {
            if (newFilterData[keys[i]] !== '') {
                    buttonStatus = false;
            }
        }

        this.setState({
            formFilters: newFilterData,
            buttonStatus
        });
    }

    setFilters() {
        const filters = {};
        for(const filter in this.state.formFilters) {
            if (this.state.formFilters.hasOwnProperty(filter) && this.state.formFilters[filter]) {
                filters[filter] = this.state.formFilters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters() {
        const filters = {
            PLATE: '',
            TYPE: '',
            REPERTORY: '',
            STATUS: '',
            DATE_FROM: '',
            DATE_TO: '',
            PREADMISSION_DATE_RANGE: '',
            PREADMISSION_DATE_START: '',
            PREADMISSION_DATE_END: '',
            DATE_RANGE: '',
            PREADMISSION:'',
            WITH_OUT_REQUEST_UP:''
        };
        this.setState({
            formFilters: filters,
            buttonStatus: true
        });
    }
    renderDateComponent(label,id, onChangeFunction){
        return <DateRangePicker
            onApply={onChangeFunction}
            ranges={
                                    {
                                        'Hoy': [moment(), moment()],
                                        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                                        'Última semana': [moment().subtract(6, 'days'), moment()],
                                        'Últimas 2 semanas': [moment().subtract(13, 'days'), moment()],
                                        'Este mes': [moment().startOf('month'), moment().endOf('month')],
                                        'Mes anterior': [moment().subtract(1, 'month').startOf('month'),
                                            moment().subtract(1, 'month').endOf('month')]
                                    }
                                }
            locale={{
                                    format: 'DD/MM/YYYY',
                                    applyLabel: 'Aplicar',
                                    cancelLabel: 'Limpiar',
                                    fromLabel: 'Desde',
                                    toLabel: 'Hasta',
                                    customRangeLabel: 'Seleccionar rango',
                                    daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                                    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                                        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre',
                                        'Diciembre'],
                                    firstDay: 1
                                }}
            containerStyles={{display: 'block'}}
        >
            <Input type="text" placeholder={label} id={id} className="form-control-sm"
                   onChange={this.handleFilterChange}
                   value={this.state.formFilters[id] || this.state.defaultFilters[id]}/>
        </DateRangePicker>
    }

    onDateRangeSelected(event, picker) {
        const filterChangeEvent = {
            target: {
                id: 'DATE_RANGE',
                value: `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`
            }
        };
        this.handleFilterChange(filterChangeEvent);

        filterChangeEvent.target.id = 'DATE_FROM';
        filterChangeEvent.target.value = picker.startDate.format('YYYY-MM-DD');
        this.handleFilterChange(filterChangeEvent);

        filterChangeEvent.target.id = 'DATE_TO';
        filterChangeEvent.target.value = picker.endDate.format('YYYY-MM-DD');
        this.handleFilterChange(filterChangeEvent);
    }

    onDatePreadmissionRangeSelected(event, picker) {
        const filterChangeEvent = {
            target: {
                id: 'PREADMISSION_DATE_RANGE',
                value: `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`
            }
        };
        this.handleFilterChange(filterChangeEvent);

        filterChangeEvent.target.id = 'PREADMISSION_DATE_START';
        filterChangeEvent.target.value = picker.startDate.format('YYYY-MM-DD');
        this.handleFilterChange(filterChangeEvent);

        filterChangeEvent.target.id = 'PREADMISSION_DATE_END';
        filterChangeEvent.target.value = picker.endDate.format('YYYY-MM-DD');
        this.handleFilterChange(filterChangeEvent);
    }

    render() {
        let availableStatus = [];
        if (this.state.defaultFilters && this.state.defaultFilters.STATUS) {
            availableStatus = this.state.defaultFilters.STATUS.toString().split(',');
        } else {
            for (const statusId of TransferenceStatusForNotary) {
                availableStatus.push(statusId.toString());
            }
        }

        return (
            <Form className={'px-2'}>
                <Row>
                    {['signed', 'full','forSend','fullPreadmission'].indexOf(this.state.activeTab) !== -1 ?
                    <Col md={3}>
                        <FormGroup>
                            {this.renderDateComponent('Fecha firma contrato','DATE_RANGE',this.onDateRangeSelected)}
                        </FormGroup>
                    </Col>
                    : ''}

                    {['forSend','fullPreadmission'].indexOf(this.state.activeTab) !== -1 ?
                    <Col md={3}>
                        <FormGroup>
                            {this.renderDateComponent('Fecha preingreso','PREADMISSION_DATE_RANGE',this.onDatePreadmissionRangeSelected)}
                        </FormGroup>
                    </Col>
                    : ''}
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text" placeholder="Patente" id="PLATE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.PLATE}/>
                        </FormGroup>
                    </Col>

                    {['history'].includes(this.state.activeTab) === false &&<Col md={3}>
                        <FormGroup>
                            <Input type="select" id="TYPE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.TYPE}>
                                <option value="">Tipo de contrato</option>
                                {Object.keys(TransferenceType).map((typeId, index, typeList) => {
                                    const type = TransferenceType[typeId];
                                    return (<option key={`type-option-${index}`} value={type.id}>{type.label}</option>);
                                })}
                            </Input>
                        </FormGroup>
                    </Col>}
                    {['forSign', 'signed', 'full','forSend','fullPreadmission','history'].indexOf(this.state.activeTab) !== -1 ?
                        <Col md={3}>
                            <FormGroup>
                                <Input type="text" placeholder="Número de repertorio" id="REPERTORY" className="form-control-sm"
                                       onChange={this.handleFilterChange}
                                       value={this.state.formFilters.REPERTORY}/>
                            </FormGroup>
                        </Col>
                        : ''}

                    {['forSend','fullPreadmission'].indexOf(this.state.activeTab) !== -1 ?
                        <Col md={3}>
                            <FormGroup>
                                <Input type="text" placeholder="Número de preingreso" id="PREADMISSION" className="form-control-sm"
                                       onChange={this.handleFilterChange}
                                       value={this.state.formFilters.PREADMISSION}/>
                            </FormGroup>
                        </Col>
                        : ''}
                    {['forPreAdmission','forSign', 'signed', 'full', 'history'].indexOf(this.state.activeTab) === -1 && <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="STATUS" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.STATUS}>
                                <option value="">Estado</option>
                                {Object.keys(TransferenceStatus)
                                    .filter(status => TransferenceStatusForNotary.indexOf(TransferenceStatus[status].id) !== -1 && availableStatus.indexOf(TransferenceStatus[status].id.toString()) !== -1)
                                    .map((statusId, index, statusList) => {
                                        const status = TransferenceStatus[statusId];

                                        return (<option key={`type-option-${index}`}
                                                        value={status.id}>{status.label}</option>);
                                    })}
                            </Input>
                        </FormGroup>
                    </Col>
                    }
                    <Col md={3} className={`${this.state.activeTab === 'full' ? '': 'not-show'}`}>
                        <FormGroup>
                            <Input type="select" id="TO_ENTER" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.TO_ENTER}>
                                <option value="">Ingreso al registro civil</option>
                                {Object.keys(NotaryTag).map((typeId, index, typeList) => {
                                    const type = NotaryTag[typeId];
                                    return (<option key={`type-option-${index}`} value={type.id}>{type.label}</option>);
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <Button
                            className="btn btn-sm btn-primary-darker btn-filter"
                            onClick={this.setFilters}
                            disabled={this.state.buttonStatus}>
                            Aplicar
                            </Button>
                    </Col>
                </Row>
            </Form>
        );
    }


}

export default TransferenceListFilter;
