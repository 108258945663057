import React, {Component} from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Pagination from './pagination/Pagination';

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.defaultPageRowsCount = 5;
        this.maxPagesRowsCount = props.maxPagesRowsCount || 25;
        this.state = {
            data: [],
            pages: null,
            loading: false,
            expandedRows: [],
        };

        this.fetchData = this.fetchData.bind(this);
    }

    extractPagesFromPageData(extra) {
        const recordsTotal = extra.recordsTotal ||  extra.recordsFiltered
        return Math.ceil(recordsTotal / this.maxPagesRowsCount);
    }

    fetchData(state, instance) {
        //TODO build params
        const sorted = state.sorted.reduce((acum, column)=>{
            acum[column.id] = column.desc;
            return acum;
        },{})

        const params = {
            per_page: state.pageSize,
            page: state.page + 1,
            sorted: JSON.stringify(sorted)
        };
        if(typeof this.props.notShowLoading === 'undefined' || this.state.data.length === 0) {
          this.setState({ loading: true });
        }
        if (this.props.fetchDataCallback) {
            this.props.fetchDataCallback(params)
                .then(response => {
                    this.setState({
                        data: response.data,
                        pages: this.extractPagesFromPageData(response.extra),
                        loading: false
                    });
                })
        }
    }

    reloadData() {
        this.refReactTable.fireFetchData();
    }

    render() {
        let {expandedRows} = this.state;
        if(typeof this.props.isDefaultExpanded === 'function') {
          this.state.data.forEach((data,  index)=>{
            if(this.props.isDefaultExpanded(data)){
              if(typeof expandedRows[index] === 'undefined') {
                expandedRows[index] = {};
              }
            }
          })
        }
        return (
            <div>
                <ReactTable
                    ref={(refReactTable) => {this.refReactTable = refReactTable;}}
                    PaginationComponent={Pagination}
                    manual
                    sortable={false}
                    columns={this.props.columns}
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    defaultPageSize={this.maxPagesRowsCount}
                    minRows={this.defaultPageRowsCount}
                    onFetchData={this.fetchData}
                    loadingText={'Cargando datos...'}
                    noDataText={'No hay datos que mostrar'}
                    className="-striped -highlight pro-data-table"
                    SubComponent={this.props.SubComponent}
                    expanded={expandedRows}
                    onExpandedChange={(newExpanded, index) => {
                      this.setState({
                        ...this.state,
                        expandedRows: newExpanded,
                      });
                      return true;
                    }}
                />
            </div>
        );
    }
}

export default DataTable;
