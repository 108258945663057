import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import {Permissions} from '@autofact/operation-lib-model-notary-platform';
import {endpoints} from '../../config';
import AuthService from "../../services/AuthService";
import PrivateRoute from '../../components/private-route/PrivateRoute';
import PropsRoute from '../../components/props-route/PropsRoute';
import {MainLayout} from '../../theme/index';

import Home from '../../pages/home/Home';
import Login from '../../pages/login/Login';
import Logout from '../../pages/logout/Logout';
import TransferenceList from '../../pages/transference-list/TransferenceList';
import IdentityList from '../../pages/identity-list/IdentityList';
import NotaryMultiMeeting from "../../pages/notary-multimeeting"
import FirmaDocumentosList from '../../pages/firma-documentos-list/FirmaDocumentosList';
import FirmaDocumentosFisicosList from '../../pages/firma-documentos-physic-list/FirmaDocumentosFisicosList';
import TransferenceDetail from '../../pages/transference-detail/TransferenceDetail';
import FirmasDocumentosDetail from '../../pages/firmas-documentos-detail/FirmasDocumentosDetail';
import FirmasDocumentosFisicosDetail from '../../pages/firmas-documentos-fisicos-detail/FirmasDocumentosFisicosDetail';
import PreAdmissionList from '../../pages/pre-admission-list/PreAdmissionList';
import RedirectExternal from '../../components/redirect-external/RedirectExternal';
import NotFound from '../../pages/not-found/NotFound';
import Forbidden from '../../pages/forbidden/Forbidden';
import PreAdmissionDetail from '../../pages/pre-admission-detail/PreAdmissionDetail';
import PreAdmissionCreation from '../../pages/pre-admission-creation/PreAdmissionCreation';
import PreAdmissionEdition from '../../pages/pre-admission-edition/PreAdmissionEdition';
import AssignMassiveRepertory from '../../pages/assign-masive-repertory/AssignMassiveRepertory';
import TransferenceMassiveSign from '../../pages/transference-massive-sign/TransferenceMassiveSign';
import DocumentsSignMassiveSign from '../../pages/documents-sign-massive-sign/DocumentsSignMassiveSign';
import Preferences from '../../pages/preferences/Preferences';
import NotaryDashboard from '../../pages/notary-dashboard/NotaryDashboard';
import NotaryBuySellProcess from '../../pages/notary-buy-sell-process/NotaryBuySellProcess';
import NotaryTransference from '../../pages/notary-transfence/NotaryTransference';
import TransferenceSignatureMassiveRepresentation from '../../pages/transference-signature-massive-representation/TransferenceSignatureMassiveRepresentation';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false
        };

        this.setIsAuthenticated = this.setIsAuthenticated.bind(this);
    }

    componentWillMount() {
        AuthService.getAuthData().then(authData => {
            if (authData) {
                this.setIsAuthenticated(true);
            }
            else {
                this.setIsAuthenticated(false);
            }
        });
    }

    setIsAuthenticated(isLoggedIn) {
        this.setState({isLoggedIn});
    }

    render() {
        return (
            <BrowserRouter>
              <Switch>
              <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/tele-presencia/multi-meeting" exact component={NotaryMultiMeeting} permission={Permissions.TELE_PRESENCE_JOIN_MULTIPLE_MEETINGS} />
                <MainLayout isLoggedIn={this.state.isLoggedIn}>
                        <Route path="/" exact component={Home}/>
                        <PropsRoute path="/login" exact component={Login} setIsAuthenticated={this.setIsAuthenticated}/>
                        <Route path='/external-login' component={() => window.location = endpoints.autofactProApi.url}/>
                        <PropsRoute path="/logout" exact component={Logout} setIsAuthenticated={this.setIsAuthenticated}/>
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/tele-presencia/:code?" exact component={IdentityList} permission={Permissions.TRANSFERENCE_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencias" exact component={TransferenceList} permission={Permissions.TRANSFERENCE_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencia/ver/:code" exact component={TransferenceDetail} permission={Permissions.TRANSFERENCE_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/preingresos" exact component={PreAdmissionList} permission={Permissions.PRE_ADMISSION_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/preingreso/ver/:id" exact component={PreAdmissionDetail} permission={Permissions.PRE_ADMISSION_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/preingreso/registrar" exact component={PreAdmissionCreation} permission={Permissions.PRE_ADMISSION_CREATE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/preingreso/editar/:id" exact component={PreAdmissionEdition} permission={Permissions.PRE_ADMISSION_CREATE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencias/asignar-repertorio-masivo" exact component={AssignMassiveRepertory} permission={Permissions.TRANSFERENCE_REPERTORY_EDIT} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencias/firma-masiva" exact component={TransferenceMassiveSign} permission={Permissions.TRANSFERENCE_SIGN} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencias/firma-masiva-estado/:operationId" exact component={TransferenceMassiveSign} permission={Permissions.TRANSFERENCE_SIGN} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencias/firma-masiva-representacion-estado/:operationId" exact component={TransferenceSignatureMassiveRepresentation} permission={Permissions.TRANSFERENCE_REPRESENTATION_SIGNATURE_NOTARY_OFFICE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/preferencias" exact component={Preferences} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/firma-documentos" exact component={FirmaDocumentosList} permission={Permissions.SIGN_DOCUMENT_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/firma-documentos/firma-masiva/:scope?" exact component={DocumentsSignMassiveSign} permission={Permissions.TRANSFERENCE_SIGN} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/firma-documentos/firma-masiva-estado/:operationId" exact component={DocumentsSignMassiveSign} permission={Permissions.TRANSFERENCE_SIGN} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/firma-documentos/ver/:id" exact component={FirmasDocumentosDetail} permission={Permissions.TRANSFERENCE_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/firma-documentos-fisicos" exact component={FirmaDocumentosFisicosList} permission={Permissions.SIGN_DOCUMENT_PHYSIC_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/firma-documentos-fisicos/ver/:id" exact component={FirmasDocumentosFisicosDetail} permission={Permissions.SIGN_DOCUMENT_PHYSIC_SEE} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/notaria" exact component={NotaryDashboard} permission={Permissions.NOTARY_CONTRACT_SIGN} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/notaria/nueva-compraventa/:code" exact component={NotaryBuySellProcess} permission={Permissions.NOTARY_CONTRACT_ADD} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/notaria/ver/:code" exact component={NotaryTransference} permission={Permissions.NOTARY_CONTRACT_ADD} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/notaria/modificar-compraventa/:code" exact component={NotaryBuySellProcess} permission={Permissions.NOTARY_CONTRACT_ADD} />
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/transferencias/firma-masiva-representacion" exact component={TransferenceSignatureMassiveRepresentation} permission={Permissions.TRANSFERENCE_REPRESENTATION_SIGNATURE_NOTARY_OFFICE} />

                        <Route path="/redireccionando" exact component={RedirectExternal}/>
                        <Route path="/403" exact component={Forbidden}/>
                        <Route path="*" component={NotFound}/>
                </MainLayout>
              </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
