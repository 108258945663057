import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

const titleByType = {
    info: 'Información',
    primary: 'Información',
    secondary: 'Información',
    success: 'Felicidades',
    danger: 'Error',
    warning: 'Advertencia',
};

const textColorByType = {
    info: 'text-white',
    primary: 'text-white',
    secondary: 'text-white',
    success: 'text-white',
    danger: 'text-white',
    warning: 'text-dark',
};

class ModalMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        const messageType = this.props.messageType || 'secondary';
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                   centered autoFocus backdrop={'static'}>
                <ModalHeader toggle={this.toggle}
                             className={`bg-${messageType} ${textColorByType[messageType]}`}>
                    {titleByType[messageType]}
                </ModalHeader>
                <ModalBody>
                    {this.props.message}
                </ModalBody>
            </Modal>
        );
    }
}

export default ModalMessage;
