import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TransferenceSignatureMassiveRepresentationForm from "../../components/transference-signature-massive-representation-form/TransferenceSignatureMassiveRepresentationForm";

class TransferenceSignatureMassiveRepresentation extends Component {
    render() {
        return (
            <div>
                <div className="page-title">
                    <FontAwesomeIcon icon="list-alt" alt="Icon" className="icon-title" />
                    <h2 className="text-title">Firma masiva de contratos en Representación</h2>
                </div>
                <div className="main-wrapper-page">
                    <TransferenceSignatureMassiveRepresentationForm operationId={this.props.match.params.operationId} />
                </div>
            </div>);
    }
}

export default withRouter(TransferenceSignatureMassiveRepresentation);
