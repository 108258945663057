import React, {Component} from 'react';
import {withRouter} from 'react-router';
import NotaryForm from '../../components/notary-form/NotaryForm';

class Preferences extends Component {
    render() {
        return (
            <div className="main-wrapper-page">
                <h2 className="page-title">Preferencias</h2>
                <NotaryForm/>
            </div>
        );
    }
}

export default withRouter(Preferences);
