import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {formats} from '../../config';
import DataTable from '../data-table/DataTable';
import BackendApiService from "../../services/BackendApiService";

class FirmaDocumentosList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            activeTab: '',
            exportResult: '',
            exporting: false,
            params: this.props.filters || {},
        }
        this.selectRow = this.selectRow.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({activeTab: props.id});
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    async exportData(format, code) {
        const params = {
            where: {},
            format: format
        };
        const exportResult = await BackendApiService.getFirmasDocumentosList(params);
        return exportResult.data;
    }

    download(format, code) {
        this.setState({exporting: true});
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format, code)
            .then((response) => {
                link.href = response.url;
                link.click();
                this.setState({exporting: false});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({exporting: false});
            });
    }
    selectRow(e, item){
        if(typeof this.props.selectRow === 'function'){
            this.props.selectRow(item.id, item, e.target.checked);
        }
    }
    render() {
        const columns = [];
        if (this.state.activeTab === 'forApproval') {
            columns.push(this.renderCheckBoxColumn());
        }
        columns.push(this.renderIdColumn());
        columns.push(this.renderCreatedDateColumn());
        columns.push(this.renderCompanyColumn());
        if(['mandateForSign', 'forSign'].includes(this.state.activeTab ) === false) {
            columns.push(this.renderIsMandateColumn());
        }
        columns.push(this.renderViewColumn())
        columns.push(this.renderStatusColumn())
        
        if (this.props.approving) {
            columns.push(this.renderApprovalStatusColumn())
        }
        const selectedCount = Object.keys(this.props.selected).length;
        return (
            <div className={`firmas-notarios-list-wrapper`}>
                {
                    selectedCount > 0 && <h5 className="mb-0 ml-2">{selectedCount} seleccionado(s)</h5>
                }
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    maxPagesRowsCount={50}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }

    renderCheckBoxColumn = () =>{
        return {
            Header: <input type="checkbox" value={'all'} onChange={(e)=> this.selectRow(e, 'all')} 
                        checked={this.props.selectedAll} disabled={this.props.approving}/>,
            accessor: 'id',
            width: 40,
            Cell: row => {
                return <input type="checkbox" value={row.original.idtransferencia}
                              onChange={(e) => this.selectRow(e, row.original)} disabled={this.props.approving}
                              checked={this.props.selected.hasOwnProperty(row.original.id)}/>;
            }
        }
    }
    renderCompanyColumn = () =>{
        return  {
            Header: 'Empresa',
            accessor: 'company',
            Cell: row => {
                const subsidiary = row.original.subsidiary || {};
                const company = subsidiary.company || {};
                return company.name || '';
            }
        };
    }
    renderIsMandateColumn = () => {
        return  {
            Header: 'Es mandato',
            accessor: 'company',
            Cell: row => {
                return row.original.extern_source === 'transference' ? 'SI': 'NO' ;
            }
        };
    }
    renderCreatedDateColumn = () => {
        return {
            Header: 'Fecha de creación',
            accessor: 'timestamp',
            Cell: row => {
                let date = moment(row.original.timestamp);
                return (
                    <span className="pre-line">{date.format(formats.date)}</span>
                )
            }
        }
    }
    renderIdColumn = () => {
        return {
            Header: 'ID',
            accessor: 'id',
        }
    }

    renderViewColumn = () => {
        return {
            Header: 'Ver',
            accesor: 'idtransferencia',
            Cell: row => {
                const {original: data} = row;
                return (
                    <span key={`detail-link-span-${data.id}`}>
                        <Link key={`detail-link-${data.id}`}
                              className="btn-primary-darker btn-see-link"
                              to={`firma-documentos${data.tipo_firma_idtipo_firma === 3?'-fisicos':''}/ver/${row.original.id}`}>VER</Link>
                    </span>
                );
            }
        }
    }
    renderStatusColumn = () => {
        return {
            Header: 'Estado',
            accesor: 'estado',
            Cell: row => {
                const signStatus = row.original.sign_status || {};
                return (
                    <span key={`status-${row.original.id}`}
                          className={`transference-status transference-status-sm transference-status-${signStatus}`}>
                            {signStatus.name || 'Desconocido'}
                        </span>
                );
            }
        }
    }
    renderApprovalStatusColumn = () => {
        return {
            Header: 'Estado (Aprobación)',
            accesor: 'estado',
            Cell: row => {
                const status = this.props.approvingData[row.original.id];
                if (status) {
                    const mapStatusStyles = {
                        pending: { colorClass: '', text: 'PENDIENTE' },
                        inProgress: { colorClass: 'transference-status-0', text: 'EN PROGRESO ...' },
                        success: { colorClass: 'transference-status-1', text: 'APROBADO' },
                        failed: { colorClass: 'bg-red', text: 'ERROR' },
                    }
                    return (
                        <span className={`transference-status transference-status-sm ${mapStatusStyles[status].colorClass}`}>{
                            mapStatusStyles[status].text}
                        </span>
                    );
                } else {
                    return ''
                }
            }
        }
    }
}


export default FirmaDocumentosList;
