import React from 'react';
import Grid from '@material-ui/core/Grid';
import ImageViewer from './ImageViewer';
import StepsIndicator from './StepsIndicator';
import {makeStyles} from '@material-ui/core';
import VideoViewer from './VideoViewer';
import MeetViewer from './MeetViewer';
import {isAvailableStep, STEP_NAMES} from "./api";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  }
}))

const SummaryPanel = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <StepsIndicator data={data} />
      <Grid container spacing={3} className={`extra-margin-bottom ${classes.container}`}>

        {isAvailableStep(data, STEP_NAMES.PREVIEW_CAPTURE_PHOTO) && <Grid item xs={3}>
          <ImageViewer title={'Cédula'} img={data.cardFront ?? ''} />
        </Grid>}
        <Grid item xs={3} >
          <ImageViewer title={'Foto'} img={data.facePhoto ?? ''} />
        </Grid>
        <Grid item xs={3}>
          <ImageViewer title={'Firma'} img={data.handwrittenSignature ?? ''} />
        </Grid>
        {data?.captureVideo && <Grid item xs={3}>
          <VideoViewer title={'Video'} url={data.captureVideo} />
        </Grid>}
        {isAvailableStep(data, STEP_NAMES.CAPTURE_VIDEO_NOTARY) && <Grid className='grid-relative' item xs={3}>
          <MeetViewer title={'Captura de video'} code={data.code} data={data} />
        </Grid>}
      </Grid>
    </>
  )
};

export default SummaryPanel;
