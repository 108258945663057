import React, {Component} from 'react';
import {Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Alert} from 'reactstrap';
import BackendApiService from '../../services/BackendApiService';
import PdfBrowser from '../pdf-browser/PdfBrowser';
import DocumentSelector from '../firma-documentos-document-selector/DocumentSelector';
import FirmaDocumentosActionsContainer from '../firma-documentos-actions-container/FirmaDocumentosActionsContainer';
import ActivityList from '../activity-list/ActivityList';

class FirmaDocumentosFisicosDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signDocumentData: null,
            documentSelected: null,
            activeTab: 'documentSelector',
            loading: true,
            isMessageVisible: false,
            message:''
        };

        this.documentSelectedHandler = this.documentSelectedHandler.bind(this);
        this.showSuccesMessage       = this.showSuccesMessage.bind(this);
        this.showErrorMessage        = this.showErrorMessage.bind(this);
        this.onDismiss               = this.onDismiss.bind(this);
    }

    async componentDidMount() {
       this.loadFirmasDocumentosData();
    }
    async loadFirmasDocumentosData(){
        try {
            const signDocumentData = await BackendApiService.getFirmasDocumentosData(this.props.id, true);

            if (signDocumentData) {
                //Process transference data
                let documentSelected = null;
                if (signDocumentData.sign_documents.length > 0) {
                    documentSelected = signDocumentData.sign_documents[0].document.path;
                }
                this.setState({loading: false, signDocumentData, documentSelected});
            }
            else {
                this.setState({loading: false, signDocumentData: null});
            }
        }
        catch (e) {
            console.error(e);
            this.setState({loading: false, signDocumentData: null});
        }
    }

    onDismiss() {
        this.setState({ isMessageVisible: false });
    }

    showSuccesMessage(message, forceReload = false) {
        this.setState({
            message: {
                success: true,
                text: message
            },
            isMessageVisible: true,
            loading: forceReload
        })
        if(forceReload){
            this.loadFirmasDocumentosData();
        }

    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    documentSelectedHandler(document) {
        this.setState({documentSelected: `${document.path}?t=${Date.now()}`});
    }

    render() {
        if (this.state.loading) {
            return (<div>Cargando datos</div>);
        }
        if (!this.state.signDocumentData) {
            return (<div>Identificador inválido o no tiene permisos a este documento.</div>);
        }
        const signStatus = this.state.signDocumentData.sign_status || {};

        return (<div>
            {this.state.isMessageVisible && <Alert className="mt-2" color={this.state.message.success?'success':'danger'} isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
            }
            <div>{signStatus.name}</div>
            <Row>
                <Col sm={8}>
                    {this.state.documentSelected ?
                        <PdfBrowser src={`${this.state.documentSelected}`}/>
                        :
                        <span>Select a document</span>
                    }
                </Col>
                <Col sm={4}>
                    <FirmaDocumentosActionsContainer
                      signDocumentData={this.state.signDocumentData}
                      showSuccesMessage={this.showSuccesMessage}
                      showErrorMessage={this.showErrorMessage}
                    />
                    <Nav tabs className="sidebar-tabs">
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 'documentSelector' ? 'active' : ''}
                                onClick={() => this.toggleTab('documentSelector')}
                            >
                                Documentos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 'activityList' ? 'active' : ''}
                                onClick={() => this.toggleTab('activityList')}
                            >
                                Actividad
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="documentSelector" className="transference-document-list-tab-wrapper">
                            <DocumentSelector documentList={this.state.signDocumentData.sign_documents} onDocumentSelect={this.documentSelectedHandler}/>
                        </TabPane>
                        <TabPane tabId="activityList" className="transference-activity-list-tab-wrapper">
                            <ActivityList
                              activityList={this.state.signDocumentData.SignActivities  }
                              titleProperty={  'title' }
                              idProperty={  'id' }
                              timestampProperty={ 'timestamp'  }
                              descriptionProperty={ 'description'  }
                            />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </div>);
    }
}

export default FirmaDocumentosFisicosDetailContainer;
