import React, {Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import DashBoardSummaryComponent from '../dashboard-summary-component/DashBoardSummaryComponent.js'
import DashBoardRequestUpComponent from '../dashboard-request-up-component/DashBoardRequestUpComponent'
import DashBoardStatisticsComponent from '../dashboard-statistics-component/DashBoardStatisticsComponent.js'

const tabsDefinition = {
    summary: {
        id: 'summary',
        title: 'Su resumen de trabajo',
        componentName:DashBoardSummaryComponent
    },
    statistics: {
        id: 'statistics',
        title: 'ESTADÍSTICAS',
        componentName:DashBoardStatisticsComponent
    },
    request_upload: {
        id: 'request_upload',
        title: 'SUBIR SOLICITUDES',
        componentName:DashBoardRequestUpComponent
    },
};

class DashBoardHeaderContainer extends Component{
    constructor(props) {
        super(props);

        this.state = {
            activeTab: tabsDefinition.summary.id,
        };
    }

    async componentDidMount() {
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const activeTab = this.state.activeTab;

        return <div className={'mb-5'}>
             <Nav tabs className="tabs-light">
            {Object.keys(tabsDefinition).map((key, index) => {
                const tab = tabsDefinition[key];
                return (
                    <NavItem key={`tab-nav-item-${index}`}>
                        <NavLink
                            key={`tab-nav-${index}`}
                            className={activeTab === tab.id ? 'active' : ''}
                            onClick={() => this.toggleTab(tab.id)}
                        >
                            {tab.title}
                        </NavLink>
                    </NavItem>
                );
            })}
        </Nav>
        <TabContent activeTab={activeTab}>
            {Object.keys(tabsDefinition).map((key, index) => {
                const tab = tabsDefinition[key];
                return (
                    <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                        {activeTab === tab.id ?
                            <tab.componentName
                                id={tab.id}
                                tab={tab.id}
                                key={`tab-list-${index}`}
                                showMore={this.props.showMore}
                                showSuccesMessage={this.props.showSuccesMessage}
                                showErrorMessage={this.props.showErrorMessage}
                            />
                            :
                            <div key={`tab-loading-${index}`}>Cargando</div>
                        }
                    </TabPane>
                );
            })}
        </TabContent>
        </div>
    }
}

export default DashBoardHeaderContainer;
