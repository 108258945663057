import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Alert } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';

class FirmasDocumentosApproveButton extends Component {

    constructor(props){
        super(props);
        this.state = {
            rejectComment: '',
            alertMessage: {},
            isMessageVisible: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }


    onChangeHandler = (event) => {
        const stateToChange = {};
        stateToChange[event.target.id] = event.target.value;
        this.setState(stateToChange);
    }

    handleSubmit = async () => {
        if(this.state.rejectComment === '') {
            this.setState({
                alertMessage: {
                    text: 'Campo comentario es obligatorio',
                    success: false,
                },
                isMessageVisible: true,
            });
            console.error('falta comentario');
            return;
        }

        const response = await BackendApiService.rejectDocument(
            this.props.id,
            this.state.rejectComment
            );

        if (!response) {
            this.setState({
                alertMessage: {
                    text: 'Algo falló',
                    success: false,
                },
                isMessageVisible: true,
            });
        }

        setTimeout(function(){
            window.location.reload();
        }, 1500);
    }

    render(){
        let message = null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={'danger'}
                             isOpen={this.state.isMessageVisible}>
                {this.state.alertMessage.text}
            </Alert>
        }

        return (
            <React.Fragment>
                <Button onClick={this.toggle} color='danger'>
                    {this.state.reject ? 'RECHAZANDO...':'RECHAZAR'}
                    <i className='glyphicon glyphicon-remove-sign text-icon' />
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    { message }
                    <ModalHeader toggle={this.toggle}>Rechazo Firma Documento</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="rejectComment">Comentario</Label>
                            <Input type="textarea"
                                name="rejectComment"
                                id="rejectComment"
                                value={this.state.rejectComment} onChange={this.onChangeHandler} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleSubmit}>Rechazar</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FirmasDocumentosApproveButton;
