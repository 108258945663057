import React, { Component } from 'react';
import {makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CardContent from "@material-ui/core/CardContent";
import Placeholder from "./Placeholder";
import {IdentityMeeting} from '@autofact/identity-meet-react-component';
import BackendApiService from '../../services/BackendApiService'
import AuthService from '../../services/AuthService'
import {Permissions} from '@autofact/operation-lib-model-notary-platform';
import {isCompletedStep, STEP_NAMES} from './api'
import {endpoints} from '../../config'
import _ from 'lodash';

const messages = {
  OK: 'NOTARY-OK',
  REJECT: 'NOTARY-REJECT',
  NOK: 'NOTARY-NOK',
}

const STATUS_NOTARY_ASSIGN_TELEPRESENCE = {
  ASSIGNED: 'assigned',
  REJECTED_NOTARY: 'rejected_notary',
  CANCELLED_PLATFORM: 'cancelled_platform',
  ACCEPTED_NOTARY: 'accepted_notary',
  ASSIGNED_NOTARY_WITHOUT_CAPACITY: 'assigned_notary_without_capacity',
  NOT_THERE_NOTARY: 'not_there_notary',
};

const RELATIONS_STATUS_NOTARY_ASSIGN_TELEPRESENCE = {
  [messages.OK]: STATUS_NOTARY_ASSIGN_TELEPRESENCE.ACCEPTED_NOTARY,
  [messages.REJECT]: STATUS_NOTARY_ASSIGN_TELEPRESENCE.REJECTED_NOTARY,
  [messages.NOK]: STATUS_NOTARY_ASSIGN_TELEPRESENCE.NOT_THERE_NOTARY,
};

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%'
  },
  cardContent: {
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

class MeetViewer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      meetingInfo: null,
      loading: false,
      finished: false,
      started: false,
      code: ''
    }
    this.sendCode = this.sendCode.bind(this);
    this.finishMeet = this.finishMeet.bind(this);
    this.meetingFinished = this.meetingFinished.bind(this);
    this.startMeet = this.startMeet.bind(this);
  }


  componentDidMount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
        this.props.title !== nextProps.title ||
        this.props.code !== nextProps.code ||
        !_.isEqual(this.props.data, nextProps.data) ||
        !_.isEqual(this.state.meetingInfo, nextState.meetingInfo) ||
        this.state.loading !== nextState.loading ||
        this.state.finished !== nextState.finished ||
        this.state.started !== nextState.started ||
        this.state.code !== nextState.code
    ) {
        return true;
    }
    return false;
}

  startMeet = () => {
    this.setState({loading: true, meetingInfo: null, started: true})
    BackendApiService.getIdentityMeet(this.props.code).then(meetingInfo=>{
      this.setState({
        meetingInfo: meetingInfo,
        loading: false
      })
    }).catch(error=>{
      this.setState({
        loading: false
      })
    })
  }

  sendCode = (msg) => {
      this.setState({code: msg});
  }

  finishMeet = (res) => {
    if(res === 'CLIENT-OK'){

      const msg = this.state.code;
      const meetingId = this.state?.meetingInfo?.Meeting?.MeetingId;
      console.log("Try finish meeting:",{ meetingId });
      BackendApiService.finishIdentityMeet(meetingId)
        .then(() => {
          console.log("finish ok", { meetingId });
        })
        .catch((e) => console.log("finish not ok", { meetingId }));

      if([messages.NOK, messages.REJECT].includes(msg)) {
        BackendApiService.rejectIdentity(this.props.code).then(()=>{console.log('rejected ok')}).catch((e)=>console.log('rejected not ok'))
      }

    const params = {
      identityId: this.props.data.id,
      status: RELATIONS_STATUS_NOTARY_ASSIGN_TELEPRESENCE[msg],
    };
    BackendApiService.updateStatusNotaryAssignTelepresence(params).then(() => { console.log('updateStatusNotaryAssignTelepresence ok') }).catch((e) => console.log('updateStatusNotaryAssignTelepresence not ok'));
    this.setState({
      meetingInfo: null,
      finished: true,
    });
    }
  }
  meetingFinished = (file) => {
    this.setState({
      meetingInfo: null,
      finished: true
    });
      }

  renderMeet = () => {
    const {loading, meetingInfo, finished, started} = this.state;
    const tokenExpired = this.props.data.tokenExpired
    const canStart = AuthService.canDo(Permissions.TELE_PRESENCE_START_MEET)
    const isCompleted = isCompletedStep(STEP_NAMES.CAPTURE_VIDEO_NOTARY, this.props.data)

    if(loading) {
      return <Placeholder label={'Cargando'}/>
    }else if(meetingInfo){
      return (
      <Grid container className='full-height' >
        <Grid item xs={12} className='full-height'>
          <IdentityMeeting
            meetingInfo={meetingInfo}
            onMeetingFinished={this.meetingFinished}
            localRecording={true}
            remoteRecording={true}
            uploadUrl={`${endpoints.meeting.url}meetings/${this.props.code}/recording`}
            code={this.state.code}
            onMessageCode={(m) => this.finishMeet(m)}
          />
        </Grid>
          <div className='card-grid-action-bar'>
            <Button variant="contained" color="primary" onClick={(e)=>this.sendCode(messages.OK)}>
              Aceptar
            </Button>
            <Button variant="contained" color="secondary" onClick={(e)=>this.sendCode(messages.REJECT)}>
                Rechazar
            </Button>
        </div>
      </Grid>
      )
    }else if(finished){
      return <Placeholder label={'Conexion terminada'}/>;
    }else if(isCompleted){
      return <Grid>
        <Placeholder label={'Conversacion completada'}/>
      </Grid>
    } else if(!started && canStart && !tokenExpired){
      return <Grid>
        <Placeholder label={'Debe unirse a la conversacion'}/>
         <div className='card-grid-action-bar'>
          <Button variant="contained" color="primary" onClick={this.startMeet}>
            Unirse
          </Button>
         </div>
      </Grid>
    }else{
      return <Placeholder label={'No se pudo cargar el componente'}/>;
    }
  }
  render() {
    return (
      <Card elevation={5} style={useStyles.card} className='full-height'>
        <CardContent style={useStyles.cardContent} className='full-height'>
          {this.renderMeet()}
        </CardContent>
      </Card>
    )
  }
}


export default MeetViewer;
