
class AmmountHelper {

    static toNumber(value = ''){
        try {
            if(value){
                const stringValue = value.replaceAll('$', '').replaceAll('.', '');
                return Number(stringValue);
            }
            return value;
        } catch (error) {
            return value;
        }

    }

    static higherValue(first = 0, second = 0){
        try {
            if( first && second){
                return Math.max(this.toNumber(first),this.toNumber(second));
            }
            return null
        } catch (error) {
            return null;
        }
    }

    static calculateTax(first, second){
        try {
            
            const higher = this.higherValue(this.toNumber(first), this.toNumber(second));
            if(higher){
                const tax = higher * parseFloat(1.5) / 100;
                return Math.round(tax);
            }
            return null;
        } catch (error) {
            return null
        }
    }

    static formatCurrency(value) {
        const formatter = new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP'
        });
      
        return formatter.format(value);
      }

}

export default AmmountHelper;