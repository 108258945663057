import { useState, useEffect } from 'react'
import { Row, Form } from 'reactstrap'
import CirculationPermission from '../notary-information-validation/circulation-permission/CirculationPermission';
import BackendApiService from "../../services/BackendApiService";
import AlertCAVRequest from '../notary-information-validation/alert-cav-request/AlertCAVRequest';
import ClientsRut from '../notary-information-validation/ClientsRut/ClientsRut';
import SalePrice from '../notary-information-validation/sale-price/SalePrice';
import { app } from '../../config';
import rutHelper from '../../helpers/rutHelper';

const InformationStep = (props) => {
  const { data, updateData, updatePretransfer, updateCavCode, updateBuyerIsCompany } = props;
  const { cavCode, setCavCode} = updateCavCode;
  const { validationForm, contractForm, ticket, code } = data;

  const [cavDelivered, setCavDelivered] = useState(false);
  const [taxesResult, setTaxesResult] = useState(false);
  const [reportDone, setReportDone] = useState(false);
  const [isFirstTry, setIsFirstTry] = useState(true);
  const [cavTryCount, setCavTryCount] = useState(0);

  useEffect(() => {

    if(!validationForm.cavStatus){
      if(isFirstTry){
        waitForDelivering(ticket);
        setIsFirstTry(false);
      } else {
        if(ticket !== "" && !cavDelivered){
          setTimeout(() => {
            waitForDelivering(ticket);
          }, app.msToRequesCavStatus);
        }
      }
    } else {
      setCavDelivered(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cavTryCount, isFirstTry])

  useEffect(()=>{
    if(cavDelivered && cavCode && cavCode !== "" && !validationForm.cavStatus) {
        getReport(cavCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cavCode]);

  const waitForDelivering = async (ticket) => {
    try {
      const response = await BackendApiService.GetCAVRequestStatus(ticket);
      if(!response || !response.status){
          throw new Error("GetCAVRequestStatus Error: Couldn't get data");
      }

      if(response.data.status === app.cavDeliveredText){
        setCavDelivered(true);
        const index = response.data.products.findIndex(el => el?.tipoProducto === "informe");
        setCavCode(response.data.products[index].externalId);
      }
      else {
        setCavDelivered(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCavTryCount(cavTryCount + 1);
    }
}

const getReport = async (code) => {
    try {

    if(code && code !== ""){
      const response = await BackendApiService.getReport(cavCode);

      if(!response){
      throw new Error("getReport Error: Couldn't get data");
      }
      console.log("Report obtained successfully");
      const informe =  response;
      const owner = informe.propietarios.at(-1);
      const newContractForm = contractForm;
      const newValidationForm = validationForm;
      const ruts = owner.rut.split(";");
      const names = owner.nombre.split(";");

      ruts.forEach((rut, indx) => {
        const exist =  newContractForm.people.sellers.sellers.findIndex(el=> el.identityCode === rut);
        if(exist === -1) {
          newContractForm.people.sellers.sellers.push({
            fullName: names[indx],
            identityCode: rut.replaceAll('.', '').replaceAll('-', ''),
            acquisitionDate: owner.fechaAdquisicion,
            registrationDate: owner.fechaInscripcion,
            number: owner.numero,
            repertoire: owner.repertorio,
            sex: owner.genero,
            isCompany: rutHelper.isCompanyRut(rut)
          });
        }
      });

      newContractForm.plate = informe.informacionLegal.patente;
      newContractForm.vehicle.brand = informe.informacionMecanica.marca;
      newContractForm.vehicle.model = informe.informacionMecanica.modelo;
      newContractForm.vehicle.cleanModel = informe.informacionMecanica.modeloLimpio;
      newContractForm.vehicle.year = informe.informacionMecanica.anno;
      newContractForm.vehicle.chassisNumber = informe.informacionMecanica.numeroChasis;
      newContractForm.vehicle.motorNumber = informe.informacionMecanica.numeroMotor;
      newContractForm.vehicle.color = informe.informacionMecanica.color ?? '';
      newContractForm.vehicle.fuel = informe.informacionMecanica.combustible ?? '';
      newContractForm.vehicle.version = informe.informacionMecanica.version ?? '';
      newValidationForm.theftCharge = informe.informacionLegal.encargoRobo;
      newValidationForm.domainLimitation = informe.informacionLegal.limitaciones ? true : false;
      newValidationForm.annotation = informe.informacionLegal.observaciones ? true : false;
      newValidationForm.fines = informe.informacionLegal.tieneMultasAnotadas ? true : false;
      newValidationForm.cavStatus = true;
      updateAllData(newValidationForm, newContractForm);
      updatePretransfer();
      setReportDone(true);
    }
    else {
        throw new Error("getReport Error: Report code not found: ", code);
    }
    
    } catch (error) {
      console.log(error);
    }
}

  
  const callbackRuts = (childData) => {
      const newContractForm = contractForm;
      newContractForm.people.buyers.buyers = childData;
      updateAllData(null, newContractForm);
  }

  const callbackPriceAndTaxes = (childData) => {
      setTaxesResult(childData);
      const contract = contractForm;
      contract.vehicle = { ...contractForm.vehicle, ...childData };
      updateAllData(null, contract);
  }

  const callbackCirculationPermission = (childData) => {
    const newContractForm = contractForm;
    newContractForm.vehicle.circulationPermission = { ...contractForm.vehicle.circulationPermission, ...childData };
    updateAllData(null, newContractForm);
  }

  const updateAllData = (validation, contract) => {
    updateData(validation, contract);
  }

  return (
    <div>
      <Row>
        <AlertCAVRequest success={cavDelivered}/>
        <div className='w-100 gy-1'>
          <h1 className='my-4'> Validación de información </h1>
          <Form className="p-4 bg-white shadow-sm rounded ">
              <CirculationPermission
                data={ {
                  ...contractForm.vehicle.circulationPermission,
                  code
                }}
                updateData={callbackCirculationPermission}
                updatePretransfer={updatePretransfer}
              />
              <ClientsRut
                updateRuts={callbackRuts}
                ruts={contractForm.people.buyers.buyers}
                buyerCompany={validationForm?.buyerCompany || false}
                updateBuyerIsCompany={updateBuyerIsCompany}
              />
              <SalePrice
                vehicleData={data.contractForm}
                isCavOk={validationForm.cavStatus || reportDone}
                updateTaxes={callbackPriceAndTaxes}
                taxesResult={taxesResult}
                updatePretransfer={updatePretransfer}
              />
          </Form>
        </div>
      </Row>
    </div>
  )
}

export default InformationStep