import React, {Component} from 'react';
import {Alert} from 'reactstrap';
import {Redirect} from "react-router-dom";


import PreAdmissionForm from "../../components/pre-admission-form/PreAdmissionForm";
import BackendApiService from '../../services/BackendApiService'

class PreAdmissionCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMessageVisible: false,
            message:'',
            redirect: false
        };

        this.showSuccesMessage       = this.showSuccesMessage.bind(this);
        this.showErrorMessage        = this.showErrorMessage.bind(this);
        this.onDismiss               = this.onDismiss.bind(this);
        this.createPreAdmision               = this.createPreAdmision.bind(this);
    }
    onDismiss() {
        this.setState({ isMessageVisible: false });
    }

    showSuccesMessage(message) {
        this.setState({
            message: {
                success: true,
                text: message
            }, isMessageVisible: true
        })
    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    async createPreAdmision(data){
        console.log("registrando preadmision")
        console.log(data);

        const dataKeyMap = {
            plate: 'patente',
            agreementPrice: 'precio_convenio',
            proprietaryRUT: 'propietario_rut',
            proprietaryQuality: 'propietario_calidad',
            proprietaryCommunity: 'propietario_comuneros',
            acquirerRUT: 'adquiriente_rut',
            acquirerQuality: 'adquiriente_calidad',
            acquirerCommunity: 'adquiriente_comuneros',
            addressStreet: 'calle',
            addressNumber: 'calle_numero',
            addressRest: 'adquiriente_resto_domicilio',
            addressCommunity: 'comuna_codigo',
            addressTelephone: 'telefono',
            documentNumber: 'documento_numero',
            documentDate: 'documento_fecha'
        }
        let dataMap = {};
        for(let key in data){
            if(dataKeyMap.hasOwnProperty(key)){
                dataMap[dataKeyMap[key]] = data[key];
            }
        }
        try{
            const response = await BackendApiService.savePreAdmisionManual(dataMap)
            if(response === false){
                this.showErrorMessage("Ocurrio un error al registrar el preingreso");
            }else{
                this.showSuccesMessage("Preingreso guardado con éxito");
                this.setState({redirect: true})
            }

        }catch (e){
            this.showErrorMessage("Ocurrio un error al registrar el preingreso");
        }

    }
    render() {
        if(this.state.redirect){
            return <Redirect to='/preingresos' />
        }else{
            return (<div className="main-wrapper-page">
                <h2 className="page-title">Registrar nuevo preingreso</h2>
                {this.state.isMessageVisible && <Alert className="mt-2" color={this.state.message.success?'success':'danger'} isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                    {this.state.message.text}
                </Alert>
                }
                <PreAdmissionForm
                    saveHandler={this.createPreAdmision}
                />
            </div>);
        }

    }
}

export default PreAdmissionCreation;
