import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import AuthService from "../../services/AuthService";

class Logout extends Component {

    componentWillMount() {
        AuthService.logout();
        this.props.setIsAuthenticated(false);
    }

    render() {
        return (
            <div>
                Login out...
                <Redirect to={'/'}/>
            </div>
        );
    }
}

export default Logout;
