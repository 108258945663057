import { useState, useEffect } from "react";
import NotaryContractNaturalPersonForm from "../notary-contract-forms/NotaryContractNaturalPersonForm";
import { Button, Form, UncontrolledCollapse } from "reactstrap";
import NotaryContractVehicleForm from "../notary-contract-forms/NotaryContractVehicleForm";
import NotaryContractRepresentativeForm from "../notary-contract-forms/NotaryContractRepresentativeForm";
import NotaryContractCompanyForm from "../notary-contract-forms/NotaryContractCompanyForm";
import NotaryContractLegalRepresentativeForm from "../notary-contract-forms/NotaryContractLegalRepresentativeForm";
import BackendApiService from "../../services/BackendApiService";
import { ArrowDropDownCircle } from "@mui/icons-material";
import rutHelper from "../../helpers/rutHelper";
const ContractFillStep = ({data, updateData, createOrUpdatetransfer}) => {
    const { contractForm, code, isContractUpdate } = data;
    const { plate, vehicle, people, payment } = contractForm;
    const { buyers, sellers } = people;
    const { sellers: sellersArray, representatives: sellersRepresentatives } = sellers;
    const { buyers: buyersArray, representatives: buyersRepresentative } = buyers;
    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [sellerOpen, setSellerOpen] = useState(true);
    const [buyerOpen, setBuyerOpen] = useState(true);
    const [carOpen, setCarOpen] = useState(true);
    
    useEffect( () => {
        const loadLocations = async() => {
            const regionsList = await BackendApiService.getRegionsList();
            const communesList = await BackendApiService.getCommunesListForSelect();
            setRegions(regionsList);
            setCommunes(communesList);
        }
        loadLocations();
    },[plate])

    useEffect(()=>{
        window.scrollTo(0, 0);
        window.onclick = (event) => {
            if(event.target.id === "submit-transfer-button"){
                setSellerOpen(true);
                setBuyerOpen(true);
                setCarOpen(true);
                document.getElementById("hidde-submit").click();
            }
        }
    },[])

    const uploadFile = async (file, type) => {
        const url = await BackendApiService.uploadFile(file, code, type);
        return url;
    }
    
    const updatePerson = (form, type, subtype, index) => {
        const contract = contractForm;
        const tipo = type === 'vendedor' ? 'sellers' : 'buyers';
        const subtipo = subtype === 'persona' ? tipo : 'representatives';
        if(contract.people[tipo][subtipo].length > 0){
            contract.people[tipo][subtipo][index] = form;
            updateData( null, contract);
        }
    }

    const updateCar = (form) => {
        const contract = contractForm;
        contract.vehicle = form;
        updateData(null, contract);
    }

    const updatePayment = (arr) => {
        const contract = contractForm;
        contract.payment = arr;
        updateData(null, contract);
    }
    
    return(
        <div className='d-flex flex-column w-100'>
            <div className='m-5'>
                <div className='d-flex justify-content-between'>
                <span className='text-26 text-dark font-weight-bold'>Creación de contrato de compra-venta</span>
                <span className='text-primary-dark font-weight-bolder text-20'>Vehículo: {plate}</span>
                </div>
                <div className='contract-creation-box mt-3'>
                    <div className='w-100 gy-1'>
                        <Form className="p-2" onSubmit={ createOrUpdatetransfer } id="transference-form">
                            <div className=' d-flex align-items-center title-box-step-validation px-2 mb-3 pointer' id="seller-toggler" onClick={()=>{setSellerOpen(!sellerOpen)}}>
                                <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
                                <span className='font-weight-bold my-1'>1</span>
                                </div>
                                <span className='h1 dark-color font-weight-bolder ml-2 mb-0'>Información del vendedor</span>
                                <ArrowDropDownCircle className="ml-auto text-primary-dark" />
                            </div>
                            <UncontrolledCollapse toggler="#seller-toggler" isOpen={sellerOpen}>
                                {sellersArray.map((item, indx) => 
                                    <div key={`sellers-${indx}`}>
                                        { rutHelper.isCompanyRut(sellersArray[0].identityCode) ?
                                            <NotaryContractCompanyForm  
                                                key={`company-seller-${indx}-form`}
                                                type="vendedor" 
                                                company={item} 
                                                number={indx}  
                                                regions={regions} 
                                                updateData={updatePerson} 
                                                uploadFile={uploadFile}
                                            />
                                            :
                                            <NotaryContractNaturalPersonForm 
                                                key={`person-sellers-${indx}-form`}
                                                type="vendedor"
                                                person={item}
                                                number={indx}
                                                regions={regions}
                                                updatePerson={updatePerson}
                                                uploadFile={uploadFile}
                                                isContractUpdate={isContractUpdate}
                                                hasRepresentatives={ sellersRepresentatives.length > 0 }
                                            />
                                        }
                                    </div>
                                )}
                                {sellersRepresentatives.map((item, indx) => 
                                    <div key={`sellers-representatives-${indx}`}>
                                    { rutHelper.isCompanyRut(sellersArray[0].identityCode) ?
                                        <NotaryContractLegalRepresentativeForm
                                            key={`legal-representative-sellers-${indx}-form`}
                                            person={item} 
                                            number={indx} 
                                            type="vendedor"
                                            communes={communes} 
                                            companyInDay={sellers?.sellers[0].companyInDay} 
                                            updatePerson={updatePerson} 
                                            uploadFile={uploadFile}
                                        />
                                    :
                                        <NotaryContractRepresentativeForm 
                                            key={`representative-sellers-${indx}-form`}
                                            type="vendedor" 
                                            person={item} 
                                            number={indx} 
                                            communes={communes} 
                                            updatePerson={updatePerson} 
                                            uploadFile={uploadFile} 
                                            isContractUpdate={isContractUpdate}
                                        />
                                    }
                                    </div>
                                )}
                            </UncontrolledCollapse>
                            <div className=' d-flex align-items-center title-box-step-validation px-2 mb-3 mt-4 pointer' id="buyer-toggler" onClick={ ()=> setBuyerOpen(!buyerOpen)}>
                                <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
                                <span className='font-weight-bold my-1'>2</span>
                                </div>
                                <span className='h1 dark-color font-weight-bolder ml-2 mb-0'>Información del comprador</span>
                                <ArrowDropDownCircle className="ml-auto text-primary-dark" />
                            </div>
                            <UncontrolledCollapse toggler="#buyer-toggler" isOpen={buyerOpen}>
                                {buyersArray.map((item, indx) =>
                                <div key={`buyers-${indx}`}>
                                    { item.isCompany ?
                                        <NotaryContractCompanyForm  
                                            key={`company-buyer-${indx}-form`}
                                            type="comprador" 
                                            company={item} 
                                            number={indx}  
                                            regions={regions} 
                                            updateData={updatePerson} 
                                            uploadFile={uploadFile}
                                        />
                                        :
                                        <NotaryContractNaturalPersonForm 
                                            key={`person-buyer-${indx}-form`}
                                            type="comprador"
                                            person={item}
                                            number={indx}
                                            regions={regions}
                                            updatePerson={updatePerson}
                                            uploadFile={uploadFile}
                                            isContractUpdate={isContractUpdate}
                                            hasRepresentatives={ buyersRepresentative.length > 0 }
                                        />
                                    }
                                </div>
                                )}
                                {buyersRepresentative.map((item, indx) =>
                                <div key={`buyers-representatives-${indx}`}>
                                    { buyersArray[0].isCompany ?
                                        <NotaryContractLegalRepresentativeForm
                                            key={`legal-representative-buyer-${indx}-form`}
                                            person={item} 
                                            number={indx}
                                            type="comprador"
                                            communes={communes} 
                                            companyInDay={buyersArray[0].companyInDay} 
                                            updatePerson={updatePerson} 
                                            uploadFile={uploadFile}
                                        />
                                        :
                                        <NotaryContractRepresentativeForm 
                                            key={`representative-buyer-${indx}-form`}
                                            type="comprador" 
                                            person={item} 
                                            number={indx} 
                                            communes={communes} 
                                            updatePerson={updatePerson} 
                                            uploadFile={uploadFile} 
                                            isContractUpdate={isContractUpdate}
                                        />
                                    }
                                </div>
                                )}
                            </UncontrolledCollapse>
                            <div className=' d-flex align-items-center title-box-step-validation px-2 mb-3 mt-4 pointer' id="vehicle-toggler" onClick={()=> setCarOpen(!carOpen)}>
                                <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
                                <span className='font-weight-bold my-1'>2</span>
                                </div>
                                <span className='h1 dark-color font-weight-bolder ml-2 mb-0'>Información del vehículo</span>
                                <ArrowDropDownCircle className="ml-auto text-primary-dark" />
                            </div>
                            <UncontrolledCollapse toggler="#vehicle-toggler" isOpen={carOpen}>
                                <NotaryContractVehicleForm 
                                    car={vehicle} 
                                    plate={plate}
                                    payment={payment}
                                    communes={communes}
                                    uploadFile={uploadFile} 
                                    updateData={updateCar}
                                    updatePay={updatePayment}
                                />
                            </UncontrolledCollapse>
                            <Button type="submit" id="hidde-submit" hidden>run</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractFillStep;