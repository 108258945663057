import React, { Component } from 'react';
import { Button } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';
import IdentityStatusModal from '../identity-status-modal/IdentityStatusModal';

class TransferenceIdentityButton extends Component {

    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            flows: [],
            dataLoaded: false
        }
    }

    openModal = () =>{
        this.setState({showModal: true, dataLoaded: false})
        BackendApiService.getIdentityStatus(this.props.code).then(response=>{
            const state = {...this.state};
            state.flows = response;
            state.dataLoaded = true;
            this.setState(state)
        }).catch(console.error)
    }
    cancelModal = () =>{
        this.setState({showModal: false})
    }
    render(){
        const {flows, dataLoaded} = this.state;
        return (
            <div className="mb-2">
                {this.state.showModal && <IdentityStatusModal
                  open={this.state.showModal}
                  flows={flows}
                  dataLoaded={dataLoaded}
                  handleClose={this.cancelModal}
                />}
                <Button  onClick={this.openModal} color='primary-dark' block >
                    Ver proceso de firma
                </Button>
            </div>
        );
    }
}

export default TransferenceIdentityButton;
