import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackendApiService from '../../../services/BackendApiService';

const AlertCAVConditions = (props) => {

    const { validations, pretransferCode, aceptCondition, isOpen } = props;
    
    const [people, setPeople] = useState([]);
    const [limitations, setLimitations] = useState(false);
    const [theftCharge, setTheftCharge] = useState(false);
    const [withDebt, setWithDebt] = useState(false);
    const [withFines, setWithFines] = useState(false);

    useEffect(() => {
        if(validations.foodDebt.people.data?.people){
            setPeople(validations.foodDebt.people.data.people);
        }
        if(validations.domainLimitation){
            setLimitations(validations.domainLimitation);
        }
        if(validations.theftCharge){
            setTheftCharge(validations.theftCharge);
        }
        if(validations.fines){
            setWithFines(validations.fines);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        const checkPeopleDebt = (personArray) => {
            return personArray.some(person => person.cantidadVigentes === true);
        };

        setWithDebt(checkPeopleDebt(people));  
    }, [people]);
        
    const confirm = () => {
        aceptCondition()
    }

    const cancel = () =>{
        props.history.replace("/notaria");
    }

    
    const archive = async () => {
        try {
            const resp = await BackendApiService.archivePretransfer(pretransferCode);

            if(!resp || resp.affectedRows === 0){
                throw new Error('No se pudo archivar la pretransferencia');
            }

            props.history.replace("/notaria");
        } catch (error) {
            alert(error.message);
        }
    }
    
    return(
        <Modal isOpen={ isOpen } size="lg" toggle={() => props.closeModal()}>
            <div className='modal-header bg-primary-darker text-white'> 
                <p className='text-white bold h2 my-auto'>{
                    limitations || theftCharge ? 'Alerta de condiciones del CAV' : withDebt ? 'Deuda de pensión alimenticia' : 'Aceptación de condiciones del CAV'
                }</p>
                <div className="close-icon" role={'button'} onClick={() => props.closeModal()}>
                    <FontAwesomeIcon icon='times-circle' />
                </div>
            </div>
            <ModalBody className="p-4 mt-3 text-center h1 orange-color">
                {
                    (limitations || theftCharge) ? (
                        <div className='d-flex flex-column align-items-center gray9-color'>
                            <FontAwesomeIcon icon="exclamation-circle" className='exclamation-red'/>
                            <p className='text-24 mt-4 bold'> La patente registra: </p>
                            <ul className='text-left bold'>
                            { limitations && <li>Anotaciones vigentes incorporadas al registro</li> }
                            { theftCharge && <li>Encargo por robo</li> }
                            </ul>
                            <p className='mt-5'>Estas condiciones no permiten continuar con el proceso</p>                            
                        </div>
                    ) : 
                    (
                        <div>
                            {
                            (withDebt || withFines) && (
                                    <div>
                                        <FontAwesomeIcon icon="exclamation-circle" className='orange-color'/>
                                        {withDebt && <p className='bold my-3'>{withFines ? '1. ' : ''} Al menos un RUT registra deuda de pensión alimenticia</p>}
                                        {withFines && <p className='bold my-3'>{withDebt ? '2. ' : ''} La placa patente registra multas</p>}
                                    </div>
                                )
                            }
                            <p>
                                ¿Se acepta el contrato de compra-venta con la información y las condiciones que el certificado de anotaciones vigentes CAV y 
                                el registro nacional de deudores de pensión de alimentos presenta?
                            </p>               
                        </div>
                    )
                }
            </ModalBody>
            <ModalFooter className={`d-flex justify-content-center mb-5 ${(limitations || theftCharge) ? '' : 'mt-3'} `}>
                {
                    limitations || theftCharge ? <Button color="primary-dark bold text-uppercase" onClick={() => archive()}>Archivar</Button>
                    : 
                    <>
                        <button  className="btn btn-outline-primary-dark rounded bold text-uppercase" onClick={ cancel }> No, finalizar proceso</button>
                        <button className='btn btn-primary-dark bold text-uppercase' onClick={confirm}>si, aceptar condiciones</button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )
}

export default AlertCAVConditions;