import React, { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import { getTransferenceStatusFromId, getTransferenceTypeFromId } from '@autofact/operation-lib-model-notary-platform';
import BackendApiService from '../../services/BackendApiService';
import PdfBrowser from '../pdf-browser/PdfBrowser';
import DocumentSelector from '../document-selector/DocumentSelector';
import ActivityList from '../activity-list/ActivityList';
import ActionsContainer from '../transference-actions-container/TransferenceActionsContainer';
import { TRANSFERENCE_CONFIG_STATUS } from '../../helpers/TransferenceConfigHelper';

class TransferenceDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transferenceData: null,
            documentSelected: null,
            activeTab: 'documentSelector',
            loading: true,
            isMessageVisible: false,
            message: '',
            isNotarialFlowRepresentation: false,
        };

        this.documentSelectedHandler = this.documentSelectedHandler.bind(this);
        this.showSuccesMessage = this.showSuccesMessage.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    async componentDidMount() {
        await this.loadTransferenceData();
    }
    async loadTransferenceData() {
        try {
            const transferenceData = await BackendApiService.getTransferenceData(this.props.code);
            console.log('transferenceData', transferenceData);
            if (transferenceData) {
                //Process transference data
                let documentSelected = null;
                if (transferenceData.documents.length > 0) {
                    documentSelected = transferenceData.documents[0].ruta_fisica;
                }
                const isNotarialFlowRepresentation = this.isNotarialFlowRepresentation(transferenceData);
                console.log('isNotarialFlowRepresentation', isNotarialFlowRepresentation);
                this.setState({ loading: false, transferenceData, documentSelected, isNotarialFlowRepresentation });
            }
            else {
                this.setState({ loading: false, transferenceData: null, isNotarialFlowRepresentation: false });
            }
        }
        catch (e) {
            console.error(e);
            this.setState({ loading: false, transferenceData: null, isNotarialFlowRepresentation: false });
        }
    }

    onDismiss() {
        this.setState({ isMessageVisible: false });
    }

    async showSuccesMessage(message, forceReload = false) {
        this.setState({
            message: {
                success: true,
                text: message
            },
            isMessageVisible: true,
            loading: forceReload
        })
        if (forceReload) {
            await this.loadTransferenceData();
        }

    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    documentSelectedHandler(document) {
        this.setState({ documentSelected: document.ruta_fisica });
    }

    isNotarialFlowRepresentation(transferData) {
        const { notarialFlowRepresentationConfig } = transferData;
        if (!notarialFlowRepresentationConfig || Object.keys(notarialFlowRepresentationConfig).length === 0) {
            return false;
        }
        const { estado: status } = notarialFlowRepresentationConfig;
        if (status !== TRANSFERENCE_CONFIG_STATUS.ACTIVE) {
            return false;
        }
        return true;
    }

    render() {
        if (this.state.loading) {
            return (<div>Cargando datos</div>);
        }
        if (!this.state.transferenceData) {
            return (<div>Código inválido o no tiene permisos a esta transferencia.</div>);
        }

        console.log('this.state.transferenceData.tipo', this.state.transferenceData.tipo)
        const TransferenceType = getTransferenceTypeFromId(this.state.transferenceData.tipo);
        console.log('TransferenceType', TransferenceType);
        const CurrentTransferenceStatus = getTransferenceStatusFromId(this.state.transferenceData.estado);
        console.log('CurrentTransferenceStatus', CurrentTransferenceStatus);
        return (<div>
            {this.state.isMessageVisible && <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'} isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
            }
            <div>{TransferenceType.label} <span>{this.state.transferenceData.patente}</span></div>
            <div>{CurrentTransferenceStatus.label}</div>
            <Row>
                <Col sm={8}>
                    {this.state.documentSelected ?
                        <PdfBrowser src={`${this.state.documentSelected}?t=${Date.now()}`} />
                        :
                        <span>Select a document</span>
                    }
                </Col>
                <Col sm={4}>
                    <ActionsContainer
                        transferenceData={this.state.transferenceData}
                        showSuccesMessage={this.showSuccesMessage}
                        showErrorMessage={this.showErrorMessage}
                        showPreAdmisionDocument={this.documentSelectedHandler}
                    />
                    <Nav tabs className="sidebar-tabs">
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 'documentSelector' ? 'active' : ''}
                                onClick={() => this.toggleTab('documentSelector')}
                            >
                                Documentos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === 'activityList' ? 'active' : ''}
                                onClick={() => this.toggleTab('activityList')}
                            >
                                Actividad
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="documentSelector" className="transference-document-list-tab-wrapper">
                            <DocumentSelector documentList={this.state.transferenceData.documents} isNotarialFlowRepresentation={this.state.isNotarialFlowRepresentation} onDocumentSelect={this.documentSelectedHandler} transferStatus={this.state.transferenceData.estado} subsidiaryRepresentationFlow={this.state.transferenceData.subsidiaryRepresentationFlow} />
                        </TabPane>
                        <TabPane tabId="activityList" className="transference-activity-list-tab-wrapper">
                            <ActivityList activityList={this.state.transferenceData.activity} />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </div>);
    }
}

export default TransferenceDetailContainer;
