import AuthService from '../../services/AuthService';

const CheckAccess = ({children, permission}) => {
    return (
        permission && AuthService.canDo(permission) ?
            children
            :
            []
    );
};

export default CheckAccess;
