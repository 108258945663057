import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { app } from '../../../config';
import rutHelper from '../../../helpers/rutHelper';

const BuyerInformation = (props) => {
  
  const { buyersData, representativesData, modals, isContractUpdate } = props;
  const { buyers, selectBuyer, buyerType } = buyersData;
  const { representatives, selectARepresentative} = representativesData;
  const { openModalForBuyer, openModalForReprensentative, openModalRemove } = modals;

  const startRemovingRepresentative = (representative, index) => {
    selectARepresentative({index, ...representative });
    openModalRemove(app.personType.buyerRepresentative);
  };

  const editButtonRepresentativeClick = (representative, index) => {
    selectARepresentative({index, ...representative});
    openModalForReprensentative();
  }

  const startRemovingBuyer = (buyer, index) => {
    selectBuyer({index, ...buyer });
    openModalRemove(app.personType.buyer);
  };

  const editButtonBuyerClick = (buyer, index) => {
    selectBuyer({index, ...buyer});
    openModalForBuyer();
  }

  const buyerInformationJSX = () => {
     return buyers.map((buyer, index) => {
      return(
        <div className='mt-4' key={`representative-${index}`}>
          {index >= 1 && <hr className="flex-grow-1 my-4" /> }
          <span className='text-muted font-weight-bold text-14'>
            {buyerType === app.buyerType.company ? 'Empresa' : buyerType === app.buyerType.naturalPerson? `Comprador ${index + 1}` : buyerType === app.buyerType.buyFor? 'Información del tercero (para)': ''}
          </span>
          <div className='row mt-4 text-16'>
            <div className='col-lg-2 col-md-6'>
              <p className='text-14 m-0'>Rut:</p>
              <p className='bold m-0'> {rutHelper.toDotedRut(buyer.identityCode)}</p>
            </div>
            <div className='col-lg-2 col-md-6'>
              <p className='text-14 m-0'>{buyer.isCompany ? 'Razón Social' : 'Nombres'}:</p>
              <p className='bold m-0'> {buyer.name}</p>
            </div>

          {
            !buyer.isCompany &&
            <>
              <div className='col-lg-2 col-md-6'>
                <p className='text-14 m-0'>Apellido Paterno:</p>
                <p className='bold m-0'> {buyer.fatherLastName}</p>
              </div>
              <div className='col-lg-2 col-md-6'>
                <p className='text-14 m-0'>Apellido Materno:</p>
                <p className='bold m-0'> {buyer.motherLastName}</p>
              </div>
            </>
          }
            <div className={`${buyer.isCompany? 'offset-xl-5 offset-lg-4' : 'offset-xl-1'} col-xl-3 col-lg-4 col-md-12`} >
              <button type='button' className='btn btn-sm border-0 btn-edit bold' onClick={() => editButtonBuyerClick(buyer,index)}> 
                <FontAwesomeIcon icon='pen' className='mr-3'/>Editar
              </button>
              <button type='button' className='btn btn-sm border-0 btn-remove bold' onClick={() => startRemovingBuyer(null, index)} disabled={(buyer.isCompany && representatives.length >= 1 && !isContractUpdate)} >
                <FontAwesomeIcon icon='trash' className='mr-3'/>Eliminar 
              </button>
            </div>
          </div>
        </div>
      )
    })
  }

  const representativeInformationJSX = () => {
    return representatives.map((representative, index) => {
      return (
        <div className='mt-4' key={`representative-${index}`}>
          { index >= 1 && <hr className="flex-grow-1 my-4" /> }
          <span className='text-muted font-weight-bold text-14'>
            {buyerType === app.buyerType.company ? `Información del representante ${index + 1}` : 'Información de quién compra'}
          </span>
          <div className='row mt-4 text-16'>
            <div className='col-lg-2 col-md-6'>
              <p className='text-14 m-0'>Rut:</p>
              <p className='bold m-0'> {rutHelper.toDotedRut(representative.identityCode)}</p>
            </div>
            <div className='col-lg-2 col-md-6'>
              <p className='text-14 m-0'>Nombres:</p>
              <p className='bold m-0'> {representative.name}</p>
            </div>
            <div className='col-lg-2 col-md-6'>
              <p className='text-14 m-0'>Apellido Paterno:</p>
              <p className='bold m-0'> {representative.fatherLastName}</p>
            </div>
            <div className='col-lg-2 col-md-6'>
              <p className='text-14 m-0'>Apellido Materno:</p>
              <p className='bold m-0'> {representative.motherLastName}</p>
            </div>
            <div className='col-xl-3 col-lg-4 offset-xl-1 col-md-12'>
              <button type='button' className='btn btn-sm border-0 btn-edit bold' onClick={() => editButtonRepresentativeClick(representative,index)}> 
                <FontAwesomeIcon icon='pen' className='mr-3'/>Editar
              </button>
              <button type='button' className='btn btn-sm border-0 btn-remove bold' onClick={() => startRemovingRepresentative(null, index)} disabled={buyerType === (app.buyerType.buyFor && buyers.length >= 1 && !isContractUpdate)} > 
                <FontAwesomeIcon icon='trash' className='mr-3'/>Eliminar 
              </button>
            </div>
          </div>
        </div>
      )
    })
  };

  return (
    <div className='mt-4'>
      {(buyerType === app.buyerType.naturalPerson || buyerType === app.buyerType.company) && buyerInformationJSX()}
      {buyerType === app.buyerType.buyFor && representativeInformationJSX()}
  
      <div className={buyers.length >= 1 ? 'mt-4' : ''}>
        {(buyers.length && representatives.length) ? <hr className="flex-grow-1 my-4" /> : null}
        {buyerType === app.buyerType.company && representativeInformationJSX()}
        {buyerType === app.buyerType.buyFor && buyerInformationJSX()}
      </div>
    </div>
  );
}

export default BuyerInformation