import React, {Component} from 'react';
import {PreAdmissionPersonQuality,getPreAdmissionPersonQualityFromId} from '@autofact/operation-lib-model-notary-platform';
import {Form, FormGroup, Label, Input, Row, Col, Button, ButtonGroup} from 'reactstrap';
import {Alert} from 'reactstrap';
import BackendApiService from "../../services/BackendApiService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import Rut from 'rutjs'
import PropTypes from "prop-types";
import moment from 'moment';

class PreAdmissionForm extends Component {
    constructor(props) {
        super(props);
        let date = '';
        if(this.props.preAdmission.documento_fecha){
            date = moment.utc(this.props.preAdmission.documento_fecha).format('YYYY-MM-DD')
        }
        this.state = {
            plate: this.props.preAdmission.patente || '',
            agreementPrice: this.props.preAdmission.precio_convenio|| '',
            proprietaryRUT: this.props.preAdmission.propietario_rut||'',
            proprietaryQuality: this.props.preAdmission.propietario_calidad||'',
            proprietaryCommunity: this.props.preAdmission.propietario_comuneros || 0,
            acquirerRUT: this.props.preAdmission.adquiriente_rut||'',
            acquirerQuality: this.props.preAdmission.adquiriente_calidad||'',
            acquirerCommunity: this.props.preAdmission.adquiriente_comuneros|| 0,
            addressStreet: this.props.preAdmission.calle||'',
            addressNumber: this.props.preAdmission.calle_numero||'',
            addressRest: this.props.preAdmission.adquiriente_resto_domicilio||'',
            addressCommunity: this.props.preAdmission.comuna_codigo||'',
            addressTelephone: this.props.preAdmission.telefono||'',
            documentNumber: this.props.preAdmission.documento_numero||'',
            documentDate: date,
            communeList: [],
            inProcess: false,
            comuneFetchFinished:false
        };
        this.config = {
            validators:{
                plate: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                agreementPrice: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                proprietaryRUT: [{func: this.isNotEmpty, message: 'El campo no es válido'},{func:this.isValidRut,message:'No es un rut válido'}],
                proprietaryQuality: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                proprietaryCommunity: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                acquirerRUT: [{func: this.isNotEmpty, message: 'El campo no es válido'},{func:this.isValidRut,message:'No es un rut válido'}],
                acquirerQuality: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                acquirerCommunity: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                addressStreet: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                addressNumber: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                addressCommunity: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                addressTelephone: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                documentNumber: [{func: this.isNotEmpty, message: 'El campo no es válido'}],
                documentDate: [{func: this.isNotEmpty, message: 'El campo no es válido'}],

            }
        }

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.onSaveHandler = this.onSaveHandler.bind(this);
        this.isValidRut = this.isValidRut.bind(this);
        this.isNotEmpty = this.isNotEmpty.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    isValid(){
        const keys = Object.keys(this.config.validators);
        let isValid = true;
        keys.forEach(key=>{
            const validate = this.validateInput(key);
            isValid = isValid && validate.valid;
        })
        return isValid;
    }
    validateInput(input){
        const validate = {
            valid: true,
            message:''
        }
        const validators = this.config.validators[input] || [];
        const _this = this;
        for(let pos = 0 ;  pos < validators.length && validate.valid ; pos ++){
            const validator = validators[pos];
            if(typeof validator.func == 'function'){
                if(!validator.func.apply(_this,[input])){
                    validate.valid = false;
                    validate.message = validator.message;
                }
            }
        }
        return validate;
    }
    isNotEmpty(input){
        return this.state[input] !== '';
    }

    isValidRut(input){
        var rutObject = new Rut(this.state[input]);
        return rutObject.isValid;
    }
    showInputStatus(input){
        const validate = this.validateInput(input);
        if(validate.valid){
            return null
        }else{
            return <div className="text-danger">{validate.message}</div>
        }

    }

    componentDidMount() {
        BackendApiService.getCommunesListForSelect().then(communeList => {
            let firstId = '';
            if(communeList.length > 0){
                firstId = communeList[0].codigo_postal
            }

            this.setState({comuneFetchFinished:true,communeList, addressCommunity:this.props.preAdmission.comuna_codigo || firstId})
        });
        const firsPreAdmisionPersonQuality = getPreAdmissionPersonQualityFromId(1);
        if(firsPreAdmisionPersonQuality){
            this.setState({
                'proprietaryQuality':this.props.preAdmission.propietario_calidad || firsPreAdmisionPersonQuality.id,
                'acquirerQuality':this.props.preAdmission.adquiriente_calidad || firsPreAdmisionPersonQuality.id})
        }
    }

    onFocusHandler(event){

    }
    onChangeHandler(event) {
        const stateToChange = {};
        stateToChange[event.target.id] = event.target.value;
        this.setState(stateToChange);
    }
    async onSaveHandler(event){
        event.preventDefault();
        this.setState({inProcess: true})
        try{
            await this.props.saveHandler(this.state)
        }catch (e){
            this.setState({inProcess: false})
        }
    }

    render() {
        const personQualityOptions = [];
        for (const quality in PreAdmissionPersonQuality) {
            if (PreAdmissionPersonQuality.hasOwnProperty(quality)) {
                personQualityOptions.push(<option key={quality} value={PreAdmissionPersonQuality[quality].id}>{PreAdmissionPersonQuality[quality].label}</option>);
            }
        }
        const isValidForm = this.isValid();

        return (<Form className="pre-admission-form">
            <Row className="field-row">
                {!isValidForm && <Col sm={12}><Alert className="mt-2" color={'danger'} isOpen={!isValidForm}>
                    El formulario contiene errores
                </Alert></Col>}
            </Row>
            <Row className="field-row">

                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="plate" className="text-right">Patente</Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="plate"
                                id="plate"
                                placeholder="AA.0000-0"
                                className="form-control-sm"
                                value={this.state.plate}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('plate')}

                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="agreementPrice" className="text-right">Precio convenio</Label>
                        <Col sm={10}>
                            <Input type="number"
                                   name="agreementPrice"
                                   id="agreementPrice"
                                   className="form-control-sm"
                                   value={this.state.agreementPrice}
                                   onChange={this.onChangeHandler}
                                   onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('agreementPrice')}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="field-row">
                <Col sm={4}>
                    <FormGroup row>
                        <Label sm={3} for="proprietaryRUT" className="text-right">Run Propietario</Label>
                        <Col sm={9}>
                            <Input type="text"
                                   name="proprietaryRUT"
                                   id="proprietaryRUT"
                                   className="form-control-sm"
                                   value={this.state.proprietaryRUT}
                                   onChange={this.onChangeHandler}
                                   onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('proprietaryRUT')}
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup row>
                        <Label sm={3} for="proprietaryQuality" className="text-right pl-0">Calidad Propietario</Label>
                        <Col sm={9}>
                            <Input type="select"
                                   name="proprietaryQuality"
                                   id="proprietaryQuality"
                                   className="form-control-sm"
                                   value={this.state.proprietaryQuality}
                                   onChange={this.onChangeHandler}
                                   onFocus={this.onFocusHandler}
                            >
                                {personQualityOptions}
                            </Input>
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup row>
                        <Label sm={3} for="proprietaryCommunity" className="text-right">Comuneros</Label>
                        <Col sm={9}>
                            <Input
                                type="number"
                                name="proprietaryCommunity"
                                id="proprietaryCommunity"
                                className="form-control-sm"
                                value={this.state.proprietaryCommunity}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('proprietaryCommunity')}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="field-row">
                <Col sm={4}>
                    <FormGroup row>
                        <Label sm={3} for="acquirerRUT" className="text-right">Run Adquiriente</Label>
                        <Col sm={9}>
                            <Input
                                type="text"
                                name="acquirerRUT"
                                id="acquirerRUT"
                                className="form-control-sm"
                                value={this.state.acquirerRUT}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('acquirerRUT')}
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup row>
                        <Label sm={3} for="acquirerQuality" className="text-right pl-0">Calidad Adquiriente</Label>
                        <Col sm={9}>
                            <Input
                                type="select"
                                name="acquirerQuality"
                                id="acquirerQuality"
                                className="form-control-sm"
                                value={this.state.acquirerQuality}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            >
                                {personQualityOptions}
                            </Input>
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup row>
                        <Label sm={3} for="acquirerCommunity" className="text-right">Comuneros</Label>
                        <Col sm={9}>
                            <Input
                                type="number"
                                name="acquirerCommunity"
                                id="acquirerCommunity"
                                className="form-control-sm"
                                value={this.state.acquirerCommunity}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('acquirerCommunity')}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="field-row">
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="addressStreet" className="text-right">Calle</Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="addressStreet"
                                id="addressStreet"
                                className="form-control-sm"
                                value={this.state.addressStreet}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('addressStreet')}
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="addressNumber" className="text-right">Número Calle</Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="addressNumber"
                                id="addressNumber"
                                placeholder="S/N o Número de la calle"
                                className="form-control-sm"
                                value={this.state.addressNumber}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('addressNumber')}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="field-row">
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="addressRest" className="text-right">Resto Domicilio</Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="addressRest"
                                id="addressRest"
                                className="form-control-sm"
                                value={this.state.addressRest}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('addressRest')}
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="addressCommunity" className="text-right">Comuna</Label>
                        <Col sm={10}>
                            <Input type="select" name="addressCommunity" id="addressCommunity"
                                   className="form-control-sm" value={this.state.addressCommunity} onChange={this.onChangeHandler} >
                                {this.state.communeList.map((commune, index) => {
                                    return <option key={index} value={commune.codigo_postal}>{commune.nombre}</option>
                                })}
                            </Input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="field-row">
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="addressTelephone" className="text-right">Teléfono</Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="addressTelephone"
                                id="addressTelephone"
                                className="form-control-sm"
                                value={this.state.addressTelephone}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('addressTelephone')}
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={6} />
            </Row>
            <Row className="field-row">
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="documentNumber" className="text-right">Número Documento</Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                name="documentNumber" id="documentNumber"
                                className="form-control-sm"
                                value={this.state.documentNumber}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('documentNumber')}
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup row>
                        <Label sm={2} for="documentDate" className="text-right">Fecha Documento</Label>
                        <Col sm={10}>
                            <Input
                                type="date"
                                name="documentDate"
                                id="documentDate"
                                className="form-control-sm"
                                value={this.state.documentDate}
                                onChange={this.onChangeHandler}
                                onFocus={this.onFocusHandler}
                            />
                            {this.showInputStatus('documentDate')}
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="field-row">
                <ButtonGroup>

                    {!this.state.inProcess && this.state.comuneFetchFinished && <Button
                        className="btn btn-sm btn-primary mr-4"
                        tag={Link}
                        to="/preingresos"
                    >
                        Cancelar
                    </Button> }
                    {!this.state.comuneFetchFinished && <span
                        className="btn btn-sm btn-primary mr-4"
                    >
                        Cancelar
                    </span> }


                    {!this.state.inProcess && <Button
                        className="btn btn-sm btn-primary-dark"
                        onClick={this.onSaveHandler}
                        disabled={!isValidForm}
                    >
                        {this.props.saveText}
                    </Button> }
                    {this.state.inProcess && <Button
                        color="secondary"
                        className="btn btn-sm"
                        onClick={this.onSaveHandler}
                    >
                        <FontAwesomeIcon icon="spinner" spin/><span>Guardando...</span>
                    </Button> }
                </ButtonGroup>
            </Row>
        </Form>);
    }
}
PreAdmissionForm.defaultProps = {
    preAdmission: {},
    saveText:"Guardar"
}

PreAdmissionForm.propsType = {
    preAdmission: PropTypes.object,
    saveText: PropTypes.string,
}
export default PreAdmissionForm;
