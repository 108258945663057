import React, { Component } from 'react';
import { Button } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';

class TransferenceApproveButton extends Component {

    constructor(props){
        super(props);
        this.approveTransference = this.approveTransference.bind(this);
        this.state = {
            approving: false
        }
    }

    approveTransference() {
        this.setState({approving: true});
        BackendApiService.approveTransference(this.props.code)
            .then(() => {
                window.location.reload();
            })
            .catch(error => {
                alert('error aprobando transferencia');
                console.error(error);
            })
            .finally(() => this.setState({approving: false}));
    }

    render(){
        return (
            <Button disabled={this.state.approving} onClick={this.approveTransference} color='success'>
                {this.state.approving ? 'APROBANDO...':'APROBAR'}
                <i className='glyphicon glyphicon-remove-sign text-icon' />
            </Button>
        );
    }
}

export default TransferenceApproveButton;
