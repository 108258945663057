import React, { Component } from 'react';
import { Button } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';

class TransferenceRegisterPreAdmissionButton extends Component {

    constructor(props){
        super(props);
        this.state = {
            inProcess: false
        }
        this.registerPreAdmission = this.registerPreAdmission.bind(this);
    }

    registerPreAdmission() {
        this.setState({inProcess: true})
        BackendApiService.registerPreAdmision(this.props.code).then(response=>{
            this.setState({inProcess: false})
            if(response  === false){
                this.props.showErrorMessage("Ocurrió un error al registrar el preingreso")
            }else{
                this.props.showSuccesMessage("Preingreso registrado con éxito",true)
            }
        }).catch(error=>{
            this.setState({inProcess: false})
            console.log(error);
            this.props.showErrorMessage("Ocurrió un error al registrar el preingreso")
        })
    }

    render(){
        let button = <Button onClick={this.registerPreAdmission} color='primary'>
            Registrar Preingreso
            <i className='glyphicon glyphicon-log-in text-icon'></i>
        </Button>;
        if(this.state.inProcess){
            button = <Button color='secondary'>
                Registrando preingreso
                <i className='glyphicon glyphicon-refresh'></i>
            </Button>
        }
        return  button
    }
}

export default TransferenceRegisterPreAdmissionButton;
