import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import ReactTimeAgo from "react-time-ago/modules/ReactTimeAgo";
import Typography from '@material-ui/core/Typography';
import {isActiveStep, isCurrentStep, transformItem, allAvailableSteps, STEP_NAMES} from "../identity/api";
import Chip from "@material-ui/core/Chip";
import DataTable from '../data-table/DataTable';
import Info from '@material-ui/icons/Info'
import SummaryPanel from '../identity/SummaryPanel';
import config from '../../config';
import { withRouter} from "react-router-dom";


TimeAgo.addLocale(es);
const TIME_TO_RELOAD = parseInt(config.app.timeToUpdateList, 10);
let timeIntervalIdentifier = null;

class IdentityList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            activeTab: '',
            params: this.props.filters || {},
        }
    }

    componentWillReceiveProps(props) {
        this.setState({activeTab: props.id});
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            if('inProcess' === this.props.id) {
                timeIntervalIdentifier =  setInterval(()=>{
                    this.reloadData()
                }, TIME_TO_RELOAD)
            }else{
                if(timeIntervalIdentifier) {
                    clearInterval(timeIntervalIdentifier);
                }
            }
        }
    }

    componentDidMount() {
        if('inProcess' === this.props.id || 'full' === this.props.id) {
            timeIntervalIdentifier =  setInterval(()=>{
                this.reloadData()
            }, TIME_TO_RELOAD)
        }else{
            if(timeIntervalIdentifier) {
                clearInterval(timeIntervalIdentifier);
            }
        }
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    isDefaultExpanded = (row) => {
        const pathCode = this.props.match.params.code;
        const data = transformItem(row);
        return isCurrentStep(STEP_NAMES.CAPTURE_VIDEO_NOTARY, data) || pathCode === row.code;
    }

    render() {
        const columns = [];
        columns.push(this.renderTypeColumn());
        return (
            <div className={`transference-list-wrapper`}>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                    SubComponent={this.expandData}
                    isDefaultExpanded={this.isDefaultExpanded}
                    notShowLoading={true}
                />
            </div>
        );
    }

    expandData = (row) => {
        const data = transformItem(row.original);
        return <SummaryPanel key={`flow`} data={data} />
    }

    renderActionsColumn = () =>{
       return {
           Header: '',
           accessor: 'tipo',
           Cell: row => {
               return (
                 <Info fontSize={'large'} />
               );
           }
       };
    }
    renderTypeColumn = () =>{
       return {
           Header: '',
           accessor: 'id',
           Cell: row => {
               const data = transformItem(row.original);
               return (
                 <Box>
                     <Typography variant="h6">
                         {data.lastName && data.name ? `${data.lastName}, ${data.name}` : (data.externName ?? 'Nombre inválido')}
                         {' '}
                         {data.rut ? `(${data.rut})` : 'RUT inválido'}
                         {' '}
                         {data.code}
                         {' '}
                         <ReactTimeAgo date={data.timestamp} locale={'es'}  />
                     </Typography>

                     {allAvailableSteps(data).map((label, index) => {
                         const active = isActiveStep(label.key, data);
                         return <Chip className="ml-2" key={`chip-${index}`} disabled={!active} label={label.label} size={'small'} color={active ? 'primary' : 'default'} />
                     })}
                 </Box>
               );
           }
       };
    }

}

export default withRouter(IdentityList);
