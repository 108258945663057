import React, {Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, Alert} from 'reactstrap';
import TransferenceListContainer from '../../components/transference-list-container/TransferenceListContainer';
import TransferenceListFilter from '../../components/transference-list-filter/TransferenceListFilter';
import  DashBoardHeaderContainer from '../../components/dashboard-header-container/DashBoardHeaderContainer'
import {Permissions, TransferenceStatus} from '@autofact/operation-lib-model-notary-platform';
import {Link, withRouter} from "react-router-dom";

import FilterIcon from '../../theme/assets/filter_conces.svg';
import CheckAccess from "../../components/check-access/CheckAccess";
import AuthService from '../../services/AuthService';

class TransferenceList extends Component {

    constructor(props) {
        super(props);
        const { tabsDefinition, listFilters, recordCount } = TransferenceList.getDefinition();
        this.state = {
            tabsDefinition: Object.assign({}, tabsDefinition),
            activeTab: tabsDefinition.forApproval.id,
            filtersCollapsed: true,
            isMessageVisible: false,
            message: {},
            listFilters: { ...listFilters },
            recordCount: { ...recordCount },
            listSorted: {
                full: Object.assign({}, tabsDefinition.full.sorted),
            }
        };

        this.toggleFilters = this.toggleFilters.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.showSuccesMessage = this.showSuccesMessage.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.showDetailsInTabGeneric = this.showDetailsInTabGeneric.bind(this);
        this.buildFilters = this.buildFilters.bind(this);
        this.setFiltersCallback = this.setFiltersCallback.bind(this);
        this.currentTableRef = React.createRef();
        this.filterComponentRef = React.createRef();
    }

    static getDefinition() {
        const tabsDefinition = {
            forApproval: {
                id: 'forApproval',
                title: 'Contratos por aprobar',
                filters: { STATUS: TransferenceStatus.NOTARY_APPROVAL.id }
            },
            repertory: {
                id: 'repertory',
                title: 'Repertorio',
                filters: { STATUS: TransferenceStatus.REPERTORY.id }
            },
            representation: {
                id: 'representation',
                title: 'Representación',
                filters: { STATUS: TransferenceStatus.STATUS_ADVANCED_SIGNATURE_REPRESENTATIVE.id }
            },
            forSign: {
                id: 'forSign',
                title: 'Contratos por firmar',
                filters: { STATUS: [TransferenceStatus.NOTARY.id, TransferenceStatus.PACKING.id] }
            },
            signed: {
                id: 'signed',
                title: 'Firmados',
                filters: { STATUS: [TransferenceStatus.NOTARIZED.id, TransferenceStatus.MANUAL.id].join(',') }
            },
            re_income: {
                id: 're_income',
                title: 'Reingresos',
                filters: { WITH_RE_INCOME: true, STATUS: [TransferenceStatus.NOTARY_APPROVAL.id].join(',') }
            },
            full: {
                id: 'full',
                title: 'Consultar todos',
                filters: {},
                sorted: { firma_notario: false }
            },
            history: {
                id: 'history',
                title: 'Historico',
                filters: {},
                sorted: { firma_notario: false }
            }
        };

        const recordCount = {
            forApproval: 0,
            repertory: 0,
            representation: 0,
            forSign: 0,
            signed: 0,
            re_income: 0,
            full: 0,
            history: 0
        };

        const listFilters = {
            forApproval: Object.assign({}, tabsDefinition.forApproval.filters),
            repertory: Object.assign({}, tabsDefinition.repertory.filters),
            representation: Object.assign({}, tabsDefinition.representation.filters),
            forSign: Object.assign({}, tabsDefinition.forSign.filters),
            signed: Object.assign({}, tabsDefinition.signed.filters),
            re_income: Object.assign({}, tabsDefinition.re_income.filters),
            full: Object.assign({}, tabsDefinition.full.filters),
            history: Object.assign({}, tabsDefinition.history.filters)
        };

        const hasTransferenceRepresentationNotarySee = AuthService.canDo(Permissions.TRANSFERENCE_REPRESENTATION_NOTARY_SEE);
        if (!hasTransferenceRepresentationNotarySee) {
            delete tabsDefinition.representation;
            delete listFilters.representation;
            delete recordCount.representation;
        }
        return { tabsDefinition, recordCount, listFilters };
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            const listFilters = this.state.listFilters;
            listFilters[tab] = this.state.tabsDefinition[tab].filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
            this.filterComponentRef.current.resetFilters();
        }
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({activeTab: this.props.location.state.tab})
        }
        const recordCount = {};
        Object.keys(this.state.tabsDefinition).forEach(tabKey => {
            recordCount[tabKey] = 0;
        });
        this.setState({recordCount});
    }

    toggleFilters() {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    buildFilters(tab, filters = null, sorted = null) {
        const where = filters || this.state.listFilters[tab];
        const sort = sorted || this.state.listSorted[tab];

        if (sort) {
            return {where: JSON.stringify(where), sorted: JSON.stringify(sort)};
        }

        return {where: JSON.stringify(where)};
    }

    setFiltersCallback(filters) {
        const tab = this.state.activeTab;
        const listFilters = this.state.listFilters;
        for (const filterKey in this.state.tabsDefinition[tab].filters) {
            if (this.state.tabsDefinition[tab].filters.hasOwnProperty(filterKey)) {
                if (filters.hasOwnProperty(filterKey)) {
                    const filterValue = this.state.tabsDefinition[tab].filters[filterKey].toString().split(',').filter(filterValue => filterValue.toString() === filters[filterKey].toString());
                    filters[filterKey] = filterValue.join(',');
                }
                else {
                    filters[filterKey] = this.state.tabsDefinition[tab].filters[filterKey];
                }
            }
        }
        listFilters[tab] = filters;
        this.setState({listFilters});
        this.currentTableRef.current.refreshFilters(this.buildFilters(tab, listFilters[tab]));
    }

    setSortedCallback(sorted) {
        this.setState({listSorted: sorted});
    }

    showDetailsInTabGeneric(filters, customTab){
        console.log(`called ${JSON.stringify(filters)}`)
            const tab = customTab || this.state.tabsDefinition.full.id;
            const listFilters = Object.assign({},this.state.listFilters);
            listFilters[tab] = filters;
            const newState = {
                activeTab: tab,
                filtersCollapsed: true
            };
            if(!customTab) {
                newState.listFilters = listFilters;
            }
            this.setState({...newState});
            if(!customTab){
                setTimeout(() => {
                    this.setFiltersCallback(listFilters[tab]);
                }, 100);
            }
    }

    onDismiss() {
        this.setState({isMessageVisible: false});
    }

    showSuccesMessage(message) {
        this.setState({
            message: {
                success: true,
                text: message
            }, isMessageVisible: true
        })
    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    render() {
        const activeTab = this.state.activeTab;
        const listMenu = [];
        switch (activeTab) {
            case 'repertory': {
                listMenu.push(
                    <CheckAccess permission={Permissions.TRANSFERENCE_REPERTORY_EDIT}>
                        <Link key={`massive-repertory`} className="btn btn-sm btn-primary-dark mr-2"
                              to={`transferencias/asignar-repertorio-masivo`}>Repertorios rápidos</Link>
                    </CheckAccess>
                    );
                break;
            }
            case 'forSign': {
                listMenu.push(
                    <CheckAccess permission={Permissions.TRANSFERENCE_SIGN}>
                        <Link key={`massive-sign`} className="btn btn-sm btn-primary-dark mr-2"
                              to={`transferencias/firma-masiva`}>Firma masiva</Link>
                    </CheckAccess>);
                break;
            }
            case 'representation': {
                listMenu.push(
                <CheckAccess permission={Permissions.TRANSFERENCE_REPRESENTATION_SIGNATURE_NOTARY_OFFICE}>
                    <Link key={`massive-sign`} className="btn btn-sm btn-primary-dark mr-2"
                        to={`transferencias/firma-masiva-representacion`}>Firma masiva</Link>
                </CheckAccess>);
                break;
            }
            default: {
                break;
            }
        }
        let message = null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'}
                             isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
        }
        return (
            <div>
                {message}
                <DashBoardHeaderContainer
                    showMore={this.showDetailsInTabGeneric}
                    showSuccesMessage={this.showSuccesMessage}
                    showErrorMessage={this.showErrorMessage}
                />
                <div className='col-md-12'>
                    <Nav tabs className="tabs-dark">
                        {Object.keys(this.state.tabsDefinition).map((key, index) => {
                            const tab = this.state.tabsDefinition[key];
                            return (
                              <NavItem key={`tab-nav-item-${index}`}>
                                  <NavLink
                                    key={`tab-nav-${index}`}
                                    className={activeTab === tab.id ? 'active' : ''}
                                    onClick={() => this.toggleTab(tab.id)}
                                  >
                                      {tab.title} ({this.state.recordCount[tab.id]})
                                  </NavLink>
                              </NavItem>
                            );
                        })}
                        <div className='col-md-12'>
                            <NavItem className="nav-button">
                                {listMenu}
                                <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters}>
                                    <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                                </Button>
                            </NavItem>
                        </div>
                    </Nav>
                </div>
                <Collapse isOpen={!this.state.filtersCollapsed}>
                    <div className="filter-form-wrapper">
                        <TransferenceListFilter ref={this.filterComponentRef}
                                                defaultFilters={this.state.tabsDefinition[this.state.activeTab].filters}
                                                activeTab={this.state.activeTab}
                                                setFiltersCallback={this.setFiltersCallback}/>
                    </div>
                </Collapse>
                <TabContent activeTab={activeTab}>
                    {Object.keys(this.state.tabsDefinition).map((key, index) => {
                        const tab = this.state.tabsDefinition[key];
                        return (
                            <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                                {activeTab === tab.id ?
                                    <TransferenceListContainer
                                        id={tab.id}
                                        tab={tab.id}
                                        ref={this.currentTableRef}
                                        key={`tab-list-${index}`}
                                        updateCount={count => {
                                            const recordCountState = this.state.recordCount;
                                            recordCountState[tab.id] = count;
                                            this.setState({recordCount: recordCountState});
                                        }}
                                        filters={this.buildFilters(tab.id)}
                                    />
                                    :
                                    <div key={`tab-loading-${index}`}>Cargando</div>
                                }
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        );
    }

}

export default withRouter(TransferenceList);
