import React, {Component} from 'react';
import {withRouter} from 'react-router';
import FirmaDocumentosDetailContainer from "../../components/firmas-documentos-detail-container/FirmaDocumentosDetailContainer";

class FirmasDocumentosDetail extends Component {

    render() {
        return(
            <div>
                <FirmaDocumentosDetailContainer id={this.props.match.params.id}/>
            </div>
        );
    }
}

export default withRouter(FirmasDocumentosDetail);
