import { useState, useEffect } from 'react'
import { FormGroup, Input, Label, Row, Col, Button, Tooltip, UncontrolledAlert , FormText} from 'reactstrap'
import { NumericFormat } from 'react-number-format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackendApiService from '../../../services/BackendApiService';
import AmmountHelper from '../../../helpers/AmmountHelper';

const SalePrice = ({
  vehicleData,
  isCavOk,
  updateTaxes,
  taxesResult,
  updatePretransfer
}) => {

  const [report, setReport] = useState({});
  const [inputVersion, setInputVersion] = useState('');
  const [inputSiiCode, setInputSiiCode] = useState('');
  const [inputSalePrice, setInputSalePrice] = useState('');
  const [inputValuationPrice, setInputValuationPrice] = useState('');
  const [defaultInputSalePrice, setDefaultInputSalePrice] = useState('');
  const [defaultValuationPrice, setDefaultValuationPrice] = useState('');
  const [calculated, setCalculated] = useState(false);
  const [valuationDone, setValuationDone] = useState(false);
  const [valuationFailed, setValuationFailed] = useState(false);
  const [tooltipPrice, setTooltipPrice] = useState(false);
  const [tooltipCodeOpen, setTooltipCodeOpen] = useState(false);
  const [tooltipValuationOpen, setTooltipValuationOpen] = useState(false);
  const [taxes, setTaxes] = useState({ SIICode: '', brand: '', model: '', version: '', year: '', valuationPrice: '', registrationDocValue: '', price: '', taxValue: '' });
  const [valuations, setValuations] = useState([]);
  const [sourceRating, setSourceRating] = useState('');
  
  useEffect(() => {

    if(vehicleData.vehicle.SIICode){
      setValuationDone(true);
      setInputSiiCode(vehicleData.vehicle.SIICode);
      setDefaultValuationPrice(vehicleData.vehicle.valuationPrice);
      setInputValuationPrice(vehicleData.vehicle.valuationPrice);
      setDefaultInputSalePrice(vehicleData.vehicle.price);
      setInputVersion(vehicleData.vehicle.version);
      setTaxes({
        SIICode: vehicleData.vehicle.SIICode, 
        brand: vehicleData.vehicle.brand, 
        model: vehicleData.vehicle.model, 
        version: vehicleData.vehicle.version,
        year: vehicleData.vehicle.year, 
        valuationPrice: vehicleData.vehicle.valuationPrice, 
        registrationDocValue: vehicleData.vehicle.registrationDocValue, 
        price: vehicleData.vehicle.price, 
        taxValue: vehicleData.vehicle.taxValue,
        invoice: vehicleData.vehicle?.invoice,
        sourceRating,
      });
      setCalculated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicleData.vehicle.SIICode])


  useEffect(() => {

    if(isCavOk){

      const getFiscalValuationAlternative = async (year, brand, model) => {
        try {
    
          const response = await BackendApiService.getFiscalValuationAlt({year, brand, model});
          if(!response || response.status !== "OK"){
            throw new Error("getFiscalValuationAlternative Error: Couldn't get data");
          }
          
          const valuationList = response.data;
          const carModel = vehicleData.vehicle.cleanModel.split(' ')[0];
          const filteredValuations = valuationList.filter((el) => {              
            return carModel.includes(el.modelo)
          });
          setValuations(filteredValuations);

        } catch (error) {
          console.log(error.message);
          setValuationFailed(true);
          setValuationDone(true);
        }
      }
  
      const getFiscalValuation = async (plate) => {
        try {
          
          if(plate && plate !== ""){
            const response = await BackendApiService.getFiscalValuation(plate);
            
            if(!response || response.status !== "OK"){
              throw new Error("getFiscalValuation Error: Couldn't get data");
            }
            
            const valuationList = response.data;
            const carModels = vehicleData.vehicle.cleanModel.split(' ').filter((el) => el !== '')
            const filteredValuations = valuationList.filter((el) => {              
              const fmodels =  carModels.map(model => {
                return el.modelo.includes(model) || model.includes(el.modelo) ? el.modelo : null;
              })
              return fmodels.includes(el.modelo);
            });
            setValuations(filteredValuations);

          }
          else {
            throw new Error("getFiscalValuation Error: Plate not found");
          }
          
        } catch (error) {
          console.log(error.message);
          getFiscalValuationAlternative(vehicleData.vehicle.year, vehicleData.vehicle.brand, vehicleData.vehicle.model);
        }
      }
  
      getFiscalValuation(vehicleData.plate);
    }

  },[vehicleData, isCavOk])

  useEffect(() => {
    if(taxesResult){
      setTaxes(taxesResult);
    }
  }, [taxesResult])

  const toggleTooltipPrice = () => {
    setTooltipPrice(!tooltipPrice);
  }

  const toggleTooltipSiiCode = () => {
    setTooltipCodeOpen(!tooltipCodeOpen);
  }

  const toggleTooltipValuation = () => {
    setTooltipValuationOpen(!tooltipValuationOpen);
  }


  const calculateTax = () => {
    const { marca, modelo, valor_permiso, year } = report;
    const tax = AmmountHelper.calculateTax(inputSalePrice, inputValuationPrice);
    const taxesToDeliver = {
      SIICode: inputSiiCode,
      brand: marca || vehicleData.vehicle.brand,
      model: modelo || vehicleData.vehicle.model,
      version: inputVersion || vehicleData.vehicle.version,
      year: year || vehicleData.vehicle.year,
      valuationPrice: inputValuationPrice,
      price: inputSalePrice,
      registrationDocValue: valor_permiso || vehicleData.vehicle.registrationDocValue,
      taxValue: tax.toString(),
      invoice: inputValuationPrice,
      sourceRating,
    }
    updateTaxes(taxesToDeliver);
    updatePretransfer();
    setCalculated(true);
  }
  
  const jsxImpuestosItem = (title, text = '', className = '') => {
    return (
      <div className={className}>
        <p className='mb-auto'><strong>{title}</strong></p>
        <p>{text}</p>
      </div>
    )
  }

  const updateVersion = (el) => {
        const selectedVersion  = valuations.find( (item) => item.version === el.value);
        if(selectedVersion){
          setInputSiiCode(selectedVersion.codigoNuevo || selectedVersion.codigo);
          setInputValuationPrice(selectedVersion.valor_tasacion);
          setDefaultValuationPrice(selectedVersion.valor_tasacion);
          setInputVersion(selectedVersion.version);
          setReport(selectedVersion);
          setSourceRating('selected');
        }
        if(el.value === 'otro'){
          setInputSiiCode('');
          setInputVersion('otro');
          setInputValuationPrice('');
          setDefaultValuationPrice('');
          setSourceRating('manual');
        }
        if(el.value === 'SC'){
          setInputSiiCode('SC');
          setInputVersion('SC');
          setInputValuationPrice('');
          setDefaultValuationPrice('');
        }
        setValuationDone(true);
  }

  return (
    <div className='mb-5'>
      <div className=' d-flex align-items-center title-box-step-validation px-2 mb-3'>
        <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
          <span className='font-weight-bold my-1'>3</span>
        </div>
        <span className='h2 dark-color font-weight-bolder ml-2 mb-0'>Precio de venta y cálculo de impuesto</span>
      </div>

      {
      (valuationDone && !valuationFailed) &&
        <UncontrolledAlert  className='text-center text-dark success-alert'>
          <div>
            <FontAwesomeIcon icon={"check-circle"}/>
            {
              ['otro', 'SC'].includes(inputVersion) ?
              <span className='ml-3'>Ingrese manualmente los datos.</span>
              :
              <span className='ml-3'>Se obtuvo el cod. de SII y la tasación del vehículo automáticamente.</span>
            }
          </div>
        </UncontrolledAlert >
      }

      {
        valuationFailed &&
        <UncontrolledAlert  className='text-center text-dark alert-light'>
          <div>
            <FontAwesomeIcon icon={"exclamation-triangle"} className='text-warning'/>
            <span className='ml-3'>No se pudo obtener automáticamente el valor de la tasación del vehículo. Ingresa los datos a continuación.</span>
          </div>
        </UncontrolledAlert >
      }

      <Row className='row mt-4'>
        <FormGroup className="col-lg-4 col-md-6 col-sm-12">
            <Label for="car-version">
                Versión
            </Label>
            <Input type="select" id="version-id" className="bg-white" value={ inputVersion } onChange={ (el) => updateVersion(el.target)}>
                <option value='' disabled>Seleccione una versión</option>
                {valuations.map((el, k)=>
                    <option key={k} value={el.version}>{`${el.modelo} ${el.version}`} | {el.codigo} </option>
                )}
                <option value='otro'>Otro Modelo</option>
                {vehicleData.vehicle.year === new Date().getFullYear() &&
                  <option value='SC'>Sin codigo SII</option>
                }
            </Input>
        </FormGroup>
        <FormGroup className="col-lg-4 col-md-6 col-sm-12">
          <Label>
            Ingresa precio de venta del vehículo*
            <span id="tooltipPrice" className='ml-2'><FontAwesomeIcon icon='question-circle' /> </span>.
            <Tooltip placement="right" isOpen={tooltipPrice} autohide={false} target="tooltipPrice" toggle={toggleTooltipPrice} className='tooltip-form-help'>
              El impuesto a pagar se calculará en base al precio más alto entre la tasación del vehículo y el precio de venta ingresado
            </Tooltip>
          </Label>
          <NumericFormat 
            id="inputSalePrice"
            type="text"
            thousandSeparator='.'
            decimalSeparator=','
            prefix={'$'}
            disabled={!valuationDone}
            value={defaultInputSalePrice}
            className='form-control'
            onValueChange={(values) => {
              setInputSalePrice(values.value);
            }}
          />
        </FormGroup>
        <FormGroup className="col-lg-4 col-md-6 col-sm-12">
          <Label>
            Ingresa código SII
            <span id="tooltipCode" className='ml-2'><FontAwesomeIcon icon='question-circle' /> </span>.
            <Tooltip placement="right" isOpen={tooltipCodeOpen} autohide={false} target="tooltipCode" toggle={toggleTooltipSiiCode} className='tooltip-form-help' >
              Ingresa el código de SII que aparece regularmente en el permiso de circulación.
              <a href='https://static.autofact.cl/conces/transferencia/codigo_sii.png' target="_blank" className='underline-text-decoration text-light pl-1' rel="noreferrer">ver ejemplo</a>
            </Tooltip>
          </Label>
          <Input type="input" id="inputSiiCode" className="form-control" value={inputSiiCode} disabled={!valuationDone || inputVersion === 'SC'} onChange={(e) => setInputSiiCode(e.target.value) } />
          {valuationFailed && <FormText color='danger'>(Ingresa manualmente el cód. de SII)</FormText>}
        </FormGroup>
        <FormGroup className="col-lg-4 col-md-6 col-sm-12">
          <Label>
            Ingresa la tasación del vehículo 
            <span id="tooltipValuation" className='ml-2'><FontAwesomeIcon icon='question-circle' /> </span>.
            <Tooltip placement="right" isOpen={tooltipValuationOpen} autohide={false} target="tooltipValuation" toggle={toggleTooltipValuation} className='tooltip-form-help'>
              El impuesto a pagar se calculará en base al precio más alto entre la tasación del vehículo y el precio de venta ingresado 
            </Tooltip>
          </Label>
          <NumericFormat 
            id="inputValuationPrice"
            thousandSeparator='.'
            decimalSeparator=','
            prefix={'$'}
            disabled={!valuationDone}
            value={defaultValuationPrice}
            className='form-control'
            onValueChange={(values) => {
              setInputValuationPrice(values.value);
            }}
          />
          {valuationFailed && <FormText color='danger'>(Ingresa manualmente el valor de la tasación)</FormText>}
        </FormGroup>
        <FormGroup className="col-lg-12 col-md-6 col-sm-12 my-auto">
          <Button className="btn btn-outline-primary-dark font-weight-bolder" 
            disabled={!valuationDone || inputSalePrice.length === 0 || inputSiiCode.length === 0 || inputValuationPrice.length === 0} 
            onClick={() => calculateTax()}>
            { calculated? 'Recalcular impuesto' : 'Calcular impuesto' }
          </Button>
        </FormGroup>
      </Row>
      {
        calculated &&
        <Row className='mt-5'>
          <Col lg={3} sm={6}>
            <div className='container'>
              { jsxImpuestosItem('Código',taxes.SIICode ) }
              { jsxImpuestosItem('Marca', taxes.brand, 'mt-4') }
              { jsxImpuestosItem('Modelo ', taxes.model, 'mt-4') }
              { jsxImpuestosItem('Año ', taxes.year, 'mt-4') }
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className='container'>
              { jsxImpuestosItem('Valor Tasación', `$${Intl.NumberFormat('es-ES').format(taxes.valuationPrice)}`) }
              { jsxImpuestosItem('Precio de venta', `$${Intl.NumberFormat('es-ES').format(taxes.price)}`, 'mt-4') }
              { taxes.registrationDocValue && jsxImpuestosItem('Valor permiso', `$${Intl.NumberFormat('es-ES').format(taxes.registrationDocValue)}`, 'mt-4') }
              { jsxImpuestosItem('Versión', taxes.version, 'mt-4') }
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className='container'>
              <div>
                <p className='mb-auto'><strong>Valor impuesto</strong></p>
                <p className='tax-big-text'>{`$${Intl.NumberFormat('es-ES').format(taxes.taxValue)}`}</p>
              </div>
              <div className='w-75'>
                <p>El impuesto se calcula en base al precio máximo entre venta y tasación.</p>
              </div>
            </div>
          </Col>
        </Row>
      }
    
    </div>
  )
}

export default SalePrice