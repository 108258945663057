import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ActivityList extends Component{
    render() {
        const activityRender = [];
        const titleProperty = this.props.titleProperty || 'titulo';
        const timestampProperty = this.props.timestampProperty || 'timestamp';
        const descriptionProperty = this.props.descriptionProperty || 'descripcion';
        const idProperty = this.props.idProperty || 'idtransferencia_activity';
        this.props.activityList.forEach(activity => {
            activityRender.push(
                <li key={`activity-${activity[idProperty]}`} className="activity-item-wrapper">
                    <div className="activity-icon"><FontAwesomeIcon icon="flag"/></div>
                    <div className="activity-title">{activity[titleProperty]}</div>
                    <div className="activity-date">{activity[timestampProperty]}</div>
                    <div className="activity-description">{activity[descriptionProperty]}</div>
                </li>
            )
        });
        return (<div className="transference-activity-list-tab-container">
            <ul className="transference-activity-list">
                {activityRender}
            </ul>
        </div>);
    }
}

export default ActivityList;
