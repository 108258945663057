import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ModalRepresentative from './modals/ModalRepresentative'
import BuyerInformation from './BuyerInformation'
import ModalBuyer from './modals/ModalBuyer'
import ModalRemove from './modals/ModalRemove'
import { app } from '../../../config'

const BuyerTypeSelection = (props) => {

  const { data } = props;
  const { buyerInformation, category, loadingState, representativeInfo, isContractUpdate } = data;
  const { buyers, createBuyer, updateBuyer, removeBuyer, buyerIsCompany } = buyerInformation;
  const { createBuyerRep, representatives, removeBuyerRepresentative, updateRepresentative } = representativeInfo;
  const { updateBuyerCategory, buyerCategory} = category;
  const { loading, setLoading} = loadingState;

  const [targetBuyer, setTargetBuyer] = useState({});
  const [targetRepresentative, setTargetRepresentative] = useState({})
  const [openModalBuyer, setOpenModalBuyer] = useState(false);
  const [openModalRepresentative, setOpenModalRepresentative] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [personType, setPersonType] = useState('');
  const [activeBtn, setActiveBtn] = useState(null);

  useEffect(() => {
    setActiveBtn(buyerCategory || null);
  }, [buyerCategory]);

  const handleItemClick = index => {
    setActiveBtn(index);
    updateBuyerCategory(index);

  };

  const toDefaultButtons = () => {
    if(buyers.length === 0 && representatives.length === 0 && !isContractUpdate){
      setActiveBtn(null);
    }
  }

  const openModalForBuyer = () => setOpenModalBuyer(true);
  const closeModalForBuyer = () => {
    setTargetBuyer({});
    setOpenModalBuyer(false)
  };

  const openModalForReprensentative = () => setOpenModalRepresentative(true);
  const closeModalForRepresentative = () => {
    setTargetRepresentative({});
    setOpenModalRepresentative(false)
  };

  const openModalRemove = (person) => {
    setPersonType(person);
    setOpenRemove(true);
  }
  const closeModalRemove = () => {
    setTargetBuyer({});
    setTargetRepresentative({});
    setOpenRemove(false);
  };


  
  const createNewBuyer = async (buyer) => {
    await createBuyer(buyer);
  };
  
  const createNewBuyerRep = async (representative) => {
    await createBuyerRep(representative);
  };


  const selectABuyer = (buyer) => setTargetBuyer(buyer);
  const selectARepresentative = (representative) => setTargetRepresentative(representative);

  const updateActualBuyer = async (buyer, index) => {
    await updateBuyer(buyer, index);
    setTargetBuyer({});
  }

  const updateActualRepresentative = async (representative, index) => {
    await updateRepresentative(representative, index);
    setTargetRepresentative({});
  }

  const removeActualBuyer = async (index) => {
    await removeBuyer(index);
    setTargetBuyer({});
    toDefaultButtons();
  };

  const removeActualRepresentative = async (index) => {
    await removeBuyerRepresentative(index);
    setTargetRepresentative({});
    toDefaultButtons();
  };

  return (
    <div className='mx-2 mt-4'>
    <div className='d-flex align-items-center gray-light-bg'>
      <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
        <span className='font-weight-bold my-1'>2</span>
      </div>
      <span className='dark-color font-weight-bolder ml-2 text-20'>Selecciona el tipo de comprador</span>
    </div>
    <div className="buyer-btn-group btn-group mt-5">
      <ModalBuyer 
          data={{isOpen: openModalBuyer, targetBuyer, buyerType: activeBtn, isContractUpdate: isContractUpdate}}  
          methods={{close: closeModalForBuyer, create: createNewBuyer, update: updateActualBuyer}} 
          states={{loading:{loading, setLoading}}}
      />
      <ModalRepresentative 
          data={{isOpen: openModalRepresentative, targetRepresentative, representativeType: activeBtn}}  
          methods={{close: closeModalForRepresentative, create: createNewBuyerRep, update: updateActualRepresentative}} 
          states={{loading:{loading, setLoading}}}
      />
      <ModalRemove properties={{isOpen: openRemove, close: closeModalRemove, personType, remove: {removeActualBuyer, removeActualRepresentative}, loading: {loading, setLoading}, buyer: targetBuyer, representative: targetRepresentative }} />

      <button type="button" className={`btn mr-4 text-20 bold ${activeBtn === app.buyerType.naturalPerson ? 'btn-active' : ''}`} onClick={() => handleItemClick(app.buyerType.naturalPerson)} disabled={(buyerIsCompany) || (isContractUpdate && buyerCategory !== app.buyerType.naturalPerson) || representatives.length >= 1}>
        <FontAwesomeIcon className="solo-user-icon mr-4" icon="user"/> Persona natural
      </button>
      <button type="button" className={`btn mr-4 text-20 bold ${activeBtn === app.buyerType.company ? 'btn-active' : ''}`} onClick={() => handleItemClick(app.buyerType.company)} disabled={(!buyerIsCompany) || (isContractUpdate && buyerCategory !== app.buyerType.company)} >
        <FontAwesomeIcon className='mr-4' icon="building" /> Empresa
      </button>
      <button type="button" className={`btn text-20 bold ${activeBtn === app.buyerType.buyFor ? 'btn-active' : ''}`} onClick={() => handleItemClick(app.buyerType.buyFor)} disabled={(buyerIsCompany) || (isContractUpdate && buyerCategory !== app.buyerType.buyFor)} >
        <FontAwesomeIcon className="group-user-icon mr-1" icon="user"/> 
        <FontAwesomeIcon className='mr-1' icon="arrow-right"/>
        <FontAwesomeIcon className="group-user-icon mr-4" icon="user"/> Compra para
      </button>
    </div>
    <BuyerInformation 
      buyersData={{buyers: buyers, selectBuyer: selectABuyer, buyerType: buyerCategory}}
      representativesData={{representatives, selectARepresentative}}
      modals={{openModalForBuyer, openModalForReprensentative, openModalRemove}}
      isContractUpdate={isContractUpdate}
    />
    <div className={`${activeBtn? 'mt-5' : ''}`}>
      {[
        { currentBtn: activeBtn === app.buyerType.naturalPerson, label: 'Agregar comprador', click: openModalForBuyer},
        { currentBtn: activeBtn === app.buyerType.company && buyers.length === 0, label: 'Agregar empresa', click: openModalForBuyer},
        { currentBtn: activeBtn === app.buyerType.company && buyers.length >= 1, label: 'Agregar representante legal', click: openModalForReprensentative },
        { currentBtn: activeBtn === app.buyerType.buyFor && representatives.length === 0, label: 'Agregar comprador', click: openModalForReprensentative },
        { currentBtn: activeBtn === app.buyerType.buyFor, label: 'Agregar tercero (para)', click: openModalForBuyer,},
      ].map(({ currentBtn, label, click}, index) => currentBtn && (
        <button key={`add-btn-${index+1}`} type="button" className='btn btn-lg btn-outline-primary-dark mr-md-3 mr-sm-1 mb-sm-2' onClick={click}>
          <FontAwesomeIcon className="mr-2" icon="plus"/> {label}
        </button>
      ))}
    </div>
    
  </div>
  )
}

export default BuyerTypeSelection