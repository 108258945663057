import React, { Component } from 'react';
import { Button } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';

class FirmasDocumentosApproveButton extends Component {

    constructor(props){
        super(props);
        this.approveFirmasDocumentos = this.approveFirmasDocumentos.bind(this);
        this.state = {
            approving: false,
        }
    }


    approveFirmasDocumentos() {
        this.setState({approving: true});
        BackendApiService.approveFirmasDocumentos(this.props.id)
            .then(() => {
                window.location.reload();
            })
            .catch(error => {
                alert('error aprobando documento');
                console.error(error);
            })
            .finally(() => this.setState({approving: false}));
    }


    render(){


        return (
                <Button disabled={this.state.approving} onClick={this.approveFirmasDocumentos} color='success' className="mr-2">
                    {this.state.approving ? 'APROBANDO ...':'APROBAR'}
                    <i className='glyphicon glyphicon-remove-sign text-icon' />
                </Button>
        );
    }
}

export default FirmasDocumentosApproveButton;
