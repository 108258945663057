import React from 'react'
import { Modal } from 'reactstrap';
import { useState } from 'react';
import { withRouter } from 'react-router';
import BackendApiService from "../../../services/BackendApiService";

const ModalRemove = ({toggleActions, transferenceCode, history}) => {

  const { isOpen, closeThis } = toggleActions;

  const [loading, setLoading] = useState(false);
  const [comentario, setComentario] = useState('');
  const [eliminar, setEliminar] = useState('');
  const [eliminarIsValid, setEliminarIsValid] = useState(true);

  const cleanInputs = () => {
    setComentario('');
    setEliminar('');
  };

  const cleanValidationInputs = () => {
    setEliminarIsValid(true);
  }

  const inputValidations = () => {
  
    cleanValidationInputs();
    if (eliminar.length === 0 || eliminar.trim().toLocaleUpperCase() !== "ELIMINAR") {
      setEliminarIsValid(false);
      return false;
    }
  
    return true;
  };

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  const handleEliminarChange = (event) => {
    setEliminar(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const formValid = inputValidations();
      console.log(formValid)
      if(!formValid){
        return;
      }
      
      setLoading(true);
      const cancelResponse = await BackendApiService.cancelTransference(transferenceCode, comentario);
      console.log(cancelResponse)
      if(!cancelResponse || !cancelResponse.ok){
        throw new Error('No se pudo anular el contrato');
      }
  
      cleanInputs();
      console.log('Eliminado exitoso')
      setLoading(false);
      history.replace('/notaria');
      
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

  };

  return (
    <Modal isOpen={isOpen} toggle={() => closeThis()} className='modal-contract-remove' centered>
      <div className="modal-content">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Está a punto de eliminar este contrato</h2>
          <button type="button" className="close white-circle-close" onClick={() => closeThis()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group mb-4">
            <label className='bold'>Comentario</label>
            <textarea className="form-control" rows={5} onChange={ e => handleComentarioChange(e)}></textarea>
          </div>
          <div className="form-group">
            <label className='bold'>Confirmar: Para confirmar debe escribir la palabra "eliminar" en el cuadro y luego presionar aceptar</label>
            <input type="text" className={`form-control ${!eliminarIsValid ? 'is-invalid' : ''}`} onChange={e => handleEliminarChange(e)} />
            <div className="invalid-feedback">
              Ingrese la palabra "<strong>Eliminar</strong>".
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-start mb-2">
          <button className="btn btn-lg btn-outline-primary-dark px-5" onClick={() => closeThis()} >CANCELAR</button>
          <button type="submit" className="btn btn-lg btn-primary-dark px-5" disabled={loading} onClick={() => handleSubmit()} > 
            {
              loading ? 
              [<span key="span-k-1" className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>, 'ELIMINANDO CONTRATO']
              : 'ACEPTAR'
            }
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(ModalRemove);