import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import './TransferenceList.style.css';
import { formats, app } from '../../config';
import DataTable from '../data-table/DataTable';
import BackendApiService from "../../services/BackendApiService";
import {getTransferenceTypeFromId, getTransferenceStatusFromId, TransferenceStatus} from '@autofact/operation-lib-model-notary-platform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalMessage from "../modal-message/ModalMessage";

const TABS_NAMES = {
    FOR_SIGN: 'forSign',
    FOR_APPROVAL: 'forApproval',
    REPERTORY: 'repertory'
};

const SHOW_ORIGIN_TRFEXPRESS_IN = [
    TABS_NAMES.FOR_SIGN,
    TABS_NAMES.FOR_APPROVAL,
    TABS_NAMES.REPERTORY
];

const SHOW_COLUM_ORIGIN = [
    TABS_NAMES.FOR_SIGN,
    TABS_NAMES.FOR_APPROVAL,
    TABS_NAMES.REPERTORY
];

const SHOW_COLUNM_REPRESENTATION = [
    TABS_NAMES.FOR_APPROVAL,
];

class TransferenceList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            activeTab: '',
            exportResult: '',
            exporting: false,
            params: this.props.filters || {},
            generating:{},
            entered: false
        }
        this.selectRow = this.selectRow.bind(this);
        this.selectRowByTag = this.selectRowByTag.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({activeTab: props.id});
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    async exportData(format, code) {
        const params = {
            where: JSON.stringify({
                CODE: code,
            }),
            format: format
        };
        const exportResult = await BackendApiService.getTransferenceList(params);
        return exportResult.data;
    }

    download(format, code) {
        this.setState({exporting: true});
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format, code)
            .then((response) => {
                link.href = response.url;
                link.click();
                this.setState({exporting: false});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({exporting: false});
            });
    }
    removeGeneratingPdf =(idTransferencia) => {
        const generating = Object.assign({}, this.state.generating);
        delete generating[idTransferencia]
        this.setState({generating});
    }
    generatePdfRegCivil = async (idTransferencia, type) =>{
        try{
            let generating = Object.assign({}, this.state.generating);
            generating[idTransferencia] = true;
            this.setState({generating});
            const response =  await BackendApiService.generatePDFToRegCivil([idTransferencia], type);

            if(response.data && typeof response.data !== 'undefined' && (response.data.s3Path || (response.data[0] && response.data[0].s3Path))){
                generating = Object.assign({}, this.state.generating);
                generating[idTransferencia] = response.data.s3Path || response.data[0].s3Path
                this.setState({generating});
            }else{
                generating = Object.assign({}, this.state.generating);
                delete generating[idTransferencia]
                this.setState({generating});
                alert(response.message);
            }
        }catch (e){
            console.log(e);
            const generating = Object.assign({}, this.state.generating);
            delete generating[idTransferencia] ;
            this.setState({generating});

        }
    }

    selectRow(e, item){
        if(typeof this.props.selectRow ==='function'){
            this.props.selectRow(item.idtransferencia, item, e.target.checked);
        }
    }

    selectRowByTag(e, item){
        this.props.selectRowByTag(item.idtransferencia, item, e.target.checked);
    }

    render() {

        const columns = [];
        if(['forPreAdmission','forSend'].includes(this.state.activeTab)){
            columns.push(this.renderCheckBoxColumn());
        }

        if(['full'].includes(this.state.activeTab)){
            columns.push(this.renderCheckBoxForTag());
            columns.push(this.renderTagColumn());
        }

        columns.push(this.renderTypeColumn());

        if(SHOW_COLUM_ORIGIN.includes(this.state.activeTab)){
            columns.push(this.renderOriginColumn(this.state.activeTab));
        }

        if (SHOW_COLUNM_REPRESENTATION.includes(this.state.activeTab)) {
            columns.push(this.renderRepresentationColumn());
        }

        columns.push(this.renderPlateColumn());
        columns.push(this.renderMarkColumn());
        columns.push(this.renderRepertoryColumn());

        if(!['reincome', 'full'].includes(this.state.activeTab)) {
            columns.push(this.renderCreatedDateColumn());
        }

        if(['full'].includes(this.state.activeTab)) {
            columns.push(this.renderSignDateColumn());
        }

        if(this.state.activeTab === 're_income') {
            columns.push(this.renderRequestNumberColumn());
        }
        if(['signed','re_income'].includes(this.state.activeTab)){
            columns.push(this.renderSignDateColumn());
        }
        if(this.state.activeTab === 're_income') {
            columns.push(this.renderRejectDateColumn());
        }
        columns.push(this.renderEndDateColumn());
        if(['signed', 'full','re_income'].includes(this.state.activeTab)){
            columns.push(this.renderPDFColumn());
        }
        columns.push(this.renderViewColumn());
        if(this.state.activeTab === 're_income') {
            columns.push(this.renderPdfRegCivil());
        }
        if(this.state.activeTab !== 're_income')
            columns.push(this.renderStatusColumn());
        if(['forSend'].includes(this.state.activeTab))
            columns.push(this.renderEditPreAdmissionColumn())
        if(this.props.inProgress || this.props.fails > 0){
            columns.push(this.renderProgressColumn())
        }

        return (
            <div className={`transference-list-wrapper`}>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }
    renderCheckBoxColumn = () =>{
        return {
            Header: '',
            accessor: 'idtransferencia',
            width: 40,
            Cell: row => {
                return <input type="checkbox" value={row.original.idtransferencia}
                              onClick={(e)=>this.selectRow(e, row.original)}
                              checked={this.props.selected.hasOwnProperty(row.original.idtransferencia)}/>;
            }
        }
    }

    renderOriginColumn = (column) => {
        return {
            Header: <div className="text-center">Origen</div>,
            accessor: 'origin',
            width: 100,
            Cell: row => {
                return <div className="pre-line text-center">{this.calculateRenderOrigin(row, column)}</div>
            }
        }
    }

    renderCheckBoxForTag = () =>{
        return {
            Header:<input type="checkbox" value={'all'}
                onChange={(e)=> this.selectRowByTag(e, 'all')}
                checked={this.props.selectedAll}
                className={`${this.state.entered ? 'not-show': ''}`}/>,
            accessor: 'idtransferencia',
            width: 80,
            Cell: row => {
                return <input type="checkbox" value={row.original.idtransferencia}
                            onChange={(e)=> this.selectRowByTag(e, row.original)}
                            checked={this.props.selected.hasOwnProperty(row.original.idtransferencia)}/>;
            }
        }
    }

    renderTypeColumn = () =>{
       return {
           Header: 'Tipo',
           accessor: 'tipo',
           width: 70,
           Cell: row => {
               const status = getTransferenceTypeFromId(row.original.tipo);
               return (
                   <span
                       title={`${status.label}: ${status.description}`}
                       key={`detail-type-${row.original.idtransferencia}`}
                   >
                        <div className="transference-contract-type">
                            <div className="info">
                                <FontAwesomeIcon icon='check' className='icon'/>
                                {status.shortLabel}
                            </div>
                        </div>
                    </span>
               );
           }
       };
    }

    renderTagColumn  = () => {
        return {
            Header: 'Etiqueta',
            accessor: 'tag',
            width: 100,
            Cell: row => {
                if (row.original.valorEtiqueta) {
                    return (
                        <span className="pre-line"><b>{row.original.valorEtiqueta}</b></span>
                    )
                }
                else {
                    return (
                        <span className="pre-line"><b>Sin Ingresar</b></span>
                    )
                }
            }
        }
    }

    renderPlateColumn = () =>{
        return  {
            Header: 'Patente',
            accessor: 'patente',
            width: 90,
            sortable: true
        };
    }
    renderMarkColumn = () =>{
        return  {
            Header: 'Marca | Modelo | Año',
            accessor: 'vehiculo_idvehiculo',
            Cell: row => {
                const marca = <span className="bold">{row.original.marca}</span>;
                return (
                    <div className="pre-line">
                        <span className="bold">{marca}</span>
                        <span>{` ${row.original.modelo} `}</span>
                        <span className="bold">{row.original.year}</span>
                    </div>
                );
            }
        };
    }

    renderRepertoryColumn = () =>{
        return  {
            Header: 'Repertorio',
            accessor: 'numero_repertorio',
            width: 90,
            sortable: ['forSign','signed','full'].includes(this.state.activeTab)
        }
    }
    renderRequestNumberColumn = () =>{
        return  {
            Header: 'Solicitud',
            accessor: 'numero_solicitud',
            width: 90,
        }
    }
    renderCreatedDateColumn = () => {
        return {
            Header: 'Fecha de creación',
            accessor: 'timestamp',
            width: 160,
            Cell: row => {
                let date = moment(row.original.timestamp);
                return (
                    <span className="pre-line">{date.format(formats.date)}</span>
                )
            }
        }
    }
    renderPdfRegCivil =() =>{
        return {
            Header: 'Generar pdf',
            accessor: 'firma_notario',
            width: 160,
            Cell: row => {
                let title = '-';
                let type = null;

                const original = row.original;
                if(original.con_prohibicion || original.es_transferencia_lenta) {
                    title = 'PDF V6';
                    type = 'join_pdf_slow';
                }else if(original.idpreingreso) {
                    title = 'PDF';
                }else if(original.con_factura) {
                    title = 'PDF Factura';
                    type = 'join_pdf_invoice';
                }

                let inProcess = false;
                let complete = false;
                if(typeof this.state.generating[original.idtransferencia] !== 'undefined') {
                    if(this.state.generating[original.idtransferencia] === true) {
                        inProcess = true;
                    }else{
                        complete = true;
                    }
                }
                if(complete){
                    return  <a
                      key="export-pdf-download"
                      href={this.state.generating[original.idtransferencia]}
                      target="_blank"
                      onClick={() => this.removeGeneratingPdf(original.idtransferencia)}
                      rel="noopener noreferrer"
                      className={'btn donwload-button btn-sm actions'}
                      >
                        <FontAwesomeIcon icon="file-download" />
                        Descargar PDF
                    </a>
                }else{
                    return <button key={`detail-link-${original.idtransferencia}`}
                                   className={inProcess?'btn-primary':'btn-primary-darker'}
                                   disabled={inProcess}
                                   onClick={() => this.generatePdfRegCivil(original.idtransferencia, type)}
                    >{`${inProcess?'Generando':'Generar'} ${title}`}</button>
                }
            }
        }
    }
    renderSignDateColumn = () => {
        return {
            Header: 'Fecha de firma',
            accessor: 'firma_notario',
            width: 160,
            Cell: row => {
                if (row.original.firma_notario) {
                    let date = moment(row.original.firma_notario);
                    return (
                        <span className="pre-line">{date.format(formats.date)}</span>
                    )
                }
                else {
                    return '';
                }
            }
        }
    }
    renderRejectDateColumn = () => {
        return {
            Header: 'Fecha de rechazo',
            accessor: 'timestamp_reject',
            width: 160,
            Cell: row => {
                if (row.original.timestamp_reject) {
                    let date = moment(row.original.timestamp_reject);
                    return (
                        <span className="pre-line">{date.format(formats.date)}</span>
                    )
                }
                else {
                    return '';
                }
            }
        }
    }
    renderEndDateColumn = () => {
        return {
            Header: 'Fecha de término',
            accessor: 'termino',
            width: 160,
            Cell: row => {
                if (row.original.termino) {
                    let date = moment(row.original.termino);
                    return (
                        <span className="pre-line">{date.format(formats.date)}</span>
                    )
                }
                else {
                    return '';
                }
            }
        }
    }
    renderPDFColumn = () => {
        return {
            Header: 'PDF',
            width: 60,
            accesor: 'idtransferencia',
            Cell: row => {
                if (this.state.exporting) {
                    return <ModalMessage message={'Obteniendo datos...'}/>
                }
                return (
                    <span key={`detail-link-span-${row.original.idtransferencia}`}>
                        <button key={`detail-link-${row.original.idtransferencia}`}
                                className="btn-primary-darker btn-see-link"
                                onClick={() => this.download('pdf-package',row.original.codigo)}
                        >PDF</button>
                    </span>
                );
            }
        }
    }
    renderViewColumn = () => {
        return {
            Header: 'Ver',
            width: 60,
            accesor: 'idtransferencia',
            Cell: row => {
                return (
                    <span key={`detail-link-span-${row.original.idtransferencia}`}>
                        <Link key={`detail-link-${row.original.idtransferencia}`}
                              className="btn-primary-darker btn-see-link"
                              to={`transferencia/ver/${row.original.codigo}`}>VER</Link>
                    </span>
                );
            }
        }
    }
    renderStatusColumn = () => {
        return {
            Header: 'Estado',
            accesor: 'estado',
            width: 160,
            Cell: row => {
                //TODO check the user status, some users can't see some status
                return (
                    <span key={`status-${row.original.idtransferencia}`}
                          className={`transference-status transference-status-sm transference-status-${row.original.estado}`}>
                            {TransferenceList.getStatusLabel(row.original.estado)}
                        </span>
                );
            }
        }
    }
    renderProgressColumn = () => {
        return {
            Header: 'Progreso',
            accesor: 'estado',
            width: 160,
            Cell: row => {
                const selected = this.props.selected[row.original.idtransferencia];
                if(selected){
                    if(selected.hasOwnProperty('success')){
                        if(selected.success){
                            return <FontAwesomeIcon icon='check' className='icon'/>
                        }else{
                            return <div style={{whiteSpace: 'normal'}}>{selected.message}</div>;
                        }
                    }else if(selected.inProgress){
                        return <FontAwesomeIcon icon='spinner' className='icon' spin/>
                    }
                }
                return null;
            }
        }
    }
    renderEditPreAdmissionColumn = () => {
        return {
            Header: 'PreIngreso',
            accesor: 'idpreingreso',
            width: 160,
            Cell: row => {
                return <Link key={`preadmission-edit`} className="btn btn-sm btn-primary-dark mr-2"
                             to={`/preingreso/editar/${row.original.idpreingreso}`}>Editar</Link>
            }
        }
    }

    calculateRenderOrigin = (row, column) => {
        const { is_trf_express: isTrfExpress, origen } = row.original;
        const { textColorClass } = app.expressTransfer;
        const originOwn = (<span className="yellow-color"><b>Propio</b></span>);
        const originExpressTransfer = (<span className={textColorClass}><b>Transferencia Express</b></span>);
        const originAutofact = (<span className="text-primary-dark px-auto"><b>Autofact</b></span>);

        if (isTrfExpress && isTrfExpress === 1 && SHOW_ORIGIN_TRFEXPRESS_IN.includes(column)) {
            return originExpressTransfer;
        }

        if ([TABS_NAMES.FOR_SIGN].includes(column)) {
            if (origen === 1) {
                return originOwn;
            }
            return originAutofact;
        }
    }

    renderRepresentationColumn = (column) => {
        return {
            Header: <div className="text-center">Representación</div>,
            accessor: 'origin',
            Cell: (row) => {
                const { is_representation: isRepresentation } = row.original;
                const text = isRepresentation ? 'Sí' : 'No';
                return <div className="pre-line text-center">{text}</div>
            }
        }
    }

    static getStatusLabel = (status) => {
        let statusLabel = getTransferenceStatusFromId(status).label;
        if (parseInt(status, 10) === TransferenceStatus.STATUS_ADVANCED_SIGNATURE_REPRESENTATIVE.id) {
            statusLabel = 'REPRESENTACIÓN';
        }
        return statusLabel;
    }
}

export default TransferenceList;
