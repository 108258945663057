import { useState, useEffect } from 'react'
import RepresentativetInformation from '../notary-contract-creation/RepresentativetInformation'
import SellerInformation from '../notary-contract-creation/SellerInformation'
import ModalRepresentative from '../notary-contract-creation/ModalRepresentative'
import ModalRemoveRepresentative from '../notary-contract-creation/ModalRemoveRepresentative'
import BuyerTypeSelection from '../notary-contract-creation/buyers/BuyerTypeSelection'

const companyValidSex = [
  3, 'E'
];

const ContractCreationStep = (props) => {

  const {data, updateData, updatePretransfer, isContractUpdate, changeSellerCompanyState, modifyPerson} = props;
  const {contractForm, validationForm, sellerIsCompany} = data;


  const [showModalRepresentative, setShowModalRepresentative] = useState(false);
  const [showModalDel, setShowModalDel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [representatives, setRepresentatives] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [buyerRepresentatives, setBuyerRepresentatives] = useState([]);
  const [buyerCategory, setBuyerCategory] = useState(null);
  const [representativeIndex, setRepresentativeIndex] = useState(null);
  const [selectedRepresentative, setSelectedRepresentative] = useState({});

  useEffect(() => {
    setRepresentatives(contractForm.people.sellers.representatives);
    setBuyers(contractForm.people.buyers.buyers);
    setBuyerRepresentatives(contractForm.people.buyers.representatives);
  }, [contractForm])

  useEffect(() => {
    setBuyerCategory(validationForm.peopleExtras.people.buyers.buyerType);
  }, [validationForm])

  useEffect(() => {
    const isCompany = contractForm.people.sellers.sellers.some((el) => companyValidSex.includes(el.sex) && el.identityCode.slice(0, -1) >= 50000000);
    changeSellerCompanyState(isCompany);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractForm.people.sellers])

  const updateBuyerCategory = (category) => {
    let newValidationForm = validationForm;
    setBuyerCategory(category)
    newValidationForm.peopleExtras.people.buyers.buyerType = category;
    updateData(newValidationForm, null);

  };

  const closeModalAdd = () => {
    setSelectedRepresentative({});
    setRepresentativeIndex(null);
    setShowModalRepresentative(false)
  };
  const openModalRepresentative = () => setShowModalRepresentative(true);
  const closeModalDel = () => setShowModalDel(false);
  const openModalDel = () => setShowModalDel(true);

  const openModalUpdate = (index) => {
    let representative = representatives[index];
    setSelectedRepresentative(representative);
    setRepresentativeIndex(index);
    openModalRepresentative();
  }

  const createBuyerRep = async (representative) => {
    setLoading(true);
    let newContractForm = contractForm;
    let newValidationForm = validationForm;
    const resp = await modifyPerson(representative);

    if(resp && resp.affectedRows){
      newContractForm.people.buyers.representatives = [...buyerRepresentatives, representative];
      updateData(newValidationForm, newContractForm);
      await updatePretransfer();
      setBuyerRepresentatives([...buyerRepresentatives, representative]);
    }
    setLoading(false);
  };

  const createBuyer = async (buyer) => {
    setLoading(true);
    let newContractForm = contractForm;
    let newValidationForm = validationForm;

    const resp = await modifyPerson(buyer);
    if(resp && resp.affectedRows){
      newContractForm.people.buyers.buyers = [...buyers, buyer];
      updateData(newValidationForm, newContractForm);
      await updatePretransfer();
      setBuyers([...buyers, buyer]);
    }
    setLoading(false);
  };

  
  
  const removeRepresentative = async (index) => {
    let newContractForm = contractForm;
    let newRepresentatives = representatives;
    
    newRepresentatives.splice(index, 1);
    newContractForm.people.sellers.representatives = newRepresentatives;
    updateData(null, newContractForm);
    await updatePretransfer();
    setRepresentatives(newRepresentatives);
    setRepresentativeIndex(null);
    setLoading(false);
    closeModalDel();
    
  }
  
  const createSellerRep = async (representative) => {
    setLoading(true);
    let newContractForm = contractForm;
    const resp = await modifyPerson(representative);
    if(resp && resp.affectedRows){
      newContractForm.people.sellers.representatives = [...representatives, representative];
      updateData(null, newContractForm);
      await updatePretransfer();
      setRepresentatives([...representatives, representative]);
    }
    setLoading(false);
    closeModalAdd();
  }
  
  const updateRepresentative = async (representative) => {
    setLoading(true);
    let newContractForm = contractForm;

    const resp = await modifyPerson(representative);

    if(resp && resp.affectedRows){
      const currentRepresentative = newContractForm.people.sellers.representatives[representativeIndex];
      const newRepresentative = {...currentRepresentative, ...representative};
      newContractForm.people.sellers.representatives[representativeIndex] = newRepresentative;
      updateData(null, newContractForm);
      await updatePretransfer();
      setRepresentatives(newContractForm.people.sellers.representatives);
    }
    setLoading(false);
    setRepresentativeIndex(null);
    setSelectedRepresentative({});
    closeModalAdd();
  }

  const updateBuyer = async (buyer, index) => {
    setLoading(true);
    let newContractForm = contractForm;

    const resp = await modifyPerson(buyer);
    if(resp && resp.affectedRows){
      newContractForm.people.buyers.buyers[index] = buyer;
      updateData(null, newContractForm);
      await updatePretransfer();
      setBuyers(newContractForm.people.buyers.buyers);
    }
    setLoading(false);
  };

  const updateBuyerRepresentative = async (representative, index) => {
    setLoading(true);
    let newContractForm = contractForm;

    const resp = await modifyPerson(representative);
    if(resp && resp.affectedRows){
      newContractForm.people.buyers.representatives[index] = representative;
      updateData(null, newContractForm);
      await updatePretransfer();
      setBuyerRepresentatives(newContractForm.people.buyers.representatives);
    }

    setLoading(false);
  };
  

  const removeBuyer = async (index) => {
    setLoading(true);
    let newBuyersList = buyers;
    let newValidationData = { ...validationForm };
    let newContractData = { ...contractForm };
  
    newBuyersList.splice(index, 1);
    newContractData.people.buyers.buyers = newBuyersList;
    updateData(newValidationData, newContractData);
    await updatePretransfer();
    setBuyers(newBuyersList);
    setLoading(false);
  };

  const removeBuyerRepresentative = async (index) => {
    setLoading(true);
    let newRepresentativeList = buyerRepresentatives;
    let newValidationData = { ...validationForm };
    let newContractData = { ...contractForm };
  
    newRepresentativeList.splice(index, 1);
  
    newContractData.people.buyers.representatives = newRepresentativeList;
    updateData(newValidationData, newContractData);
    await updatePretransfer();
    setBuyerRepresentatives(newRepresentativeList);
    setLoading(false);
  };

  return (
    <div className='d-flex flex-column w-100'>
      <div className='m-5'>
        <div className='d-flex justify-content-between'>
          <span className='text-26 text-dark font-weight-bold'>{isContractUpdate? 'Modificación' : 'Creación'} de contrato de compra-venta</span>
          <span className='text-primary-dark font-weight-bolder text-20'>Vehículo: {contractForm.plate}</span>
        </div>
        <div className='contract-creation-box mt-3'>
          <ModalRepresentative 
            data={{isOpen: showModalRepresentative, close: closeModalAdd, createSellerRep, selectedRepresentative, loading, updateRepresentative }}
            modifyPerson={ modifyPerson } />
          <ModalRemoveRepresentative data={{isOpen: showModalDel, loading: {loading, setLoading}, removeRepresentative, close: closeModalDel, representative: representativeIndex}}/>
          <SellerInformation sellerData={contractForm.people.sellers}/>
          <RepresentativetInformation data={{
            representatives, 
            openRemoveModal: openModalDel,
            openModalUpdate, 
            setRepresentativeIndex, 
            openModalRepresentative,
            sellerIsCompany,
            }} 
            isContractUpdate={ isContractUpdate }/>
          <BuyerTypeSelection 
            data={{
              buyerInformation: { buyers, setBuyers, createBuyer, updateBuyer, removeBuyer, buyerIsCompany: validationForm?.buyerCompany || false },
              representativeInfo: { createBuyerRep, representatives: buyerRepresentatives, removeBuyerRepresentative, updateRepresentative: updateBuyerRepresentative },
              category: {buyerCategory, updateBuyerCategory},
              loadingState: {loading, setLoading},
              isContractUpdate: isContractUpdate
            }} 
          />
        </div>
      </div>
    </div>
  )
}

export default ContractCreationStep