import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import AuthService from '../../services/AuthService';

const PrivateRoute = ({component: Component, path, isLoggedIn, permission}) => {
    return (<Route
        exact
        path={path}
        render={() =>
            isLoggedIn ? (
                AuthService.canDo(permission) ?
                    <Component/>
                    :
                    <Redirect
                        to={'403'}
                    />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: window.location.pathname}
                    }}
                />
            )
        }
    />)
};

export default PrivateRoute;
