import React from 'react';
import Stepper from "@material-ui/core/Stepper";
import {allAvailableSteps, isActiveStep, isCurrentStep} from "./api";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";


const StepsIndicator = ({ data }) => {
   let currentIndex = -1;
   const steps = [];
   const allSteps = allAvailableSteps(data);
  allSteps.forEach((label, index) => {
    steps.push(
      <Step key={`step-${index}`}>
        <StepLabel>{label.label}</StepLabel>
      </Step>
    )
    if(isActiveStep(label.key, data)) {
      currentIndex = index;
      if(index === (allSteps.length -1)){
        if(!isCurrentStep(label.key, data) ){
          currentIndex = currentIndex + 1;
        }
      }
    }
  })
  return (
    <Stepper alternativeLabel activeStep={currentIndex}>
      {steps}
    </Stepper>
  )
};

export default StepsIndicator;
