import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import {STEP_LABELS} from '../identity/api'

class IdentityListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilters: {
                RUT: '',
                CODE: '',
                STEP_ACTIVE: ''
            },
            activeTab: '',
            defaultFilters: props.defaultFilters,
            buttonStatus: true
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({defaultFilters: props.defaultFilters, activeTab: props.activeTab});
    }

    handleFilterChange(event) {
        console.log(event);
        let newFilterData = {};
        newFilterData[event.target.id] = event.target.value;
        newFilterData = Object.assign(this.state.formFilters, newFilterData);

        const keys = Object.keys(newFilterData);
        let buttonStatus= true;

        for (let i = 0; i < keys.length; i += 1) {
            if (newFilterData[keys[i]] !== '') {
                    buttonStatus = false;
            }
        }

        this.setState({
            formFilters: newFilterData,
            buttonStatus
        });
    }

    setFilters() {
        const filters = {};
        for(const filter in this.state.formFilters) {
            if (this.state.formFilters.hasOwnProperty(filter) && this.state.formFilters[filter]) {
                filters[filter] = this.state.formFilters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters() {
        const filters = {
            RUT: '',
            CODE: '',
            STEP_ACTIVE: ''
        };
        this.setState({
            formFilters: filters,
            buttonStatus: true
        });
    }


    render() {
        return (
            <Form className={'px-2'}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text" placeholder="Rut" id="RUT" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.RUT}/>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text" placeholder="Codigo" id="CODE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.CODE}/>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="STEP_ACTIVE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.STEP_ACTIVE}>
                                <option value="">Paso activo</option>
                                {STEP_LABELS.map((step, index)=>{
                                    return (<option key={`step-option-${index}`} value={step.key}>{step.label}</option>);
                                })}
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <Button
                            className="btn btn-sm btn-primary-darker btn-filter"
                            onClick={this.setFilters}
                            disabled={this.state.buttonStatus}>
                            Aplicar
                            </Button>
                    </Col>
                </Row>
            </Form>
        );
    }


}

export default IdentityListFilter;
