import React, {Component} from 'react';
import {withRouter} from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DocumentSignMassiveSignForm from "../../components/documents-sign-massive-sign-form/DocumentSignMassiveSignForm";

class DocumentsSignMassiveSign extends Component {
    render() {
        return (
            <div>
                <div className="page-title">
                    <FontAwesomeIcon icon="list-alt" alt="Icon" className="icon-title" />
                    <h2 className="text-title">Firma masiva de documentos</h2>
                </div>
                <div className="main-wrapper-page">
                    <DocumentSignMassiveSignForm operationId={this.props.match.params.operationId} scope={this.props.match.params.scope} />
                </div>
            </div>);
    }
}

export default withRouter(DocumentsSignMassiveSign);
