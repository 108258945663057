import { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, FormGroup, Label, Input, Button, ModalFooter, FormFeedback } from 'reactstrap'
import { app } from '../../../../config';
import rutHelper from '../../../../helpers/rutHelper';
import BackendApiService from '../../../../services/BackendApiService';
import { CircularProgress } from '@material-ui/core';
import PeopleHelper from '../../../../helpers/PeopleHelper';

const ModalRepresentative = (props) => {

  const { isOpen, targetRepresentative, representativeType, isContractUpdate } = props.data;
  const { close, create, update } = props.methods;
  const { loading } = props.states;

  const [inputRut, setInputRut] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputSecondLN, setInputSecondLN] = useState('');
  const [isAnUpdate, setIsAnUpdate] = useState(false);
  const [invalidRut, setInvalidRut] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidSecondLN, setInvalidSecondLN] = useState(false);
  const [representative, setRepresentative] = useState({});
  const [searching, setSearching] = useState(false);

  useEffect(() => {

    if(Object.keys(targetRepresentative).length !== 0 && isOpen) {
      setIsAnUpdate(true);
      setInputRut(targetRepresentative.identityCode);
      setInputName(targetRepresentative.name);
      setInputLastName(targetRepresentative.fatherLastName);
      setInputSecondLN(targetRepresentative.motherLastName);
    } else{
      setIsAnUpdate(false);
    }

  }, [targetRepresentative, isOpen]);

  const cleanInputs = () => {
    setInputRut('');
    setInputName('');
    setInputLastName('');
    setInputSecondLN('');
  };

  const cleanInvalidInputs = () => {
    setInvalidRut(false);
    setInvalidName(false);
    setInvalidLastName(false);
    setInvalidSecondLN(false);
  }

  const inputValidations = () => {
    cleanInvalidInputs();

    const validatedRut = rutHelper.validateRut(inputRut);

    if(inputRut.length === 0 || !validatedRut || inputName.length === 0 || inputLastName.length === 0 || inputSecondLN.length === 0){
      if (inputRut.length === 0 || !validatedRut) setInvalidRut(true);
      if (inputName.length === 0) setInvalidName(true);
      if (inputLastName.length === 0) setInvalidLastName(true);
      if (inputSecondLN.length === 0) setInvalidSecondLN(true);
      return false;
    }
  
    return true;
  };

  const addRepresentative = async () => {

    let newRepresentative = {
      identityCode: inputRut,
      fullName: `${inputName} ${inputLastName} ${inputSecondLN}`,
      motherLastName: inputSecondLN,
      email: '',
      phone: '',
      sex: '',
      ...{
        ...representative,
        name: inputName,
        fatherLastName: inputLastName,
        motherLastName: inputSecondLN
      }
    };
  
    if (!inputValidations()) {
      return;
    }
  
    if (isAnUpdate) {
      const filteredNewRepresentativeData = PeopleHelper.filterNoEmptyObjectData(newRepresentative);
      filteredNewRepresentativeData.address && delete filteredNewRepresentativeData.address;

      const { index, ...restOfTargetRepresentative } = targetRepresentative;
      const concatRepresentative = {...restOfTargetRepresentative, ...filteredNewRepresentativeData};
      await update(concatRepresentative, index);
    } else {
      await create(newRepresentative);
    }
  
    onClose();
  };

  const onClose = () => {
    cleanInvalidInputs();
    cleanInputs();
    close();
  };

  const searchRepresentative = async (rut) => {
    setSearching(true);
    const response = await BackendApiService.getPersonByRut(rut);
    if(response){
      setRepresentative(response);
      setInputName(response.name);
      setInputLastName(response.fatherLastName);
      setInputSecondLN(response.motherLastName);
    }else{
      setRepresentative({});
      setInputName('');
      setInputLastName('');
      setInputSecondLN('');
    }
    setSearching(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size={'lg'} centered>
      <div className="modal-header bg-primary-darker text-white">
        <h3 className="modal-title">
          { representativeType === app.buyerType.company && (isAnUpdate? 'Modificar representante legal' : 'Agregar representante legal') }
          { representativeType === app.buyerType.buyFor && (isAnUpdate? 'Modificar persona que compra' : 'Agregar persona que compra') }
        </h3>
        <button type="button" className="close white-circle-close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          {
            representativeType === app.buyerType.buyFor &&
              <div className="d-flex">
                <p className="p-2 text-secondary bold text-14">Información de quién compra</p>
                <hr className="flex-grow-1" />
              </div>
          }
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="inputRut">
                { representativeType === app.buyerType.company && 'Rut del representante' }
                { representativeType === app.buyerType.buyFor && 'Rut de quién compra' }
                </Label>
                <Input type="text" name="rut" id="inputRut" maxLength="9" defaultValue={inputRut} 
                  onInput={(e) => setInputRut(e.target.value)}
                  onBlur={(e) => searchRepresentative(e.target.value)}
                  invalid={invalidRut} 
                  disabled={isContractUpdate && isAnUpdate}/>
                <FormFeedback valid={false}>Ingrese un Rut válido</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={1}>
            <div className='d-flex flex-column justify-content-center h-100 mt-2'>
              <CircularProgress size={25} className={searching? '': 'd-none'} />
            </div>
          </Col>
          </Row>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="inputName">
                  { representativeType === app.buyerType.company && 'Nombres del representante' }
                  { representativeType === app.buyerType.buyFor && 'Nombres de quién compra' }
                </Label>
                <Input type="text" name="firstName" id="inputName" defaultValue={inputName} onInput={(e) => setInputName(e.target.value)} invalid={invalidName}/>
                <FormFeedback valid={false}>Ingrese un nombre válido</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="inputLastName">
                  { representativeType === app.buyerType.company && 'Apellido paterno del representante' }
                  { representativeType === app.buyerType.buyFor && 'Apellido paterno de quién compra' }
                </Label>
                <Input type="text" name="lastName" id="inputLastName" defaultValue={inputLastName} onInput={(e) => setInputLastName(e.target.value)} invalid={invalidLastName} />
                <FormFeedback valid={false}>Ingrese un apellido válido</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="inputSecondLN">
                  { representativeType === app.buyerType.company && 'Apellido materno del representante' }
                  { representativeType === app.buyerType.buyFor && 'Apellido materno de quién compra' }
                </Label>
                <Input type="text" name="secondLastName" id="inputSecondLN" defaultValue={inputSecondLN} onInput={(e) => setInputSecondLN(e.target.value)} invalid={invalidSecondLN}/>
                <FormFeedback valid={false}>Ingrese un apellido válido</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <ModalFooter className="justify-content-center">
        <Button color="outline-primary-dark bold" onClick={onClose}>
          CANCELAR
        </Button>
        <Button color="primary-dark bold" onClick={() => addRepresentative()}>
        {loading.loading? [
                    <span key="span-k-1" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key="span-k-2" className='ml-2'> Cargando...</span>
                  ] :
                    <span>{isAnUpdate? 'ACTUALIZAR' : 'AGREGAR'}</span>
                  }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalRepresentative