import { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input, CustomInput, FormText, FormFeedback } from "reactstrap";
import SubtitleDivider from "../subtitle-divider/SubtitleDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rutHelper from "../../helpers/rutHelper";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileHelper from "../../helpers/FileHelper";
import { CircularProgress } from "@material-ui/core";
import { PatternFormat } from 'react-number-format'
import {TransferenceDocumentType} from '@autofact/operation-lib-model-notary-platform';

const {BUYER_AUTHORIZATION, SELLER_AUTHORIZATION} = TransferenceDocumentType;
const NotaryContractRepresentativeForm = ({person, type, number, communes, updatePerson, uploadFile, isContractUpdate}) => {
    const subtype = 'representantes';
    const docTypeId = type === 'vendedor' ? SELLER_AUTHORIZATION.id : BUYER_AUTHORIZATION.id;

    const [fileLoading, setFileLoading] = useState(false);
    const [tempFile, setTempFile] = useState(' ');
    const [notaryPower, setNotaryPower] = useState({});
    const [invalidFile, setInvalidFile] = useState(false);

    useEffect(()=>{
        const notaryPower = ()=>{
            let power = person?.documents ? person.documents[0] : null;
            power = power ?? {
                name: '',
                typeId: docTypeId,
                url: '',
                identityCode: person.identityCode
            };
            setNotaryPower(power);
        };
        notaryPower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const comunas = communes || [];

    const uploadNotaryPower = async(el) => {
        const files = el.files;
        const file = files[0] || null;
        if(file?.type && FileHelper.allowedExtensions(file.type)){
            setInvalidFile(false);
            setTempFile(file?.name);
            setFileLoading(true);
            let url = '';
            let fileName = '';
            url = await uploadFile(file, 'poder-notarial');
            fileName = FileHelper.getFileNameFromUrl(url);
            const newNotaryPower = ({ url, name: fileName, typeId: docTypeId, identityCode: person.identityCode});
            setNotaryPower(newNotaryPower);
            const newPersona = person;
            if(newPersona.documents === undefined){
                newPersona.documents = [];
            }
            const index = newPersona?.documents?.findIndex( element => element.typeId === docTypeId);
            if(index !== -1){
                newPersona.documents[index] = newNotaryPower;
            }else{
                newPersona.documents.push(newNotaryPower);
            }
            updatePerson(newPersona, type, subtype, number);
            setFileLoading(false);
        }else{
            setInvalidFile(true);
        }
    }

    const updateForm = el => {
        const newPersona = person;
        let index = el.id.split('-')[0];
        if(index.substring(0,6) === "notary"){
            const subIndex = index.split('.')[1];
            if(newPersona.notary === undefined){
                newPersona.notary = {};
            }
            newPersona.notary[subIndex] = el.value;
        } else{
            newPersona[index] = el.value;
        }

        if(index === "name" || index === "fatherLastName" || index === "motherLastName"){
            if(index === "name") {
                newPersona.fullName = `${el.value} ${newPersona.fatherLastName} ${newPersona.motherLastName}`;
            }
            if(index === "fatherLastName") {
                newPersona.fullName = `${newPersona.name} ${el.value} ${newPersona.motherLastName}`;
            }
            if(index === "motherLastName") {
                newPersona.fullName = `${newPersona.name} ${newPersona.fatherLastName} ${el.value}`;
            }
        }

        updatePerson(newPersona, type, subtype, number);
    };

    const removeFile = () =>{
        const newNotaryPower = {
            name: '',
            typeId: docTypeId,
            url: '',
            identityCode: person.identityCode
        };
        setNotaryPower(newNotaryPower)
        const newPersona = person;
        setTempFile(' ');
        newPersona.documents = [];
        updatePerson(newPersona, type, subtype, number);
    }

    return(
        <>
            <div className=' d-flex align-items-center subtitle-box-step-validation px-2 mb-2'>
                <div className='d-flex justify-content-center'>
                    <span className='font-weight-bold my-1'><FontAwesomeIcon icon={'user'} size='lg' color='gray' /></span>
                </div>
                <span className='h2 dark-color font-weight-bold ml-2 mb-0'>Representante {number + 1} </span>
            </div>
            <SubtitleDivider>Información personal del representante</SubtitleDivider>
            <Row>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`identityCode-${person.identityCode}`}>Rut del representante</Label>
                        <Input type="text" id={`identityCode-${person.identityCode}`} className="bg-white"
                        value={ rutHelper.toDotedRut(person.identityCode) }
                        onChange={(e)=> updateForm(e.target)} required disabled={type ==='vendedor' && isContractUpdate}
                        invalid={!rutHelper.validateRut(person.identityCode)}
                        />
                        <FormFeedback>Ingrese un RUT válido</FormFeedback>
                    </FormGroup>
                </Col>
                {type === 'vendedor' &&
                    <>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`power-${person.identityCode}`}>Subir Poder</Label>
                                {notaryPower.url ?
                                    <div className='d-flex flex-row align-items-center'>
                                        <a className="link-block" target="_blank" rel="noreferrer" href={notaryPower.url}>
                                            <AttachFileIcon />
                                            { FileHelper.getFileNameFromUrl(notaryPower.url)}
                                        </a>
                                        <FontAwesomeIcon icon="trash" color='red' className='pointer ml-2' onClick={ () => removeFile()}  disabled={type ==='vendedor' && isContractUpdate}/>
                                    </div>
                                :
                                    <>
                                        <div className="d-flex">
                                            <CustomInput type="file" id={`sii-${person?.identityCode}`} className="bg-white mr-2" label={tempFile} accept=".png, .jpg, .pdf" onChange={ e => uploadNotaryPower(e.target)} invalid={invalidFile}/>
                                            { fileLoading && <i className='ml-auto pt-2 pr-2'><CircularProgress size={20}/></i>}
                                        </div>
                                        <FormText>{invalidFile && 'Seleccione uno de los '}Formatos permitidos: JPG, PNG, PDF</FormText>
                                    </>
                                }
                            </FormGroup>
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`name-${person.identityCode}`}>Nombres del representante</Label>
                        <Input type="text" id={`name-${person.identityCode}`} className="bg-white" value={person.name} onChange={(e)=> updateForm(e.target)} required  disabled={type ==='vendedor' && isContractUpdate}/>
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`fatherLastName-${person.identityCode}`}>Apellido paterno del representante</Label>
                        <Input type="text" id={`fatherLastName-${person.identityCode}`} className="bg-white" value={person.fatherLastName} onChange={(e)=> updateForm(e.target)} required  disabled={type ==='vendedor' && isContractUpdate} />
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`motherLastName-${person.identityCode}`}>Apellido materno del representante</Label>
                        <Input type="text" id={`motherLastName-${person.identityCode}`} className="bg-white" value={person.motherLastName} onChange={(e)=> updateForm(e.target)} required  disabled={type ==='vendedor' && isContractUpdate}/>
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`sex-${person.identityCode}`}>Sexo</Label>
                        <Input type="select" id={`sex-${person.identityCode}`} className="bg-white" value={person.sex } onChange={(e)=> updateForm(e.target)} required  disabled={type ==='vendedor' && isContractUpdate}>
                            <option value='' disabled>Seleccione una opción</option>
                            <option value='F'>Femenino</option>
                            <option value='M'>Masculino</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            {type === 'vendedor' &&
                <>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary.date-${person.identityCode}`}>Fecha poder</Label>
                                <Input type="date" id={`notary.date-${person.identityCode}`} className="bg-white" value={person.notary?.date} onChange={(e)=> updateForm(e.target)} required disabled={type ==='vendedor' && isContractUpdate}/>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary.name-${person.identityCode}`}>Notario</Label>
                                <Input type="text" id={`notary.name-${person.identityCode}`} className="bg-white" value={person.notary?.name} onChange={(e)=> updateForm(e.target)} required disabled={type ==='vendedor' && isContractUpdate}/>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary-${person.identityCode}`}>Notaría (opcional)</Label>
                                <Input type="select" id={`notary.notary-${person.identityCode}`} className="bg-white" value={person.notary?.notary} onChange={(e)=> updateForm(e.target)} disabled={type ==='vendedor' && isContractUpdate}>
                                    <option value="">Seleccione</option>
                                    <option value="PRIMERA">PRIMERA</option>
                                    <option value="SEGUNDA">SEGUNDA</option>
                                    <option value="TERCERA">TERCERA</option>
                                    <option value="CUARTA">CUARTA</option>
                                    <option value="QUINTA">QUINTA</option>
                                    <option value="SEXTA">SEXTA</option>
                                    <option value="SÉPTIMA">SÉPTIMA</option>
                                    <option value="OCTAVA">OCTAVA</option>
                                    <option value="NOVENA">NOVENA</option>
                                    <option value="DÉCIMA">DÉCIMA</option>
                                    <option value="UNDÉCIMA">UNDÉCIMA</option>
                                    <option value="DUODÉCIMA">DUODÉCIMA</option>
                                    <option value="DECIMOTERCERA">DECIMOTERCERA</option>
                                    <option value="DECIMOCUARTA">DECIMOCUARTA</option>
                                    <option value="DECIMOQUINTA">DECIMOQUINTA</option>
                                    <option value="DECIMOSEXTA">DECIMOSEXTA</option>
                                    <option value="DECIMOSÉPTIMA">DECIMOSÉPTIMA</option>
                                    <option value="DECIMOCTAVA">DECIMOCTAVA</option>
                                    <option value="DECIMONOVENA">DECIMONOVENA</option>
                                    <option value="VIGÉSIMA">VIGÉSIMA</option>
                                    <option value="VIGÉSIMO PRIMERA">VIGÉSIMO PRIMERA</option>
                                    <option value="VIGÉSIMO SEGUNDA">VIGÉSIMO SEGUNDA</option>
                                    <option value="VIGÉSIMO TERCERA">VIGÉSIMO TERCERA</option>
                                    <option value="VIGÉSIMO CUARTA">VIGÉSIMO CUARTA</option>
                                    <option value="VIGÉSIMO QUINTA">VIGÉSIMO QUINTA</option>
                                    <option value="VIGÉSIMO SEXTA">VIGÉSIMO SEXTA</option>
                                    <option value="VIGÉSIMO SÉPTIMA">VIGÉSIMO SÉPTIMA</option>
                                    <option value="VIGÉSIMO OCTAVA">VIGÉSIMO OCTAVA</option>
                                    <option value="VIGÉSIMO NOVENA">VIGÉSIMO NOVENA</option>
                                    <option value="TRIGÉSIMA">TRIGÉSIMA</option>
                                    <option value="TRIGÉSIMO PRIMERA">TRIGÉSIMO PRIMERA</option>
                                    <option value="TRIGÉSIMO SEGUNDA">TRIGÉSIMO SEGUNDA</option>
                                    <option value="TRIGÉSIMO TERCERA">TRIGÉSIMO TERCERA</option>
                                    <option value="TRIGÉSIMO CUARTA">TRIGÉSIMO CUARTA</option>
                                    <option value="TRIGÉSIMO QUINTA">TRIGÉSIMO QUINTA</option>
                                    <option value="TRIGÉSIMO SEXTA">TRIGÉSIMO SEXTA</option>
                                    <option value="TRIGÉSIMO SÉPTIMA">TRIGÉSIMO SÉPTIMA</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary.comunaId-${person.identityCode}`}>Comuna notaría</Label>
                                <Input type="select" id={`notary.comunaId-${person.identityCode}`} className="bg-white" value={person.notary?.comunaId || ''} onChange={(e)=> updateForm(e.target)} required disabled={type ==='vendedor' && isContractUpdate} >
                                    <option value='' disabled>Seleccione una comuna</option>
                                    {comunas.map((el, key) =>
                                        <option value={el.idcomuna} key={key} >{el.nombre}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            }
            <SubtitleDivider>Datos de contacto del representante</SubtitleDivider>
            <Row>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`email-${person.identityCode}`}>Correo electrónico</Label>
                        <Input type="email" id={`email-${person.identityCode}`} className="bg-white" value={person?.email} onChange={(e)=> updateForm(e.target)} required disabled={type ==='vendedor' && isContractUpdate}/>
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`phone-${person.identityCode}`}>Teléfono</Label>
                        <PatternFormat pattern='\D([+56]\d[2-9])(\d{4})(\d{4})\D*' format="+56#########" allowEmptyFormatting type="phone" title='número de teléfono' id={`phone-${person.identityCode}`} className="form-control bg-white" value={person?.phone } onChange={(e)=> updateForm(e.target)} required disabled={type ==='vendedor' && isContractUpdate}/>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default NotaryContractRepresentativeForm;
