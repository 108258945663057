import React, {Component} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, Alert, Row, Col } from 'reactstrap';
import IdentityListContainer from '../../components/identity-list-container/IdentityListContainer';
import IdentityListFilter from '../../components/identity-list-filter/IdentityListFilter';
import TelepresenceNotaryEnablementButton from '../../components/telepresence-actions/TelepresenceNotaryEnablementButton';
import AuthService from '../../services/AuthService';
import { Permissions } from '@autofact/operation-lib-model-notary-platform';
import { withRouter } from "react-router-dom";

import FilterIcon from '../../theme/assets/filter_conces.svg';

const tabsDefinition = {
    inProcess: {
        id: 'inProcess',
        title: 'En proceso',
        filters: {
            status: 1,
            new_flow: 2
        }
    },
    completed: {
        id: 'completed',
        title: 'Completadas',
        filters: { status: 2}
    },
    full: {
        id: 'full',
        title: 'Consultar todos',
        filters: {}
    }
};
const BASE_URL = `${window.location.protocol}//${window.location.host}/`;
class IdentityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabsDefinition.inProcess.id,
            filtersCollapsed: true,
            isMessageVisible:false,
            message:{},
            meetingInfo: null,
            listFilters: {
                inProcess: Object.assign({}, tabsDefinition.inProcess.filters),
                completed: Object.assign({}, tabsDefinition.completed.filters),
                full: Object.assign({}, tabsDefinition.full.filters)
            },
            recordCount: {
                inProcess: 0,
                completed: 0,
                full: 0
            },
            showTelepresenceNotaryEnablementButton: false,
            showButtonJoinMultiMeetingButton: false,
        };
        this.toggleFilters = this.toggleFilters.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.showSuccesMessage = this.showSuccesMessage.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.showDetailsInTabGeneric = this.showDetailsInTabGeneric.bind(this);
        this.buildFilters = this.buildFilters.bind(this);
        this.setFiltersCallback = this.setFiltersCallback.bind(this);
        this.currentTableRef = React.createRef();
        this.filterComponentRef = React.createRef();
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            const listFilters = this.state.listFilters;
            listFilters[tab] = tabsDefinition[tab].filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
            this.filterComponentRef.current.resetFilters();
        }
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ activeTab: this.props.location.state.tab })
        }
        const recordCount = {};
        Object.keys(tabsDefinition).forEach(tabKey => {
            recordCount[tabKey] = 0;
        });

        const showButton = AuthService.canDo(Permissions.TELE_PRESENCE_ENABLE_ATTENTION_BUTTON_VIEW);
        const showButtonJoinMultiMeeting = AuthService.canDo(Permissions.TELE_PRESENCE_JOIN_MULTIPLE_MEETINGS);
        this.setState({ recordCount, showTelepresenceNotaryEnablementButton: showButton, showButtonJoinMultiMeetingButton: showButtonJoinMultiMeeting });
    }

    toggleFilters() {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    buildFilters(tab, filters = null) {
        const where = filters || this.state.listFilters[tab];
        const pathCode = this.props.match.params.code;
        if(pathCode){
            if(!where.CODE || pathCode === where.CODE){
                where.CODE = pathCode;
            }
        }
        return {where: JSON.stringify(where)};
    }

    setFiltersCallback(filters) {
        const tab = this.state.activeTab;
        const listFilters = this.state.listFilters;
        for (const filterKey in tabsDefinition[tab].filters) {
            if (tabsDefinition[tab].filters.hasOwnProperty(filterKey)) {
                if (filters.hasOwnProperty(filterKey)) {
                    const filterValue = tabsDefinition[tab].filters[filterKey].toString().split(',').filter(filterValue => filterValue.toString() === filters[filterKey].toString());
                    filters[filterKey] = filterValue.join(',');
                }
                else {
                    filters[filterKey] = tabsDefinition[tab].filters[filterKey];
                }
            }
        }
        listFilters[tab] = filters;
        this.setState({listFilters});
        this.currentTableRef.current.refreshFilters(this.buildFilters(tab, listFilters[tab]));
    }

    showDetailsInTabGeneric(filters){
        console.log(`called ${JSON.stringify(filters)}`)
        const tab = tabsDefinition.full.id;
        const listFilters = Object.assign({},this.state.listFilters);
        listFilters[tab] = filters;
        this.setState({
            activeTab: tab,
            listFilters,
            filtersCollapsed: true
        });
        setTimeout(() => {
            this.setFiltersCallback(listFilters[tab]);
        }, 100);


    }

    onDismiss() {
        this.setState({isMessageVisible: false});
    }

    showSuccesMessage(message) {
        this.setState({
            message: {
                success: true,
                text: message
            }, isMessageVisible: true
        })
    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    clearMeet = () => {
        this.setState({meetingInfo: null})
    }

    renderTelepresenceNotaryEnablementButton = () => {
        return (<Row className="mb-2">
            <Col>
                <TelepresenceNotaryEnablementButton />
            </Col>
        </Row>);
    }

    renderJoinMultiMeetingButton = () => {
        return (
            <Row className="mb-2">
                <Col>
                    <a className="btn btn-sm btn-primary-dark" href={`${BASE_URL}tele-presencia/multi-meeting`} target="_blank" rel="noopener noreferrer">Ver reuniones telepresencia</a>
                </Col>
            </Row>
        );
    }

    render() {
        const { showTelepresenceNotaryEnablementButton, showButtonJoinMultiMeetingButton } = this.state;
        const activeTab = this.state.activeTab;
        const listMenu = [];
        let message = null;
        let renderTelepresenceNotaryEnablementButton = showTelepresenceNotaryEnablementButton ? this.renderTelepresenceNotaryEnablementButton() : null;
        let renderJoinMultiMeetingButton = showButtonJoinMultiMeetingButton ? this.renderJoinMultiMeetingButton() : null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'}
                isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
        }

        return (
            <div>
                {(showTelepresenceNotaryEnablementButton || showButtonJoinMultiMeetingButton) &&
                    <TabContent activeTab={0} className="mb-5 p-0" >
                        <TabPane tabId={0} className="py-0" >
                            <Row className="align-items-center">
                                <Col>
                                    <p>{message}</p>
                                    {renderTelepresenceNotaryEnablementButton}
                                </Col>
                                <Col className="text-right">{renderJoinMultiMeetingButton}</Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                }
                <Nav tabs className="tabs-dark">
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <NavItem key={`tab-nav-item-${index}`}>
                                <NavLink
                                    key={`tab-nav-${index}`}
                                    className={activeTab === tab.id ? 'active' : ''}
                                    onClick={() => this.toggleTab(tab.id)}
                                >
                                    {tab.title} ({this.state.recordCount[tab.id]})
                                </NavLink>
                            </NavItem>
                        );
                    })}
                    <NavItem className="nav-button">
                        {listMenu}
                        <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters}>
                            <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                        </Button>
                    </NavItem>
                </Nav>
                <Collapse isOpen={!this.state.filtersCollapsed}>
                    <div className="filter-form-wrapper">
                        <IdentityListFilter ref={this.filterComponentRef}
                            defaultFilters={tabsDefinition[this.state.activeTab].filters}
                            activeTab={this.state.activeTab}
                            setFiltersCallback={this.setFiltersCallback}/>
                    </div>
                </Collapse>
                <TabContent activeTab={activeTab}>
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                                {activeTab === tab.id ?
                                    <IdentityListContainer
                                        id={tab.id}
                                        tab={tab.id}
                                        ref={this.currentTableRef}
                                        key={`tab-list-${index}`}
                                        updateCount={count => {
                                            const recordCountState = this.state.recordCount;
                                            recordCountState[tab.id] = count;
                                            this.setState({recordCount: recordCountState});
                                        }}
                                        filters={this.buildFilters(tab.id)}
                                    />
                                    :
                                    <div key={`tab-loading-${index}`}>Cargando</div>
                                }
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        );
    }

}

export default withRouter(IdentityList);
