import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'reactstrap';

import BackendApiService from "../../services/BackendApiService";
import FirmaDocumentosList from "../firmas-documentos-list/FirmaDocumentosList";
import ModalMessage from "../modal-message/ModalMessage";
import excelIcon from '../../theme/assets/ico-excel.png';

class FirmasDocumentosListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signDocumentsList: [],
            params: this.props.filters || {},
            fetchParams: ['where', 'per_page', 'page','sorted'],
            exporting: false,
            exportingExcel: false,
            showMessage: false,
            exportResult: '',
            recordsTotal: '',
            emp_mail: '',
            selected: {},
            selectedAll: false,
        };
        this.fetchData = this.fetchData.bind(this);
        this.download = this.download.bind(this);
        this.signDocumentListComponentRef = React.createRef();
    }

    componentWillReceiveProps(props) {
        if (props.massiveApprovalFinished) {
            this.setState({selected: {}, selectedAll: false});
        }
    }

    async fetchData(params) {
        params = Object.assign(this.state.params, params);
        params = this.filterParams(params);
        const signDocumentsList = await BackendApiService.getFirmasDocumentosList(params);
        this.setState({params, signDocumentsList, selectedAll: false});
        this.props.updateCount(signDocumentsList.extra.recordsFiltered);
        return signDocumentsList;
    }

    filterParams(params) {
        const filtered = Object.keys(params)
            .filter(key => this.state.fetchParams.includes(key))
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});
        return filtered;
    }

    async exportData(format,from) {
        const params = this.state.params;
        params.format = format;
        params.from = from;
        const exportResult = await BackendApiService.getFirmasDocumentosList(params);
        return exportResult.data;
    }

    download(format,from) {
        this.setState({exportingExcel: true})
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format,from)
            .then((response) => {
                this.setState({exporting: false, exportingExcel: false, exportResult: response.url});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({exporting: false});
            });
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            this.signDocumentListComponentRef.current.reloadData();
        }, 100);
    }

    async componentDidMount() {
        let session = await BackendApiService.getSessionData();
        if (session) {
            this.setState({
                emp_mail: session.usuario.mail
            });
        }
    }

    selectMandate = (itemId, item, isChecked) => {
        let previousSelected = {...this.state.selected};
        let isSelectedAll = this.state.selectedAll;
        const documentsData = this.state.signDocumentsList.data;
        if (item === 'all') {
            isSelectedAll = isChecked;
            if (isChecked) {
                documentsData.forEach(doc => {
                    previousSelected[doc.id] = doc;
                })
            } else {
                documentsData.forEach(doc => {
                    delete previousSelected[doc.id];
                })
            }
        } else {
            if (isChecked) {
                previousSelected[itemId] = item;
            } else {
                delete previousSelected[itemId];
            }
            isSelectedAll = Object.keys(previousSelected).length === documentsData.length;
        }
        this.setState({selected: previousSelected, selectedAll: isSelectedAll});
        this.props.selectedCallback(Object.keys(previousSelected));
    }

    render() {
        const listActions = [];
        switch (this.props.tab) {
            case 'signed': {
                listActions.push(
                    <div  key="signed-view">
                        {this.state.exportResult ?
                        <a
                        key="export-xls-download"
                        href={this.state.exportResult}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({exportResult: ''})}
                        className={'btn donwload-button btn-sm actions'}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-download" />
                            Descargar Excel
                        </a>
                        :
                        <button
                        key="export-xls"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('xlsx','signed')}
                        disabled={this.state.exporting}>
                            <img src={excelIcon} alt="Excel icon"/>
                            {this.state.exportingExcel ? 'Exportando...': 'Exportar a Excel'}
                        </button>
                        }
                    </div>
                );
                break;
            }
            case 'forSign': {
                listActions.push(
                    <div key="repertory-view">
                        {this.state.exportResult ?
                        <a
                        key="export-xls-download"
                        href={this.state.exportResult}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({exportResult: ''})}
                        className={'btn donwload-button btn-sm actions'}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-download" />
                            Descargar Excel
                        </a>
                        :
                        <button
                        key="export-xls"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('xlsx',null)}
                        disabled={this.state.exportingAll}>
                            <img src={excelIcon} alt="Excel icon"/>
                            {this.state.exportingAll? 'Exportando...': 'Exportar a Excel'}
                        </button>
                        }
                    </div>
                );
                break;
            }
            case 'full': {
                listActions.push(
                    <div key="repertory-view">
                        {this.state.exportResult ?
                        <a
                        key="export-xls-download"
                        href={this.state.exportResult}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({exportResult: ''})}
                        className={'btn donwload-button btn-sm actions'}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-download" />
                            Descargar Excel
                        </a>
                        :
                        <button
                        key="export-xls"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('xlsx','full')}
                        disabled={this.state.exporting}>
                            <img src={excelIcon} alt="Excel icon"/>
                            {this.state.exportingExcel ? 'Exportando...': 'Exportar a Excel'}
                        </button>
                        }
                    </div>
                );
                break;
            }
            default: {
                break;
            }
        }
        let successMessage = '';
        if (this.props.massiveApprovalFinished && this.props.tab === 'forApproval') {
            successMessage = <Alert color="success" isOpen={true}>
                {`Se aprobaron satisfactoriamente los mandatos seleccionados`}
            </Alert>
        }

        return (
            <div>
                {successMessage}
                <FirmaDocumentosList
                    ref={this.signDocumentListComponentRef}
                    id={this.props.id}
                    selected={this.state.selected}
                    selectedAll={this.state.selectedAll}
                    selectRow={this.selectMandate}
                    fetchDataCallback={this.fetchData}
                    approving={this.props.approving}
                    approvingData={this.props.approvingData}
                />
                {this.state.showMessage ?
                    <ModalMessage message={`Se esta generando el paquete que se enviará al correo: ${this.state.emp_mail}`} messageType="success"/>
                    :
                    ''
                }
                <div className="action-buttons">
                    {listActions}
                </div>
            </div>
        );
    }
}

export default FirmasDocumentosListContainer;
