import React, { useEffect, useState, useRef } from 'react';

import BackendApiService from '../../services/BackendApiService';
import Footer from "../../components/footer/Footer"
import {app}  from '../../config'
import styled from 'styled-components';

import Meeting from './meeting';


const { timeToReloadMultiMeeting } = app.telepresence;



const DivMainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    gap: 10px;
    background-color: #efefef;
  `;
function NotaryMultiMeeting() {

  const [meet1, setMeet1] = useState({});
  const [meet2, setMeet2] = useState({});
  const [meet3, setMeet3] = useState({});
  const [meet4, setMeet4] = useState({});
  const [meet5, setMeet5] = useState({});
  const [meet6, setMeet6] = useState({});

  const loadedMeetigns = useRef([]);
  

  useEffect(() => {

    const interval = setInterval(() => {
      getTransferenceData();
    }, timeToReloadMultiMeeting);
    
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  
  async function getMeetingsData({ transferCodes }) {
    
    const queryStringParams = {
      transferCodes,
    };
    const { data: meetingsData } = await BackendApiService.getIdentityNotaryMeetings(queryStringParams);
    const newMeets = meetingsData.filter((meet) => {
        const findIndex = loadedMeetigns.current.findIndex((meeting) => meeting.MeetingId === meet.meetingInfo.Meeting.MeetingId);
        if (findIndex === -1) {
          return meet.meetingInfo
        }
        return null;
    });

    const newLoadedMeetings = [...loadedMeetigns.current];
    const settedStates = newLoadedMeetings.map(item => item.state)

    if(!settedStates.includes(1) && newMeets.length > 0) {
      const {meetingInfo} = newMeets.shift();
      setMeet1(meetingInfo);
      newLoadedMeetings.push({MeetingId: meetingInfo.Meeting.MeetingId, state: 1});
    }
    if(!settedStates.includes(2) && newMeets.length > 0) {
      const {meetingInfo} = newMeets.shift();
      setMeet2(meetingInfo);
      newLoadedMeetings.push({MeetingId: meetingInfo.Meeting.MeetingId, state: 2});
    }
    if(!settedStates.includes(3) && newMeets.length > 0) {
      const {meetingInfo} = newMeets.shift();
      setMeet3(meetingInfo);
      newLoadedMeetings.push({MeetingId: meetingInfo.Meeting.MeetingId, state: 3});
    }
    if(!settedStates.includes(4) && newMeets.length > 0) {
      const {meetingInfo} = newMeets.shift();
      setMeet4(meetingInfo);
      newLoadedMeetings.push({MeetingId: meetingInfo.Meeting.MeetingId, state: 4});
    }
    if(!settedStates.includes(5) && newMeets.length > 0) {
      const {meetingInfo} = newMeets.shift();
      setMeet5(meetingInfo);
      newLoadedMeetings.push({MeetingId: meetingInfo.Meeting.MeetingId, state: 5});
    }
    if(!settedStates.includes(6) && newMeets.length > 0) {
      const {meetingInfo} = newMeets.shift();
      setMeet6(meetingInfo);
      newLoadedMeetings.push({MeetingId: meetingInfo.Meeting.MeetingId, state: 6});
    }
    if( newMeets.length > 0){
      console.log(`No hay mas espacios, quedan ${newMeets.length} meetings en cola`);
    }
    loadedMeetigns.current = newLoadedMeetings;
    findFinishMeeting(meetingsData, loadedMeetigns.current)
  }

  async function getTransferenceData() {
    const params = {
      page: 1,
      per_page: 50,
      sorted: '{}',
      where: '{}',
    };
    const identityList = await BackendApiService.getIdentityProcess(params);
    const { data } = identityList;
    const transferCodes = data.map(({ code }) => code);
    // Eliminamos duplicados en caso de existir
    const uniqueTransferCodes = [...new Set(transferCodes)];
    getMeetingsData({ transferCodes: uniqueTransferCodes });
  }

  async function findFinishMeeting(meetingList, newLoadedMeetings){
    
    const meetsArray = meetingList.map((meet) => meet.meetingInfo.Meeting.MeetingId);
    newLoadedMeetings.forEach((meet) => {
      if(!meetsArray.includes(meet.MeetingId)){
        onFinish(meet);
      }
    });
  }
  
  function onFinish(meet){
    console.log('onFinish', meet);
    switch (meet.state){
      case 1:
        setMeet1({});
        break;
      case 2:
        setMeet2({});
        break;
      case 3:
        setMeet3({});
        break;
      case 4:
        setMeet4({});
        break;
      case 5:
        setMeet5({});
        break;
      case 6:
        setMeet6({});
        break;
      default:
        break;
    }
    const index = loadedMeetigns.current.findIndex((item) => item.MeetingId === meet.MeetingId);
    loadedMeetigns.current.splice(index, 1)
  }

  return (
    <div style={{"backgroundColor": "#1e3c5a"}}>
        <div style={{"backgroundColor": "#efefef", padding:"10px", height: "100%"}}>
          <DivMainWrapper>
            <Meeting meetData={meet1} />
            <Meeting meetData={meet2} />
            <Meeting meetData={meet3} />
            <Meeting meetData={meet4} />
            <Meeting meetData={meet5} />
            <Meeting meetData={meet6} />
          </DivMainWrapper>
        </div>
        <Footer />
    </div>
  );
}

export default NotaryMultiMeeting;
