import { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, FormGroup, Label, Input, Button, ModalFooter, FormFeedback } from 'reactstrap'
import rutHelper from '../../helpers/rutHelper';
import BackendApiService from '../../services/BackendApiService';
import { CircularProgress } from '@material-ui/core';
import PeopleHelper from '../../helpers/PeopleHelper';

const ModalRepresentative = ({data, isCompanySeller}) => {

  const {createSellerRep, isOpen, close, loading, selectedRepresentative, updateRepresentative } = data;

  const [inputRut, setInputRut] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputSecondLN, setInputSecondLN] = useState('');
  const [isAnUpdate, setIsAnUpdate] = useState(false);
  const [invalidRut, setInvalidRut] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidSecondLN, setInvalidSecondLN] = useState(false);
  const [representative, setRepresentative] = useState({});
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if(Object.keys(selectedRepresentative).length !== 0) {
      setIsAnUpdate(true);
      setInputRut(selectedRepresentative.identityCode);
      setInputName(selectedRepresentative.name);
      setInputLastName(selectedRepresentative.fatherLastName);
      setInputSecondLN(selectedRepresentative.motherLastName);
    } else{
      setIsAnUpdate(false);
    }
  }, [selectedRepresentative])

  useEffect(() => {

  }, )

  const cleanInputs = () => {
    setInputRut('');
    setInputName('');
    setInputLastName('');
    setInputSecondLN('');
  };

  const cleanInvalidInputs = () => {
    setInvalidRut(false);
    setInvalidName(false);
    setInvalidLastName(false);
    setInvalidSecondLN(false);
  }

  const inputValidations = () => {
    cleanInvalidInputs();
    const validatedRut = rutHelper.validateRut(inputRut);
  
    if (inputRut.length === 0 || !validatedRut || inputName.length === 0 || inputLastName.length === 0 || inputSecondLN.length === 0) {
      if (inputRut.length === 0 || !validatedRut) setInvalidRut(true);
      if (inputName.length === 0) setInvalidName(true);
      if (inputLastName.length === 0) setInvalidLastName(true);
      if (inputSecondLN.length === 0) setInvalidSecondLN(true);
      return false;
    }
  
    return true;
  };

  const createOrUpdateRepresentative = () => {

    const valid = inputValidations();

    if(!valid){
      return;
    }
    let newRepresentative = {
      identityCode: inputRut,
      fullName: `${inputName} ${inputLastName} ${inputSecondLN}`,
      motherLastName: inputSecondLN,
      email: '',
      phone: '',
      sex: '',
      ...{
        ...representative,
        name: inputName,
        fatherLastName: inputLastName,
        motherLastName: inputSecondLN
      }
    };

    cleanInputs();
    if(isAnUpdate){
      const filteredRepresentativeData = PeopleHelper.filterNoEmptyObjectData(newRepresentative);
      filteredRepresentativeData.address && delete filteredRepresentativeData.address;
      updateRepresentative(newRepresentative) 
    } else {
      createSellerRep(newRepresentative);
    } 
  };

  const onClose = () => {
    cleanInvalidInputs();
    cleanInputs();
    close();
  }

  const searchRepresentative = async (rut) => {
    setSearching(true);
    const res = await BackendApiService.getPersonByRut(rut);
    if(res){
      setRepresentative(res);
      setInputName(res.name);
      setInputLastName(res.fatherLastName);
      setInputSecondLN(res.motherLastName);
    }else{
      setRepresentative({});
      setInputName('');
      setInputLastName('');
      setInputSecondLN('');
    }
    setSearching(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size={'lg'}>
      <div className="modal-header bg-primary-darker text-white">
        <h3 className="modal-title">{ isAnUpdate? 'Modificar' :'Agregar'} representante {isCompanySeller ? 'legal': ''}</h3>
        <button type="button" className="close white-circle-close" onClick={() => onClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form disabled={loading}>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="inputRut">Rut del representante</Label>
              <Input type="text" name="rut" id="inputRut" maxLength="9" defaultValue={inputRut}
               onInput={(e) => setInputRut(e.target.value)} 
               onBlur={(e) => searchRepresentative(e.target.value)}
               invalid={invalidRut}
               />
              <FormFeedback valid={false}>Ingrese un Rut válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={1}>
            <div className='d-flex flex-column justify-content-center h-100 mt-2'>
              <CircularProgress size={25} className={searching? '': 'd-none'} />
            </div>
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="inputName">Nombre del representante</Label>
              <Input type="text" name="firstName" id="inputName" defaultValue={inputName} onInput={(e) => setInputName(e.target.value)} invalid={invalidName}/>
              <FormFeedback valid={false}>Ingrese un nombre válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="inputLastName">Apellido paterno del representante</Label>
              <Input type="text" name="lastName" id="inputLastName" defaultValue={inputLastName} onInput={(e) => setInputLastName(e.target.value)} invalid={invalidLastName} />
              <FormFeedback valid={false}>Ingrese un apellido válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="inputSecondLN">Apellido materno del representante</Label>
              <Input type="text" name="secondLastName" id="inputSecondLN" defaultValue={inputSecondLN} onInput={(e) => setInputSecondLN(e.target.value)} invalid={invalidSecondLN}/>
              <FormFeedback valid={false}>Ingrese un apellido válido</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        </Form>
      </div>
      <ModalFooter className="justify-content-center">
      <Button color="outline-primary-dark" onClick={() => onClose()}> CANCELAR </Button>
        <Button color="primary-dark" onClick={() => createOrUpdateRepresentative()}>
        {loading? [
                    <span key="span-k-1" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key="span-k-2" className='ml-2'> Cargando...</span>
                  ] :
                    <span>{isAnUpdate? 'ACTUALIZAR' : 'AGREGAR'}</span>
                  }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalRepresentative
