import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Warning} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  placeholder: {
    border: '1px solid #bbb',
    height: '100%',
    minHeight: 300,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#efefef',
    color: '#999'
  },
  icon: {
    display: 'block'
  }
}))

const Placeholder = ({ label }) => {
  const classes = useStyles();

  return (
    <div className={classes.placeholder}>
      <span className={classes.icon}>
        <Warning fontSize={'large'} />
      </span>
      <h1>{label}</h1>
    </div>
  )
}

export default Placeholder;