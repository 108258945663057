import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {Redirect} from "react-router-dom";
import BackendApiService from '../../services/BackendApiService';

class TransferenceSignButton extends Component {

    constructor(props) {
        super(props);
        this.signTransference = this.signTransference.bind(this);
        this.state = {
            signing: false,
            signUrl: ''
        };
    }

    async signTransference() {
        this.setState({signing: true});
        const signData = await BackendApiService.requestSignDataForTransference(this.props.code);
        const documentDataList = [];
        if (signData
            && signData.fileHeadData
            && signData.fileHeadData.Metadata
            && signData.fileHeadData.Metadata.sha1
            && signData.fileHeadData.Metadata.pages
            && signData.ticketData) {
            documentDataList.push(signData);
        }
        else {
            console.error(signData);
            this.setState({signing: false});
            return alert('Error preparando firma...');
        }

        if (documentDataList.length > 0) {
            const BASE_URL = `${window.location.protocol}//${window.location.host}/`;
            const urlCallback = `${BASE_URL}transferencias/firma-masiva-estado`;
            const signOperation = await BackendApiService.requestNewSignOperation(documentDataList, urlCallback, urlCallback);
            if (signOperation) {
                return this.setState({signing: false, signUrl: signOperation.linkOperation});
            }
            else {
                this.setState({signing: false});
                return alert('Error preparando firma...');
            }
        }
    }

    render() {
        if (this.state.signUrl) {
            return (<Redirect to={{
                pathname: '/redireccionando',
                state: {to: this.state.signUrl}
            }}/>);
        }
        return (
            <Button disabled={this.state.signing} onClick={this.signTransference} color='success'>
                {this.state.signing ? 'PREPARANDO FIRMA...' : 'FIRMAR'}
                <i className='glyphicon glyphicon-remove-sign text-icon'/>
            </Button>
        );
    }
}

export default TransferenceSignButton;
