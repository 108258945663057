import React, { useRef, useEffect, useState } from 'react'
import { Modal } from "reactstrap";
import {PhoneAndroid, LocalPrintshopOutlined} from "@material-ui/icons";
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rutHelper from '../../helpers/rutHelper';
import BackendApiService from '../../services/BackendApiService';


const SignModal = ({toggle, person, plate, data, updateSigner}) => {

  const componentRef = useRef();

  const { isOpen, close } = toggle;
  const [loading, setLoading] = useState(true);
  const [sign, setSign] = useState(null);

  

  useEffect(()=>{
    const signData = {...data,...{
      communeSignBuyer: "",
      communeSignSeller: "",
      rut: person.rut,
      name:person?.nombre_completo,
      phone_number:person?.celular,
      mail:person?.email
    }}

    const startSign = async () => {
      setLoading(true);
      const signResponse = await BackendApiService.startSignContrat(signData);
      setSign(signResponse);
      setLoading(false);
    }

    if(isOpen && !person.sign.endSign) startSign();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen])

  useEffect(()=>{
    if(sign){
      const newPerson = person;
      newPerson.sign.startSign = true;
      newPerson.code = sign.code;
      updateSigner(person.signNumber-1 , newPerson);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sign])
  
  return (
    <Modal isOpen={isOpen} toggle={() => close()} className='modal-sign' centered>
      <div className="modal-content">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Proceso de firma electrónica</h2>
          <button type="button" className="close white-circle-close" onClick={() => close()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-4 gray-light-bg">
          <div className='text-center'>
            <p className='text-20'>
              El proceso de firma para el contrato {plate} iniciado correctamente. Mediante un dispositivo móvil o tablet ambas partes pueden acceder al proceso de firma electrónica.
            </p>
          </div>
          {!loading ?
            (<>
              <div className='row mx-1 p-4 thin-border'>
                <div className='col-4 d-flex flex-column bold'>
                  <span className='text-18'>Firma {person.roleDescription || ''}</span>
                  <span className='text-14'>{person.nombre_completo || ''}</span>
                  <span className='text-18'>{rutHelper.toDotedRut(person.rut || '')}</span>
                </div>

                <div className='col-8'>
                  <div className='row'>
                    <div className='col-auto pr-0 d-flex flex-column justify-content-center text-right'>
                      <PhoneAndroid fontSize={'large'}/>
                    </div>
                    <div className='col'>
                      <span>
                        Escanea este código QR con un dispositivo móvil o tablet o ingresa a https://firma.id.autofactpro.com e ingresa el siguiente código.
                      </span>
                    </div>
                  </div>
                  <div className='row mt-3 justify-content-between'>
                    <img src={sign?.qr} alt="QR CODE" className='col-sm-auto qr-width' ref={componentRef}/>
                    <div className='col-auto d-flex flex-column justify-content-center text-center white-bg'>
                        <span className='text-14'>Código operación</span>
                        <span className='text-20 bold'>{sign?.code}</span>
                    </div>
                  </div>
                </div>

              </div>
              <div className='mt-2'>
                <ReactToPrint
                  trigger={() => <button className='btn btn-link primary-dark-color text-16 bold'><LocalPrintshopOutlined /> Imprimir código QR </button>}
                  content={() => componentRef.current}
                  bodyClass='d-flex align-items-center justify-content-center w-100'

                />            
              </div>
            </>)
            :
            <div className='qr-loading thin-border d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon icon="spinner" className='icon' spin size='3x'/>
            </div>
          }
        </div>
        <div className="modal-footer justify-content-center mb-5">
          <button className="btn btn-lg btn-primary-dark" disabled={loading}  onClick={() => close()}>OK, CERRAR</button> 
        </div>
      </div>
    </Modal>
  )
}

export default SignModal