import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotaryTag } from '@autofact/operation-lib-model-notary-platform';

import BackendApiService from "../../services/BackendApiService";
import TransferenceList from "../transference-list/TransferenceList";
import TransferenceHistoryList from "../transference-history-list/TransferenceHistoryList";
import ModalMessage from "../modal-message/ModalMessage";
import excelIcon from '../../theme/assets/ico-excel.png';
import {Permissions} from '@autofact/operation-lib-model-notary-platform';
import AuthService from '../../services/AuthService';

class TransferenceListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transferenceList: [],
            params: this.props.filters || {},
            fetchParams: ['where', 'per_page', 'page','sorted'],
            exporting: false,
            exportingExcel: false,
            showMessage: false,
            exportResult: '',
            recordsTotal: '',
            emp_mail: '',
            transferenceByTagSelected: {},
            selectedAll: false,
            selectedTagOption: '',
            tagValue: ''
        };
        this.fetchData = this.fetchData.bind(this);
        this.download = this.download.bind(this);
        this.selectTransference = this.selectTransference.bind(this);
        this.transferenceListComponentRef = React.createRef();
    }

    async fetchData(params) {
        if (this.state.params.sorted
                && Object.keys(this.state.params.sorted).length
                && !Object.keys(JSON.parse(params.sorted)).length) {

            params.sorted = this.state.params.sorted;
        }

        params = Object.assign(this.state.params, params);
        params = this.filterParams(params);

        const transferenceList = this.props.tab === 'history'? await BackendApiService.getTransferenceHistoryList(params): await BackendApiService.getTransferenceList(params);
        this.setState({params, transferenceList});
        this.props.updateCount(transferenceList.extra.recordsTotal);
        return transferenceList;
    }

    filterParams(params) {
        const filtered = Object.keys(params)
            .filter(key => this.state.fetchParams.includes(key))
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});
        return filtered;
    }

    async exportData(format,from) {
        const params = this.state.params;
        params.format = format;
        params.from = from;
        const exportResult = this.props.tab === 'history'? await BackendApiService.getTransferenceHistoryList(params): await BackendApiService.getTransferenceList(params);
        return exportResult.data;
    }

    download(format,from) {
        if (format === 'pdf-package') {
            this.setState({showMessage: true});
            this.setState({exporting: true});
        }else{
            this.setState({exportingExcel: true})
        }
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format,from)
            .then((response) => {
                this.setState({exporting: false, exportingExcel: false, exportResult: response.url});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({exporting: false});
            });
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            this.transferenceListComponentRef.current.reloadData();
        }, 100);
    }

    async componentDidMount() {
        let session = await BackendApiService.getSessionData();
        if (session) {
            this.setState({
                emp_mail: session.usuario.mail
            });
        }
    }

    async selectTransference(id, item, selected) {
        let transferenceByTagSelected = Object.assign({},this.state.transferenceByTagSelected);
        let selectedAll = false;

        if(selected && (item === 'all' || !item.valorEtiqueta)){
            if (item === 'all') {
                transferenceByTagSelected = {};
                for (let i = 0; i < this.state.transferenceList.data.length; i+= 1) {
                    transferenceByTagSelected[this.state.transferenceList.data[i].idtransferencia] =
                        this.state.transferenceList.data[i];
                }
                selectedAll = selected;
            } else {
                transferenceByTagSelected[id] = item;
            }
        }else{
            if (item === 'all') {
                transferenceByTagSelected = {};
            }
            delete transferenceByTagSelected[id];
        }

        this.setState({
            transferenceByTagSelected,
            selectedAll
        })
    }

    handleChangeTag = async selectedTagOption => {
        const transferenceListLocal = this.state.transferenceByTagSelected;
        await this.setState(
          { selectedTagOption },
        );

        const params = {
            transferences:[],
        };

        const keys = await Object.keys(transferenceListLocal);
        const transferenceList = await keys.map(async (key) => {
            return new Promise(async (resolve, reject) => {
                params.transferences.push({
                    code: transferenceListLocal[key].codigo,
                });

                let transferenceList = null;
                for (let i = 0;i < this.state.transferenceList.data.length; i += 1 ) {
                    if(transferenceListLocal[key].idtransferencia
                        === this.state.transferenceList.data[i].idtransferencia
                    )
                    await this.state.transferenceList.data.splice(i,1);
                    transferenceList = this.state.transferenceList;
                }
                resolve (transferenceList);
              });
        });

        const response = await BackendApiService.setTagEnteredToCivilregister(params);
        if (response.hasOwnProperty('affectedRows')
            && response.affectedRows
            === Object.keys(
                this.state.transferenceByTagSelected).length
            ) {
                await this.setStateAsync({
                    selectedAll: false,
                    transferenceList,
                    transferenceByTagSelected: {},
                    tagValue: ''
                });

                await Promise.all(this.state.transferenceList);
        }

        return response.affectedRows;
      };

      setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
      }

    render() {
        const listActions = [];
        switch (this.props.tab) {
            case 'repertory': {
                if(AuthService.canDo(Permissions.TRANSFERENCE_REPERTORY_EDIT)){
                    listActions.push(
                        <div key="repertory-view">
                            <Link key={`massive-repertory`} className="btn btn-primary-darker btn-sm mr-2" to={`transferencias/asignar-repertorio-masivo`}>
                                Repertorios rápidos
                            </Link>
                        </div>
                    );
                }
                break;
            }
            case 'signed': {
                listActions.push(
                    <div  key="signed-view">
                        <button
                        key="export-pdf-package"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('pdf-package',null)}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-pdf" />
                            {this.state.exporting ? 'Exportando...': 'Exportar PDF Paquete'}
                        </button>
                        {this.state.exportResult ?
                        <a
                        key="export-xls-download"
                        href={this.state.exportResult}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({exportResult: ''})}
                        className={'btn donwload-button btn-sm actions'}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-download" />
                            Descargar Excel
                        </a>
                        :
                        <button
                        key="export-xls"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('xlsx','signed')}
                        disabled={this.state.exporting}>
                            <img src={excelIcon} alt="Excel icon"/>
                            {this.state.exportingExcel ? 'Exportando...': 'Exportar a Excel'}
                        </button>
                        }
                    </div>
                );
                break;
            }
            case 'forSign': {
                listActions.push(
                    <div key="repertory-view">
                        {this.state.exportResult ?
                        <a
                        key="export-xls-download"
                        href={this.state.exportResult}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({exportResult: ''})}
                        className={'btn donwload-button btn-sm actions'}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-download" />
                            Descargar Excel
                        </a>
                        :
                        <button
                        key="export-xls"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('xlsx',null)}
                        disabled={this.state.exportingAll}>
                            <img src={excelIcon} alt="Excel icon"/>
                            {this.state.exportingAll? 'Exportando...': 'Exportar a Excel'}
                        </button>
                        }
                    </div>
                );
                break;
            }
            case 'full': {
                listActions.push(
                    <div key="repertory-view">
                        {this.state.exportResult ?
                        <a
                        key="export-xls-download"
                        href={this.state.exportResult}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.setState({exportResult: ''})}
                        className={'btn donwload-button btn-sm actions'}
                        disabled={this.state.exporting}>
                            <FontAwesomeIcon icon="file-download" />
                            Descargar Excel
                        </a>
                        :
                        <button
                        key="export-xls"
                        className={'btn btn-gray btn-sm actions'}
                        onClick={() => this.download('xlsx','full')}
                        disabled={this.state.exporting}>
                            <img src={excelIcon} alt="Excel icon"/>
                            {this.state.exportingExcel ? 'Exportando...': 'Exportar a Excel'}
                        </button>
                        }
                    </div>
                );
                break;
            }
            default: {
                break;
            }
        }

        return (
            <div>
                <div className={`mark-as-entered ${this.props.tab === 'full' ? '':'not-show'}`}>
                    <Select
                        value={this.state.tagValue}
                        onChange={this.handleChangeTag}
                        options={
                            Object.keys(NotaryTag).map((typeId, index, typeList) => {
                                const type = NotaryTag[typeId];
                                if (type && type.id === 0) {
                                    return {
                                        value: type.id,
                                        label: type.label
                                    };
                                }
                                return {};
                            })
                        }
                        name="tag"
                        id="tag"
                        className={`form-control-sm ${Object.keys(this.state.transferenceByTagSelected).length ? '' : 'not-show'}`}
                    />
                </div>
                {this.props.tab !== 'history' &&<TransferenceList
                    ref={this.transferenceListComponentRef}
                    id={this.props.id}
                    selected={this.state.transferenceByTagSelected}
                    selectRowByTag={this.selectTransference}
                    fetchDataCallback={this.fetchData}
                    selectedAll={this.state.selectedAll}
                />}

                {this.props.tab === 'history' &&<TransferenceHistoryList
                  ref={this.transferenceListComponentRef}
                  id={this.props.id}
                  fetchDataCallback={this.fetchData}
                />}
                {this.state.showMessage ?
                    <ModalMessage message={`Se esta generando el paquete que se enviará al correo: ${this.state.emp_mail}`} messageType="success"/>
                    :
                    ''
                }
                <div className="action-buttons">
                    {listActions}
                </div>
            </div>
        );
    }
}

export default TransferenceListContainer;
