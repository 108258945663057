class rutHelper {
  
  static toDotedRut(rut){
    const cleanRut = this.toCleanRut(rut);
    const formattedRut = cleanRut?.replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    return formattedRut;
  };

  static toCleanRut(rut){
    const cleanRut = rut.replaceAll('.', '').replace('-', '');
    return cleanRut;
  }

  static checkRutList(rutsArray){
    const lenghtValidate = rutsArray.some( val => val.length < 8 || val.length > 9);
    const formatValidate = rutsArray.some( val => !/[0-9kK]{0,9}/.test(val));
    const digitValidate = rutsArray.some( val => !this.validateRut(val));
    return lenghtValidate || formatValidate || digitValidate;
  }

  static validateRut(rutString){
    if(rutString){
      const rut = this.toCleanRut(rutString);
      const verificador = rut.slice(-1).toLowerCase();
      let numbers = rut.slice(0, -1);
      let M=0,S=1;
      for(;numbers;numbers=Math.floor(numbers/10)){
        S=(S+numbers%10*(9-M++%6))%11;
      }
      const validacion = S?S-1:'k';
      return verificador.toString() === validacion.toString();
    }
    return false;
  }

  static isCompanyRut(rut){
    const cleanRut = this.toCleanRut(rut);
    const sliceRut = cleanRut.slice(0, -1);
    return sliceRut > 55000000;
  }
}

export default rutHelper;