import React, { Component } from 'react';
import {  Card, CardBody } from 'reactstrap';

import {  Permissions } from '@autofact/operation-lib-model-notary-platform';
import CheckAccess from '../check-access/CheckAccess';
import CheckStatus from '../check-status/CheckStatus';
import SignDocumentStatus from '../../services/SignDocumentStatus'

import FirmaDocumentosSignButton from "../firma-documents-actions/FirmaDocumentosSignButton";
import FirmasDocumentosApproveButton from "../firma-documents-actions/FirmasDocumentosApproveButton";
import FirmasDocumentosRejectButton from "../firma-documents-actions/FirmasDocumentosRejectButton";
import FirmasDocumentosUploadButton from "../firma-documents-actions/FirmasDocumentosUploadButton";

class FirmaDocumentosActionsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signDocumentData: this.props.signDocumentData
        };
    }

    render() {
        const {sign_configs:configs=[]} = this.state.signDocumentData;
        const needUpload = configs.some(config=>{
            return config.name === 'notary_need_upload' && parseInt(config.value, 10) === 1 && parseInt(config.status, 10) === 1
        });
        return (<Card>
            <CardBody>
                <CheckAccess permission={Permissions.TRANSFERENCE_SEE}>
                    <CheckStatus actual={this.state.signDocumentData.sign_status_id} required={[parseInt(SignDocumentStatus.NOTARIO.id, 10)]}>
                        {!needUpload && <FirmaDocumentosSignButton id={this.props.signDocumentData.id} />}
                    </CheckStatus>
                </CheckAccess>

                <CheckAccess permission={Permissions.TRANSFERENCE_SEE}>
                    <CheckStatus actual={this.state.signDocumentData.sign_status_id} required={[ parseInt(SignDocumentStatus.APROBACION_NOTARIO.id, 10)]}>
                        {!needUpload && <FirmasDocumentosApproveButton id={this.props.signDocumentData.id} />}
                    </CheckStatus>
                </CheckAccess>

                <CheckAccess permission={Permissions.TRANSFERENCE_SEE}>
                    <CheckStatus actual={this.state.signDocumentData.sign_status_id} required={[ parseInt(SignDocumentStatus.APROBACION_NOTARIO.id, 10)]}>
                        {!needUpload && <FirmasDocumentosRejectButton id={this.props.signDocumentData.id} />}
                    </CheckStatus>
                </CheckAccess>

                <CheckAccess permission={Permissions.SIGN_DOCUMENT_PHYSIC_SEE}>
                    <CheckStatus actual={this.state.signDocumentData.sign_status_id} required={[ parseInt(SignDocumentStatus.APROBACION_NOTARIO.id, 10)]}>
                        {this.state.signDocumentData.sign_type_id === 3 && needUpload && <FirmasDocumentosUploadButton id={this.props.signDocumentData.id} signDocumentData={this.props.signDocumentData}  />}
                    </CheckStatus>
                </CheckAccess>
            </CardBody>
        </Card>);
    }
}

export default FirmaDocumentosActionsContainer;
