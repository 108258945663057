import React, {Component} from 'react';
import {getPreAdmissionPersonQualityFromId} from '@autofact/operation-lib-model-notary-platform';
import moment from 'moment-timezone';
import {formats} from '../../config';

class PreAdmissionDetail extends Component {
    render() {
        const pdfLink = this.props.preAdmissionData.documentData ?
            <a
                href={this.props.preAdmissionData.documentData.ruta_fisica}
                rel="noopener noreferrer"
                className="btn btn-primary-darker btn-sm btn-see-link"
                target="_blank">
                VER PDF
            </a>
            : [];

        const personQualityPropietaryObject = getPreAdmissionPersonQualityFromId(this.props.preAdmissionData.propietario_calidad);
        const personQualityPropietary = personQualityPropietaryObject ? personQualityPropietaryObject.label : [];
        const personQualityNewOwnerObject = getPreAdmissionPersonQualityFromId(this.props.preAdmissionData.adquiriente_calidad);
        const personQualityNewOwner = personQualityNewOwnerObject ? personQualityNewOwnerObject.label : [];

        return (
            <div className="main-wrapper-page pre-admission-detail-page">
                <h2 className="page-title">Detalle de Preingreso Código: {this.props.preAdmissionData.codigo_preingreso}</h2>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Patente</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.patente}</dd>
                    <dt className="col-sm-1">Precio convenio</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.precio_convenio}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Run Propietario</dt>
                    <dd className="col-sm-3">{this.props.preAdmissionData.propietario_rut}</dd>
                    <dt className="col-sm-1 pl-0">Calidad Propietario</dt>
                    <dd className="col-sm-3">{personQualityPropietary}</dd>
                    <dt className="col-sm-1">Comuneros</dt>
                    <dd className="col-sm-3">{this.props.preAdmissionData.propietario_comuneros}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Run Adquiriente</dt>
                    <dd className="col-sm-3">{this.props.preAdmissionData.adquiriente_rut}</dd>
                    <dt className="col-sm-1 pl-0">Calidad Adquiriente</dt>
                    <dd className="col-sm-3">{personQualityNewOwner}</dd>
                    <dt className="col-sm-1">Comuneros</dt>
                    <dd className="col-sm-3">{this.props.preAdmissionData.adquiriente_comuneros}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Calle</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.calle}</dd>
                    <dt className="col-sm-1">Número Calle</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.calle_numero}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Resto Domicilio</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.adquiriente_resto_domicilio}</dd>
                    <dt className="col-sm-1">Comuna</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.comuna_codigo}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Teléfono</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.telefono}</dd>
                    <dt className="col-sm-1">Código Postal</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.codigo_postal}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Número Documento</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.documento_numero}</dd>
                    <dt className="col-sm-1">Fecha Documento</dt>
                    <dd className="col-sm-5">{moment(this.props.preAdmissionData.documento_fecha).utc().format(formats.shortDate)}</dd>
                </dl>
                <dl className="row align-items-center">
                    <dt className="col-sm-1">Lugar Documento</dt>
                    <dd className="col-sm-5">{this.props.preAdmissionData.documento_lugar}</dd>
                    <dt className="col-sm-1">Documento</dt>
                    <dd className="col-sm-5 no-border">{pdfLink}</dd>
                </dl>
            </div>
        );
    }
}

export default PreAdmissionDetail;
