import React, { useRef, useEffect, useState } from 'react';
import DataTable from '../data-table/DataTable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formats } from '../../config';
import BackendApiService from '../../services/BackendApiService';
import AuthService from '../../services/AuthService';

const PretransferenceList = (props) => {
  const dataTableRef = useRef();
  const [userName, setUserName] = useState("");

  const fetchData = async (paginate) => {
    const params = { ...paginate }
    const dataResponse = await BackendApiService.getPretransferenceList(params);
    return dataResponse;
  };

  const getAuth = async() => {
    try {
      const data = await AuthService.getAuthData();
      setUserName(data.usuario.nombre);
      
    } catch (error) {
      console.log("Couldn't get user unformation");
    }
  }

  const Actions = ({ row }) => { //NOSONAR
    return (
      <>
        <Link className="btn btn-link btn-sm py-0 primary-dark-color bold" to={`notaria/nueva-compraventa/${row.codigo}`}>
          Continuar contrato
        </Link>
      </>
    );
  };

  useEffect(() => {
    fetchData();
    getAuth();
  }, []);

  const columns = [
    {
      Header: 'Patente',
      accessor: 'patente',
      width: 100,
      Cell: (row) => {
        const r = row.original.transference_object?.contractForm;
        if(r?.plate){
          return (
            <p> 
              <b> {r.plate} </b>
            </p>
          );
        } else {
          return <p>Sin información</p>
        }
      },
    },
    {
      Header: 'Marca/Modelo/Año',
      accessor: 'transference_object',
      minWidth: 100,
      Cell: (row) => {
        const r = row.original.transference_object?.contractForm?.vehicle;
        if(r?.brand && r?.year && r?.model){
          return (
            <p>
              { r.brand + " " + r.model + " " + r.year }
            </p>
          );
        } else {
          return <p>Sin información</p>
        }
      },
    },
    {
      Header: 'Fecha creación', 
      accessor: 'timestamp',
      Cell: (row) => {
        let date = moment(row.original.timestamp);
        return date.format(formats.date);
      },
    },
    {
      Header: <div className='text-center'>Acciones</div>,
      accessor: 'actions',
      width: 250,
      Cell: (row) => <div className='text-center'> <Actions row={row.original} /> </div>,
    },
  ];

  return (
    <div>
      <p className='text-16'><b>Hola {userName}, </b>aquí puedes ver las revisiones pre contrato y retomar el proceso de creación del contrato.</p>
      <DataTable
        ref={dataTableRef}
        key={`data-table-${props.id}`}
        id={props.id}
        columns={columns}
        fetchDataCallback={fetchData}
      />
    </div>
  );
};

export default PretransferenceList;
