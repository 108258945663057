import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import AuthService from '../../services/AuthService';
import LoadingOverlay from 'react-loading-overlay';
LoadingOverlay.propTypes = undefined

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logged: false,
            login: true,
            redirect: this.props.location.state && this.props.location.state.from ? this.props.location.state.from : '/'
        }
    }

    async componentDidMount() {
        this.setState({login: true});
        const loginResult = await AuthService.login();
        let logged = false;
        if (loginResult) {
            logged = true;
        }
        setTimeout(() => {
            this.setState({logged, login: false});
            this.props.setIsAuthenticated(logged);
        }, 2000);
    }

    render() {
        if (this.state.login) {
            return (
                <div className="loading-overlay">
                    <LoadingOverlay
                        className="loading"
                        active={true}
                        spinner
                        fadeSpeed={100}
                        text='Cargando...'
                        />
                </div>
            );
        }
        else {
            if (this.state.logged) {
                return <Redirect to={this.state.redirect}/>
            }
            else {
                return (<div>
                    Redirecting...
                    <Redirect to={'external-login'}/>
                </div>);
            }
        }
    }
}

export default Login;
