import React, { Component } from 'react';
import moment from 'moment';
import { TransferenceDocumentType, getDocumentTypeFromId } from '@autofact/operation-lib-model-notary-platform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formats } from '../../config';
import AuthService from '../../services/AuthService';
import { NOTARY_CONFIG_AUTOFACT_REPRESENTATION_VALUE } from '../../helpers/NotaryAutofactRepresentationHelper';

const RNDPATypes = [
    TransferenceDocumentType.RNDPA_BACKUP.id,
    TransferenceDocumentType.RNDPA_CERTIFICATE.id,
];

const REPRESENTATION_CONTRACT_DOCUMENTS_TYPES = [
    TransferenceDocumentType.PACKAGE_TYPE_MANDATE_BUYER_FEA.id,
    TransferenceDocumentType.PACKAGE_TYPE_MANDATE_SELLER_FEA.id,
    TransferenceDocumentType.REPRESENTATION_CONTRACT_TYPE.id,
    TransferenceDocumentType.CONTRACT_BUYER_FIRST.id,
];

const DOCUMENTS_TO_HIDE_IN_REPRESENTATION_SIGNATURE = [
    TransferenceDocumentType.CONTRACT.id,
    TransferenceDocumentType.MANDATE_BUYER.id,
    TransferenceDocumentType.MANDATE_SELLER.id,
];

const ACTIVE_DOCUMENT_VALUE = 1;
const DELETED_DOCUMENT_VALUE = -2;

class DocumentSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documentList: [],
        };
    }

    async componentDidMount() {
        const documentList = await this.filterData();
        this.setState({ documentList });
    }

    async filterData() {
        const authData = await AuthService.getAuthData();
        const { notary } = authData;
        const representativeSignature = notary.autofactRepresentation === NOTARY_CONFIG_AUTOFACT_REPRESENTATION_VALUE.ENABLED
                 && this.props.isNotarialFlowRepresentation
                 && this.props.subsidiaryRepresentationFlow
        let documentList = this.props.documentList.filter(document => {
            let result = false;
            if(document.estado !== DELETED_DOCUMENT_VALUE){
                if(representativeSignature && REPRESENTATION_CONTRACT_DOCUMENTS_TYPES.includes(document.tipo)) {
                    result = true;
                } else if(representativeSignature && (DOCUMENTS_TO_HIDE_IN_REPRESENTATION_SIGNATURE.includes(document.tipo) || !getDocumentTypeFromId(document.tipo))){
                    result = false;
                } else if (document.estado === ACTIVE_DOCUMENT_VALUE || RNDPATypes.includes(document.tipo)) {
                    result = true;
                }
            }
            return result;
        });
        if(representativeSignature){
            const pack = documentList.find((document) => document.tipo === TransferenceDocumentType.PACKAGE.id);
            if(pack) {
                this.props.onDocumentSelect(pack);
            } else {
                const representativeContract = documentList.find((document) => document.tipo === TransferenceDocumentType.REPRESENTATION_CONTRACT_TYPE.id);
                if(representativeContract) {
                    this.props.onDocumentSelect(representativeContract);
                }
            }
        }
        return documentList;
    }

    render() {
        const documentsRender = [];
        const rndpaDocsList = [];
        let componentElement = null;

        this.state.documentList.forEach(document => {
            const DocumentType = getDocumentTypeFromId(document.tipo);
            let documentTitle = DocumentType ? DocumentType.label : 'OTRO';
            if (DocumentType && DocumentType.id === TransferenceDocumentType.OTHER.id) {
                documentTitle += ` (${document.nombre})`;
            }
            componentElement = (<li className={'nav-item document-item-wrapper'} key={`document-item-${document.iddocumento}`}>
                <a href={document.ruta_fisica} onClick={(event) => {
                    event.preventDefault();
                    if (this.props.onDocumentSelect) {
                        this.props.onDocumentSelect(document);
                    }
                }}>
                    <div className="document-icon"><FontAwesomeIcon icon="file-pdf" /></div>
                    <div className={'document-title'}>{documentTitle}</div>
                    <div className={'document-date'}>{moment(document.timestamp_modificacion).format(formats.date)}</div>
                </a>
            </li>);

            if (document.estado === ACTIVE_DOCUMENT_VALUE && !RNDPATypes.includes(document.tipo)) {
                documentsRender.push(componentElement);
                return;
            }

            if (REPRESENTATION_CONTRACT_DOCUMENTS_TYPES.includes(document.tipo)) {
                documentsRender.push(componentElement);
                return;
            }

            if ((typeof rndpaDocsList[document.persona_idpersona] === 'undefined') || (document.tipo === TransferenceDocumentType.RNDPA_CERTIFICATE.id && rndpaDocsList[document.persona_idpersona].tipo !== TransferenceDocumentType.RNDPA_CERTIFICATE.id) || (document.tipo === rndpaDocsList[document.persona_idpersona].tipo && rndpaDocsList[document.persona_idpersona].timestamp < moment(document.timestamp))) {
                rndpaDocsList[document.persona_idpersona] = { tipo: document.tipo, timestamp: moment(document.timestamp) };
                documentsRender[document.persona_idpersona] = componentElement;
                return;
            }

        });
        return (<div>
            <ul className="transference-document-list">
                {documentsRender}
            </ul>
        </div>);
    }
}

export default DocumentSelector;
