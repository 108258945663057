import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {TabContent, TabPane, Nav, NavLink, NavItem, Button, Col, Row, Alert } from 'reactstrap';
import './NotaryDashboard.style.css'
import WaitingContractList from  '../../components/waiting-contract-list/WaitingContractList';
import AllNotaryContracts from  '../../components/all-notary-contracts/AllNotaryContracts';
import classnames from 'classnames';
import NotaryPlatformCavRequest from '../../components/notary-contracts-cav-request/NotaryContractsCavRequest';
import PretransferenceList from '../../components/pretransference-list/PretransferenceList';
 


class NotaryDashboard extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            activeTab: '1',
            openBuySellModal: false,
            errorAlert: false
        };
        this.toggle = this.toggle.bind(this);
        this.cancelBuySellProcess = this.cancelBuySellProcess.bind(this);
        this.showAlert = this.showAlert.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.errorAlert){
            setTimeout(() => {
                this.setState({errorAlert: false});
            }, 5000);
        }
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
            activeTab: tab
            });
        }
    }

    cancelBuySellProcess(isOpened){
        if(isOpened){
            this.setState({openBuySellModal:false})
        }
    }

    renderModalCav() {
        this.setState({openBuySellModal: true}); 
    }

    showAlert() {
        this.setState({errorAlert: true}); 
    }

    render() {
        return (
            <div>
                 <Alert color="danger" isOpen={this.state.errorAlert} >
                    <strong>Error: </strong> No se pudo iniciar la solicitud de CAV.
                </Alert>
                {this.state.openBuySellModal && 
                    <NotaryPlatformCavRequest
                        closingModal={this.cancelBuySellProcess}
                        errorAlert={() => this.showAlert()}
                    />
                }
                <Row>
                    <Col>
                        <Button 
                        variant="contained" 
                        className=" btn btn-primary-dark rounded-4"
                        onClick={() => this.renderModalCav()}><i>+</i> Iniciar nueva compra venta</Button>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Nav tabs className="tabs-light">
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                            >
                                <h2 className='text-uppercase text-weight-bold'>revisiones precontratos</h2>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                            >
                                <h2 className='text-uppercase text-weight-bold'>contratos en proceso</h2>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                            >
                                <h2 className='text-uppercase text-weight-bold'>Contratos ingresados a RC</h2>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className="w-100" activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                            <Row className="d-flex flex-column">
                                {
                                    this.state.activeTab === '1' && <PretransferenceList />
                                }
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row className="my-5 d-flex flex-column">
                                {
                                    this.state.activeTab === '2' && <WaitingContractList />
                                }
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                {
                                    this.state.activeTab === '3' && <AllNotaryContracts />
                                }
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
            
        );
    }
}

export default withRouter(NotaryDashboard);
