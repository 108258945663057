import { Row, Col, FormGroup, Label, Input, CustomInput, FormText, FormFeedback } from "reactstrap";
import SubtitleDivider from "../subtitle-divider/SubtitleDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rutHelper from "../../helpers/rutHelper";
import { useEffect, useState } from "react";
import BackendApiService from "../../services/BackendApiService";
import AttachFile from "@material-ui/icons/AttachFile";
import FileHelper from "../../helpers/FileHelper";
import { CircularProgress } from "@material-ui/core";
import {TransferenceDocumentType} from '@autofact/operation-lib-model-notary-platform';
import { PatternFormat, NumericFormat } from 'react-number-format'

const {RUT_SII} = TransferenceDocumentType;

const NotaryContractCompanyForm = ({ company, regions, type, number, updateData, uploadFile }) => {
    const subtype = 'persona';
    const docTypeId = RUT_SII.id;
    const [communes, setCommunes] = useState([]);
    const[siiRut, setSiiRut] = useState({});
    const [fileLoading, setFileLoading] = useState(false);
    const [tempFile, setTempFile] = useState(' ');
    const [loading, setLoading] = useState(true);
    const [invalidFile, setInvalidFile] = useState(false);
    const [isPublicDeed, setIsPublicDeed] = useState(false);

    useEffect(()=>{
        const companyData = () => {
            const empresa  = company;
            empresa.sex  = 'E';
            if(empresa.companyInDay === undefined) empresa.companyInDay = 0;
            updateData(empresa, type, subtype, number);
            setSiiRut({
                name: '',
                typeId: docTypeId,
                url: '',
                identityCode: company.identityCode
            });

            if(company.publicDeedFolio){
                setIsPublicDeed(true);
            } else {
                setIsPublicDeed(false);
            }

            setLoading(false);
        }

        const companyRut = () => {
            let docRut = company?.documents?.find((el) => el.typeId === docTypeId);
            if(docRut) setSiiRut(docRut);
        }
        companyData();
        companyRut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const communeData = async() =>{
            const response = await BackendApiService.getCommuneListByRegion(company?.address?.regionId);
            setCommunes(response);
        }
        communeData();
    },[company?.address?.regionId])

    const uploadSiiRut = async(el) => {
        const files = el.files;
        const file = files[0] || null;
        if(file?.type && FileHelper.allowedExtensions(file.type)){
            setInvalidFile(false);
            setTempFile(file?.name);
            setFileLoading(true);
            let url = '';
            let fileName = '';
            url = await uploadFile(file, 'rut-sii');
            fileName = FileHelper.getFileNameFromUrl(url);
            const newSiiRut = {
                url,
                name: fileName,
                typeId: docTypeId,
                identityCode: company.identityCode
            }
            setSiiRut(newSiiRut);
            const newCompany = company;
            if(newCompany.documents === undefined) newCompany.documents = [];
            const index = newCompany.documents.findIndex( element => element.typeId === docTypeId);
            if(index !== -1){
                newCompany.documents[index] = newSiiRut;
            }else{
                newCompany.documents.push(newSiiRut);
            }
            updateData(newCompany, type, subtype, number);
            setFileLoading(false);
        }else{
            setInvalidFile(true);
        }
    }

    const updateForm = (el) =>{
        const newCompany = company;

        let index = el.id.split('-')[0];
        if(index.substring(0,7) === "address"){
            const subIndex = index.split('.')[1];
            if(newCompany.address === undefined) newCompany.address = [];
            newCompany.address[subIndex] = el.value;
        }else{
            if(index === "companyInDay"){
                newCompany[index] = el.checked ? 1 : 0;
            }
            else if(index === "publicDeed"){
                if (el.checked) {
                    setIsPublicDeed(true);
                } else {
                    setIsPublicDeed(false);
                    newCompany.publicDeedFolio = '';
                }
            }
            else if(index === "publicDeedFolio"){
                const positiveNumberRegex = /^\d*\.?\d+$/;
                if (positiveNumberRegex.test(el.value) || el.value === '') {
                    newCompany[index] = el.value;
                }
            }
            else{
                newCompany[index] = el.value;
            }
        }
        updateData(newCompany, type, subtype, number);
    }

    const removeFile = () => {
        const newSiiRut = {
            name: '',
            typeId: docTypeId,
            url: '',
            identityCode: company.identityCode
        };
        setSiiRut(newSiiRut);
        setTempFile(' ');
        const newCompany = company;
        newCompany.documents = [];
        updateData(newCompany, type, subtype, number);
    }

    return(
        <>
            <div className=' d-flex align-items-center subtitle-box-step-validation px-2 mb-2'>
                <div className='d-flex justify-content-center'>
                    <span className='font-weight-bold my-1'><FontAwesomeIcon icon={'user'} size='lg' color='gray' /></span>
                </div>
                <span className='h2 dark-color font-weight-bold ml-2 mb-0'>Empresa</span>
            </div>
            {!loading &&
                <>
                    <SubtitleDivider>Información de la empresa</SubtitleDivider>
                    <Row className="mb-3">
                        <Col xs="4">
                            <FormGroup check inline className="p-3 border border-light rounded">
                                <Label check>
                                    <Input type="checkbox" id={`companyInDay-${company?.identityCode}`} className="font-weight-bold"
                                    checked={company.companyInDay === 0 ? false : true} onChange={(e)=> updateForm(e.target)}/> Es empresa en un día
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`identityCode-${company?.identityCode}`}>Rut Empresa</Label>
                                <Input type="text" id={`identityCode-${company?.identityCode}`} className="bg-white" value={ rutHelper.toDotedRut(company?.identityCode) } onChange={(e)=> updateForm(e.target)} invalid={!rutHelper.validateRut(company.identityCode)} required />
                                <FormFeedback>Ingrese un RUT válido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`name-${company?.identityCode}`}>Razón social</Label>
                                <Input type="text" id={`name-${company?.identityCode}`} className="bg-white" value={company?.name} onChange={(e)=> updateForm(e.target)} required/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <Label>Subir Rut SII</Label>
                                {siiRut?.url ?
                                    <div className='d-flex flex-row align-items-center'>
                                        <a className="link-block" target="_blank" rel="noreferrer" href={siiRut?.url}>
                                            <AttachFile />
                                            { FileHelper.getFileNameFromUrl(siiRut?.url)}
                                        </a>
                                        <FontAwesomeIcon icon="trash" color='red' className='pointer ml-2' onClick={ () => removeFile()}/>
                                    </div>
                                :
                                    <>
                                        <div className="d-flex">
                                            <CustomInput type="file" id={`sii-${company?.identityCode}`} className="bg-white mr-2" label={tempFile} accept=".png, .jpg, .pdf" onChange={(e) => uploadSiiRut(e.target)} invalid={invalidFile}/>
                                            { fileLoading && <i className='ml-auto pt-2 pr-2'><CircularProgress size={20}/></i>}
                                        </div>
                                        <FormText>{invalidFile && 'Seleccione uno de los '}Formatos permitidos: JPG, PNG, PDF</FormText>
                                    </>
                                }
                            </FormGroup>
                        </Col>
                        <Col lg="4" md="8">
                            <Row>
                                <Col xs="auto" className="d-flex align-items-center">
                                    <FormGroup check>
                                        <Label check>
                                        <Input type="checkbox" id={`publicDeed-${company?.identityCode}`} checked={isPublicDeed}
                                        className="bigger-checkbox" onChange={(e)=> updateForm(e.target)}/>
                                            <span className="text-20 ml-2">Escritura Pública</span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                <FormGroup>
                                    <Label for={`publicDeedFolio-${company?.identityCode}`}>Folio</Label>
                                    <Input
                                        type="text"
                                        id={`publicDeedFolio-${company?.identityCode}`}
                                        value={company.publicDeedFolio}
                                        onChange={(e)=> updateForm(e.target)}
                                        required={isPublicDeed}
                                        disabled={!isPublicDeed}
                                    />
                                </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <SubtitleDivider>Dirección de la empresa</SubtitleDivider>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`address.regionId-${company?.identityCode}`}>Región</Label>
                                <Input type="select" id={`address.regionId-${company?.identityCode}`} className="bg-white" value={company?.address?.regionId} onChange={(e)=> updateForm(e.target)} required>
                                    <option value='' disabled>Seleccione una región</option>
                                    {regions.map((el, k)=>
                                        <option value={el.id} key={k}>{el.nombre}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`address.comunaId-${company?.identityCode}`}>Comuna</Label>
                                <Input type="select" id={`address.comunaId-${company?.identityCode}`} className="bg-white" value={company?.address?.comunaId} onChange={(e)=> updateForm(e.target)} required>
                                    <option value='' disabled>Seleccione una comuna</option>
                                    {communes.map((el, k) =>
                                        <option value={el.idcomuna} key={k}>{el.nombre}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for={`address.street-${company?.identityCode}`}>Nombre de la calle</Label>
                                <Input type="text" id={`address.street-${company?.identityCode}`} className="bg-white" value={company?.address?.street} onChange={(e)=> updateForm(e.target)} required/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for={`address.number-${company?.identityCode}`}>Número de la calle</Label>
                                <NumericFormat allowNegative={false} id={`address.number-${company?.identityCode}`} className="form-control bg-white" value={company?.address?.number} min="1" onChange={(e)=> updateForm(e.target)} required/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for={`address.rest-${company?.identityCode}`}>Datos adicionales de la dirección (opcional)</Label>
                                <Input type="Select" id={`address.rest-${company?.identityCode}`} className="bg-white" value={company?.address?.rest} onChange={(e)=> updateForm(e.target)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <SubtitleDivider>Datos de contacto</SubtitleDivider>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`email-${company?.identityCode}`}>Correo electrónico</Label>
                                <Input type="email" id={`email-${company?.identityCode}`} className="bg-white" value={company.email} onChange={(e)=> updateForm(e.target)} required/>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`phone-${company?.identityCode}`}>Teléfono</Label>
                                <PatternFormat pattern='\D([+56]\d[2-9])(\d{4})(\d{4})\D*' format="+56#########" allowEmptyFormatting type="phone" title='número de teléfono' id={`phone-${company?.identityCode}`} className="form-control bg-white" value={company.phone} onChange={(e)=> updateForm(e.target)} required/>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default NotaryContractCompanyForm;
