import React from 'react'
import { Modal, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

const ModalGoToEdit = ({toggle, pretransferData, history, location, match}) => {

  const { isOpen, closeModal } = toggle;

  const backToCreationStep = async () => {
    const sessionStorageObject = JSON.parse(sessionStorage.getItem('pretransfer'));
    if(sessionStorageObject){
      sessionStorage.removeItem('pretransfer')
    } 

    const pretransfer = { 
      contractForm: pretransferData.transference_object?.contractForm, 
      validationForm: pretransferData.transference_object?.validationForm, 
      ticket: pretransferData.transference_object?.cav_ticket,
      activeStep: 2,
      cavCode: pretransferData.cav_code,
      transferPath: location.pathname,
      transferCode: match.params.code,
      buyersBeforeUpdate: pretransferData.transference_object?.contractForm?.people?.buyers,
      };

    sessionStorage.setItem('pretransfer', JSON.stringify(pretransfer));
    history.replace(`/notaria/modificar-compraventa/${pretransferData.codigo}`);
  }

  return (
    <Modal isOpen={isOpen} toggle={() => closeModal()} className='modal-contract-edit' centered>
      <div className="modal-content">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Modificar contrato</h2>
          <button type="button" className="close white-circle-close" onClick={() => closeModal()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body text-center pt-4 pb-4">
          <FontAwesomeIcon icon='exclamation-circle' className='orange-color'/>
          <h1 className="text-20 orange-color">¿Necesitas modificar datos del contrato?</h1>
        </div>
        <div className="modal-footer justify-content-center py-5">
          <Button className="btn-outline-primary-dark" size="sm"  onClick={() => closeModal()}>CANCELAR</Button>{' '}
          <Button color="primary-dark" size="sm" onClick={ () => backToCreationStep()} > SI, IR AL FORMULARIO Y EDITAR </Button>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(ModalGoToEdit);