import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransferenceStatus } from '@autofact/operation-lib-model-notary-platform';
import Documents from './Documents';
import NotificationModal from './NotificationModal';
import ModalGoToEdit from './ModalGoToEdit';
import ModalRemove from './remove-contract/ModalRemove';
import Activity from './Activity';
import RemoveSignatureModal from './remove-contract/RemoveSignatureModal';
import SignModal from './SignModal';
import BackendApiService from '../../services/BackendApiService';
import { app } from '../../config';
import SignActions from './SignActions';
import LegalCostsModal from './payment-modals/LegalCostsModal';
import TransferenceRepertoryModal from '../repertory-modal/TransferenceRepertoryModal';
import TaxPaymentModal from './payment-modals/TaxPaymentModal';
import TaxPaymentProgressNotify from './TaxPaymentProgressNotify';
import contractHeaderSettings from '../../helpers/contractHeaderSettings';
import PdfBrowser from '../pdf-browser/PdfBrowser';
import rutHelper from '../../helpers/rutHelper';

const NOTARIZED = 1;
const CHECK_SIGN_INTERVAL = app.signProcess.check_sign_interval;

const ContractPreview = ({transferenceData, pretransferData, methods}) => {

  const { getTransference } = methods;

  const [activeTab, setActiveTab] = useState('documents');
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [PDFContract, setPDFContract] = useState('');
  const [modalRemoveIsOpen, setModalRemoveIsOpen] = useState(false);
  const [signatureRemoveIsOpen, setSignatureRemoveIsOpen] = useState(false);
  const [signPeople, setSignPeople] = useState([]);
  const [removeSignData, setRemoveSignData] = useState({rut: '', role: null, code: ''});
  const [legalCostsModalisOpen, setLegalCostsModalisOpen] = useState(false);
  const [signModalIsOpen, setSignModalIsOpen] = useState(false);
  const [signingPerson, setSigningPerson] = useState({});
  const [signData, setSignData] = useState({});
  const [taxPaymentModalOpen, setTaxPaymentModalOpen] = useState(false);
  const [taxPaymentOnProgress, setTaxPaymentOnProgress] = useState(false);
  const [contractHeader, setContractHeader] = useState({});
  const [inRepertory, setInRepertory] = useState(false);
  const [repertory, setRepertory] = useState('');
  const [repertoryLoading, setRepertoryLoading] = useState(false);
  const [contractHaveSignature, setContractHaveSignature] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let timeoutId;

    const fetchTransference = async () => {
      await getTransference(transferenceData.codigo);
    };

    if ([TransferenceStatus.STATUS_TAX_IN_PROCESS.id, TransferenceStatus.PACKING.id].includes(transferenceData.estado)) {
      timeoutId = setTimeout(fetchTransference, 5000);
    }

    if (transferenceData.estado === 11 || transferenceData.estado === 12) {
      setTaxPaymentOnProgress(false);
      openTaxPaymentModal();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferenceData, taxPaymentOnProgress]);

  useEffect(() => {

    let newPeopleList = [];

    if(transferenceData.people?.vendedor_representantes){
      const sellerRep = transferenceData.people.vendedor_representantes.map((person, index, people) => ({
        ...person,
        indexByRole: people.length > 1 ? index : -1,
        legalRepresentative: rutHelper.isCompanyRut(transferenceData.people.compradores[0].rut),
      }));
      newPeopleList = newPeopleList.concat(sellerRep);
    }

    if(transferenceData.people?.vendedores){
      const sellers = transferenceData.people.vendedores.map((person, index, people) => ({
        ...person,
        indexByRole: people.length > 1 ? index : -1,
      }));
      newPeopleList = newPeopleList.concat(sellers);
    }

    if(transferenceData.people?.comprador_representantes){
      const buyerRep = transferenceData.people.comprador_representantes.map((person, index, people) => ({
        ...person,
        indexByRole: people.length > 1 ? index : -1,
        legalRepresentative: rutHelper.isCompanyRut(transferenceData.people.vendedores[0].rut),
      }));
      newPeopleList = newPeopleList.concat(buyerRep);
    }

    if(transferenceData.people?.compradores){
      const buyers = transferenceData.people.compradores.map((person, index, people) => ({
        ...person,
        indexByRole: people.length > 1 ? index : -1,
      }));
      newPeopleList = newPeopleList.concat(buyers);
    }

    setContractHeader(contractHeaderSettings(transferenceData.estado));

    const newSignData = {
      transference_id: transferenceData.idtransferencia,
      conpanyId: transferenceData.empresa_idempresa,
      plate: transferenceData.patente,
      code: transferenceData.codigo,
      type: "DEFAULT",
      notarized: NOTARIZED,
      company: "",
      source: "TRANSFERENCE",
      is_frequent_seller_or_buyer: false,
      is_multiple_signatures: false,
    }
    setSignData(newSignData);

    const signPeopleArray = newPeopleList.filter(person => person.firma === 1);

    signPeopleArray.forEach((val, indx) => {
      const newPerson = val;
      newPerson.sign = {
        startSign: false,
        endSign: false
      }
      signPeopleArray[indx] = newPerson;
    });
    setSignPeople(signPeopleArray);
    setSignPeople(newPeopleList.filter(person => person.firma === 1))

    setRepertory(transferenceData.numero_repertorio || '');

    const hasAuditTrue = signPeopleArray.some(person => person.audit !== null);
    setContractHaveSignature(hasAuditTrue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferenceData])

  useEffect(()=>{
    const checkSign = setInterval(()=> {
      signPeople.forEach((person, indx)=>{
        const sign = person.sign;
        if(sign.startSign && !sign.endSign){
          check(person.code, indx);
        }
      })
    }, CHECK_SIGN_INTERVAL);

    return () => {
      clearInterval(checkSign)
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signPeople])

  const check = async(code, index) => {
    const response = await BackendApiService.checkSign(code)
    if(response){
        const [ data ] = response;
        if(data?.client_finish){
          const newSignPeople = signPeople;
          newSignPeople[index].sign.endSign = true;
          setSignPeople(newSignPeople);
          await getTransference(transferenceData.codigo);
          setSignModalIsOpen(false);
        }
    }
  }

  const updateSigner = (index, person) => {
    const newSignPeople = signPeople;
    newSignPeople[index] = person;
    setSignPeople(newSignPeople);
  }

  const openModalEdit = () => setModalEditIsOpen(true);
  const closeModalEdit = () => setModalEditIsOpen(false);

  const openModalRemove = () => setModalRemoveIsOpen(true);
  const closeModalRemove = () => setModalRemoveIsOpen(false);

  const openRemoveSignature = (rut, role) => {
    setRemoveSignData({rut, role, code: transferenceData.codigo})
    setSignatureRemoveIsOpen(true);

  }

  const closeRemoveSignature = () => setSignatureRemoveIsOpen(false);

  const openSignModal = (person, index) => {
    setSigningPerson({signNumber: index, ...person});
    setSignModalIsOpen(true);

  };

  const closeSignModal = () => setSignModalIsOpen(false);
  const openlegalCostsModal = () => setLegalCostsModalisOpen(true);
  const closelegalCostsModal = () => setLegalCostsModalisOpen(false);

  const openTaxPaymentModal = ()=> setTaxPaymentModalOpen(true);
  const closeTaxPaymentModal = ()=> setTaxPaymentModalOpen(false);

  const startTaxPayment = ()=> setTaxPaymentOnProgress(true)

  const setPDFToDisplay = (url) => setPDFContract(url);

  const toggleActiveTab = () => {
    switch (activeTab) {
      case 'documents':
        setActiveTab('history');
        break;
      case 'history':
        setActiveTab('documents');
        break;
      default:
        setActiveTab('documents');
        break;
    }
  };

  const openRepertoryModal = () => setInRepertory(true);
  const closeRepertoryModal = () => setInRepertory(false);

  const assignRepertory = async () => {
    try{
      setRepertoryLoading(true);
      const response = await BackendApiService.assignSimpleRepertory(transferenceData.codigo, repertory);
      if(response.countFail !== 0){
          setRepertoryLoading(false);
          alert('Error asignando repertorio');
      }else{
        await getTransference(transferenceData.codigo)
        setRepertoryLoading(false);
        closeRepertoryModal();
      }
    }catch(error){
      setRepertoryLoading(false);
      alert('Error asignando repertorio');
      console.error(error);
    }
  }

  const handleChangeRepertory = (e) => {
    setRepertory(e.target.value);
  }

  const handleMouseEnter = () => {
    if (contractHaveSignature) {
      setShowAlert(true);
    }
  };

  const handleMouseOut = () => {
    if (showAlert) {
      setShowAlert(false);
    }
  };

  return (
    <div className='container-fluid p-5'>
      <RemoveSignatureModal data={removeSignData} isOpen={signatureRemoveIsOpen} methods={{closeThis: closeRemoveSignature, getTransference: getTransference}} />
      {
        transferenceData.estado === TransferenceStatus.CLIENT_SIGNATURE.id && <NotificationModal />
      }
      <SignModal toggle={{isOpen: signModalIsOpen ,close: closeSignModal}} person={signingPerson} plate={transferenceData.patente || ''} data={signData} updateSigner={updateSigner}/>
      <ModalGoToEdit toggle={{isOpen: modalEditIsOpen, closeModal: closeModalEdit}} pretransferData={pretransferData} />
      <TransferenceRepertoryModal
        open={inRepertory}
        title={"ASIGNAR REPERTORIO"}
        bodyText={"Ingresa el número de repertorio para este contrato"}
        cancelHandler={closeRepertoryModal}
        confirmHandler={assignRepertory}
        inProcess={repertoryLoading}
        repertory={repertory}
        handlerChange={handleChangeRepertory}
        className={"repertory-modal"}
        />
      <LegalCostsModal isOpen={ legalCostsModalisOpen } methods={ {closeThis: closelegalCostsModal, startTaxPayment, getTransference, openTaxPaymentModal} } data={{ taxValue: pretransferData.transference_object?.contractForm?.vehicle?.taxValue || 0, codigo: transferenceData?.codigo }} />
      <TaxPaymentModal isOpen={ taxPaymentModalOpen } data={{ code: transferenceData.codigo, taxPaymentOnProgress: taxPaymentOnProgress }} methods={{ closeModal: closeTaxPaymentModal }} />
      <div className='d-flex justify-content-between'>
        <h1 className='text-26'> {contractHeader.title} </h1>
        <h2 className='text-primary-dark text-20'>Vehículo: {transferenceData.patente || ''}</h2>
      </div>
      {transferenceData.estado === TransferenceStatus.STATUS_TAX_IN_PROCESS.id &&
        <TaxPaymentProgressNotify />
      }
      <div className='preview-box mt-4'>
        <div className='row'>
          <div className='col order-lg-1 order-sm-2'>
            <div className='d-flex flex-row align-items-center mb-2'>
              <span className='text-14 pr-3'>Estado del contrato:</span>
              <span className={`text-14 ${contractHeader?.cssClass}`}><FontAwesomeIcon className='mr-2' icon={contractHeader?.icon || 'pen'} />{contractHeader?.label}</span>
            </div>
            <div>
            <PdfBrowser src={`${PDFContract}`}/>
            </div>
          </div>
          <div className='col-lg-3 col-sm-12 order-sm-1 mb-sm-5 sidebar-actions'>
            {((transferenceData.estado === TransferenceStatus.APPROVAL.id || transferenceData.estado === TransferenceStatus.TAKE_APPROVAL.id) && !taxPaymentOnProgress) &&
              <button className='btn btn-primary-dark btn-pay bold mx-auto'  onClick={() => openlegalCostsModal()}> PAGAR COSTOS LEGALES </button>
            }
            {(taxPaymentOnProgress || [TransferenceStatus.STATUS_TAX_IN_PROCESS.id, TransferenceStatus.REPERTORY.id].includes(transferenceData.estado))  && !transferenceData.numero_repertorio &&
              <button className='btn btn-primary-dark btn-pay bold mx-auto' onClick={() => openRepertoryModal()} disabled={(taxPaymentOnProgress || transferenceData.estado === TransferenceStatus.STATUS_TAX_IN_PROCESS.id)}> ASIGNAR REPERTORIO </button>
            }
            {transferenceData.numero_repertorio &&
              <>
                <div className='d-flex flex-row repertory mb-0'><span className='bolder mr-3'>Repertorio: </span>{transferenceData.numero_repertorio}</div>
             </>
            }
            {([TransferenceStatus.CLIENT_SIGNATURE.id, TransferenceStatus.APPROVAL.id, TransferenceStatus.TAKE_APPROVAL.id].includes(transferenceData.estado)) &&
              signPeople.length > 0 && signPeople.map((person, index) => <SignActions key={index} person={person} index={index} actions={{openRemoveSignature, openSignModal}}/>)
            }
            {transferenceData.estado === TransferenceStatus.CLIENT_SIGNATURE.id &&
              <div  onMouseEnter={handleMouseEnter} onMouseOut={handleMouseOut}>
                <button className={`btn w-100 btn-outline-primary-dark bold`}
                  onClick={() => openModalEdit()} disabled={contractHaveSignature} style={{ pointerEvents: contractHaveSignature ? 'none' : '' }}>
                    <FontAwesomeIcon icon={'pen'}  /> Modificar contrato
                  </button>
              </div>
            }
            {
              showAlert &&
              <div id="alertMessage" className={`alert alert-warning-af mt-2`} role="alert">
                <p className="text-16 bold"> <FontAwesomeIcon icon="exclamation-circle" className='yellow10-color'/> Atención</p>
                <p>No se puede modificar el contrato. Para realizar cambios, se deben eliminar todos los procesos de firma realizados previamente.</p>
              </div>
            }
            <div>
              <div className='sidebar-tabs'>
                <button className={activeTab === 'documents'? 'active' : ''} onClick={() => toggleActiveTab()}>Documentos</button>
                <button className={activeTab === 'history'? 'active' : ''} onClick={() => toggleActiveTab()}>Historial actividad</button>
              </div>
              { (activeTab === 'documents' && transferenceData.documents) &&
                  <Documents documents={ transferenceData.documents } screenFile={ {PDFContract, setPDFToDisplay} } />
              }
              { (activeTab === 'history' && transferenceData.activity) &&
                  <Activity transferenceActivity={transferenceData.activity} />
              }
              {transferenceData.estado !== TransferenceStatus.REJECTED.id &&
               <button className='btn w-100 btn-outline-danger mt-4 bold' onClick={() => openModalRemove()}> <FontAwesomeIcon icon={'times-circle'} /> Eliminar contrato</button>
              }
              <ModalRemove toggleActions={{isOpen: modalRemoveIsOpen, closeThis: closeModalRemove}} transferenceCode={transferenceData.codigo || ''}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractPreview
