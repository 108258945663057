import React, {Component} from 'react';


import BackendApiService from "../../services/BackendApiService";
import IdentityList from "../identity-list/IdentityList";
import { isCurrentStep, STEP_NAMES, transformItem } from '../identity/api';
import config from '../../config';

class IdentityListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            identityList: [],
            params: this.props.filters || {},
            fetchParams: ['where', 'per_page', 'page','sorted'],
            exporting: false,
            exportingExcel: false,
            showMessage: false,
            exportResult: '',
            recordsTotal: '',
            emp_mail: '',
            transferenceByTagSelected: {},
            selectedAll: false,
            selectedTagOption: '',
            tagValue: '',
            notificationPermission: false,
        };
        this.audio = new Audio(config.app.notificationUrl);
        this.fetchData = this.fetchData.bind(this);
        this.identityListComponentRef = React.createRef();
    }

    async  fetchData(params) {
        params = Object.assign(this.state.params, params);
        params = this.filterParams(params);
        const identityList = await BackendApiService.getIdentityProcess(params);
        this.notifyIfanyProcessChange(this.state.identityList, identityList);
        this.setState({params, identityList});
        this.props.updateCount(identityList.extra.recordsFiltered);
        return identityList;
    }
    
    notifyIfanyProcessChange = (oldIdentityList, newiIdentityList) => {
        try{
            
            const oldData = oldIdentityList.data || [];
            const newData = newiIdentityList.data || [];
            const oldDataMap = oldData.reduce((accumulator, data) => {
                return Object.assign({}, accumulator, { [data.id]: transformItem(data) });
            }, {});
            
            const inCaptureVideoNotary = newData.filter(row => {
                const data = transformItem(row);
                return isCurrentStep(STEP_NAMES.CAPTURE_VIDEO_NOTARY, data) && !data.tokenExpired;
            })
            const needNotify = inCaptureVideoNotary.some(row => {
                if(typeof oldDataMap[row.id] !=='undefined'){
                    return isCurrentStep(STEP_NAMES.HANDWRITE_SIGNATURE, oldDataMap[row.id]);
                }
                return true;
            });
            if (needNotify){
                this.audio.play();
                this.titleNotification();
                if(this.state.notificationPermission === 'granted'){
                    try {
                        const notification = new Notification('Plataforma Notarios - Telepresencia', {
                            body: '¡Tiene un nuevo proceso de identidad al que responder!',
                        });
                        notification.addEventListener('click', () => {
                            window.focus();
                        });
                    } catch (error) {
                        console.log('Notification error: ', error);
                    }
                    
                }else{
                    console.log('Notification not granted by user');
                }
            }


                
        }catch (e) {
            console.log('not sound audio')
        }

    }

    filterParams(params) {
        const filtered = Object.keys(params)
            .filter(key => this.state.fetchParams.includes(key))
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});
        return filtered;
    }


    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            this.identityListComponentRef.current.reloadData();
        }, 100);
    }

    titleNotification(number) {
        try {
            if(number){
                document.title = `(${number}) ${ number > 1 ? '¡Nuevos Proceso de identidad!' : '¡Nuevo Proceso de identidad!'}`;
            }else{
                document.title = `🔔 ¡Nuevo Proceso de identidad!`;
            }
        } catch (error) {
            console.log('set title error: ', error);
        }
        
    }

    async componentDidMount() {
        let session = await BackendApiService.getSessionData();
        if (session) {
            this.setState({
                emp_mail: session.usuario.mail
            });
        }
        try {
            const permission = await Notification.requestPermission();
            this.setState({notificationPermission: permission});
        } catch (error) {
            console.log('Notification error: ', error);
        }
        
    }

    componentDidUpdate() {
        try {
            if(document.visibilityState === 'visible'){
                document.title = 'Autofact PRO Notarios';
            }
        } catch (error) {
            console.log('set title error: ', error);
        }
    }

    render() {
        return (
                <IdentityList
                    ref={this.identityListComponentRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                    selectedAll={this.state.selectedAll}
                />
        );
    }
}

export default IdentityListContainer;
