import React,{Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import DataTable from "../data-table/DataTable";
import moment from "moment";
import {formats} from "../../config";

class DashBoardRequestUpListComponent extends Component{
    state = {
        file:null,
        fileName:null,
        firstRepertory:'',
        lastRepertory:'',
        inProgress:false
    }
    dataTableRef = React.createRef();
    reloadData() {
        this.dataTableRef.current.reloadData();
    }
    render() {
        const columns = [];
        columns.push(this.renderStartRepertory())
        columns.push(this.renderEndRepertory())
        columns.push(this.renderCreatedDateColumn())
        columns.push(this.renderStatus());
        columns.push(this.renderViewColumn());
        return <div className={`request-up-list-wrapper col-md-7 mt-5 border-left`}>
            <div className={'text-primary-darker request-upload-list-title'}>Solicitudes ingresadas</div>
            <DataTable
                ref={this.dataTableRef}
                key={`data-table-request-up`}
                id={`data-table-request-up`}
                fetchDataCallback={this.fetchData}
                columns={columns}
                maxPagesRowsCount={5}
            />
        </div>
    }
    fetchData = async (params) =>{
        const response = await BackendApiService.uploadedRequests(params)
        console.log(response);
        return response
    }
    renderStartRepertory = () =>{
        return  {
            Header: 'Repertorio inicial',
            accessor: 'repertory_start'
        };
    }
    renderEndRepertory = () =>{
        return  {
            Header: 'Repertorio final',
            accessor: 'repertory_end'
        };
    }
    renderStatus = () =>{
        return  {
            Header: 'Estado',
            accessor: 'status',
            Cell: row => {
                switch (row.original.status) {
                    case 'UPLOADED':
                        return <span className={'alert alert-success'}>Cargado correctamente</span>
                    default:
                        return <span className={'alert alert-warning'}>{row.original.status}</span>
                }
            }
        };
    }
    renderCreatedDateColumn = () => {
        return {
            Header: 'Fecha',
            accessor: 'timestamp',
            width: 160,
            Cell: row => {
                let date = moment(row.original.timestamp);
                return (
                    <span className="pre-line">{date.format(formats.date)}</span>
                )
            }
        }
    }
    renderViewColumn = () => {
        return {
            Header: 'Ver',
            width: 60,
            accesor: 'idnotary_request_up',
            Cell: row => {
                return (
                    <span key={`detail-link-span-${row.original.idnotary_request_up}`}>
                        <a key={`detail-link-${row.original.idnotary_request_up}`}
                              className="btn-primary-darker btn-see-link"
                                target={'_blank'}
                                rel={"noopener noreferrer"}
                              href={row.original.document_url}>VER</a>
                    </span>
                );
            }
        }
    }
}

export default DashBoardRequestUpListComponent;
