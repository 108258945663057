import React, { useEffect, useState } from 'react'
import {Stepper, Step, StepLabel} from "@material-ui/core";
import { withRouter } from 'react-router';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NotaryTransference.style.css'
import BackendApiService from '../../services/BackendApiService';
import ContractPreview from '../../components/notary-contract-preview/ContractPreview';
import { TransferenceStatus } from '@autofact/operation-lib-model-notary-platform';

const steps = [
  "Validación de información",
  "Resultado de validación",
  "Creación de contrato",
  "Completar contrato",
  "Revisión y firma de contrato",
  "Pago de Impuestos",
  "Repertorio",
  "Firma Notario",
  "Ingreso a Reg.Civil"
];

const NotaryTransference = (props) => {

  const [pageLoading, setPageLoading] = useState(false);
  const [transferenceData, setTransferenceData] = useState({
    documents: [],
    activity: [],
    people: {}
  });
  const [activeStep, setActiveStep] = useState(4);
  const [transferenceDeleted, setTransferenceDeleted] = useState(false);
  const [pretransfer, setPretransfer] = useState({});
  const [isRejected, setIsRejected] = useState(false);

  useEffect(() => {
    const transferenceLoad = async () => {
      setPageLoading(true);
      await getTransference(props.match.params.code);
      setPageLoading(false);
    };

    transferenceLoad();
  }, [props.match.params.code]);

  useEffect(() => {

    const getPretransferByTransfer = async (transferId) => {
      try {
        const response = await BackendApiService.getPretransferByTransfer(transferId);
        if(!response  || response.length === 0){
          throw new Error("Couldn't get the pretransfer");
        }

        if(response.length === 1){
          setPretransfer(response[0]);
        } else {
          throw new Error("This transfer has more than one pretransfer assigned");
        }


      } catch (error) {
        console.log('Error getPretransfer: ',error);
      }
    }

    const estado  = transferenceData.estado;
    if(TransferenceStatus.STATUS_TAX_IN_PROCESS.id === estado ) setActiveStep(5);

    if([TransferenceStatus.REPERTORY.id, TransferenceStatus.PACKING.id].includes(estado)) setActiveStep(6);

    if(TransferenceStatus.NOTARY.id === estado) setActiveStep(7);

    if(TransferenceStatus.INGRESADO_STEV.id === estado) setActiveStep(8);

    if(TransferenceStatus.REJECTED.id === estado){
      steps[9] = "Rechazado";
      setActiveStep(9);
      setIsRejected(true);
    }
    if(transferenceData.estado){
      if(transferenceData.estado === -2){
        setTransferenceDeleted(true);
        setTimeout(() => {
          props.history.replace("/notaria");
        }, 4000);
      } else {
        getPretransferByTransfer(transferenceData.idtransferencia);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferenceData]);

  const getTransference = async (code) => {
    try {
      const transference = await BackendApiService.getTransferenceDataForNotaries(code);
      if (transference) {
        setTransferenceData(transference);
        setActiveStep(4);
      }
    }
    catch (error) {
      console.error(error);
    }

  };

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep} className="w-100 buy-sell-stepper">
        {
          steps.map((item, index) => {
            return(
              <Step key={`step-${index}`} >
                {isRejected && index === 9 ?
                  <StepLabel StepIconComponent={()=><FontAwesomeIcon icon='times-circle' className='MuiSvgIcon-root MuiStepIcon-root tag-rejected'/>}>{item}</StepLabel>
                  :
                  <StepLabel>{item}</StepLabel>
                }
              </Step>
            );
          })
        }
      </Stepper>
      { pageLoading ?
        (
          <div className="loading-overlay">
            <LoadingOverlay
              className="loading"
              active={pageLoading}
              spinner
              fadeSpeed={100}
              text='Cargando información...'
            />
          </div>
        ) :
        transferenceDeleted ?
        (
          <div className='d-flex flex-column align-items-center mt-5'>
            <h1>Este contrato ha sido eliminado</h1>
            <p className='text-muted'> <FontAwesomeIcon icon='spinner' className='icon' spin/> Redireccionando a página de inicio</p>
          </div>
        ) :
        (
          <div className='step-content'>
            {activeStep === steps.length ? (<>Pantalla final</>):(
                <ContractPreview transferenceData= {transferenceData} pretransferData= {pretransfer} methods={{getTransference: getTransference}} />
            )}
          </div>
        )
      }
    </div>
  )
}

export default withRouter(NotaryTransference)
