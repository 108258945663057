import React, { Component } from 'react';
import { Button } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';
import TransferenceRepertoryModal from '../repertory-modal/TransferenceRepertoryModal'

class TransferenceRepertoryButton extends Component {

    constructor(props){
        super(props);
        this.assignRepertory = this.assignRepertory.bind(this);
        this.openModal = this.openModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.handlerChange = this.handlerChange.bind(this);
        this.state = {
            assigning: false,
            repertory: this.props.repertory || '',
            inAssign: false
        }
    }

    async assignRepertory () {
        this.setState({assigning: true});
        try{
            const response = await BackendApiService.assignMasiveRepertory([
                {
                    transferenceCode: this.props.code,
                    repertoryNumber: this.state.repertory
                }
            ]);
            this.setState({assigning: false, inAssign: false, repertory:''});
            if(response.countFail !== 0){
                alert('Error asignando repertorio');
            }else{
                window.location.reload();
            }
        }catch(error){
            this.setState({assigning: false, inAssign: false, repertory:''});
            alert('Error asignando repertorio');
            console.error(error);
        }
    }

    openModal(){
        this.setState({inAssign: true})
    }
    cancelModal(){
        this.setState({inAssign: false})
    }
    handlerChange(e){
        console.log(e);
        this.setState({repertory: e.target.value})
    }
    render(){
        const text = this.props.editButton?'Editar N° de repertorio':'Añadir N° de repertorio'
        return (
            <div className="mb-2">
                <TransferenceRepertoryModal
                    open={this.state.inAssign}
                    title={text}
                    cancelHandler={this.cancelModal}
                    confirmHandler={this.assignRepertory}
                    inProcess={this.state.assigning}
                    repertory={this.state.repertory}
                    handlerChange={this.handlerChange}
                    />
                <Button disabled={this.state.assigning} onClick={this.openModal} color='primary-dark' block >
                    {text}
                </Button>
            </div>
        );
    }
}

export default TransferenceRepertoryButton;
