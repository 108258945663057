import React, {Component} from 'react';
import {withRouter} from 'react-router';
import FirmaDocumentosFisicosDetailContainer from "../../components/firmas-documentos-fisicos-detail-container/FirmaDocumentosFisicosDetailContainer";

class FirmasDocumentosFisicosDetail extends Component {

    render() {
        return(
            <div>
                <FirmaDocumentosFisicosDetailContainer id={this.props.match.params.id}/>
            </div>
        );
    }
}

export default withRouter(FirmasDocumentosFisicosDetail);
