import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import PropTypes from "prop-types";


const titleByType = {
    info: 'Información',
    primary: 'Información',
    secondary: 'Información',
    success: 'Felicidades',
    danger: 'Error',
    warning: 'Advertencia',
};

const textColorByType = {
    info: 'text-white',
    primary: 'text-white',
    secondary: 'text-white',
    success: 'text-white',
    danger: 'text-white',
    warning: 'text-dark',
};

class ModalConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.confirmFunction = this.confirmFunction.bind(this);
        this.cancelFunction = this.cancelFunction.bind(this);
    }

    confirmFunction(){
        this.setState({modal:false});
        this.props.confirmFunction();
    }
    cancelFunction(){
        this.setState({modal:false});
        if(typeof  this.props.cancelFunction == 'function')
            this.props.cancelFunction();
    }

    render() {
        const messageType = this.props.messageType || 'secondary';
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                   centered autoFocus backdrop={'static'}>
                <ModalHeader toggle={this.toggle}
                             className={`bg-${messageType} ${textColorByType[messageType]}`}>
                    {titleByType[messageType]}
                </ModalHeader>
                <ModalBody>
                    {this.props.message}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirmFunction}>{this.props.confirmText}</Button>{' '}
                    {this.props.notIncludeCancelButton === false && <Button color="secondary" onClick={this.cancelFunction}>{this.props.cancelText}</Button>}
                </ModalFooter>
            </Modal>
        );
    }
}
ModalConfirm.defaultProps = {

    confirmText:"Aceptar",
    cancelText:"Cancelar",
    notIncludeCancelButton: false
}

ModalConfirm.propsType = {
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmFunction: PropTypes.func.required,
    cancelFunction: PropTypes.func,
    notIncludeCancelButton : PropTypes.bool
}
export default ModalConfirm;
