import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import PreAdmissionDetail from "../pre-admission-detail/PreAdmissionDetail";

class PreAdmissionDetailContainer extends Component{
    constructor(props) {
        super(props);

        this.state = {
            preAdmissionData: null,
            loading: true
        };
    }

    async componentDidMount() {
        try {
            const preAdmissionData = await BackendApiService.getPreAdmissionData(this.props.preAdmissionId);
            if (preAdmissionData) {
                this.setState({loading: false, preAdmissionData});
            }
            else {
                this.setState({loading: false, preAdmissionData: null});
            }
        }
        catch (e) {
            console.error(e);
            this.setState({loading: false, preAdmissionData: null});
        }
    }

    render() {
        if (this.state.loading) {
            return (<div>Cargando datos</div>);
        }
        if (!this.state.preAdmissionData) {
            return (<div>No tiene permisos a este preingreso.</div>);
        }
        return <PreAdmissionDetail preAdmissionData={this.state.preAdmissionData} />;
    }
}

export default PreAdmissionDetailContainer;
