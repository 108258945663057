import { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, CustomInput, FormText, FormFeedback } from "reactstrap";
import SubtitleDivider from "../subtitle-divider/SubtitleDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rutHelper from "../../helpers/rutHelper";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileHelper from "../../helpers/FileHelper";
import {TransferenceDocumentType} from '@autofact/operation-lib-model-notary-platform';
import { CircularProgress } from "@material-ui/core";
import { merge } from "lodash";

const {
    EXTRACT_BUYER_AUTHORIZATION, VALIDITY_BUYER_AUTHORIZATION,
    EXTRACT_SELLER_AUTHORIZATION,VALIDITY_SELLER_AUTHORIZATION
} = TransferenceDocumentType;

const NotaryContractLegalRepresentativeForm = ({person, number, type, communes, companyInDay, updatePerson, uploadFile}) => {
    const subtype = 'representantes';
    const companyDocTypeId = type=== 'vendedor' ? EXTRACT_SELLER_AUTHORIZATION.id :  EXTRACT_BUYER_AUTHORIZATION.id;
    const companyCertTypeId =type=== 'vendedor' ? VALIDITY_SELLER_AUTHORIZATION.id :  VALIDITY_BUYER_AUTHORIZATION.id;
    const [companyDoc, setCompanyDoc] = useState({});
    const [companyCert, setCompanyCert] = useState({});
    const [invalidDoc, setInvalidDoc] = useState(false);
    const [invalidCert, setInvalidCert] = useState(false);
    const [certificateUploading, setCertificateUploading] = useState(false);
    const [companyDeedsUplading, setCompanyDeedsUploading] = useState(false);


    useEffect(()=>{
        const notary = () => {
            const newPersona = person;
            newPersona.notary = newPersona.notary === undefined ? {
                date: '',
                name: '',
                notary: '',
                comunaId: ''
            } : newPersona.notary;
            updatePerson(newPersona, type, subtype, number);
        }
        const notaryDocuments = ()=>{
            let companydoc = person?.documents?.find( el => el.name.substring(0,9) === 'escritura');
            companydoc = companydoc ?? {
                name: '',
                typeId: companyDocTypeId,
                url: '',
                identityCode: person.identityCode
            };
            setCompanyDoc(companydoc);
            let companycert = person?.documents?.find( el => el.name.substring(0,11) === 'certificado');
            companycert = companycert ?? {
                name: '',
                typeId: companyCertTypeId,
                url: '',
                identityCode: person.identityCode
            };
            setCompanyCert(companycert);
        };
        notary();
        notaryDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const uploadNotaryDocuments = async(el, docType) => {
        const files = el.files;
        const file = files[0] || null;
        if(file?.type && FileHelper.allowedExtensions(file.type)){
            setInvalidDoc(false);
            setInvalidCert(false);

            if(docType === 'certificado-vigencia'){
                setCertificateUploading(true);
            }
            if(docType === 'escritura-empresa'){
                setCompanyDeedsUploading(true);
            }
            let url = '';
            let fileName = '';
            url = await uploadFile(file, docType);
            fileName = FileHelper.getFileNameFromUrl(url);
            switchDocTypes(docType, url, fileName);
            setCertificateUploading(false);
            setCompanyDeedsUploading(false);
        }else{
            const listTypes = {
                'escritura-empresa': ()=>setInvalidDoc(true),
                'certificado-vigencia':()=>setInvalidCert(true)
            }
            return listTypes[docType]();
        }
    }

    const switchDocTypes = (doctype, url, name) => {
        let newPersona = person;
        let notaryDocument = {};
        if(newPersona.documents === undefined){
            newPersona.documents = [];
        }
        switch (doctype) {
            case 'escritura-empresa':
                newPersona.documents = removeAlldocumentslike(newPersona.documents, 'escritura');
                notaryDocument = merge(companyDoc, {url, name});
                setCompanyDoc(notaryDocument);
                break;
            case 'certificado-vigencia':
                newPersona.documents = removeAlldocumentslike(newPersona.documents, 'certificado');
                notaryDocument = merge(companyCert, {url, name});
                setCompanyCert(companyCert);
                break;
            default:
                break;
        }
        if(url){
            if(newPersona.hasOwnProperty('documents')){
                newPersona.documents.push(notaryDocument);
            }else{
                newPersona.documents = [notaryDocument]
            }
        }

        updatePerson(newPersona, type, subtype, number);
    }

    const updateForm = el => {
        const newPersona = person;
        let index = el.id.split('-')[0];
        if(index.substring(0,6) === "notary"){
            const subIndex = index.split('.')[1];
            if(newPersona.notary === undefined){
                newPersona.notary = {};
            }
            newPersona.notary[subIndex] = el.value;
        } else{
            newPersona[index] = el.value;
        }

        if(index === "name" || index === "fatherLastName" || index === "motherLastName"){
            if(index === "name") {
                newPersona.fullName = `${el.value} ${newPersona.fatherLastName} ${newPersona.motherLastName}`;
            }
            if(index === "fatherLastName") {
                newPersona.fullName = `${newPersona.name} ${el.value} ${newPersona.motherLastName}`;
            }
            if(index === "motherLastName") {
                newPersona.fullName = `${newPersona.name} ${newPersona.fatherLastName} ${el.value}`;
            }
        }

        updatePerson(newPersona, type, subtype, number);
    };

    const removeFile = (docType) =>{

        switchDocTypes(docType, '', '');
    }

    const removeAlldocumentslike = (arr, val) => {
        const documents = arr.filter( el => el.name.substring(0, val.length) !== val);
        return documents
    }

    return(
        <>
            <div className=' d-flex align-items-center subtitle-box-step-validation px-2 mb-2'>
                <div className='d-flex justify-content-center'>
                    <span className='font-weight-bold my-1'><FontAwesomeIcon icon={'user'} size='lg' color='gray' /></span>
                </div>
                <span className='h2 dark-color font-weight-bold ml-2 mb-0'>Representante Legal {number + 1} </span>
            </div>

            <Row>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`identityCode-${person.identityCode}`}>Rut del representante</Label>
                        <Input type="text" id={`identityCode-${person.identityCode}`} className="bg-white" value={ rutHelper.toDotedRut(person.identityCode) } onChange={(e)=> updateForm(e.target)} invalid={!rutHelper.validateRut(person.identityCode)} required/>
                        <FormFeedback>Ingrese un RUT válido</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for={`name-${person.identityCode}`}>Nombres del representante</Label>
                        <Input type="text" id={`name-${person.identityCode}`} className="bg-white" value={person.name} onChange={(e)=> updateForm(e.target)} required/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for={`fatherLastName-${person.identityCode}`}>Apellido paterno del representante</Label>
                        <Input type="text" id={`fatherLastName-${person.identityCode}`} className="bg-white" value={person.fatherLastName} onChange={(e)=> updateForm(e.target)} required/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for={`motherLastName-${person.identityCode}`}>Apellido materno del representante</Label>
                        <Input type="text" id={`motherLastName-${person.identityCode}`} className="bg-white" value={person.motherLastName} onChange={(e)=> updateForm(e.target)} required/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`document-date-${person.identityCode}`}>Fecha escritura</Label>
                        <Input type="date" id={`notary.date-${person.identityCode}`} className="bg-white" value={person?.notary?.date || ''} onChange={(e)=> updateForm(e.target)} required/>
                    </FormGroup>
                </Col>
                {companyInDay === 0 &&
                    <>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary.name-${person.identityCode}`}>Notario</Label>
                                <Input type="text" id={`notary.name-${person.identityCode}`} className="bg-white" value={person?.notary?.name} onChange={(e)=> updateForm(e.target)} required/>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary.notary-${person.identityCode}`}>Notaría (opcional)</Label>
                                <Input type="select" id={`notary.notary-${person.identityCode}`} className="bg-white" value={person?.notary?.notary}  onChange={(e)=> updateForm(e.target)}>
                                    <option value="">Seleccione</option>
                                    <option value="PRIMERA">PRIMERA</option>
                                    <option value="SEGUNDA">SEGUNDA</option>
                                    <option value="TERCERA">TERCERA</option>
                                    <option value="CUARTA">CUARTA</option>
                                    <option value="QUINTA">QUINTA</option>
                                    <option value="SEXTA">SEXTA</option>
                                    <option value="SÉPTIMA">SÉPTIMA</option>
                                    <option value="OCTAVA">OCTAVA</option>
                                    <option value="NOVENA">NOVENA</option>
                                    <option value="DÉCIMA">DÉCIMA</option>
                                    <option value="UNDÉCIMA">UNDÉCIMA</option>
                                    <option value="DUODÉCIMA">DUODÉCIMA</option>
                                    <option value="DECIMOTERCERA">DECIMOTERCERA</option>
                                    <option value="DECIMOCUARTA">DECIMOCUARTA</option>
                                    <option value="DECIMOQUINTA">DECIMOQUINTA</option>
                                    <option value="DECIMOSEXTA">DECIMOSEXTA</option>
                                    <option value="DECIMOSÉPTIMA">DECIMOSÉPTIMA</option>
                                    <option value="DECIMOCTAVA">DECIMOCTAVA</option>
                                    <option value="DECIMONOVENA">DECIMONOVENA</option>
                                    <option value="VIGÉSIMA">VIGÉSIMA</option>
                                    <option value="VIGÉSIMO PRIMERA">VIGÉSIMO PRIMERA</option>
                                    <option value="VIGÉSIMO SEGUNDA">VIGÉSIMO SEGUNDA</option>
                                    <option value="VIGÉSIMO TERCERA">VIGÉSIMO TERCERA</option>
                                    <option value="VIGÉSIMO CUARTA">VIGÉSIMO CUARTA</option>
                                    <option value="VIGÉSIMO QUINTA">VIGÉSIMO QUINTA</option>
                                    <option value="VIGÉSIMO SEXTA">VIGÉSIMO SEXTA</option>
                                    <option value="VIGÉSIMO SÉPTIMA">VIGÉSIMO SÉPTIMA</option>
                                    <option value="VIGÉSIMO OCTAVA">VIGÉSIMO OCTAVA</option>
                                    <option value="VIGÉSIMO NOVENA">VIGÉSIMO NOVENA</option>
                                    <option value="TRIGÉSIMA">TRIGÉSIMA</option>
                                    <option value="TRIGÉSIMO PRIMERA">TRIGÉSIMO PRIMERA</option>
                                    <option value="TRIGÉSIMO SEGUNDA">TRIGÉSIMO SEGUNDA</option>
                                    <option value="TRIGÉSIMO TERCERA">TRIGÉSIMO TERCERA</option>
                                    <option value="TRIGÉSIMO CUARTA">TRIGÉSIMO CUARTA</option>
                                    <option value="TRIGÉSIMO QUINTA">TRIGÉSIMO QUINTA</option>
                                    <option value="TRIGÉSIMO SEXTA">TRIGÉSIMO SEXTA</option>
                                    <option value="TRIGÉSIMO SÉPTIMA">TRIGÉSIMO SÉPTIMA</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for={`notary.comunaId-${person.identityCode}`}>Comuna notaría</Label>
                                <Input type="select" id={`notary.comunaId-${person.identityCode}`} className="bg-white" value={person?.notary?.comunaId} onChange={(e)=> updateForm(e.target)} required>
                                    <option value='' disabled>Seleccione una comuna</option>
                                    {communes.map((el, k) =>
                                        <option value={el.idcomuna} key={k}>{el.nombre}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                    </>
                }
                <Col xs="4">
                    <FormGroup>
                        <Label for={`email-${person.identityCode}`}>Email del representante</Label>
                        <Input type="email" id={`email-${person.identityCode}`} className="bg-white" value={person?.email} onChange={(e)=> updateForm(e.target)} required/>
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label for={`sex-${person.identityCode}`}>Sexo</Label>
                        <Input type="select" id={`sex-${person.identityCode}`} className="bg-white" value={person.sex } onChange={(e)=> updateForm(e.target)} required>
                            <option value='' disabled>Seleccione una opción</option>
                            <option value='F'>Femenino</option>
                            <option value='M'>Masculino</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <SubtitleDivider>Documentos</SubtitleDivider>
            <Row>
                <Col xs="4">
                    <Label>Constitución de Sociedad / Escritura Empresa</Label>
                    {companyDoc.url ?
                        <div className='d-flex flex-row align-items-center'>
                            <a className="link-block" target="_blank" rel="noreferrer" href={companyDoc.url}>
                                <AttachFileIcon />
                                { FileHelper.getFileNameFromUrl(companyDoc.url)}
                            </a>
                            <FontAwesomeIcon icon="trash" color='red' className='pointer ml-2' onClick={ () => removeFile('escritura-empresa')}/>
                        </div>
                    :
                        <>
                            <div className="d-flex">
                                <CustomInput type="file" id={`upload-escritura-publica-${person.identityCode}`} className="bg-white mr-2" label=" " accept=".png, .jpg, .pdf" onChange={ (e)=> uploadNotaryDocuments(e.target, 'escritura-empresa')} invalid={invalidDoc}/>
                                { companyDeedsUplading && <i className='ml-auto pt-3'><CircularProgress size={20}/></i>}
                            </div>
                            <FormText>{invalidDoc && 'seleccione uno de los '}Formatos permitidos: JPG, PNG, PDF</FormText>
                        </>
                    }
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label>Certificado vigencia empresa</Label>
                        {companyCert.url ?
                            <div className='d-flex flex-row align-items-center'>
                                <a className="link-block" target="_blank" rel="noreferrer" href={companyCert.url}>
                                    <AttachFileIcon />
                                    { FileHelper.getFileNameFromUrl(companyCert.url)}
                                </a>
                                <FontAwesomeIcon icon="trash" color='red' className='pointer ml-2' onClick={ () => removeFile('certificado-vigencia')}/>
                            </div>
                        :
                            <>
                                <div className="d-flex">
                                    <CustomInput type="file" id={`upload-certificado-vigencia-${person.identityCode}`} className="bg-white mr-2" label=" " accept=".png, .jpg, .pdf" onChange={ (e)=> uploadNotaryDocuments(e.target, 'certificado-vigencia')} invalid={invalidCert}/>
                                    { certificateUploading && <i className='ml-auto pt-3'><CircularProgress size={20}/></i>}
                                </div>
                                <FormText>{invalidCert && 'Seleccione uno de los '}Formatos permitidos: JPG, PNG, PDF</FormText>
                            </>
                        }
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}

export default NotaryContractLegalRepresentativeForm;
