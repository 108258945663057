import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import { TransferenceDocumentType, getDocumentTypeFromId } from '@autofact/operation-lib-model-notary-platform';
import FileHelper from '../../helpers/FileHelper';
import pdfIcon from '../../theme/assets/pdf_icon.svg';

const Documents = ({documents, screenFile}) => {

  const { PDFContract, setPDFToDisplay } = screenFile;

  const RNDPATypes = [
    TransferenceDocumentType.RNDPA_BACKUP.id,
    TransferenceDocumentType.RNDPA_CERTIFICATE.id
  ];

  const ACTIVE_DOCUMENT_VALUE = 1;
  const DELETED_DOCUMENT_VALUE = -2;

  const [documentsRender, setDocumentsRender] = useState([]);
  const rndpaDocsList = [];
  const timestamp = Date.now();

  useEffect(() => {

    const addDocumentsToRender = () => {

      const changePDFToDisplay = (route) => {
        setPDFToDisplay(`${route}?${timestamp}`);
      };

      const newDocumentsRender = [];
      documents.forEach(async document => {
        if (document.estado === ACTIVE_DOCUMENT_VALUE || (RNDPATypes.includes(document.tipo) && document.estado !== DELETED_DOCUMENT_VALUE)) {
          const documentType = getDocumentTypeFromId(document.tipo);

          let documentTitle = documentType ? documentType.label : 'OTRO';
          if (documentType && documentType.id === TransferenceDocumentType.OTHER.id) {
            documentTitle += ` (${document.nombre})`;
          }

          const componentElement = (

            <div className='d-flex flex-column align-items-start mb-1' key={`document-item-${document.iddocumento}`}>
              <button className='btn btn-link btn-sm text-dark' onClick={() => changePDFToDisplay(document.ruta_fisica)}>
                <img src={pdfIcon} alt='icon_pdf' className='mr-2 '/>
                <span className='text-16 bold'>{documentTitle}</span>
              </button>
              <button className='btn btn-link btn-sm text-primary-dark bold' onClick={() => handleDownload(document.ruta_fisica)}>
                <FontAwesomeIcon icon={'download'} className='mr-2' />
                <span className='text-14'>
                  Descargar
                </span>
              </button>
            </div>

          );

          if (document.estado === ACTIVE_DOCUMENT_VALUE && !RNDPATypes.includes(document.tipo)) {
            newDocumentsRender.push(componentElement);
            return;
          }

          if (
            (typeof rndpaDocsList[document.persona_idpersona] === 'undefined')
            || (document.tipo === TransferenceDocumentType.RNDPA_CERTIFICATE.id && rndpaDocsList[document.persona_idpersona].tipo !== TransferenceDocumentType.RNDPA_CERTIFICATE.id)
            || (document.tipo === rndpaDocsList[document.persona_idpersona].tipo && rndpaDocsList[document.persona_idpersona].timestamp < moment(document.timestamp))
          ) {
            rndpaDocsList[document.persona_idpersona] = { tipo: document.tipo, timestamp: moment(document.timestamp) };
            newDocumentsRender[document.persona_idpersona] = componentElement;
            return;
          }
        }
      });

      setDocumentsRender(newDocumentsRender);
    }

    addDocumentsToRender();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  useEffect(() => {
    if(PDFContract === ''){
      const route = Object.values(documents).find((doc) => doc.ruta_fisica !== undefined);

      if(route){
        setPDFToDisplay(route.ruta_fisica);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PDFContract])

  const handleDownload = (url) => {
    const fileUrl = url;
    FileHelper.downloadFile(fileUrl);
  }


  return (
    <div className='documents-pannel'>
     { documentsRender }
    </div>
  )
}

export default Documents
