import React, {Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, Alert} from 'reactstrap';
import FirmasDocumentosListContainer from '../../components/firmas-documentos-list-container/FirmasDocumentosListContainer';
import FirmasDocumentosListFilter from '../../components/firmas-documentos-list-filter/FirmasDocumentosListFilter';
import {Permissions} from '@autofact/operation-lib-model-notary-platform';
import  SignDocumentStatus from '../../services/SignDocumentStatus'
import {Link, withRouter} from "react-router-dom";

import FilterIcon from '../../theme/assets/filter_conces.svg';
import CheckAccess from "../../components/check-access/CheckAccess";
import {app} from '../../config';

const SIGN_TYPE_NOT_PHYSIC = app.signTypesNotPhysic;
const tabsDefinition = {
    forApproval: {
        id: 'forApproval',
        title: 'Documentos por aprobar',
        filters: {
            process_type: SIGN_TYPE_NOT_PHYSIC,
            status: SignDocumentStatus.APROBACION_NOTARIO.id,
        }
    },
    forSign: {
        id: 'forSign',
        title: 'Documentos por firmar',
        filters: {
            process_type: SIGN_TYPE_NOT_PHYSIC,
            extern_source: 'not_transference',
            status: [SignDocumentStatus.NOTARIO.id].join(',')
        }
    },
    mandateForSign: {
        id: 'mandateForSign',
        title: 'Mandatos por firmar',
        filters: {
            process_type: SIGN_TYPE_NOT_PHYSIC,
            extern_source: 'transference',
            status: [SignDocumentStatus.NOTARIO.id].join(',')
        }
    },
    signed: {
        id: 'signed',
        title: 'Firmados',
        filters: {
            process_type: SIGN_TYPE_NOT_PHYSIC,
            status: [SignDocumentStatus.LEGALIZADO.id].join(',')
        }
    },
    full: {
        id: 'full',
        title: 'Consultar todos',
        filters: {
            process_type: SIGN_TYPE_NOT_PHYSIC,
            status:[SignDocumentStatus.APROBACION_NOTARIO.id, SignDocumentStatus.NOTARIO.id, SignDocumentStatus.LEGALIZADO.id].join(',')
        }
    }
};

class FirmaDocumentosList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabsDefinition.forApproval.id,
            filtersCollapsed: true,
            isMessageVisible:false,
            message:{},
            listFilters: {
                forApproval: Object.assign({}, tabsDefinition.forApproval.filters),
                forSign: Object.assign({}, tabsDefinition.forSign.filters),
                mandateForSign: Object.assign({}, tabsDefinition.mandateForSign.filters),
                signed: Object.assign({}, tabsDefinition.signed.filters),
                full: Object.assign({}, tabsDefinition.full.filters)
            },
            recordCount: {
                forApproval: 0,
                forSign: 0,
                mandateForSign: 0,
                signed: 0,
                full: 0
            }
        };
        this.toggleFilters = this.toggleFilters.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.showSuccesMessage = this.showSuccesMessage.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.showDetailsInTabGeneric = this.showDetailsInTabGeneric.bind(this);
        this.buildFilters = this.buildFilters.bind(this);
        this.setFiltersCallback = this.setFiltersCallback.bind(this);
        this.currentTableRef = React.createRef();
        this.filterComponentRef = React.createRef();
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            const listFilters = this.state.listFilters;
            listFilters[tab] = tabsDefinition[tab].filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
            this.filterComponentRef.current.resetFilters();
        }
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({activeTab: this.props.location.state.tab})
        }
        const recordCount = {};
        Object.keys(tabsDefinition).forEach(tabKey => {
            recordCount[tabKey] = 0;
        });
        this.setState({recordCount});
    }

    toggleFilters() {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    buildFilters(tab, filters = null) {
        const where = filters || this.state.listFilters[tab];
        return {where : JSON.stringify(where)};
    }

    setFiltersCallback(filters) {
        const tab = this.state.activeTab;
        const listFilters = this.state.listFilters;
        for (const filterKey in tabsDefinition[tab].filters) {
            if (tabsDefinition[tab].filters.hasOwnProperty(filterKey)) {
                if (filters.hasOwnProperty(filterKey)) {
                    const filterValue = tabsDefinition[tab].filters[filterKey].toString().split(',').filter(filterValue => filterValue.toString() === filters[filterKey].toString());
                    filters[filterKey] = filterValue.join(',');
                }
                else {
                    filters[filterKey] = tabsDefinition[tab].filters[filterKey];
                }
            }
        }
        listFilters[tab] = filters;
        this.setState({listFilters});
        this.currentTableRef.current.refreshFilters(this.buildFilters(tab, listFilters[tab]));
    }

    showDetailsInTabGeneric(filters){
        console.log(`called ${JSON.stringify(filters)}`)
            const tab = tabsDefinition.full.id;
            const listFilters = Object.assign({},this.state.listFilters);
            listFilters[tab] = filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
        setTimeout(() => {
            this.setFiltersCallback(listFilters[tab]);
        }, 100);


    }

    onDismiss() {
        this.setState({isMessageVisible: false});
    }

    showSuccesMessage(message) {
        this.setState({
            message: {
                success: true,
                text: message
            }, isMessageVisible: true
        })
    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    render() {
        const activeTab = this.state.activeTab;
        const listMenu = [];
        switch (activeTab) {
            case 'forSign': {
                listMenu.push(
                    <CheckAccess permission={Permissions.TRANSFERENCE_SIGN}>
                        <Link key={`massive-sign`} className="btn btn-sm btn-primary-dark mr-2"
                              to={`firma-documentos/firma-masiva/not_transference`}>Firma masiva</Link>
                    </CheckAccess>);
                break;
            }
            case 'mandateForSign': {
                listMenu.push(
                    <CheckAccess permission={Permissions.TRANSFERENCE_SIGN}>
                        <Link key={`massive-sign`} className="btn btn-sm btn-primary-dark mr-2"
                              to={`firma-documentos/firma-masiva/transference`}>Firma masiva</Link>
                    </CheckAccess>);
                break;
            }
            default: {
                break;
            }
        }
        let message = null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'}
                             isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
        }
        return (
            <div>
                {message}
                <Nav tabs className="tabs-dark">
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <NavItem key={`tab-nav-item-${index}`}>
                                <NavLink
                                    key={`tab-nav-${index}`}
                                    className={activeTab === tab.id ? 'active' : ''}
                                    onClick={() => this.toggleTab(tab.id)}
                                >
                                    {tab.title} ({this.state.recordCount[tab.id]})
                                </NavLink>
                            </NavItem>
                        );
                    })}
                    <NavItem className="nav-button">
                        {listMenu}
                        <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters}>
                            <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                        </Button>
                    </NavItem>
                </Nav>
                <Collapse isOpen={!this.state.filtersCollapsed}>
                    <div className="filter-form-wrapper">
                        <FirmasDocumentosListFilter ref={this.filterComponentRef}
                                                defaultFilters={tabsDefinition[this.state.activeTab].filters}
                                                activeTab={this.state.activeTab}
                                                setFiltersCallback={this.setFiltersCallback}/>
                    </div>
                </Collapse>
                <TabContent activeTab={activeTab}>
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                                {activeTab === tab.id ?
                                    <FirmasDocumentosListContainer
                                        id={tab.id}
                                        tab={tab.id}
                                        ref={this.currentTableRef}
                                        key={`tab-list-${index}`}
                                        updateCount={count => {
                                            const recordCountState = this.state.recordCount;
                                            recordCountState[tab.id] = count;
                                            this.setState({recordCount: recordCountState});
                                        }}
                                        filters={this.buildFilters(tab.id)}
                                    />
                                    :
                                    <div key={`tab-loading-${index}`}>Cargando</div>
                                }
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        );
    }

}

export default withRouter(FirmaDocumentosList);
