module.exports = {
  'CREADO': { 'id': '1', 'label': 'CREADO' },
  'ESPERANDO_FIRMA': { 'id': '2', 'label': 'ESPERANDO FIRMA' },
  'APROBACION': { 'id': '3', 'label': 'APROBACIÓN' },
  'REVISIÓN': { 'id': '4', 'label': 'REVISIÓN' },
  'GESTIÓN': { 'id': '5', 'label': 'GESTIÓN' },
  'APROBACION_NOTARIO': { 'id': '6', 'label': 'APROBACIÓN NOTARIO' },
  'NOTARIO': { 'id': '7', 'label': 'NOTARIO' },
  'LEGALIZADO': { 'id': '8', 'label': 'LEGALIZADO' },
};
