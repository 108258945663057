import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';

import BackendApiService from '../../services/BackendApiService';

class TransferenceRejectButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rejectCategory: null,
            rejectComment: '',
            rejectOptions: [],
        };
        this.rejectTransference = this.rejectTransference.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getNotaryRejectionList();
    }

    async getNotaryRejectionList() {
        const list = await BackendApiService.getRejectionList();
        if(list && list.data && list.data.data) {
            const options = list.data.data.map(element => {
                return {value: element.idrechazo_notario, label: element.nombre}
            })
            this.setState({rejectOptions: options})
        }
    }
    
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }    

    handleSelectChange = (rejectCategory) => {
        this.setState({ rejectCategory });
    }
    
    onChangeHandler = (event) => {
        const stateToChange = {};
        stateToChange[event.target.id] = event.target.value;
        this.setState(stateToChange);
    }

    handleSubmit = async () => {
        if(this.state.rejectCategory === null) {
            alert('Campo Categoría de Rechazo es obligatorio');
            console.error('falta categoria');
            return;
        }

        if(this.state.rejectComment === '') {
            alert('Campo comentario es obligatorio');
            console.error('falta comentario');
            return;
        }

        const response = await this.rejectTransference(this.state.rejectCategory.value, this.state.rejectComment);
        if (!response) {
            alert('Algo Falló :(');
        }
        window.location.reload();
    }

    async rejectTransference(notaryRejectionCategory, comment) {
        const postData = {notaryRejectionCategory, comment}
        return await BackendApiService.rejectTransference(this.props.code, postData);
    }

    render() {
        const { rejectCategory } = this.state;
        return (
            <div>
            <Button onClick={this.toggle} color='danger'>
                RECHAZAR
                <i className='glyphicon glyphicon-remove-sign text-icon'></i>
            </Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Rechazo de Transferencia</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="selectCategory">Motivo</Label>
                        <Select 
                            value={rejectCategory}
                            onChange={this.handleSelectChange}
                            options={this.state.rejectOptions}
                            isSearchable={true}
                            name="rejectCategory"
                            id="rejectCategory"
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label for="rejectComment">Comentario</Label>
                        <Input type="textarea" 
                            name="rejectComment" 
                            id="rejectComment" 
                            value={this.state.rejectComment} onChange={this.onChangeHandler} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.handleSubmit}>Rechazar</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            </div>
        );
    }
}
        
export default TransferenceRejectButton;
        