import {endpoints} from '../config';

import BackendApiService from './BackendApiService';

const APP_PRO_DOMAIN = endpoints.autofactProApi.domain;

class AuthService {
    static async login() {
        try {
            const sessionData = await BackendApiService.getSessionData();
            if (sessionData) {
                sessionStorage.setItem('logged', '1');
                sessionStorage.setItem('session-data', JSON.stringify(sessionData));
                return true;
            }
        }
        catch (error) {
            console.error(error);
        }
        return false;
    }

    static logout() {
        BackendApiService.doLogout(APP_PRO_DOMAIN);
        sessionStorage.clear();
        return true;
    }

    static async getAuthData() {
        const logged = sessionStorage.getItem('logged');
        const sessionData = sessionStorage.getItem('session-data');
        if (logged && sessionData) {
            return JSON.parse(sessionData);
        }
        return false;
    }

    static canDo(permission) {
        const sessionData = sessionStorage.getItem('session-data');
        if (sessionData && permission) {
            const sessionObject = JSON.parse(sessionData);
            return Object.values(sessionObject.usuario.permisos_uri).includes(permission);
        }
        return true;
    }
}

export default AuthService;
