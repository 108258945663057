import React, {Component} from 'react';
import DataTable from '../data-table/DataTable';
import moment from "moment";
import {formats} from "../../config";
import {Link} from "react-router-dom";
import {getPreAdmissionStatusFromId} from "@autofact/operation-lib-model-notary-platform";

class PreAdmissionList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    render() {
        const columns = [
            {
                Header: 'Número de preingreso',
                accessor: 'codigo_preingreso',
            },
            {
                Header: 'Patente',
                accessor: 'patente'
            },
            {
                Header: 'Fecha de creación',
                accessor: 'created_at',
                Cell: row => {
                    let date = moment(row.original.created_at);
                    return date.format(formats.date);
                }
            },
            {
                Header: 'Número de repertorio',
                accessor: 'documento_numero',
            },
            {
                Header: 'Ver',
                width: 60,
                accesor: 'idpreingreso',
                Cell: row => {
                    return (<span key={`detail-link-span-${row.original.idpreingreso}`}>
                        <Link key={`detail-link-${row.original.idpreingreso}`}
                              className="btn btn-primary-darker btn-sm btn-see-link"
                              to={`preingreso/ver/${row.original.idpreingreso}`}>VER</Link>
                    </span>
                    );
                }
            },
            {
                Header: 'Estado',
                accesor: 'estado',
                width: 160,
                Cell: row => {
                    //TODO check the user status, some users can't see some status
                    return (<span key={`status-${row.original.idtransferencia}`} className={`pre-admission-status pre-admission-status-sm pre-admission-status-${row.original.estado}`}>
                        {getPreAdmissionStatusFromId(row.original.estado).label}
                    </span>
                    );
                }
            }
        ];
        return (
            <div className={`pre-admission-list-wrapper`}>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }
}

export default PreAdmissionList;
