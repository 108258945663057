import React, {Component} from 'react';

class PdfBrowser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.src !== this.props.src)
            this.setState({loading: true});
    }

    componentDidMount() {
        this.pdfIframe.onload = () => {
            this.setState({loading: false});
        }
    }

    render() {
        const title = this.props.title || this.props.src.replace(/^.*[\\/]/, '');
        return (<div>
            <div id="document-browser" className={`embed-responsive embed-responsive-1by1`}>
                <iframe
                    ref={ref => this.pdfIframe = ref}
                    src={this.props.src}
                    title={title}
                    className={`embed-responsive-item`}
                />
                {/*<object*/}
                    {/*title={title}*/}
                    {/*className={`embed-responsive-item`}*/}
                    {/*data={this.props.src}*/}
                    {/*type="application/pdf">*/}
                    {/*<embed*/}
                        {/*title={title}*/}
                        {/*className={`embed-responsive-item`}*/}
                        {/*src={this.props.src}*/}
                        {/*type="application/pdf" />*/}
                {/*</object>*/}
            </div>
        </div>);
    }
}

export default PdfBrowser;
