import React, { Component } from 'react';
import AuthService from "../../services/AuthService";
import BackendApiService from "../../services/BackendApiService";
import { Doughnut , Chart} from 'react-chartjs-2';
import { TransferenceStatus} from '@autofact/operation-lib-model-notary-platform';
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class DashBoardSummaryComponent extends Component{

    state ={
        user: {},
        summary:{},
        show:true
    }
    config = {
        'pendingForReceiving':{
            graph:{
                label:'Pendientes por recibir',
                color:'#8B4513',
                includeInTotal:true
            },
            box:{
                title:'Contratos pendientes por recibir',
                titleClassName:'wait-receive-title',
                className:'wait-receive-box col-sm-4',
            },
            filters:{}
        },
       'waitByAprove':{
           graph:{
               label:'Por aprobar',
               color:'#0CB7EF',
               includeInTotal:true
           },
           box:{
               title:'Contratos por aprobar',
               titleClassName:'wait-aprove-title',
               className:'wait-aprove-box col-sm-4',
           },
           filters:{
               STATUS:[TransferenceStatus.NOTARY_APPROVAL.id]
           },
           tab: 'forApproval'
       },
       'waitRepertory': {
            graph:{
                label:'Esperando repertorio',
                color:'#FFA401',
                includeInTotal:true
            },
            box:{
                title:'Contratos esperando repertorio',
                titleClassName:'wait-repertory-title',
                className:'wait-repertory-box col-sm-4 ml-2',
            },
            filters:{
                STATUS: TransferenceStatus.REPERTORY.id
            },
            tab: 'repertory'
       },
       'waitBySign':{
           graph:{
               label:'Por firmar',
               color:'#155892',
               includeInTotal:true
           },
           box:{
               title:'Contratos por firmar',
               titleClassName:'wait-sign-title',
               className:'wait-sign-box col-sm-4 ml-2',
           },
           filters:{
               STATUS:[TransferenceStatus.NOTARY.id, TransferenceStatus.PACKING.id]
           },
           tab: 'forSign'
       },
       'noRequestUp':{
           graph:{
               label:'Sin solicitud',
               color:'#FF562E',
               includeInTotal:false
           },
           box:{
               title:'Contratos sin solicitud',
               titleClassName:'no-request-up-title',
               className:'no-request-up-box col-sm-4 ml-2',
           },
           filters:{
               WITH_OUT_REQUEST_UP: 1
           }
       },
        'signToday':{
            graph:{
                label:'Firmados hoy',
                color:'#13AD2B',
                includeInTotal:true
            },
            box:{
                title:'Contratos firmados hoy',
                titleClassName:'signed-title',
                className:'signed-box col-sm-4 ml-2',
            },
           filters:{
               DATE_FROM: moment().format('YYYY-MM-DD'),
               DATE_TO:moment().format('YYYY-MM-DD'),
           },
            tab: 'signed'
       },'toEnter':{
            graph:{
                label: 'Contratos por ingresar',
                color:'#FFB6C1',
                includeInTotal: true
            },
            box:{
                title: 'Contratos por ingresar',
                titleClassName:'contract-to-enter',
                className:'contract-to-enter-box col-sm-4 ml-2',
            },
            filters:{
                TO_ENTER: 1
            },
            tab: 'signed'
       }
    }
    chartReference = {}

    componentWillMount() {
        AuthService.getAuthData().then(authData => {
            console.log(authData)
            if (authData) {
                this.setState({
                    user: authData.usuario
                });
            }
        }).catch(console.log)
        BackendApiService.findNotaryWorkSummary().then(workSummary => {
            const state = Object.assign({}, this.state);
            const summary = Object.assign({}, state.summary, workSummary);
            this.setState({summary: summary})
        }).catch(e => {
            console.log(e);
        })
        Chart.pluginService.register({
            afterDraw: function (chart, easing) {
                var width = chart.chart.width,
                    height = chart.chart.height,
                    ctx = chart.chart.ctx;

                ctx.restore();
                ctx.font = 'normal normal 100 44px Roboto';
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#838383";

                const text = chart.options.centertext || 0, // "75%",
                    textX = Math.round((width - ctx.measureText(text).width) / 2) - 90,
                    textY = (height / 2 - (chart.titleBlock.height - 15)) - 30;
                ctx.fillText(text, textX, textY);

                ctx.font = 'normal normal 400 14px Roboto';
                ctx.textBaseline = "middle";
                ctx.fillText('Contratos asignados', textX - 40, textY + 40);
                ctx.fillText('para hoy', textX - 40, textY + 60);
                ctx.save();
            }
        });
    }

    showMore = (e) =>{
        e.preventDefault();
        const id = e.target.id
        this.props.showMore(this.config[id].filters, this.config[id].tab)
    }
    toogleShow = () =>{
        const show = this.state.show;
        this.setState({show:!show})
    }
    renderBox = (scope)=>{
        const summaryTransferences = this.state.summary.transferences || {};
        const more = (scope === "pendingForReceiving") ? 'show-more none' : 'show-more';
        return <div className={`summary-box ${this.config[scope].box.className}`} >
            <h5 className={`sumary-title ${this.config[scope].box.titleClassName}`}>{summaryTransferences[scope]}</h5>
            <p className={'sumary-description'}>{this.config[scope].box.title}</p>
            <span className={more} id={scope} onClick={this.showMore}>Ver más ></span>
        </div>
    }
    render() {
        const nombre =this.state.user.nombre ? ` ${this.state.user.nombre}`:''
        const summaryTransferences = this.state.summary.transferences || {}
        const configKeys = Object.keys(this.config);
        const labels = configKeys.map(key=>{
            return this.config[key].graph.label
        })
        const values = configKeys.map(key=>{
            return summaryTransferences[key] || 0
        })
        const colors = configKeys.map(key=>{
            return  this.config[key].graph.color
        })
        const totalInMangmanet =  configKeys.reduce((sum,key)=>{
            if(this.config[key].graph.includeInTotal){
                return sum + summaryTransferences[key] || 0
            }
            return sum;
        }, 0)

        return <div className={'sumary'}>
                    <div className={'general-summary-title'}>
                        <p><strong>Hola {nombre}</strong>, le mostramos un resumen del estado de los contratos pendientes</p>
                        <FontAwesomeIcon icon='times-circle' className='icon' onClick={this.toogleShow} style={{    transform: `rotate(${this.state.show? 0: 45}deg)`}} />
                    </div>
            {this.state.show && <div className={'d-flex'}>


                        <div className={'col-sm-8'}>
                            <div className={'col-sm-8 d-flex'}>
                                {this.renderBox('pendingForReceiving')}
                                {this.renderBox('waitRepertory')}
                                {this.renderBox('noRequestUp')}
                                {this.renderBox('toEnter')}
                            </div>
                            <div className={'col-sm-8 d-flex mt-4'}>
                                {this.renderBox('waitByAprove')}
                                {this.renderBox('waitBySign')}
                                {this.renderBox('signToday')}
                            </div>
                        </div>
                        <div className={'col-sm-4'}>
                            <Doughnut
                                ref={(reference) => this.chartReference = reference }
                                legend={{
                                     position: 'right',
                                }}
                                data={{
                                    labels: labels,
                                    datasets:
                                        [
                                            {
                                                data: values,
                                                backgroundColor: colors
                                            }
                                        ]
                                }}
                                width={100}
                                height={50}
                                options={{
                                    maintainAspectRatio: false ,
                                    centertext:totalInMangmanet,
                                    cutoutPercentage:80
                                }}
                            />
                        </div>

                    </div>}

            </div>
    }
}

export default DashBoardSummaryComponent;
