import React, { Component } from 'react';
import { Button} from 'reactstrap';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import SummaryPanel from '../identity/SummaryPanel';
import  {transformItem} from '../identity/api'

class IdentityStatusModal extends Component {
    render(){
      const {flows, dataLoaded } = this.props;
      const stepsStatusMapped =  flows.map((flow, index)=><SummaryPanel key={`flow-${index}`} data={transformItem(flow)} />)
        return (
            <Dialog

                      fullWidth={true}
                      maxWidth={'lg'}
                      open={this.props.open}
                      onClose={this.props.handleClose}
                      aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle>Proceso de firma</DialogTitle>
                <DialogContent>
                  {dataLoaded ===false && 'Cargando datos...'}
                  {dataLoaded && stepsStatusMapped.length === 0 && 'Sin informacion de firma'}
                  {dataLoaded && stepsStatusMapped.length > 0 && stepsStatusMapped}
                </DialogContent>
                <DialogActions>
                    <Button color="primary-dark"  onClick={this.props.handleClose}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default IdentityStatusModal;
