import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, FormGroup, Label, Input } from 'reactstrap';

class TransferenceRepertoryModal extends Component {


    render(){

       let buttonText = 'INGRESAR REPERTORIO';
        if(this.props.inProcess){

            buttonText = <span><FontAwesomeIcon icon="spinner" spin/><span>ASIGNANDO</span></span>
        }

        return (
            <Modal isOpen={this.props.open} toggle={this.props.cancelHandler} className={this.props.className}>
                <div className="modal-header bg-primary-darker text-white">
                    <h2 className="modal-title">{this.props.title}</h2>
                    <button type="button" className="close white-circle-close pull-right" onClick={this.props.cancelHandler}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    <FormGroup className="d-flex flex-column align-items-center">
                        <Label for="number" className="mb-5">{this.props.bodyText || "Número"}</Label>
                        <Input
                            type="number"
                            name="number"
                            id="number"
                            className=" w-50 p-2 mb-3"
                            value={this.props.repertory}
                            onChange={this.props.handlerChange}
                        />
                    </FormGroup>
                </ModalBody>
                <div className='mb-5 mt-2 d-flex justify-content-center'>
                    <Button color="outline-primary-dark" size="sm" className="mr-3 font-weight-bold" disabled={this.props.inProcess} onClick={this.props.cancelHandler}>CANCELAR</Button>{' '}
                    <Button color="primary-dark" size="sm" className="font-weight-bold" disabled={this.props.inProcess} onClick={this.props.confirmHandler}>
                        {buttonText}</Button>
                </div>
            </Modal>
        );
    }
}

export default TransferenceRepertoryModal;
