import BackendApiService from "../services/BackendApiService";

class FileHelper{

    static getBase64(file){
        return new Promise((res, rej) =>{
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => res(reader.result);
            reader.onerror = () => rej(reader.error);
        })
    }

    static getFileNameFromUrl(url){
        let name = '';
        if(url){
            name = url.substring(url.lastIndexOf("/") + 1, url.length);
        }
        return name;
    }
    
    static downloadFile = async (fileUrl) => {
        try {
          const response = await BackendApiService.downloadFile(fileUrl);
          if(!response.data){
            throw new Error('Error at downloading file');
          }
    
          const buf = Buffer.from(response.data.data);
    
          const blob = new Blob([buf], {type: 'application/pdf'});
          const url = URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = url
          link.download = '';
          link.click();
    
        } catch (error) {
          console.log(error);
        }
      }

    static allowedExtensions(ext){
        const extensions  = ['image/jpeg', 'image/png', 'application/pdf'];
        return extensions.includes(ext);
    }
}

export default FileHelper;