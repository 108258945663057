import React, {Component} from 'react';
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {NavLink as RRNavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BackendApiService from "../../services/BackendApiService";
import Footer from '../../components/footer/Footer';
import CheckAccess  from '../../components/check-access/CheckAccess';
import logo from '../assets/logo_af_pro.svg';
import { Permissions } from '@autofact/operation-lib-model-notary-platform';

class MainLayout extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            isOpen: false,
            dropdownOpen: false,
            emp_nombre: null
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    async componentDidMount() {
        let session = await BackendApiService.getSessionData();
        if (session) {
            this.setState({
                emp_nombre: session.usuario.emp_nombre
            });
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Navbar color="dark" dark expand="md" className={'bg-primary-darker'}>
                    <NavbarBrand to="/" tag={RRNavLink}>
                        <img src={logo} alt="Autofact Pro Logo" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle}/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <div className="ml-auto">
                            <Nav navbar>
                                <div className="navbar-menu">
                                    <CheckAccess permission={Permissions.TELE_PRESENCE_SEE}>
                                        <NavItem>
                                            <NavLink to="/tele-presencia" activeClassName="active" tag={RRNavLink}>Tele-presencia</NavLink>
                                        </NavItem>
                                    </CheckAccess>
                                    <CheckAccess permission={Permissions.NOTARY_CONTRACT_SIGN}>
                                        <NavItem>
                                            <NavLink to="/notaria" activeClassName="active" tag={RRNavLink}>Contratos Notaría</NavLink>
                                        </NavItem>
                                    </CheckAccess>
                                    <NavItem>
                                        <NavLink to="/transferencias" activeClassName="active" tag={RRNavLink}>Contratos Autofact</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/preingresos" activeClassName="active" tag={RRNavLink}>Preingreso</NavLink>
                                    </NavItem>
                                    <CheckAccess permission={Permissions.SIGN_DOCUMENT_SEE}>
                                        <NavItem>
                                            <NavLink to="/firma-documentos" activeClassName="active" tag={RRNavLink}>Firma Documentos</NavLink>
                                        </NavItem>
                                    </CheckAccess>
                                    <CheckAccess permission={Permissions.SIGN_DOCUMENT_PHYSIC_SEE}>
                                        <NavItem>
                                            <NavLink to="/firma-documentos-fisicos" activeClassName="active" tag={RRNavLink}>Mandato físico</NavLink>
                                        </NavItem>
                                    </CheckAccess>
                                </div>
                                <div className="navbar-session">
                                    {
                                        this.props.isLoggedIn ?
                                            <NavItem className="navbar-emp">
                                                <NavLink>
                                                    <FontAwesomeIcon icon="building" />
                                                    <span className="emp-name">{this.state.emp_nombre}</span>
                                                </NavLink>
                                            </NavItem>
                                        :
                                            <span></span>
                                    }
                                    <NavItem>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                            <DropdownToggle caret nav>
                                                <FontAwesomeIcon icon="user"/>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                {this.props.isLoggedIn ?
                                                    <div>
                                                        <DropdownItem>
                                                            <Link to="/logout" className="dropdown-link">
                                                                <FontAwesomeIcon icon="sign-out-alt" />
                                                                Cerrar Sesión
                                                            </Link>
                                                        </DropdownItem>
                                                        <DropdownItem>
                                                            <Link to="/preferencias" className="dropdown-link">
                                                                <FontAwesomeIcon icon="user-cog"/>Preferencias
                                                            </Link>
                                                        </DropdownItem>
                                                    </div>
                                                    :
                                                    <DropdownItem>
                                                        <Link to="/login" className="dropdown-link">
                                                        <FontAwesomeIcon icon="sign-in-alt" />
                                                        Iniciar Sesión
                                                        </Link>
                                                    </DropdownItem>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </NavItem>
                                </div>
                            </Nav>
                        </div>

                    </Collapse>
                </Navbar>
                <div className={'main-container'}>
                    {this.props.children}
                </div>
                <Footer />
            </div>
        );
    }
}

export default MainLayout;
