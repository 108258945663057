import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import PreAdmissionList from "../pre-admission-list/PreAdmissionList";
import TransferenceList from "../transference-list/TransferenceList";
import ModalConfirm from "../modal-confirm/ModalConfirm";

import excelIcon from '../../theme/assets/ico-excel.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PreAdmissionListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preAdmissionList: [],
            preFullAdmissionList: [],
            transferenceList: [],
            transferenceSelected: {},
            fetchParams: ['where', 'per_page', 'page'],
            params: this.props.filters || {},
            exporting: false,
            preAdmissionInProgress: false,
            preAdmissionFullInProgress: false,
            preAdmissionFullRender: false,
            preAdmissionJoinInProgress: false,
            preAdmissionJoinUrl: null,
            preAdmissionJoinError: null,
            preAdmissionFails: 0,
            transferWithErrorObject:null,
            transferWithErrorId:null,
            exportResult: '',
            resultPreAdmissionFull: false,
            errorPreAdmissionMessage: 'Testing...',
            errorPreAdmissionType: 'primary'
        };
        this.fetchData = this.fetchData.bind(this);
        this.selectTransference = this.selectTransference.bind(this);
        this.cancelAddedUsedTransfer = this.cancelAddedUsedTransfer.bind(this);
        this.confirmAddedUsedTransfer = this.confirmAddedUsedTransfer.bind(this);
        this.cancelPreAdmissionFull = this.cancelPreAdmissionFull.bind(this);
        this.confirmPreAdmissionFull = this.confirmPreAdmissionFull.bind(this);
        this.preAdmissionListComponentRef = React.createRef();
        this.transferenceListComponentRef = React.createRef();
    }
    componentWillReceiveProps(props) {
        this.setState({activeTab: props.id});
    }

    async fetchData(params) {
        params = Object.assign(this.state.params, params);
        params = this.filterParams(params);

        if(this.props.tab === 'preAdmision') {
            const preAdmissionList = await BackendApiService.getPreAdmissionList(params);
            this.setState({params, preAdmissionList});
            this.props.updateCount(preAdmissionList.extra.recordsTotal);
            return preAdmissionList;
        } else {
            const transferenceList = await BackendApiService.getTransferenceList(params);
            this.setState({params, transferenceList});
            this.props.updateCount(transferenceList.extra.recordsTotal);
            return transferenceList;
        }
    }
    filterParams(params) {
        const filtered = Object.keys(params)
            .filter(key => this.state.fetchParams.includes(key))
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});
        return filtered;
    }

    async exportData(format) {
        const params = this.state.params;
        params.format = format;
        params.from = this.props.tab
        let exportResult = null;
        if(this.props.tab === 'preAdmision'){
            exportResult = await BackendApiService.getPreAdmissionList(params);
        }else{
            exportResult = await BackendApiService.getTransferenceList(params);
        }

        return exportResult.data;
    }
    selectTransference(id, item, selected) {
        const transferenceSelected = Object.assign({},this.state.transferenceSelected);
        let transferWithErrorObject = null;
        let transferWithErrorId = null;
       if(selected){
           if(this.props.tab === 'forSend'){
               if(item.usada_regcivil_pdf){
                   transferWithErrorObject= item;
                   transferWithErrorId = id;
               }
           }
           transferenceSelected[id] = item;
       }else{
           delete transferenceSelected[id];
       }
        this.setState({
            transferenceSelected,
            transferWithErrorObject,
            transferWithErrorId
        })
    }
    confirmAddedUsedTransfer(){
        const transferenceSelected = Object.assign({},this.state.transferenceSelected);
        transferenceSelected[this.state.transferWithErrorId] = this.state.transferWithErrorObject;
        this.setState({
            transferenceSelected,
            transferWithErrorObject:null,
            transferWithErrorId: null
        })

    }
    cancelAddedUsedTransfer(item){
        const transferenceSelected = Object.assign({},this.state.transferenceSelected);
        delete transferenceSelected[this.state.transferWithErrorId];
        this.setState({
            transferenceSelected,
            transferWithErrorObject:null,
            transferWithErrorId: null
        })
    }

    download(format) {
        this.setState({exporting: true});
        this.exportData(format)
            .then((response) => {
                this.setState({exporting: false, exportResult: response.url});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({exporting: false});
            });
    }

    async preAdmissionMasive() {
        const withFullResponse = true;
        let fails = 0;
        this.setState({preAdmissionInProgress: true, preAdmissionFails: 0})
        const transferenceSelected = Object.assign({}, this.state.transferenceSelected);
        for (let transferenceID in transferenceSelected) {
            if(transferenceSelected[transferenceID].success){
                delete transferenceSelected[transferenceID];
            }else{
                delete transferenceSelected[transferenceID].success;
                delete transferenceSelected[transferenceID].message;
                delete transferenceSelected[transferenceID].inProgress;
            }
        }
        this.setState({transferenceSelected})
        for (let transferenceID in transferenceSelected) {
            const transference = transferenceSelected[transferenceID];
            let message = 'Ha ocurrido un error';
            try {
                transferenceSelected[transferenceID].inProgress = true;
                this.setState({transferenceSelected})
                const response = await BackendApiService.registerPreAdmision(transference.codigo, withFullResponse);
                console.log(response);
                if (response === false || (response.hasOwnProperty('success') && response.success === false)) {
                    fails++;

                    if(response){
                       if(typeof  response.data == 'string'){
                           message = response.data;
                       }else{
                           message = response.message;
                       }
                    }
                    transferenceSelected[transferenceID].success = false;
                    transferenceSelected[transferenceID].message = message;
                } else {
                    transferenceSelected[transferenceID].success = true;
                }

            } catch (e) {
                transferenceSelected[transferenceID].success = false
                transferenceSelected[transferenceID].message = message
            }
            this.setState({transferenceSelected})
        }
        this.setState({preAdmissionInProgress: false, preAdmissionFails: fails});
        if(fails === 0)
            this.props.setToTabforSend();
    }

    /**
     * Show modal asking for action to do
     */
    renderPreAdmissionFull(action) {
        this.setState({ preAdmissionFullRender: action });
    }

    /**
     * Will send all transferences to queue
     */
    async confirmPreAdmissionFull() {

        const withFullResponse = true;

        try {
             this.setState({ preAdmissionFullInProgress: true });
            const trfToQueueResponse = await BackendApiService.registerPreAdmisionFull( withFullResponse);
            if (trfToQueueResponse.status !== "OK") {
                throw new Error(`${trfToQueueResponse.message}: ${trfToQueueResponse.data}`);
            }
            this.setState({ resultPreAdmissionFull: true,
                            errorPreAdmissionType: "primary",
                            errorPreAdmissionMessage: `Se han enviado a preingresar correctamente` });

        } catch (e) {
            this.setState({ resultPreAdmissionFull: true, errorPreAdmissionType: "danger", errorPreAdmissionMessage: e.message });
        }

        this.setState({ preAdmissionFullInProgress: false });
        this.renderPreAdmissionFull(false);
    }

    cancelPreAdmissionFull(item){
        // TO DO
        this.renderPreAdmissionFull(false);
    }


    async joinPdfs() {
        let preAdmissionUrl= null;
        let preAdmissionError = null;
        this.setState({
            preAdmissionJoinInProgress: true,
            preAdmissionJoinUrl: null,
            preAdmissionJoinError:null
        })
        let transferenceSelected = Object.assign({},this.state.transferenceSelected)
        const ids = Object.keys(this.state.transferenceSelected);
        try{
          const response =  await BackendApiService.generatePDFToRegCivil(ids);
            if(typeof response['data'] !== 'undefined' && response.data.s3Path){
                preAdmissionUrl = response.data.s3Path;
                transferenceSelected = {};
                this.transferenceListComponentRef.current.reloadData();
            }else{
                preAdmissionError = "Ocurrio un error creando el pdf";
            }
        }catch (e){
            console.log(e);
            preAdmissionError = "Ocurrio un error creando el pdf";
        }
        this.setState({
            preAdmissionJoinInProgress: false,
            preAdmissionJoinUrl:preAdmissionUrl,
            preAdmissionJoinError:preAdmissionError,
            transferenceSelected:transferenceSelected
        })
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            if(this.preAdmissionListComponentRef.current){
                this.preAdmissionListComponentRef.current.reloadData();
            }else if(this.transferenceListComponentRef.current){
                this.transferenceListComponentRef.current.reloadData();
            }

        }, 100);
    }

    renderModalConfirmAddTransferUsed(){
        return this.state.transferWithErrorObject && <ModalConfirm
                confirmFunction={this.confirmAddedUsedTransfer}
                cancelFunction={this.cancelAddedUsedTransfer}
                messageType="warning"
                confirmText="Si"
                cancelText="No"
                message={
                `El contrato patente ${this.state.transferWithErrorObject.patente}
                repertorio ${this.state.transferWithErrorObject.numero_repertorio}
                ya fue utilizado en la generación de otro PDF.¿Desea continuar de todas formas`}
            />
    }
    render() {
        let forSendComponent = null;
        if(this.props.tab === 'forSend'){
            if(this.state.preAdmissionJoinUrl){
                forSendComponent  = <a
                    key="export-pdf-download"
                    href={this.state.preAdmissionJoinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => this.setState({preAdmissionJoinUrl: ''})}
                    className={'btn donwload-button btn-sm actions'}
                    disabled={this.state.exporting}>
                    <FontAwesomeIcon icon="file-download" />
                    Descargar PDF
                </a>
            }else{
                forSendComponent = [<button
                    key="pre-admission-join"
                    className={'btn btn-gray btn-sm actions'}
                    onClick={()=>this.joinPdfs()}
                    disabled={this.state.preAdmissionJoinInProgress || Object.keys(this.state.transferenceSelected).length === 0}>
                    {this.state.preAdmissionJoinInProgress ? 'Generando...': 'Generar pdf'}
                </button>]
                if(this.state.preAdmissionJoinError){
                    forSendComponent.push(<span className="ml-2 align-self-center text-danger">{this.state.preAdmissionJoinError}</span>)
                }
            }
        }
        return (
            <div>
                {this.renderModalConfirmAddTransferUsed()}
                {this.props.tab === 'preAdmision' && <PreAdmissionList
                    ref={this.preAdmissionListComponentRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                />}
                {this.props.tab !== 'preAdmision' &&  <TransferenceList
                    ref={this.transferenceListComponentRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                    inProgress={this.state.preAdmissionInProgress}
                    fails={this.state.preAdmissionFails}
                    selectRow={this.selectTransference}
                    selected={this.state.transferenceSelected}
                />}

                <div className="action-buttons d-flex align-items-stretch">
                        {this.state.exportResult ?
                            <a
                                key="export-xls-download"
                                href={this.state.exportResult}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => this.setState({exportResult: ''})}
                                className={'btn donwload-button btn-sm actions'}
                                disabled={this.state.exporting}>
                                <FontAwesomeIcon icon="file-download" />
                                Descargar Excel
                            </a>
                            :
                            <button
                                key="export-xls"
                                className={'btn btn-gray btn-sm actions'}
                                onClick={() => this.download('xlsx')}
                                disabled={this.state.exporting}>
                                <img src={excelIcon} alt="Excel icon"/>
                                {this.state.exporting ? 'Exportando...': 'Exportar a Excel'}
                            </button>
                        }
                    {this.props.tab === 'forPreAdmission' &&
                    <button key="pre-admission-massive" className={'btn btn-gray btn-sm actions mr5-simple'}
                            onClick={() => this.preAdmissionMasive()}
                            disabled={this.state.preAdmissionInProgress || Object.keys(this.state.transferenceSelected).length === 0}>
                            {this.state.preAdmissionInProgress ? 'Preingresando...': 'Preingresar'}
                        </button>
                    }
                    {this.props.tab === 'forPreAdmission' &&
                    <button key="pre-admission-full" className={'btn btn-gray btn-sm actions'}
                            onClick={() => this.renderPreAdmissionFull(true)}
                            disabled={ this.state.preAdmissionFullInProgress }>
                            { this.state.preAdmissionFullInProgress ? 'Preingresando masivamente...': 'Preingresar contratos de hoy' }
                        </button>
                    }
                    { this.props.tab === 'forPreAdmission' && this.state.preAdmissionFullRender && <ModalConfirm
                        confirmFunction={this.confirmPreAdmissionFull}
                        cancelFunction={this.cancelPreAdmissionFull}
                        messageType="warning"
                        confirmText="Aceptar"
                        cancelText="Cancelar"
                        message={
                        `Se procederá(n) a pre ingresar automáticamente ${this.state.transferenceList.extra.recordsTotal} contrato(s).
                        ¿Está seguro que desea continuar?.`}
                        />
                    }
                    { this.props.tab === 'forPreAdmission' && this.state.resultPreAdmissionFull && <ModalConfirm
                        confirmFunction={ this.cancelPreAdmissionFull }
                        cancelFunction={this.cancelPreAdmissionFull}
                        messageType={ this.state.errorPreAdmissionType }
                        confirmText="Aceptar"
                        cancelText='Cancelar'
                        message={ this.state.errorPreAdmissionMessage }
                        />
                    }
                    {forSendComponent}
                </div>
            </div>
        );
    }
}

export default PreAdmissionListContainer;
