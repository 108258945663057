import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from "react-avatar-editor";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import {Refresh, Replay} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import CustomSlider from "./CustomSlider";
import {makeStyles} from "@material-ui/core";
import Placeholder from "./Placeholder";


const useStyles = makeStyles(theme => ({
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));


const propTypes = {
  img: PropTypes.string.isRequired,
  caption: PropTypes.string,
}


const ImageViewer = ({ title, img }) => {
  const classes = useStyles();
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  const rotateLeft = () => {
    setRotation(rotation - 5)
  }

  const rotateRigth = () => {
    setRotation(rotation + 5)
  }

  const handleZoom = (event, value) => {
    setScale(value)
  }

  if (!img) {
    return <Placeholder label={`Sin ${title}`} />
  }

  return (
    <Card elevation={5}>
      <CardContent className={classes.cardContent}>
        <AvatarEditor
          image={img}
          width={300}
          height={300}
          border={0}
          scale={scale}
          rotate={rotation}
        />
      </CardContent>
      <CardActions>
        <Tooltip title="Rotar a la izquierda">
          <IconButton>
            <Replay onClick={rotateLeft} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Acercar/Alejar">
          <CustomSlider min={0.1} max={2} step={0.1} value={scale} onChange={handleZoom} />
        </Tooltip>

        <Tooltip title="Rotar a la derecha">
          <IconButton>
            <Refresh onClick={rotateRigth} />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
};

ImageViewer.propTypes = propTypes;

export default ImageViewer;
