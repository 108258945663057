import { useState , useEffect } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress } from "@material-ui/core";
import BackendApiService from '../../services/BackendApiService'
import rutHelper from '../../helpers/rutHelper'
import FileHelper from '../../helpers/FileHelper';

const ValidationResult = ({
  resultData
}) => {
  const [urlPDF, setUrlPDF] = useState('');
  const [loading, setLoading] = useState(false);

  const peopleRuts = resultData.validationForm.foodDebt.people.data?.people || [];
  const companiesRuts = resultData.validationForm.foodDebt.companies || [];
  const ticket = resultData.ticket;

  useEffect(() => {

    const getDocumentUrl = async (ticket) => {
      try {
        setLoading(true);
        const response = await BackendApiService.getCAVURL(ticket);
        if(!response){
          throw new Error("couldn't get the file URL");
        }

        setUrlPDF(response.url);
        setLoading(false);
        
      } catch (error) {
        console.log(error);
      }
    }

    getDocumentUrl(ticket);

  }, [ticket])

  const handleDownload = (url) => {
    const fileUrl = url;
    FileHelper.downloadFile(fileUrl);
  }

  const jsxCavValidatedItemInfoNOk = (title, desc) => {
    return (
      <>
        <p className='mb-0 item'><FontAwesomeIcon icon={'times-circle'} color='red'/> <strong> {title} </strong></p>
        <span>{desc}</span>
      </>
    )
  }

  const jsxCavValidatedItemInfoOk = (title, desc) => {
    return (
      <>
        <p className='mb-0 item'><FontAwesomeIcon icon={'check-circle'} color='green'/> <strong> {title} </strong></p>
        <span>{desc}</span>
      </>
    )
  }

  return (
    <div className='result-layout'>
      <h1>Resultados de la validación</h1>
      <div className='container-fluid shadow p-3 bg-white rounded'>
        <h2>Deuda de pensión alimenticia</h2>
        <Row className='mt-3'>
          {
            peopleRuts.map((person, index) => {
              const fullRut = person.rutDeudor.toString() + person.dvDeudor;
              let rutProps = {color : 'green', icon: 'check-circle', message: 'No presenta deuda pendiente.'};

              if(person.cantidadVigentes)
                rutProps = {color : 'red', icon: 'times-circle', message: 'Presenta deuda pendiente.'}; 
                
              if(person.error)
                rutProps = {color : 'red', icon: 'times-circle', message: person.error};

              return (
                <Col className='col-auto' key={`rut-${index}`}>
                  <p className='mb-0 item'><FontAwesomeIcon icon={rutProps.icon} color={rutProps.color}/> <strong> Rut { rutHelper.toDotedRut(fullRut)} </strong></p>
                  <span>{rutProps.message}</span>
                </Col>
              )
            })
          }

          {
            companiesRuts.map((company, index) => {
              return (
                <Col className='col-auto' key={`rut-${index}`}>
                  <p className='mb-0 item'><FontAwesomeIcon icon='minus-circle'/><strong> Rut { rutHelper.toDotedRut(company)} </strong></p>
                  <span>Rut empresa no aplica</span>
                </Col>
              )
            })
          }

        </Row>
      </div>

      <div className='container-fluid shadow mt-4 p-3 bg-white rounded'>
        <h2>Información del CAV</h2>
        <Row className='mt-3'>
          <Col className='col-auto'>
             {
              resultData.validationForm.domainLimitation ? 
                jsxCavValidatedItemInfoNOk('Limitaciones al dominio','Presenta anotaciones vigentes incorporadas al registro')
              :
                jsxCavValidatedItemInfoOk('Limitaciones al dominio', 'A la fecha no tiene anotaciones vigentes incorporadas al registro.')
             }
          </Col>
          <Col className='col-auto'>
             {
              resultData.validationForm.theftCharge ? 
                jsxCavValidatedItemInfoNOk('Encargos por robo','Presenta encargo por robo.')
              :
                jsxCavValidatedItemInfoOk('Encargos por robo', 'A la fecha no tiene encargo por robo.')
             }
          </Col>
          <Col className='col-auto'>
             {
              resultData.validationForm.annotation ? 
                jsxCavValidatedItemInfoNOk('Observaciones en trámite','Vehículo presenta observaciones en trámite.')
              :
                jsxCavValidatedItemInfoOk('Observaciones en trámite', 'Vehículo no presenta observaciones en trámite.')
             }
          </Col>
          <Col className='col-auto'>
             {
              resultData.validationForm.fines ? 
                jsxCavValidatedItemInfoNOk('Multas anotadas','Vehículo presenta multas anotadas.')
              :
                jsxCavValidatedItemInfoOk('Multas anotadas', 'Vehículo no tiene multas anotadas.')
             }
          </Col>
        </Row>
          {
            loading? (
              <div className='file-loading'>
                <CircularProgress size={80} className='text-primary-dark'/>
                <p>Cargando documentos...</p>
              </div>
            ) : (
              <Row className='p-4'>
                <Col md={12}>
                    <Button color='outline-primary-dark' className="font-weight-bolder download-btn" onClick={() => handleDownload(urlPDF)} > <span><FontAwesomeIcon icon={"download"} /> Descargar CAV </span></Button>
                </Col>
                <Col md={12} className='mt-4'>
                  <embed src={urlPDF} width={'100%'} height={'904'}></embed>
                </Col>
              </Row>
            )
          }
      </div>
    </div>
  )
}

export default ValidationResult