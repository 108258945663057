import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RepresentativetInformation = (props) => {
  
  const { data, isContractUpdate } = props;
  const { representatives, openRemoveModal, setRepresentativeIndex, openModalUpdate, openModalRepresentative, sellerIsCompany } = data;

  const startRemovingRepresentative = (index) => {
    setRepresentativeIndex(index);
    openRemoveModal();
  };

  return (
    <div>
      {
        representatives.map((representative, index) => {
          return (
            <div className='mt-3 representative-box' key={`representative-${index}`}>
              <span className='text-muted font-weight-bold text-14'>Representante {index + 1} </span>
              <div className='row mt-4 text-16'>
                <div className='col-lg-4'>
                  <p> <strong>Nombre: </strong>{representative.name}</p>
                </div>
                <div className='col-lg-4 col-md-12'>
                  <p> <strong>Apellido Paterno: </strong>{representative.fatherLastName}</p>
                </div>
                <div className='col-lg-4 col-md-12 '>
                  <p> <strong>Apellido Materno: </strong>{representative.motherLastName}</p>
                </div>
              </div>
              {
                !isContractUpdate &&
                  <div className='row mt-4'>
                      <button type='button' className='btn border-0 btn-edit bold' onClick={() => openModalUpdate(index)}> <FontAwesomeIcon icon='pen' className='mr-3'/>Editar</button>
                      <button type='button' className='btn border-0 btn-remove bold' onClick={() => startRemovingRepresentative(index)}> <FontAwesomeIcon icon='trash' className='mr-3'/>Eliminar </button>
                  </div>
              }
            </div>
          )
        })
      }
      {
        !isContractUpdate &&
          <div className={(representatives.length > 0) ? 'mt-5' : ''}>
            <button type='button' className='btn btn-outline-primary-dark bold' onClick={() => openModalRepresentative()}> <FontAwesomeIcon icon='user' className='mr-2'/>{`Agregar representante ${sellerIsCompany ? 'legal' : ''}`}</button>
          </div>
      }
    </div>
  )
}

export default RepresentativetInformation