import React, {Component} from 'react';
import {withRouter} from 'react-router';
import PreAdmissionDetailContainer from "../../components/pre-admission-detail-container/PreAdmissionDetailContainer";

class PreAdmissionDetail extends Component {
    render() {
        return (<div>
                <PreAdmissionDetailContainer preAdmissionId={this.props.match.params.id} />
            </div>
        );
    }
}

export default withRouter(PreAdmissionDetail);
