import React, {useState, useEffect} from 'react';

import { IdentityMeeting } from '@autofact/identity-meet-react-component';

import styled from 'styled-components';


const DivChildWrapper = styled.div`
  @media (min-width: 900px) {
    height: 48vh;
  }
  @media (max-width: 900px) {
    height: 48vh;
  }
  @media (max-width: 600px) {
    height: '30vh';
  }
  display: grid;
  grid-auto-columns: 3fr;
`;

const EmptyMeet = styled.div`
  height: 100%;
  width: 100%;
  background-color: #252525;
  `;

const Meeting  = ({meetData, onFinish, state}) => {
  const [meetinId, setMeetingId] = useState();
  
  useEffect(() => {
    if(Object.keys(meetData).length > 0){
      setMeetingId(meetData.Meeting.meetingId);
    }
  }, [meetData]);

  const finishMeeting = () => {
    console.error('finishMeeting', meetinId, state)
    onFinish(meetinId, state);
  }
    return (
        <React.Fragment>
          <DivChildWrapper>
            {Object.keys(meetData).length === 0 ? 
              <EmptyMeet /> 
              :
              <IdentityMeeting
                meetingInfo={meetData}
                onClientLeft={ ()=>finishMeeting() }
                localRecording={true}
                remoteRecording={true}
                muted={true}
            />
            }
          </DivChildWrapper>
        </React.Fragment>
    )
}

export default React.memo(Meeting);