
class DateHelper {
  
  static arrayToDateES(arr){
    
    const date = new Date(arr[0], arr[1] - 1, arr[2]);
    const formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });

    return formattedDate.split("/").join("-");
  };

}

export default DateHelper;