import React, {Component} from 'react';
import DataTable from '../data-table/DataTable';
import BackendApiService from "../../services/BackendApiService";
import moment from 'moment';
import { formats } from '../../config';


class TransferenceHistoryList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            activeTab: '',
            exportResult: '',
            exporting: false,
            params: this.props.filters || {},
            generating:{},
            entered: false
        }
    }

    componentWillReceiveProps(props) {
        this.setState({activeTab: props.id});
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    async exportData(format, code) {
        const params = {
            where: JSON.stringify({
                CODE: code,
            }),
            format: format
        };
        const exportResult = await BackendApiService.getTransferenceHistoryList(params);
        return exportResult.data;
    }

    download(format, code) {
        this.setState({exporting: true});
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format, code)
            .then((response) => {
                link.href = response.url;
                link.click();
                this.setState({exporting: false});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({exporting: false});
            });
    }


    render() {
        const columns = [];

        columns.push(this.renderPlateColumn());
        columns.push(this.renderCodeColumn());
        columns.push(this.renderRepertoryColumn());
        columns.push(this.rendeRepertoryDateColumn());
        columns.push(this.renderPackUrlColumn());


        return (
            <div className={`transference-list-wrapper`}>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }





    renderCodeColumn = () =>{
        return  {
            Header: 'Codigo',
            accessor: 'code',
            sortable: true
        };
    }

    renderPlateColumn = () =>{
        return  {
            Header: 'Patente',
            accessor: 'plate',
            sortable: true
        };
    }

    rendeRepertoryDateColumn = () => {
        return {
            Header: 'Fecha de repertorio',
            accessor: 'timestamp',
            Cell: row => {
                let date = moment(row.original.repertoryTimestamp);
                return (
                  <span className="pre-line">{date.format(formats.date)}</span>
                )
            }
        }
    }


    renderRepertoryColumn = () =>{
        return  {
            Header: 'Repertorio',
            accessor: 'repertory',
            sortable: true
        }
    }

    renderPackUrlColumn = () =>{
        return  {
            Header: 'Descargar',
            accessor: 'packUrl',
            Cell: row => {
                return (
                  <span key={`detail-link-span`}>
                        <a key={`detail-link`}
                              className="btn-primary-darker btn-see-link"
                              target={'_blank'}
                              rel={'noopener noreferrer'}
                              href={row.original.packUrl}>VER</a>
                    </span>
                );
            }
        }
    }






}

export default TransferenceHistoryList;
