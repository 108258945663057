import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {TabContent, TabPane,Nav, NavItem, NavLink, Collapse, Button} from 'reactstrap';
import {TransferenceStatus} from '@autofact/operation-lib-model-notary-platform';
import AuthService from "../../services/AuthService";
import DataTable from '../data-table/DataTable';
import BackendApiService from "../../services/BackendApiService";
import classnames from 'classnames';
import FilterIcon from '../../theme/assets/filter_conces.svg';
import NotaryContractsFilters from '../notary-contracts-filters/NotaryContractsFilters';
import moment from "moment";
import {formats} from "../../config";
import contractHeaderSettings from '../../helpers/contractHeaderSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const tabs =  [
    {
        id: 'allContracts',
        name: 'Todos los contratos',
        filters: {
            SPECIFIC_STATUS: [
                TransferenceStatus.INGRESADO_STEV.id,
                TransferenceStatus.TRANSFERRED.id,
                TransferenceStatus.REJECTED.id,
                TransferenceStatus.SPECIAL_CASE.id
            ]
        }
    },
    {
        id: 'rc',
        name: 'Ingresado a Reg. Civil',
        filters: {SPECIFIC_STATUS: TransferenceStatus.INGRESADO_STEV.id}
    },
    {
        id: 'transferred',
        name: 'Transferidos',
        filters: { SPECIFIC_STATUS: TransferenceStatus.TRANSFERRED.id}
    },
    {
        id: 'rejected',
        name: 'Rechazados',
        filters: { SPECIFIC_STATUS: TransferenceStatus.REJECTED.id}
    },
]

class AllNotaryContracts extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: '',
            activeTab: {},
            filtersCollapsed: true,
            extraFilters: {}
        }
        this.dataTableRef = React.createRef();
        this.filterComponentRef = React.createRef();
        this.fetchData = this.fetchData.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    async componentWillMount(){
        const activeTab = tabs[0];
        this.setState({activeTab});
        const authData = await AuthService.getAuthData();
        this.setState({user: authData.user ?? ''});
    }

    async fetchData(paginate){
        const filters = {
            IS_FROM_NOTARY: true,
            ENTERED_INTO_STEV: true,
            ...this.state.activeTab.filters
        }
        const extraFilters = this.state.extraFilters;
        const allFilters = {...filters, ...extraFilters};
        const params = { where: JSON.stringify(allFilters), ...paginate };
        const contractsList = await BackendApiService.getTransferenceList(params);
        return contractsList;
    }

    toggle(tab) {
        this.resetFilters();
        if (this.state.activeTab.id !== tab) {
            const activeTab = tabs.find((el) => el.id === tab);
            this.setState( { activeTab })
        }
        setTimeout(() => {
            this.dataTableRef.current.reloadData();
        }, 120);
    }

    toggleFilters() {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    applyFilters(filters){
        this.setState({extraFilters: filters});
        setTimeout(() => {
            this.dataTableRef.current.reloadData();
        }, 100);
    }

    resetFilters(){
        this.setState({ extraFilters: {}});
        this.filterComponentRef.current.resetFilters();
    }

    render(){
        const columns = [
            {
                Header: 'Patente',
                accessor: 'patente',
                width: 100
            },
            {
                Header: 'Marca/Modelo/Año',
                accessor: 'description',
                minWidth: 130,
                Cell: row => {
                    const r = row.original;
                    return (<p><b> {r.marca} </b> {r.modelo} <b> {r.year} </b></p>)
                }
            },
            {
                Header: 'Repertorio',
                accessor: 'numero_repertorio',
                width: 100,
                Cell: row => {
                    return (< div className='text-center'> {row.original.numero_repertorio} </div>)
                } ,
            },
            {
                Header: 'Fecha creación',
                accessor: 'timestamp',
                width: 200,
                Cell: row => {
                    let date = moment(row.original.timestamp);
                    return date.utc().format(formats.date);
                }
            },
            {
                Header: 'Fecha ingreso RC',
                accessor: 'ts_timestamp',
                width: 200,
                Cell: row => {
                    if(row.original.ts_timestamp){
                        let date = moment(row.original.ts_timestamp);
                        return date.utc().format(formats.date);
                    }
                    return '';
                }
            },
            {
                Header: 'Estado',
                accessor: 'status',
                headerClassName: 'text-center',
                width: 200,
                Cell: row => {
                    const status = row.original.estado;
                    const headerSetting = contractHeaderSettings(status);

                    return(
                       <div className={` ${headerSetting.cssClass} ml-2`}>
                            <FontAwesomeIcon icon={headerSetting.icon} className='mr-2'/>
                            {headerSetting.label}
                        </div>
                    )
                }
            },
            {
                Header: 'Acciones',
                accessor: 'actions',
                Cell: row => (
                   <Actions row={row.original} />
                )
            },
        ];

        const Actions = ( { row } ) => {
            return (
                <>
                    <Link
                    className="text-primary-dark bolder py-0 h3"
                    to={`notaria/ver/${row.codigo}`}>
                        Ver contrato
                    </Link>
                </>
            )
        }

        const activeTab = this.state.activeTab;

        return(
            <div className='tabs-container w-100'>
                <Nav tabs className="tabs-dark">
                    { Object.keys(tabs).map((_, index) => {
                        const tab = tabs[index];
                        return(
                            <NavItem key={`tab-nav-item-${index}`}>
                                <NavLink
                                    key={`tab-nav-link-${index}`}
                                    className={classnames({ active: this.state.activeTab.id === tab.id })}
                                    onClick={() => { this.toggle(tab.id); }}
                                >
                                    { tab.name }
                                </NavLink>
                            </NavItem>
                        )
                    })}
                    <NavItem className="nav-filter-button ml-auto mr-3">
                        <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters}>
                            <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                        </Button>
                    </NavItem>
                </Nav>
                <Collapse isOpen={!this.state.filtersCollapsed}>
                    <div className="filter-form-wrapper">
                        <NotaryContractsFilters
                            ref={this.filterComponentRef}
                            setFiltersCallback={this.applyFilters}
                        />
                    </div>
                </Collapse>
                <TabContent activeTab={activeTab.id}>
                    <TabPane tabId={activeTab.id}>
                        <DataTable
                            ref={this.dataTableRef}
                            id={this.props.id}
                            columns={columns} {...this.props}
                            fetchDataCallback={this.fetchData}
                        />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

export default withRouter(AllNotaryContracts);
