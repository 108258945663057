import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, ModalFooter, Button, Form, FormFeedback, Input, FormGroup } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import BackendApiService from "../../services/BackendApiService";

const shortid = require('shortid');

const NotaryContractsCavRequest = ({
  errorAlert,
  closingModal
}) => {
  const [modal, setModal] = useState(true);
  const [plate, setPlate] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');

  const requestFunction = async (plate) => {
    try {
      setLoading(true);
      const code = shortid.generate();

      const cavResponse = await BackendApiService.CAVRequest(plate, code);
      if(!cavResponse || cavResponse.status !== 'OK' || plate.length !== 6){
        throw new Error("RequestFunction error: error while trying to request CAV");
      }

      const preTransResponse = await BackendApiService.createPretransfer({
        codigo: code,
        cav_code: cavResponse.data.ticket,
        step_id: 0,
        transference_object: '{}'
      })

      if(!preTransResponse){
        throw new Error("RequestFunction error: error while trying to create a new pre transference");
      }

      setCode(code);
      setRedirect(true);

    } catch (error) {
      console.log(error);
      errorAlert(true);
      setLoading(false);
      closeModal();
      
    }
  }

  const closeModal = () => {
    setModal(true);
    if(typeof  closeModal == 'function')
      closingModal(true);
  }

  
  return (
    redirect? (
      <Redirect
        push
        to={{
            pathname: `${window.location.pathname}/nueva-compraventa/${code}`,
            state: {
              code: code
            }
        }}
        
      />
    ) : (
      <Modal 
          isOpen={modal} 
          centered
          toggle={() => closeModal()}
          backdrop={'static'}
          className={"modal-dialog"}
        >
        <div className='modal-header bg-primary-darker h1 text-white'> 
          <p className='text-white bold h2 my-auto'>Solicitud de certificado de anotaciones vigente</p>
          <div className="close-icon" role={'button'} onClick={() => closeModal()}>
            <FontAwesomeIcon icon='times-circle' className="fa-1x" />
          </div>
        </div>
        <Form>
          <ModalBody>
          <div className="container-fluid text-center">
            <div className="row">
              <div className="col-12">
                <p className='bold orange-color h1'>
                  Ingresa la patente del vehículo a transferir
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 alert alert-warning" role="alert">
                <FontAwesomeIcon icon="exclamation-circle" className='mr-1' />
                <span className='text-dark'>
                  La solicitud del CAV es obligatoria para poder continuar con el proceso.
                </span>
              </div>
            </div>
            <FormGroup className="row mt-2">
              <div className="mx-auto  w-50">
                <Input 
                  type="text" 
                  className="form-control" 
                  id="plateInput" 
                  placeholder='Ej. ABCD12' 
                  required
                  disabled={loading}
                  onInput={(e) => setPlate(e.target.value)}/>
                <FormFeedback className="bold">
                  Ingrese una patente valida
                </FormFeedback>
              </div>
            </FormGroup>
          </div>
          </ModalBody>
          <ModalFooter className={"justify-content-center mb-4"}>
              <Button 
                className="btn-sm btn-outline-primary-dark bold cancel-btn"
                disabled={loading}
                onClick={() => closeModal()}>
                  CANCELAR
              </Button>
              <Button 
                className="btn-sm btn-primary-dark bold"
                disabled={plate.length !== 6 || loading}
                onClick={() => requestFunction(plate) } >
                  {loading? [
                    <span key="span-k-1" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key="span-k-2" className='ml-2'> Cargando...</span>
                  ] :
                    <span> SOLICITAR CAV</span>
                  }
                  
              </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  )
}

export default NotaryContractsCavRequest