import React, { Component } from 'react';
import { Form, Row, Col, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';

const AUTOFACT_REPRESENTATIVE_ACTIVE = '1';

class NotaryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signUrl: '',
            idSignatureRepresentative: '',
            signNotariesList: [],
            signRepresentativeList: [],
            saving: false,
            alertMessageConfig: {},
            autofactRepresentative: false,
        };

        this.onNotaryChangeHandler = this.onNotaryChangeHandler.bind(this);
        this.onRepresentativeChangeHandler = this.onRepresentativeChangeHandler.bind(this);
        this.isValid = this.isValid.bind(this);
        this.isValidRepresentative = this.isValidRepresentative.bind(this);
        this.updateNotarySign = this.updateNotarySign.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    async componentDidMount() {
        const alertMessageConfig = { color: 'danger', msg: 'Ocurrió un error al buscar las firmas' };

        try {
            const sessionData = await BackendApiService.getSessionData();
            if (sessionData) {
                sessionStorage.setItem('session-data', JSON.stringify(sessionData));
                this.setState({ signUrl: sessionData.notary.signatureUrl });

                if (sessionData.notary.autofactRepresentation === AUTOFACT_REPRESENTATIVE_ACTIVE && sessionData.notary.hasOwnProperty('idSignatureRepresentative')) {
                    this.setState({ idSignatureRepresentative: sessionData.notary.idSignatureRepresentative, autofactRepresentative: AUTOFACT_REPRESENTATIVE_ACTIVE })
                }
            }

            const notarySignatureList = await BackendApiService.findSignNotaries();
            const representativeSignatureList = await BackendApiService.findSignatureRepresentative();
            if (notarySignatureList || representativeSignatureList) this.setState({ signNotariesList: notarySignatureList, signRepresentativeList: representativeSignatureList });

            if (!notarySignatureList || !representativeSignatureList || !sessionData) this.setState({ alertMessageConfig });
        } catch (error) {
            console.log('Error component did mount', error);
            this.setState({ alertMessageConfig });
        }
    }

    onDismiss() {
        this.setState({ alertMessageConfig: {} });
    }

    isValid() {
        return this.state.signUrl;
    }

    isValidRepresentative() {
        return this.state.idSignatureRepresentative;
    }

    onNotaryChangeHandler(event) {
        const stateToChange = {};
        stateToChange['signUrl'] = event.target.value;
        this.setState(stateToChange);
    }

    onRepresentativeChangeHandler(event) {
        const stateToChange = {};
        stateToChange['idSignatureRepresentative'] = event.target.value;
        this.setState(stateToChange);
    }

    async updateNotarySign(e) {
        let alertMessageConfig = { color: 'danger', msg: 'Ocurrió un error al modificar la firma del notario' };

        try {
            this.setState({ saving: true });
            const response = await BackendApiService.updateNotarySign({ signUrl: this.state.signUrl, idSignatureRepresentative: this.state.idSignatureRepresentative });
            if(!response){
                this.setState({ alertMessageConfig, saving: false });
            } else {
                alertMessageConfig = { color: 'success', msg: 'Modificada la firma del notario con éxito' };
                this.setState({ saving: false, alertMessageConfig });
            }
        } catch (error) {
            console.log('Error update notary sign', error);
            this.setState({ alertMessageConfig, saving: false });
        }
    }

    render() {
        return (
            <div>
                {
                    Object.keys(this.state.alertMessageConfig).length ?
                        <Alert className="mt-2" color={this.state.alertMessageConfig.color}
                               isOpen={!!Object.keys(this.state.alertMessageConfig).length} toggle={this.onDismiss}>
                            {this.state.alertMessageConfig.msg}
                        </Alert>
                    : ''
                }
                <Form className="notary-form">
                    <Row className="field-row-preference">
                        <Col md={4}>
                            <FormGroup row>
                                <Label sm={3} for="plate" className="text-right">Firma Notario</Label>
                                <Col sm={8}>
                                    <Input type="select" name="signUrlNotary" id="signUrlNotary" className="form-control-sm" value={this.state.signUrl} onChange={this.onNotaryChangeHandler}>
                                        <option value="" disabled>Seleccione...</option>
                                        {
                                            this.state.signNotariesList.map((notary)=> {
                                                return <option key={notary.idsign_notary} value={notary.sign_url}>
                                                    {notary.sign_notary_name}
                                                </option>
                                            })
                                        }
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Col>
                        { this.state.autofactRepresentative === AUTOFACT_REPRESENTATIVE_ACTIVE && (
                            <Col md={4}>
                                <FormGroup row>
                                    <Label sm={4} for="plate" className="text-right">Firma Representante</Label>
                                    <Col md={8}>
                                        <Input type="select" name="signUrlRepresentative" id="signUrlRepresentative" className="form-control-sm" value={this.state.idSignatureRepresentative} onChange={this.onRepresentativeChangeHandler}>
                                            <option value="" disabled>Seleccione...</option>
                                            {
                                                this.state.signRepresentativeList.map((representative)=> {
                                                    return <option key={representative.idrepresentante_autofact_notaria} value={representative.idrepresentante_autofact_notaria}>
                                                        {representative.nombre_legal}
                                                    </option>
                                                })
                                            }
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                    <Row className="field-row">
                        <Col sm={6}>
                            <Button className="btn btn-sm btn-primary-darker" onClick={this.updateNotarySign} disabled={!this.isValid()}>
                                {
                                    this.state.saving ? 'Guardando...' : 'Guardar'
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

export default NotaryForm;
