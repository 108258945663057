import React, {Component} from 'react';
import {Row, Col, Input, Form, Button, Alert} from 'reactstrap';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import BackendApiService from "../../services/BackendApiService";
import {TransferenceStatus, getTransferenceStatusFromId} from '@autofact/operation-lib-model-notary-platform';
import AssignMassiveRepertoryModal from './AssignMassiveRepertoryModal';

const EXTERNAL_PROCESS = {
    MENU: 'MENU',
    SERVER_ERROR: 'SERVER_ERROR',
    LOADING: 'LOADING',
    INITIATED: 'INITIATED',
    WAITING_REPERTORIES: 'WAITING_REPERTORIES',
    REJECTED_REQUEST_REPERTORIES: 'REJECTED_REQUEST_REPERTORIES',
    FINISHED: 'FINISHED',
    MASSIVE_TRANSFER_ASSIGN_ERROR: 'MASSIVE_TRANSFER_ASSIGN_ERROR',
    PROVIDER_ERROR: 'PROVIDER_ERROR',
}

class AssignMassiveRepertoryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transferencesList: [],
            transferencesSelected: {},
            transferencesMapAssign: {},
            firstRepertoryId: null,
            firstRepertoryValue: 0,
            assignInProgress: false,
            fails: {},
            recordCount: 0,
            selectedRecordCount: 0,
            displayMessage: '',
            redirect: false,
            externalProcessActive: false,
            externalRepertoriesRequest: [],
            step:''
        };

        this.transferenceSelected = this.transferenceSelected.bind(this);
        this.assignProgresStatus = this.assignProgresStatus.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidMount() {
        this.fetchData();
        this.setState({step:'MENU'});
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.transferencesList !== this.state.transferencesList && this.props.automaticAssignment){
            this.handlerSelectedAll();
        }
        if(prevState.selectedRecordCount !== this.state.selectedRecordCount && this.props.automaticAssignment){
            this.setState({externalProcessActive: true, step: EXTERNAL_PROCESS.MENU})
        }
    }

    onDismiss() {
        this.setState({isMessageVisible: false});
    }

    showSuccesMessage(message) {
        this.setState({
            message: {
                success: true,
                text: message
            }, isMessageVisible: true
        })
    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }

    fetchData = async () => {
        const params = {
            where: JSON.stringify({
                STATUS: TransferenceStatus.REPERTORY.id,
            }),
            per_page: -1
        };
        const transferencesList = await BackendApiService.getTransferenceList(params);
        const data = transferencesList.data;
        const firstElement = data[0] || {};
        this.setState({
            transferencesList: data,
            firstRepertoryId: firstElement.codigo || null,
            recordCount: transferencesList.extra.recordsTotal
        });
    };

    transferenceSelected(e, item) {
        const stateToChange = Object.assign({}, this.state.transferencesSelected);
        if (e.target.checked) {
            stateToChange[e.target.value] = item;
        } else {
            delete stateToChange[e.target.value]
        }
        this.updateSelectedCounter(stateToChange);
        this.setState({transferencesSelected: stateToChange});
    }

    assignProgresStatus(fails) {
        this.setState({fails})
    }

    async sendExternalRepertoriesRequest(){
        const transfers = this.state.transferencesList.map( item => item.idtransferencia);
        const response =  await BackendApiService.SendExternalRepertoriesRequest(transfers);
        return response;
    }

    async getExternalRepertoryRequest(){
        const transferObject = {};
        this.state.transferencesList.forEach( item => transferObject[item.patente] = item.codigo );
        return BackendApiService.GetExternalRepertoryRequest(transferObject);
    }

    AssignExternalRepertories = async (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        this.setState({externalProcessActive: true, step: EXTERNAL_PROCESS.LOADING})
        const repertoriesRequestResponse = await this.getExternalRepertoryRequest();
        if(repertoriesRequestResponse === false){
            this.setState({ step: EXTERNAL_PROCESS.SERVER_ERROR});
        }else if(repertoriesRequestResponse.success === true  && repertoriesRequestResponse.message === 'SUCCESS'){
            this.setState({step: EXTERNAL_PROCESS.FINISHED});
        }else if(repertoriesRequestResponse.message === 'PENDING_EXTERNAL_REQUEST_PROCESS'){
            this.setState({step: EXTERNAL_PROCESS.WAITING_REPERTORIES});
        }else if(repertoriesRequestResponse.message === 'REJECTED_EXTERNAL_REQUEST_PROCESS'){
            this.setState({step: EXTERNAL_PROCESS.REJECTED_REQUEST_REPERTORIES});
        }else if(repertoriesRequestResponse.success === false && repertoriesRequestResponse.message === 'ACTIVE_EXTERNAL_REPERTORY_REQUEST_PROCESS_NOT_FOUND'){
            const tokenResponse = await this.sendExternalRepertoriesRequest();
            if(tokenResponse.status){
                if(tokenResponse.status === 'pending'){
                    this.setState({ step: EXTERNAL_PROCESS.INITIATED});
                }else if(tokenResponse.status === 'finish'){
                    this.setState({ step: EXTERNAL_PROCESS.FINISHED});
                }else if(tokenResponse.status === 'providerError'){
                    this.setState({ step: EXTERNAL_PROCESS.PROVIDER_ERROR});
                }
            }else{
                this.setState({ step: EXTERNAL_PROCESS.SERVER_ERROR});
            }
        }else if(repertoriesRequestResponse.success === false && repertoriesRequestResponse.message === 'MASSIVE_TRANSFER_ASSIGN_ERROR'){
            this.setState({ step: EXTERNAL_PROCESS.MASSIVE_TRANSFER_ASSIGN_ERROR});
        }else{
            this.setState({ step: EXTERNAL_PROCESS.SERVER_ERROR});
        }
    }

    handlerAssignRepertory = async (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        let buildTransferencesParams = [];
        for (let key in this.state.transferencesSelected) {
            buildTransferencesParams.push({
                transferenceCode: `${key}`,
                repertoryNumber: this.state.transferencesMapAssign[key]
            })
        }
        this.setState({assignInProgress: true})

        const assignMasiveResponse = await BackendApiService.assignMasiveRepertory(buildTransferencesParams, this.assignProgresStatus)
        if (!assignMasiveResponse) {
            this.showErrorMessage('Ocurrio un error asignando los repertorios');
        } else {
            if (assignMasiveResponse.countFail > 0) {
                this.showSuccesMessage(`Se asignaron ${assignMasiveResponse.countOK} y fallaron las siguientes transferencias ${assignMasiveResponse.fails.join(', ')}`);
            } else {
                this.showSuccesMessage(`Se asignaron ${assignMasiveResponse.countOK} repertorios`);
                this.setState({redirect: true});
            }
        }
        this.setState({assignInProgress: false, fails: {}, transferencesSelected: {}, transferencesMapAssign: {}});
        this.fetchData();
    };

    handlerClearSelected = (e) => {
        e.preventDefault();
        console.log('Borrando seleccionados ');
        this.setState({transferencesSelected: {}});
    };

    handlerAutocompleteRepertories = (e) => {
        e.preventDefault();
        console.log('Asignando repertorios seleccionados')
        let pos = 0;
        const transferencesMapAssign = {
            [this.state.firstRepertoryId]: this.state.firstRepertoryValue
        };
        const firstValue = parseInt(this.state.firstRepertoryValue);
        if (isNaN(firstValue)) {
            this.setState({
                displayMessage: {
                    'message': "No es un número de repertorio válido",
                    'type': 'error'
                }
            })
        } else {
            this.state.transferencesList.forEach(transference => {
                const id = transference.codigo;
                if (id !== this.state.firstRepertoryId && this.state.transferencesSelected.hasOwnProperty(id)) {
                    pos++;
                    transferencesMapAssign[id] = firstValue + pos
                }
            })
        }
        this.setState({transferencesMapAssign});
    };

    updateSelectedCounter = (selected) => {
        let selectedRecordCount = 0;
        for (let key in selected) {
            if (selected.hasOwnProperty(key)) {
                ++selectedRecordCount;
            }
        }
        this.setState({selectedRecordCount})
    };

    handlerSelectedAll = (e = { target: {}}) => {
        console.log('Seleccionando todos');
        const transferencesSelected = {};
        if (e.target.checked || this.props.automaticAssignment) {
            this.state.transferencesList.forEach(transfer => {
                transferencesSelected[transfer.codigo] = transfer
            })
        }
        this.updateSelectedCounter(transferencesSelected);
        this.setState({transferencesSelected});
    };

    handlerRepertoryChange = (id, e) => {
        const transferencesMapAssign = Object.assign({}, this.state.transferencesMapAssign);
        transferencesMapAssign[id] = e.target.value;
        let firstRepertoryValue = this.state.firstRepertoryValue;
        if (id === this.state.firstRepertoryId) {
            firstRepertoryValue = e.target.value;
        }
        this.setState({transferencesMapAssign, firstRepertoryValue})
    };

    modalProcessButton = () => {
        return <Button
              className="btn btn-sm  btn-primary-darker"
              onClick={() => this.setState({externalProcessActive:false, redirect:true})}
              >
              Aceptar
        </Button>
    }

    modalMenuButtons = () => {
        return <React.Fragment>
            <Button
                className="btn btn-sm  btn-primary-darker"
                onClick={this.AssignExternalRepertories}
            >
                Aceptar
            </Button>
            <Button
                className="btn btn-sm  btn-primary"
                onClick={() => this.setState({externalProcessActive:false, redirect:true})}
            >
                cancelar
            </Button>
        </React.Fragment>
    }

    renderTransferencesList() {
        return this.state.transferencesList.map((transference, index) => {
            let estado = getTransferenceStatusFromId(transference.estado);
            if (estado && estado.label) {
                estado = estado.label
            } else {
                estado = transference.estado
            }
            let resultado = '';
            if (this.state.fails[transference.codigo] === -1) {
                resultado = <FontAwesomeIcon icon='spinner' className='icon' spin/>
            } else if (this.state.fails[transference.codigo] === 0) {
                resultado = <FontAwesomeIcon icon='times' className='icon'/>
            } else if (this.state.fails[transference.codigo] === 1) {
                resultado = <FontAwesomeIcon icon='check' className='icon'/>
            }
            const selected = this.state.transferencesSelected.hasOwnProperty(transference.codigo);
            const background = index % 2 === 0 ? 'background' : '';
            const marked = selected ? 'background-selected' : '';
            return <Row key={index} className={`data-row field-row ml-0 ${background} ${marked}`}>
                <Col sm={1}>
                    <Input type="checkbox"
                           className="massive-checkbox form-control-sm"
                           checked={selected}
                           value={transference.codigo}
                           disabled={this.props.automaticAssignment}
                           onChange={(e) => this.transferenceSelected(e, transference)}
                    />
                </Col>
                <Col sm={2}>
                    <span className="bold">{transference.patente}</span>
                </Col>
                <Col sm={2}>
                    <span className="transference-status transference-status--1">{estado}</span>
                </Col>

                <Col sm={5}>
                    <Row>
                        <Col sm={6}>
                            <Input
                                type="number"
                                name={`transference-repertory-${transference.codigo}`}
                                className="form-control-sm"
                                disabled={!selected || this.props.automaticAssignment}
                                value={this.state.transferencesMapAssign[transference.codigo] || ''}
                                onChange={(e) => this.handlerRepertoryChange(transference.codigo, e)}
                            />
                        </Col>
                        {this.state.assignInProgress && <Col sm={2}>
                            {resultado}
                        </Col>}
                        {index === 0 &&
                        <Col
                            sm={4}>
                            <Button
                                className="btn btn-sm  btn-primary-darker"
                                onClick={this.handlerAutocompleteRepertories}
                                disabled={this.props.automaticAssignment}
                            >
                                Completar
                            </Button>
                        </Col>}
                    </Row>
                </Col>
            </Row>
        })
    }


    render() {
        let message = null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'}
                             isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
        }
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/transferencias",
                state: {tab: 'forSign'}
            }} />
        }
        const transferencesMap = this.renderTransferencesList();
        console.log(this.props.automaticAssignment);
        return (
            <div>
                <div> Asigne el <strong>primer número de repertorio</strong> para completar automáticamente los
                    siguientes de manera consecutiva.
                </div>
                {message}
                <div className="bold">Cantidad de contratos: {this.state.recordCount}</div>
                <Form className="massive-view assign-massive-repertory-form">
                    <Row className="field-headers field-row ml-0">
                        <Col sm={1}>
                            <div className="massive-checkbox">
                                <Input type="checkbox"
                                       className="form-control-sm"
                                       checked={this.state.transferencesList.length === Object.keys(this.state.transferencesSelected).length}
                                       onChange={this.handlerSelectedAll}
                                       disabled={this.props.automaticAssignment}
                                />
                                {this.state.selectedRecordCount > 0 ?
                                    <div className="indicator">
                                        <span className="indicator-number">
                                            {this.state.selectedRecordCount}
                                        </span>
                                    </div>
                                    : ''}
                            </div>
                        </Col>
                        <Col sm={2}>
                            <span className="bold">Patente</span>
                        </Col>
                        <Col sm={2}>
                            <span className="bold">Estado</span>
                        </Col>
                        <Col sm={5}>
                            <span className="bold">Repertorio</span>
                        </Col>
                    </Row>
                    {transferencesMap}
                    <Row className="botton-row">
                        <Col sm={3}>
                            <Button
                                className="btn btn-sm btn-primary form-check-input mt-0"
                                onClick={this.handlerClearSelected}
                                disabled={this.state.assignInProgress || this.props.automaticAssignment}
                            >
                                Borrar seleccionados</Button>
                        </Col>
                        <Col sm={9} className="text-right">
                            {
                                this.state.assignInProgress ?
                                    <span className="btn btn-sm btn-primary mr-2 disabled">Cancelar</span>
                                    :
                                    <Link
                                        className="btn btn-sm btn-primary mr-2"
                                        to={'/transferencias'}
                                    >Cancelar</Link>
                            }
                            {this.props.automaticAssignment?
                                <Button
                                    className="btn btn-sm  btn-primary-darker"
                                    onClick={this.AssignExternalRepertories}
                                    disabled={this.state.externalProcessActive}
                                >Aplicar</Button>
                            :
                                <Button
                                    className="btn btn-sm  btn-primary-darker"
                                    onClick={this.handlerAssignRepertory}
                                    disabled={this.state.assignInProgress}
                                >Aplicar</Button>
                            }
                            {this.state.assignInProgress && <span className="text-info ml-2 font-weight-bold">Espere mientras se asignan los repertorios</span>}
                        </Col>
                    </Row>
                </Form>
                <AssignMassiveRepertoryModal open={this.state.externalProcessActive}
                                             title={'Proceso externo de solicitud de repertorio'}
                                             process={this.state.step}
                                             okButton ={this.modalProcessButton()}
                                             menu = {this.modalMenuButtons()}
                                             />
            </div>);
    }
}

export default withRouter(AssignMassiveRepertoryForm);
