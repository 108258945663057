import {endpoints} from '../config';
import axios from 'axios'
import FileHelper from '../helpers/FileHelper';
import { app } from '../config'
axios.defaults.withCredentials = true


const API_BASE_URL = endpoints.backendApi.url;

const defaultFetchParams = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    credentials: 'include'
};

class BackendApiService {

    static async processApiResponseSingle(apiResponse, withFullResponse = false) {
        if (![200, 201].includes(apiResponse.status)) {
            if(withFullResponse){
                try{
                    const fullResponse = await apiResponse.json();
                    return {...fullResponse, success:false}
                }catch (e){
                }
            }
            return false;
        }
        const fullResponse = await apiResponse.json();
        return fullResponse.data;
    }

    static async processApiResponseList(apiResponse) {
        if (apiResponse.status !== 200) {
            return {
                data: [],
                extra: {
                    recordsTotal: 0,
                    recordsFiltered: 0
                }
            };
        }
        return await apiResponse.json();
    }

    static objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
            }
        }
        return keyValuePairs.join('&');
    }

    static async doLogout(domain) {
        const apiResponse = await fetch(`${API_BASE_URL}logout?domain=${domain}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getSessionData() {
        const apiResponse = await fetch(`${API_BASE_URL}session`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getTransferenceList(params) {
        const apiResponse = await fetch(`${API_BASE_URL}transferences?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async getTransferenceHistoryList(params) {
        const apiResponse = await fetch(`${API_BASE_URL}transferences/history?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async getFirmasDocumentosList(params) {
        const apiResponse = await fetch(`${API_BASE_URL}firmas-documentos?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async getTransferenceData(code) {
        const apiResponse = await fetch(`${API_BASE_URL}transferences/${code}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getTransferenceDataForNotaries(code) {
        return new Promise(async (resolve, reject) => {
          setTimeout(async () => {
            try {
              const apiResponse = await fetch(`${API_BASE_URL}transferences/notary-transferences/${code}`, defaultFetchParams);
              const result = await this.processApiResponseSingle(apiResponse);
              resolve(result);
            } catch (error) {
              reject(error);
            }
          }, app.msToRequestTransference);
        });
      }

    static async getFirmasDocumentosData(id, withConfig = false) {
        const apiResponse = await fetch(`${API_BASE_URL}firmas-documentos/${id}?withConfig=${withConfig}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getPreAdmissionList(params) {
        const apiResponse = await fetch(`${API_BASE_URL}pre-admissions?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async getPreAdmissionData(id) {
        const apiResponse = await fetch(`${API_BASE_URL}pre-admissions/${id}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getCommunesListForSelect(params) {
        const apiResponse = await fetch(`${API_BASE_URL}communes?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async rejectTransference(code, data) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify(data);
        const apiResponse = await fetch(`${API_BASE_URL}transferences/${code}/reject`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async approveTransference(code) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        const apiResponse = await fetch(`${API_BASE_URL}transferences/${code}/approve`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async approveFirmasDocumentos(id) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        const apiResponse = await fetch(`${API_BASE_URL}firmas-documentos/${id}/approve`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async requestSignDataForTransference(code, isRepresentativeSignature = false) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({
            isRepresentativeSignature,
        });
        const apiResponse = await fetch(`${API_BASE_URL}sign/ticket/${code}`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }
    
    static async requestSignDataForDocument(signDocumentId) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        const apiResponse = await fetch(`${API_BASE_URL}sign-document/ticket/${signDocumentId}`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async requestNewSignOperation(documentDataList, urlOK, urlKO, isRepresentativeSignature = false) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({
            documentDataList,
            urlOK,
            urlKO,
            isRepresentativeSignature,
        });
        const apiResponse = await fetch(`${API_BASE_URL}sign/operation`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async requestNewSignDocumentOperation(documentDataList, urlOK, urlKO) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({
            documentDataList,
            urlOK,
            urlKO
        });
        const apiResponse = await fetch(`${API_BASE_URL}sign-document/operation`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getSignOperationStatus(id) {
        const apiResponse = await fetch(`${API_BASE_URL}sign/operation/${id}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getSignOperationDocumentStatus(id) {
        const apiResponse = await fetch(`${API_BASE_URL}sign-document/operation/${id}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async completeSignTransference(code, operationId, isRepresentativeSignature = false) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({
            isRepresentativeSignature,
        });
        const apiResponse = await fetch(`${API_BASE_URL}sign/complete/${operationId}/${code}`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async completeSignDocument(signDocumentId, operationId) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        const apiResponse = await fetch(`${API_BASE_URL}sign-document/complete/${operationId}/${signDocumentId}`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async destroySignOperation(operationId) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'delete';
        const apiResponse = await fetch(`${API_BASE_URL}sign/operation/${operationId}`, postData);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async assignMasiveRepertory(repertories, updatedStatusFunction = null) {
        let currenctExecuted    = [];
        let assignInProcess     = 0;
        let transferencesInProcess = [];
        const maxExecutedNumber = 2;
        let countOK             = 0;
        let countFail           = 0;
        let fails               = [];
        let results             = {};
        for (let i = 0; i < repertories.length; i++) {
            results[repertories[i].transferenceCode] = -1;
            if (assignInProcess < maxExecutedNumber) {
                transferencesInProcess.push(repertories[i].transferenceCode)
                currenctExecuted.push(
                    this.assignSimpleRepertory(repertories[i].transferenceCode, repertories[i].repertoryNumber)
                );
                assignInProcess++;
            }
            if (assignInProcess === maxExecutedNumber) {
                this.notifyStatusResult(results,updatedStatusFunction);
                const assignResult = await this.processMasiveAssignResponse(currenctExecuted, transferencesInProcess);

                countOK += assignResult.countOK;
                countFail += assignResult.countFail;
                fails = fails.concat(assignResult.fails);
                results = this.updateResultStatus(results,assignResult)
                currenctExecuted = [];
                transferencesInProcess = []
                assignInProcess = 0;
                this.notifyStatusResult(results,updatedStatusFunction);
            }
        }
        if (assignInProcess > 0) {
            this.notifyStatusResult(results,updatedStatusFunction);
            const assignResult = await this.processMasiveAssignResponse(currenctExecuted, transferencesInProcess);
            countOK += assignResult.countOK;
            countFail += assignResult.countFail;
            fails = fails.concat(assignResult.fails);
            results = this.updateResultStatus(results,assignResult)
            this.notifyStatusResult(results,updatedStatusFunction);
        }
        return {
            countFail: countFail,
            countOK: countOK,
            fails: fails
        }
    }
    static notifyStatusResult(results, updatedStatusFunction){
        if (typeof updatedStatusFunction === "function") {
            updatedStatusFunction(results);
        }
    }
    static updateResultStatus(results, assignResult){
        for (let pos in results) {
            if (results.hasOwnProperty(pos) && results[pos] === -1) {
                if (assignResult.fails.includes(pos)) {
                    results[pos] = 0;
                } else {
                    results[pos] = 1;
                }
            }
        }
        return results;
    }

    static async assignSimpleRepertory(code, repertory) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({
            'repertories': [
                {
                    'transferenceCode': code,
                    'repertoryNumber': repertory
                }
            ]
        });
        try{
            const apiResponse = await fetch(`${API_BASE_URL}transferences/repertory/masive`, postData);
            return await this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }

    }

    static async processMasiveAssignResponse(currenctExecuted, transferencesInProcess) {
        let countOK = 0;
        let countFail = 0;
        let fails = [];
        const responseExecution = await Promise.all(currenctExecuted);
        responseExecution.forEach((assignMasiveResponse, index) => {
            if(assignMasiveResponse === false){
                countFail++;
                fails.push(transferencesInProcess[index])
            }else{
                countOK += assignMasiveResponse.countOK;
                countFail += assignMasiveResponse.countFail;
                fails = fails.concat(assignMasiveResponse.fails);
            }
        });
        return {
            countFail: countFail,
            countOK: countOK,
            fails: fails
        }
    }

    static async savePreAdmisionManual(data) {
        const especificParams = {
            method: 'POST',
            body: JSON.stringify(data),
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}pre-admissions`, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async registerPreAdmision(transferenceCode, withFullResponse = false) {
        const especificParams = {
            method: 'POST'
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}transferences/${transferenceCode}/pre-admission?auto-register=true`, requestParams);
        return await this.processApiResponseSingle(apiResponse,withFullResponse);
    }
    static async updatePreAdmision(id, data) {
        const especificParams = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}pre-admissions/${id}`, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async generatePDFToRegCivil(ids, type = 'join_pdf'){
        const especificParams = {
            method: 'POST',
            body: JSON.stringify({
                ids,
                type
            }),
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}transferences/generate-regcivil-pdf`, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async findSignNotaries() {
        const apiResponse = await fetch(`${API_BASE_URL}sign-notaries`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async findSignatureRepresentative() {
        const apiResponse = await fetch(`${API_BASE_URL}sign-representative`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async findNotaryWorkSummary() {
        const apiResponse = await fetch(`${API_BASE_URL}notaries/summary`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }
    static async getIdentityStatus(code) {
        const apiResponse = await fetch(`${API_BASE_URL}identity/${code}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }
    static async getIdentityProcess(params) {
        const apiResponse = await fetch(`${API_BASE_URL}identity?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async getIdentityMeet(code) {
        const apiResponse = await fetch(`${API_BASE_URL}identity/meet/${code}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async finishIdentityMeet(code) {
        const apiResponse = await fetch(`${API_BASE_URL}identity/meet/${code}/finish`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getIdentityNotaryMeetings(params) {
        const apiResponse = await fetch(`${API_BASE_URL}identity/notaries/meetings?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async rejectIdentity(code) {
        const requestParams = Object.assign({}, defaultFetchParams, {method: 'POST'});
        const apiResponse = await fetch(`${API_BASE_URL}identity/${code}/reject`, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async updateNotarySign(params) {
        const requestParams = Object.assign({}, defaultFetchParams, { method: 'POST', body: JSON.stringify(params) });
        const apiResponse = await fetch(`${API_BASE_URL}change-notary-sign`, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async setTagEnteredToCivilregister(params) {
        const requestParams = Object.assign({}, defaultFetchParams, {method: 'PUT', body: JSON.stringify(params)});
        const apiResponse = await fetch(`${API_BASE_URL}transferences/notary-tag`, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async uploadRequest(firstRepertory, lastRepertory, file){
        try{

            const s3FormData = new FormData();

            const presignedResponse = await axios.post(`${API_BASE_URL}presigned-request`);
            console.log(presignedResponse);
            const presignedResponseData = presignedResponse.data;
            if(presignedResponseData.status === 'OK'){
                const responseFields = presignedResponseData.data.fields;
                for(let key in responseFields){
                    s3FormData.append(key,responseFields[key]);
                }
                s3FormData.append('file', file);
                await axios.post(presignedResponseData.data.url, s3FormData);
                const uploadResponse = await axios.post(`${API_BASE_URL}upload-request`,{
                    'file_url': `${presignedResponseData.data.url}/${responseFields.key}` ,
                    'first_repertory':firstRepertory,
                    'last_repertory':lastRepertory,
                });
                if(uploadResponse.data.status === 'OK'){
                    return true;
                }else{
                    console.log(uploadResponse.data.message)
                }
            }else{
                console.log(presignedResponseData.message)
            }
        }catch (e) {
            console.log(e)
        }
        return false;
    }

    static async uploadUpdatedMandatePhysic(signId, subsidiaryId, code, file){
        try{

            const s3FormData = new FormData();

            const presignedResponse = await axios.post(`${API_BASE_URL}firmas-documentos/${signId}/pre-signed`, {
                subsidiaryId,
                code
            });
            const presignedResponseData = presignedResponse.data;
            if(presignedResponseData.status === 'OK'){
                const responseFields = presignedResponseData.data.fields;
                for(let key in responseFields){
                    s3FormData.append(key,responseFields[key]);
                }
                s3FormData.append('file', file);
                await axios.post(presignedResponseData.data.url, s3FormData);
                const uploadResponse = await axios.put(`${API_BASE_URL}firmas-documentos/${signId}`,{
                    'file_url': `${presignedResponseData.data.url}/${responseFields.key}`,
                    code
                });
                if(uploadResponse.data.status === 'OK'){
                    return true;
                }else{
                    console.log(uploadResponse.data.message)
                }
            }else{
                console.log(presignedResponseData.message)
            }
        }catch (e) {
            console.log(e)
        }
        return false;
    }

    /**
     * Pre admision for all transference received
     *
     * @param {*} transferenceCode Array with transferences codes
     * @param {*} withFullResponse Boolean for full or not response
     */
    static async registerPreAdmisionFull(withFullResponse = false) {

        const requestParams = Object.assign({}, defaultFetchParams,
            {
                method: 'POST',
            }
        );

        const apiResponse = await fetch(`${API_BASE_URL}pre-admissions/send-queue-full`, requestParams);
        console.log("apiResponse");
        console.log(apiResponse);
        return await this.processApiResponseSingle(apiResponse, withFullResponse);
    }

    static async uploadedRequests(params){
        const apiResponse = await fetch(`${API_BASE_URL}upload-request?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async rejectDocument(id, comment){
        const requestParams = Object.assign(
            {},
            defaultFetchParams,
            {
                method: 'POST',
                body: JSON.stringify({comment})}
            );
        const apiResponse = await fetch(
            `${API_BASE_URL}firmas-documentos/${id}/reject-sign-document`,
            requestParams
            );
        return await this.processApiResponseList(apiResponse);
    }

    static async getRejectionList(id = false){
        const apiResponse = await axios.get(`${API_BASE_URL}transferences/notary-rejection`);
        return apiResponse;
    }


    static async SendExternalRepertoriesRequest(transferIds) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({ transferIds});
        try{
            const apiResponse = await fetch(`${API_BASE_URL}transferences/request-extern-repertory`, postData);
            return this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }

    }

    static async GetExternalRepertoryRequest(transfers) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify({transfers});
        try{
             const apiResponse = await fetch(`${API_BASE_URL}transferences/check-extern-repertory`,postData);
             return this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }

    }

    static async getNotaryUsersList(){
        const apiResponse = await fetch(`${API_BASE_URL}notaries/users-list`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async CAVRequest(plate, pretransferCode) {
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify( {plate, pretransferCode} );
        try {
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/cav-request`, postData);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async GetCAVRequestStatus(ticket) {
        try{
             const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/cav-request/status/${ticket}`,defaultFetchParams);
             return this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }

    }

    static async getReport(code) {
        try{
             const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/report/${code}`,defaultFetchParams);
             return this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }

    }

    static async getFiscalValuation(plate) {
        try{
             const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/fiscal-valuation/${plate}`,defaultFetchParams);
             return this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }

    }

    static async getFiscalValuationAlt(params) {
        const {year, brand, model} = params;
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify( {year, brand, model} );
        try {
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/backup-fiscal-valuation`, postData);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false
        }
    }

    static async uploadFile(file, code, type){
        try {
            const base64File = await FileHelper.getBase64(file);
            const data = {
                code,
                mimeType: file.type,
                file: base64File,
                type: type || 'permiso-circulacion'
            }
            const uploadFile = await axios.post(`${API_BASE_URL}notary-contracts/upload`, data );
            return uploadFile.data.data;
        } catch (e) {
            return false;
        }
    }

    static async downloadFile(fileUrl){
        try {
            const apiResponse = await axios.post(`${API_BASE_URL}notary-contracts/download-file`, {fileUrl: fileUrl});
            return apiResponse;
        } catch (e) {
            return false;
        }
    }

    static async getPensionDebtor(ruts){
        const postData = Object.assign({}, defaultFetchParams);
        postData.method = 'post';
        postData.body = JSON.stringify( {personRuts: ruts} );
        try {
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/pension-debtors`, postData);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false
        }
    }

    static async createPretransfer(data){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify(data),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/create-pretransfer`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }
    static async getPretransfer(code) {
        const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/get-pretransfer/${code}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async updateNotaryTransfer(code, updatedData){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify({code, data: updatedData}),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/update-pretransfer`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async getPersonByRut(rut){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify({rut}),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/get-person`,requestParams);
            const response = await this.processApiResponseSingle(apiResponse);
            if(response){
                return {
                    name: response.nombre || '',
                    fatherLastName:response.apellido_paterno || '',
                    motherLastName:response.apellido_materno || '',
                    sex:response.sexo || '',
                    phone: `+${response.celular}` || '',
                    email:response.email || '',
                    nationality:response.nacionalidad || '',
                    maritalStatus: response.estado_civil || '',
                    occupation: response.profesion || '',
                    address: {
                        street: response.direccion || '',
                        number: response.direccion_calle_numero || '',
                        rest: response.direccion_resto || '',
                        regionId: response.region_idregion || '',
                        comunaId: response.comuna_idcomuna || ''
                    }
                }
            }
            return false;
        } catch (error) {
            console.log(error)
            return false
        }
    }

    static async getCommuneListByRegion(id) {
        if(id){
            const apiResponse = await fetch(`${API_BASE_URL}communes/${id}`, defaultFetchParams);
            return await this.processApiResponseSingle(apiResponse);
        }
        return [];
    }

    static async getRegionsList(){
        const apiResponse = await fetch(`${API_BASE_URL}regions`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async createTransference(code, data){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify(data),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/create-transfer/${code}`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async cancelTransference(code, comment = ''){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify({comment}),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}transferences/${code}/cancel`, requestParams);
            return apiResponse;
        } catch (e) {
            return false;
        }
    }

    static async getPretransferByTransfer(transferId) {
        const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/get-pretransfer-by-transfer/${transferId}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async updateTransfer(code, data){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify(data),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/update-transference/${code}`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async removeTransferBuyer(transferCode, userId){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify({code: transferCode, userId}),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/remove-buyers`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async addTransferBuyer(transferCode, data){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify({code: transferCode, data}),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/modify-buyers`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async startSignContrat(data){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify(data),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/start-sign`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async checkSign(code){
        try {

            const response  = await fetch(`${API_BASE_URL}notary-contracts/check-sign/${code}`, defaultFetchParams);
            return await this.processApiResponseSingle(response);
        } catch (e) {
            return false;
        }
    }

    static async removeSignature(transferCode, data){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify(data),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/delete-signature/${transferCode}`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async getCAVURL(ticket) {
        try{
             const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/url-cav/${ticket}`,defaultFetchParams);
             return this.processApiResponseSingle(apiResponse);
        }catch (e){
            return false;
        }
    }

    static async getPretransferenceList(params) {
        const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/pretransfer-list?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async approveTransfer(code){
        try {
            const especificParams = {
                method: 'POST',
                body: JSON.stringify({ code }),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/approve-transference`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async getAllCountries() {
        const apiResponse = await fetch(`${API_BASE_URL}countries`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async archivePretransfer(code){
        try {
            const especificParams = {
                method: 'POST',
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/archive-pretransference/${code}`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async getPeople(ruts){

        const especificParams = {
            method: 'POST',
            body: JSON.stringify({ruts: ruts}),
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/get-people`, requestParams);
        const people =  await this.processApiResponseSingle(apiResponse);

        if (people && Array.isArray(people)) {
            const peopleArray = people.map(person => {
                return {
                    identityCode: person.rut,
                    name: person.nombre || '',
                    secondName: person.segundo_nombre || '',
                    fullName: person.nombre_completo || '',
                    fatherLastName: person.apellido_paterno || '',
                    motherLastName: person.apellido_materno || '',
                    sex: person.sexo || '',
                    phone: `+${person.celular}` || '',
                    email: person.email || '',
                    nationality: person.nacionalidad || '',
                    maritalStatus: person.estado_civil || '',
                    occupation: person.profesion || '',
                    address: {
                        street: person.direccion || '',
                        number: person.direccion_calle_numero || '',
                        rest: person.direccion_resto || '',
                        regionId: person.region_idregion || '',
                        comunaId: person.comuna_idcomuna || ''
                    }
                };
            });

            return peopleArray;
        }
        return [];
    }

    static async createOrUpdatePerson(data){
        try {

            const bodyData = {
                rut: data.identityCode,
                ...(data.name && { nombre: data.name }),
                ...(data.fatherLastName && { apellido_paterno: data.fatherLastName }),
                ...(data.motherLastName && { apellido_materno: data.motherLastName }),
                ...(data.segundo_nombre && { segundo_nombre: data.segundo_nombre }),
                ...(data.fullName && { nombre_completo: data.fullName }),
                ...(data.email && { email: data.email }),
                ...(data.phone && { celular: data.phone }),
                ...(data.sex && { sexo: data.sex }),
                ...(data.address?.street && { direccion: data.address?.street }),
                ...(data.address?.number && { direccion_calle_numero: data.address?.number }),
                ...(data.addres?.rest && { direccion_resto: data.addres?.rest }),
                ...(data.address?.regionId && { region_idregion: data.address?.regionId }),
                ...(data.address?.comunaId && { comuna_idcomuna: data.address?.comunaId }),
                ...(data.nationality && { nacionalidad: data.nationality }),
                ...(data.estado_civil && { estado_civil: data.estado_civil }),
                ...(data.profesion && { profesion: data.profesion }),
            }

            const especificParams = {
                method: 'POST',
                body: JSON.stringify(bodyData),
            };
            const requestParams = Object.assign({}, defaultFetchParams, especificParams);
            const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/modify-person`, requestParams);
            return await this.processApiResponseSingle(apiResponse);
        } catch (e) {
            return false;
        }
    }

    static async getSocietyByUserAndSubsidiary(userId, subsidiaryId) {
        const apiResponse = await fetch(`${API_BASE_URL}notary-contracts/get-society-by-user-and-subsidiary/${userId}/${subsidiaryId}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async updateNotaryTelepresenceAvailability(data, withFullResponse = false) {
        const especificParams = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}notary-telepresence/update-notary-telepresence-availability`, requestParams);
        return this.processApiResponseSingle(apiResponse, withFullResponse);
    }

    static async getNotaryTelepresenceAvailability(params = {}) {
        const apiResponse = await fetch(`${API_BASE_URL}notary-telepresence/get-notary-telepresence-availability?${this.objToQueryString(params)}`, defaultFetchParams);
        return this.processApiResponseList(apiResponse);
    }

    static async hasAvailabilityofAssignmentTime(params = {}) {
        const apiResponse = await fetch(`${API_BASE_URL}notary-telepresence/has-availability-of-assignment-time?${this.objToQueryString(params)}`, defaultFetchParams);
        return this.processApiResponseList(apiResponse);
    }

    static async updateStatusNotaryAssignTelepresence(data = {}, withFullResponse = false) {
        const especificParams = {
            method: 'PUT',
            body: JSON.stringify(data),
        };
        const requestParams = Object.assign({}, defaultFetchParams, especificParams);
        const apiResponse = await fetch(`${API_BASE_URL}notary-assign-telepresence/update`, requestParams);
        return this.processApiResponseSingle(apiResponse, withFullResponse);
    }
}

export default BackendApiService;
