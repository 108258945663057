import React, {Component} from 'react';
import AssignMassiveRepertoryForm from "../../components/assign-massive-repertory-form/AssignMassiveRepertoryForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from "../../services/AuthService";

class AssignMassiveRepertory extends Component {
    constructor(props){
        super(props);
        this.state = {
            automaticAssignment: false
        };
    }

    componentDidMount(){
        this.setAutomaticAssignment();
    }

    setAutomaticAssignment(){
        AuthService.getAuthData().then(authData => {
            if ((authData) && (authData.notary) && (!authData.notary.requiredManualAssigment)) {
                this.setState({ 
                    automaticAssignment: !authData.notary.requiredManualAssigment,
                })
            }
        })
    };

    render() {
        return (
        <div>
            <FontAwesomeIcon icon="list-alt" alt="Icon" className="icon-title" />
            <h2 className="text-title">Asignar número de repertorio masivo</h2>
            <div className="main-wrapper-page">
                <AssignMassiveRepertoryForm
                    automaticAssignment={this.state.automaticAssignment}
                />
            </div>
        </div>
        );
    }
}

export default AssignMassiveRepertory;
