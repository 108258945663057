import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import BackendApiService from "../../services/BackendApiService";

class NotaryContractsFilters extends Component {

    constructor (props){
        super(props);

        this.state = {
            filters: {
                PLATE: '',
                TRANSFERENCE_DATE_FROM:'',
                TRANSFERENCE_DATE_TO:'',
                TRANSFERENCE_DATE_RANGE: '',
                REPERTORY_DATE_FROM: '',
                REPERTORY_DATE_TO: '',
                REPERTORY_DATE_RANGE: '',
                CREATED_BY:''
            },
            usersList: []
        };
        this.filterComponent = React.createRef();
        this.transferenceDateUpdate = this.transferenceDateUpdate.bind(this);
        this.repertoryDateUpdate = this.repertoryDateUpdate.bind(this);
        this.plateUpdate = this.plateUpdate.bind(this);
        this.creatorUpdate = this.creatorUpdate.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.loadUserList = this.loadUserList.bind(this);
        this.loadUserList();
    }

    async loadUserList(){
        const usersList = await BackendApiService.getNotaryUsersList();
        this.setState({usersList});
    }


    DateRangeComponent(label,id, onChangeFunction){
        return (
                <DateRangePicker
                onApply={onChangeFunction}
                ranges={
                    {
                        'Hoy': [moment(), moment()],
                        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                        'Última semana': [moment().subtract(6, 'days'), moment()],
                        'Últimas 2 semanas': [moment().subtract(13, 'days'), moment()],
                        'Este mes': [moment().startOf('month'), moment().endOf('month')],
                        'Mes anterior': [moment().subtract(1, 'month').startOf('month'),
                            moment().subtract(1, 'month').endOf('month')]
                    }
                }
                locale={{
                        format: 'DD/MM/YYYY',
                        applyLabel: 'Aplicar',
                        cancelLabel: 'Limpiar',
                        fromLabel: 'Desde',
                        toLabel: 'Hasta',
                        customRangeLabel: 'Seleccionar rango',
                        daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre',
                            'Diciembre'],
                        firstDay: 1
                }}
                containerStyles={{display: 'block'}}
            >
                <Input type="text" placeholder={label} id={id} className="form-control-sm"
                    onChange={this.handleFilterChange}
                    value={this.state.filters[id]}/>
            </DateRangePicker>
        )
    }

    handleFilterChange(event) {
        console.log(event);
        let filters = {};
        filters[event.target.id] = event.target.value;
        filters = Object.assign(this.state.filters, filters);
        this.setState({filters})
    }

    transferenceDateUpdate(_, picker){
        const startDate = picker.startDate.format('MM-DD-YYYY');
        const endDate = picker.endDate.format('MM-DD-YYYY');
        const range = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                TRANSFERENCE_DATE_FROM: startDate,
                TRANSFERENCE_DATE_TO: endDate,
                TRANSFERENCE_DATE_RANGE: range
            }
        }))
    }

    repertoryDateUpdate(_, picker){
        const startDate = picker.startDate.format('MM-DD-YYYY');
        const endDate = picker.endDate.format('MM-DD-YYYY');
        const range = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                REPERTORY_DATE_FROM: startDate,
                REPERTORY_DATE_TO: endDate,
                REPERTORY_DATE_RANGE: range
            }
        }))
    }

    plateUpdate(event){
        const value = event?.target?.value || '';
        this.setState( prevState => ({
            filters:{
                ...prevState.filters,
                PLATE: value
            }
        }))
    }

    creatorUpdate(event){
        const value = event?.target?.value || '';
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                CREATED_BY: value
            }
        }))
    }

    setFilters() {
        const filters = {};
        for(const filter in this.state.filters) {
            if (this.state.filters.hasOwnProperty(filter) && this.state.filters[filter]) {
                filters[filter] = this.state.filters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters(){
        const filters = {
            PLATE: '',
            TRANSFERENCE_DATE_FROM:'',
            TRANSFERENCE_DATE_TO:'',
            TRANSFERENCE_DATE_RANGE: '',
            REPERTORY_DATE_FROM: '',
            REPERTORY_DATE_TO: '',
            REPERTORY_DATE_RANGE: '',
            CREATED_BY:''
        };
        this.setState({filters});
    }

    render(){
        const userList = this.state.usersList;
        return (
            <Form className={'px-2'}>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Input
                                className="form-control-sm"
                                placeholder="Patente"
                                type="text"
                                id="PLATE"
                                value={this.state.filters.PLATE}
                                onChange={this.plateUpdate}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            { this.DateRangeComponent('Creación de contrato', 'TRANSFERENCE_DATE_RANGE', this.transferenceDateUpdate)}
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            { this.DateRangeComponent('Repertorio', 'REPERTORY_DATE_RANGE', this.repertoryDateUpdate)}
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input 
                                type="select"
                                id="CREATED_BY"
                                value={this.state.filters.CREATED_BY}
                                onChange={this.creatorUpdate}
                                className="form-control-sm"
                            >
                            <option value=''>Creador</option>
                            {Object.keys(userList).map( (_, key) => {
                                const nombre = `${userList[key].nombre} ${userList[key].apellido_paterno || ''} ${userList[key].apellido_materno || ''}`;
                                return(
                                    <option key={key} value={userList[key].idusuario}>{nombre}</option>
                                )
                            })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <Button
                            size="sm"
                            className="btn-primary-darker"
                            onClick={this.setFilters}
                        >
                            aplicar
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default NotaryContractsFilters