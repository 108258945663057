import React from 'react';
import {makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ReactPlayer from "react-player";
import Placeholder from "./Placeholder";

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const VideoViewer = ({ title, url }) => {
  const classes = useStyles();

  if (!url) {
    return <Placeholder label={`Sin ${title}`} />
  }

  return (
    <Card elevation={5} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <ReactPlayer url={url} controls height={300} />
      </CardContent>
    </Card>
  )
}

export default VideoViewer;
