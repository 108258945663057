import { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, FormGroup, Label, Input, Button, ModalFooter, FormFeedback } from 'reactstrap'
import { app } from '../../../../config';
import rutHelper from '../../../../helpers/rutHelper';
import BackendApiService from '../../../../services/BackendApiService';
import { CircularProgress } from '@mui/material';
import PeopleHelper from '../../../../helpers/PeopleHelper';

const ModalBuyer = (props) => {

  const { isOpen, targetBuyer, buyerType, isContractUpdate } = props.data;
  const { close, create, update } = props.methods;
  const { loading } = props.states;

  const [inputRut, setInputRut] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputSecondLN, setInputSecondLN] = useState('');
  const [isAnUpdate, setIsAnUpdate] = useState(false);
  const [invalidRut, setInvalidRut] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidSecondLN, setInvalidSecondLN] = useState(false);

  const [buyer, setBuyer] = useState({});
  const [searching, setSearching] = useState(false);

  useEffect(() => {

    if(Object.keys(targetBuyer).length !== 0 && isOpen) {
      setIsAnUpdate(true);
      setInputRut(targetBuyer.identityCode);
      setInputName(targetBuyer.name);
      setInputLastName(targetBuyer.fatherLastName);
      setInputSecondLN(targetBuyer.motherLastName);
    } else{
      setIsAnUpdate(false);
    }
  }, [targetBuyer, isOpen]);

  

  const cleanInputs = () => {
    setInputRut('');
    setInputName('');
    setInputLastName('');
    setInputSecondLN('');
  };

  const cleanInvalidInputs = () => {
    setInvalidRut(false);
    setInvalidName(false);
    setInvalidLastName(false);
    setInvalidSecondLN(false);
  }

  const inputValidations = () => {
    cleanInvalidInputs();
    const validatedRut = rutHelper.validateRut(inputRut);

    if(buyerType === app.buyerType.company){
      if (inputRut.length === 0 || !validatedRut || inputName.length === 0) {
        if (inputRut.length === 0 || !validatedRut) setInvalidRut(true);
        if (inputName.length === 0) setInvalidName(true);
        return false;
      }
    } else {
      if (inputRut.length === 0 || !validatedRut || inputName.length === 0 || inputLastName.length === 0 || inputSecondLN.length === 0) {
        if (inputRut.length === 0 || !validatedRut) setInvalidRut(true);
        if (inputName.length === 0) setInvalidName(true);
        if (inputLastName.length === 0) setInvalidLastName(true);
        if (inputSecondLN.length === 0) setInvalidSecondLN(true);
        return false;
      }
    }
  
    return true;
  };

  const addBuyer = async () => {
    let newBuyer = {
      ...{
      identityCode: inputRut,
      name: '',
      fatherLastName: '',
      motherLastName: '',
      nationality: '',
      maritalStatus: '',
      occupation: '',
      email: '',
      phone: '',
      sex: '',
      address: {
          regionId: '',
          comunaId: '',
          street: '',
          number: '',
          rest: '',
      },
    }, ...buyer};
  
    if (!inputValidations()) {
      return;
    }
  
    if (buyerType === app.buyerType.company) {
      newBuyer.fullName = inputName;
      newBuyer.name = inputName;
      newBuyer.isCompany = true;
      newBuyer.sex = 'E';
    } else {
      newBuyer.isCompany = false;
      newBuyer.fullName = `${inputName} ${inputLastName} ${inputSecondLN}`;
      newBuyer.name = inputName;
      newBuyer.fatherLastName = inputLastName;
      newBuyer.motherLastName = inputSecondLN;
    }
    
    if (isAnUpdate) {
      const filteredNewBuyerData = PeopleHelper.filterNoEmptyObjectData(newBuyer);
      filteredNewBuyerData.address && delete filteredNewBuyerData.address;

      const { index, ...restOfTargetBuyer } = targetBuyer;
      const concatBuyer = {...restOfTargetBuyer, ...filteredNewBuyerData};

      await update(concatBuyer, index);
    } else {
      await create(newBuyer);
    }
  
    onClose();
  };

  const onClose = () => {
    cleanInvalidInputs();
    cleanInputs();
    close();
  };

  const searchBuyer = async (rut) => {
    setSearching(true);
    const buyer = await BackendApiService.getPersonByRut(rut);
    if(buyer){
      setBuyer(buyer);
      setInputName(buyer.name);
      setInputLastName(buyer.fatherLastName);
      setInputSecondLN(buyer.motherLastName);
    }else{
      setBuyer({});
      setInputName('');
      setInputLastName('');
      setInputSecondLN('');
    }
    setSearching(false);
  }

  const displayFormPerson = () => {
    return (
      <Form>
        {
          buyerType === app.buyerType.buyFor &&
            <div className="d-flex">
              <p className="p-2 text-secondary bold text-14">Información del tercero (para)</p>
              <hr className="flex-grow-1" />
            </div>
        }
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="inputRut">
                { buyerType === app.buyerType.buyFor && 'Rut del tercero (PARA)' }
                { buyerType === app.buyerType.naturalPerson && 'Rut del comprador' }
              </Label>
              <Input type="text" name="rut" id="inputRut" maxLength="9" defaultValue={inputRut}
                onInput={(e) => setInputRut(e.target.value)} 
                invalid={invalidRut} 
                disabled={ isContractUpdate && isAnUpdate } 
                onBlur={(e) => searchBuyer(e.target.value)}
               />
              <FormFeedback valid={false}>Ingrese un rut válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={1}>
            <div className='d-flex flex-column justify-content-center h-100 mt-2'>
              <CircularProgress size={25} className={searching? '': 'd-none'} />
            </div>
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="inputName">
                { buyerType === app.buyerType.buyFor && 'Nombres del tercero (PARA)' }
                { buyerType === app.buyerType.naturalPerson && 'Nombres del comprador' }
              </Label>
              <Input type="text" name="firstName" id="inputName" defaultValue={inputName} onInput={(e) => setInputName(e.target.value)} invalid={invalidName} />
              <FormFeedback valid={false}>Ingrese un nombre válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="inputLastName">
                { buyerType === app.buyerType.buyFor && 'Apellido paterno del tercero (PARA)' }
                { buyerType === app.buyerType.naturalPerson && 'Apellido paterno del comprador' }
              </Label>
              <Input type="text" name="lastName" id="inputLastName" defaultValue={inputLastName} onInput={(e) => setInputLastName(e.target.value)} invalid={invalidLastName} />
              <FormFeedback valid={false}>Ingrese un apellido válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="inputSecondLN">
                { buyerType === app.buyerType.buyFor && 'Apellido materno del tercero (PARA)' }
                { buyerType === app.buyerType.naturalPerson && 'Apellido materno del comprador' }
              </Label>
              <Input type="text" name="secondLastName" id="inputSecondLN" defaultValue={inputSecondLN} onInput={(e) => setInputSecondLN(e.target.value)} invalid={invalidSecondLN} />
              <FormFeedback valid={false}>Ingrese un apellido válido</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };

  const displayFormCompany = () => {
    return (
      <Form>
        <Row form>
          <Col md={4}>
            <FormGroup>
            <Label for="inputRut">RUT empresa</Label>
              <Input type="text" name="rut" id="inputRut" maxLength="9" defaultValue={inputRut}
               onInput={(e) => setInputRut(e.target.value)}
               onBlur={(e) => searchBuyer(e.target.value)}
               invalid={invalidRut} 
               disabled={ isContractUpdate && isAnUpdate } />
              <FormFeedback valid={false}>Ingrese un rut válido</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={1}>
            <div className='d-flex flex-column justify-content-center h-100 mt-2'>
              <CircularProgress size={25} className={searching? '': 'd-none'} />
            </div>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="inputName">Razón social empresa</Label>
              <Input type="text" name="firstName" id="inputName" defaultValue={inputName} onInput={(e) => setInputName(e.target.value)} invalid={invalidName}/>
              <FormFeedback valid={false}>Ingrese razón social válida</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    )
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size={'lg'} centered>
      <div className="modal-header bg-primary-darker text-white">
        <h3 className="modal-title">
          { buyerType === app.buyerType.naturalPerson && 'Información del comprador' }
          { buyerType === app.buyerType.company && 'Información de la empresa' }
          { buyerType === app.buyerType.buyFor && (isAnUpdate? 'Modificar tercero (para)' : 'Agregar tercero (para)') }
        </h3>
        <button type="button" className="close white-circle-close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        { (buyerType === app.buyerType.naturalPerson || buyerType === app.buyerType.buyFor) && displayFormPerson() }
        { buyerType === app.buyerType.company && displayFormCompany() }
      </div>
      <ModalFooter className="justify-content-center">
        <Button color="outline-primary-dark bold" onClick={onClose}>
          CANCELAR
        </Button>
        <Button color="primary-dark bold" onClick={addBuyer}>
        {loading.loading? [
                    <span key="span-k-1" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key="span-k-2" className='ml-2'> Cargando...</span>
                  ] :
                    <span>{isAnUpdate? 'ACTUALIZAR' : 'AGREGAR'}</span>
                  }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalBuyer