import { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, FormText, InputGroup, InputGroupAddon} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress} from "@material-ui/core"
import BackendApiService from '../../../services/BackendApiService'
import FileHelper from '../../../helpers/FileHelper'
import moment from "moment";

const CirculationPermission = ({
    data,
    updateData,
    updatePretransfer
  }) => {
  
  const code = data.code;
  const [loadingFile, setLoadingFile] = useState(false);
  const [fileName, setFileName] = useState('');
  const [communes, setCommunes] = useState([]);
  const [expiration, setExpiration] = useState({
    year: '',
    day: ''
  });
  const [yearList, setYearList] = useState([]);
  const [invalidFile, setInvalidFile] = useState(false);

  useEffect(() => {
    if(communes.length === 0){
      getCommuneList();
    }
  }, [communes])

  useEffect(() => {
    if(data.document.url){
      const name = FileHelper.getFileNameFromUrl(data.document.url);
      setFileName(name);
    }
    let date = data.expirationDate;
    date = date.split('-');
    const newExpiration = {
        year: date[0] || '',
        day: date[1] ? `-${date[1]}-${date[2]}` : '',
    }
    if(!["-03-31", "-08-31"].includes(newExpiration.day)) newExpiration.day = '';
    setExpiration(newExpiration);
  }, [data])

  useEffect(()=>{
    const startYear = moment().add(1, 'y').year();
    const years = [];
    for (let year = startYear; year >= 1900; year--) {
      years.push(year);
    }
    setYearList(years);
  },[])

  const getCommuneList = async () => {
    const data = await BackendApiService.getCommunesListForSelect();
    setCommunes(data);
  }

  const uploadFile = async (file) => {
    try {
      setLoadingFile(true);
      const url = await BackendApiService.uploadFile(file, code)
      const newForm = {
        document:{
          typeId: 5,
          url: '',
          name: ''
        }
      }
      newForm.document.url = url || '';
      newForm.document.name = FileHelper.getFileNameFromUrl(url) || '';
      setLoadingFile(false);
      updateData(newForm);  
      updatePretransfer();
    } catch (error) {
      console.error("upload file error: ", error);
    }
  }

  const onChangeFile = async (event) => {
    const files = event.target.files || [];
    const file = files[0] || null;
    if(file?.type && FileHelper.allowedExtensions(file.type)){
      setInvalidFile(false);
      const fileName = file.name || '';
      await uploadFile(file);
      setFileName(fileName);
    } else {
      setInvalidFile(true)
    }
  }

  const onChangeForm = (event) => {
    const indx = event.target.id;
    const value = event.target.value;
    const newForm = data;
    newForm[indx] = value;
    updateData(newForm);
  }

  const updateExpiration = (el) => {
    const id = el.id;
    const newExpiration = expiration;
    newExpiration[id] = el.value;
    setExpiration(newExpiration);
    const date = `${newExpiration.year}${newExpiration.day}`;
    onChangeForm({target: {
      id: 'expirationDate',
      value: date
    }})
}
 

  return (
    <div className="mb-5">
      <div className=' d-flex align-items-center title-box-step-validation px-2 mb-3'>
        <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
          <span className='font-weight-bold my-1'>1</span>
        </div>
        <span className='h2 dark-color font-weight-bolder ml-2 mb-0'>Información del permiso de circulación</span>
      </div>
      <Row>
        <FormGroup className="col-md-3">
          <Label>Ingresa fecha expiración del permiso* </Label>
          <InputGroup>
              <Input type="select" id="day" className="h-auto" value={expiration.day} onChange={ (e)=> updateExpiration(e.target)} required>
                  <option value="" disabled>Mes expiración</option>
                  <option value="-03-31">31 de Marzo</option>
                  <option value="-08-31">31 de Agosto</option>
              </Input>
              <InputGroupAddon addonType="append" className="w-50">
                  <Input type="select" id="year" placeholder="Año expiración" value={expiration.year}  onChange={ (e)=> updateExpiration(e.target)} required>
                  <option value="" disabled >Año expiración</option>
                  {yearList.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                  </Input>
              </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <FormGroup className="col-md-3 rounded">
          <Label>Ingresa comuna del permiso* </Label>
          <Input 
            type="select"
            name="select"
            id="comunaId"
            className="form-control"
            onChange={(e) => onChangeForm(e)}
            value={data.comunaId}
            // defaultValue=''
          >
            <option value='' disabled> seleccione una comuna </option>
            { Object.keys(communes).map((indx) =>{
                const commune = communes[indx];
                return(
                  <option 
                    key={`option-${commune.idcomuna}`}
                    value={commune.idcomuna}
                  > {commune.nombre} </option>
                )
            })}
          </Input>
        </FormGroup>
        <div className="col-md-6">
            <Row className="d-flex align-items-center">
              <Col>
                <Label className={`btn ${invalidFile ? 'btn-outline-danger' : 'btn-outline-primary-dark'} mt-4`}>
                  <Input 
                    type="file" 
                    onChange={(e) => onChangeFile(e)}
                    accept=".png, .jpg, .pdf"
                  />
                  <FontAwesomeIcon icon="upload" className='mr-3 fa-sm' />Subir imagen permiso de circulación 
                </Label>
                <FormText className={invalidFile ? 'error-color' : null}>{invalidFile ? 'Seleccione uno de los formatos permitidos: JPG, PNG, PDF' : '(Opcional) Formatos permitidos: JPG, PNG, PDF' }</FormText>
              </Col>
              <Col>
                {loadingFile && 
                  <div className='text-center'>
                    <CircularProgress size={30} className='text-primary-dark' />
                  </div>
                }
                {fileName && !loadingFile ?
                  <div className='h2 p-3'>
                    <FontAwesomeIcon icon="file-alt" className='mr-3 fa-lg' />
                    <span>{fileName}</span>
                  </div> : null
                }
              </Col>
            </Row>
        </div>
      </Row>
    </div>
  )
}

export default CirculationPermission