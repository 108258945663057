import { useEffect, useState } from "react";
import { Row, Col, FormGroup, Label, Input, UncontrolledTooltip, CustomInput, FormText, InputGroup, InputGroupAddon, InputGroupText, FormFeedback } from "reactstrap";
import SubtitleDivider from "../subtitle-divider/SubtitleDivider";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileHelper from "../../helpers/FileHelper";
import { CircularProgress } from "@material-ui/core";
import { merge } from "lodash";
import moment from "moment";
import { NumericFormat } from 'react-number-format'
import BackendApiService from "../../services/BackendApiService";
import AmmountHelper from "../../helpers/AmmountHelper";

const CIRCULATION_PERMISSION_ID = 5;

const NotaryContractVehicleForm = ({car, plate, payment, communes, uploadFile, updateData, updatePay}) => {
    const docTypeId = CIRCULATION_PERMISSION_ID;
    const [fileLoading, setFileLoading] = useState(false);
    const [tempFile, setTempFile] = useState(' ');
    const [valuations, setValuations] = useState([]);
    const [expiration, setExpiration] = useState({});
    const [loading, setLoading] = useState(true);
    const [cashPayment, setCashPayment] = useState({
        typeId: 1,
        ammount: 0,
        checked: false
    })
    const [ bankDocument, setBankDocument] = useState({
        typeId: 3,
        ammount: 0,
        checked: false
    })
    const [ creditPayment, setCreditPayment] = useState({
        typeId: 11,
        ammount: 0,
        checked: false
    })
    const [ transferencePayment, setTransferencePayment] = useState({
        typeId: 8,
        ammount: 0,
        checked: false
    })
    const [invalidFile, setInvalidFile] = useState(false);
    const [yearList, setYearList] = useState([]);
    const [paymentSum, setPaymentSum] = useState(0);
    const [isValidAmmount, setIsValidAmmount] = useState(false);


    useEffect(()=>{

        const startYear = moment().add(1, 'y').year();
        const years = [];
        for (let year = startYear; year >= 1900; year--) {
            years.push(year);
        }
        setYearList(years);

        const setCarData = () => {
            const newCar  = {
                SIICode: '',
                brand: '',
                circulationPermission: {
                    comunaId: '',
                    expirationDate: '',
                    document: {
                        name: '',
                        typeId: docTypeId,
                        url: ''
                    }
                },
                color: '',
                fuel: '',
                model: '',
                chassisNumber: '',
                motorNumber: '',
                price: '',
                taxValue: '',
                valuationPrice: '',
                version: '',
                year: '',
                paymentType: '',
                mileage: 10
            }
            updateData(merge(newCar, car));
        }

        const setPayments = () => {
            if(payment && payment.length > 0){
                payment.forEach(el => {
                    switch (el.typeId) {
                        case 1:
                            setCashPayment(el)
                            break;
                        case 3:
                            setBankDocument(el)
                            break;
                        case 6:
                            setCreditPayment(el)
                            break;
                        case 8:
                            setTransferencePayment(el)
                            break;
                        default:
                            break;
                    }
                })
            }
        }

        const getValuationData = async() => {
            const data = await BackendApiService.getFiscalValuation(plate);
            if(data.status === 'OK'){
                setValuations(data.data)
            }
        }

        const expirationDate = () => {
            let date = car.circulationPermission.expirationDate;
            date = date.split('-');
            const newExpiration = {
                year: date[0] || '',
                day: date[1] ? `-${date[1]}-${date[2]}` : '',
            }
            if(!["-03-31", "-08-31"].includes(newExpiration.day)) newExpiration.day = '';
            setExpiration(newExpiration);
            setLoading(false);
        }
        setCarData();
        setPayments();
        getValuationData();
        expirationDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        updateTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [car.price, car.valuationPrice]);

    useEffect(()=> {
        if(car.paymentType === "otro"){
            const accumPayment = payment.reduce((total, item) => total + item.ammount, 0);
            setPaymentSum(accumPayment);
            if(accumPayment === AmmountHelper.toNumber(car.price)){
                setIsValidAmmount(true);
            } else {
                setIsValidAmmount(false);
            }
        };

    }, [car.paymentType, car.price, payment]);

    const updateForm = (el) =>{
        const newCar = car;
        let index = el.id.split('-')[0];
        if(index.substring(0,21) === "circulationPermission"){
            const subIndex = index.split('.')[1];
            newCar.circulationPermission[subIndex] = el.value;
        }else{
            newCar[index] = el.value;
        }

        if(index === "paymentType" && el.value !== "otro"){
            const value = AmmountHelper.toNumber(car.price);
            const paymentArray = [{
                typeId: el.value,
                ammount: value
            }];
            updatePay(paymentArray);
        }
        if(index === "paymentType" && el.value === "otro"){
            updatePay([]);
        }
        if(index === "price" || index === "valuationPrice"){
            const tax = AmmountHelper.calculateTax(newCar.price, newCar.valuationPrice);
            newCar.taxValue = tax;
        }
        updateData(newCar);
    }

    const updateVersion = (el) => {
        const selectedVersion  = valuations.find( (item) => item.version === el.value);
        const newCar = car;
        newCar.version = el.value;
        newCar.SIICode = selectedVersion.codigoNuevo;
        newCar.valuationPrice = selectedVersion.valor_tasacion;
        updateData(newCar);
        updateTax();
    }
    
    const upload = async(el) => {
        const files = el.files;
        const file = files[0] || null;
        if(file?.type && FileHelper.allowedExtensions(file.type)){
            setInvalidFile(false);
            setTempFile(file?.name);
            setFileLoading(true);
            let url = '';
            if(file){
                url = await uploadFile(file, 'permiso-circulacion');
            }
            const newCar = car;
            newCar.circulationPermission.document.url = url;
            newCar.circulationPermission.document.name = FileHelper.getFileNameFromUrl(url);
            updateData(newCar);
            setFileLoading(false);
        }else{
            setInvalidFile(true);
        }
    }

    const updateExpiration = (el) => {
        const id = el.id;
        const newExpiration = expiration;
        newExpiration[id] = el.value;
        setExpiration(newExpiration);
        const date = `${newExpiration.year}${newExpiration.day}`;
        updateForm({id: 'circulationPermission.expirationDate-id', value: date});
    }
    
    const updateTax = () => {
        const tax = AmmountHelper.calculateTax(car.price, car.valuationPrice);
        const newCar = car;
        newCar.taxValue = tax;
        updateData(newCar);
    }

    const updatePayment = (el) => {
        const id = el.id.split('.');
        const type = id[0];
        const index = id[1];
        let newPayment;
        const payAmmount = AmmountHelper.toNumber(el.value);
        switch (type) {
            case 'cashPayment':
                newPayment = cashPayment;
                newPayment[index] = index === 'checked' ? !newPayment.checked : payAmmount;
                setCashPayment({...newPayment});
                break;
            case 'bankDocument':
                newPayment = bankDocument;
                newPayment[index] = index === 'checked' ? !newPayment.checked : payAmmount;
                setBankDocument({...newPayment});
                break;
            case 'creditPayment':
                newPayment = creditPayment;
                newPayment[index] = index === 'checked' ? !newPayment.checked : payAmmount;
                setCreditPayment({...newPayment});
                break;
            case 'transferencePayment':
                newPayment = transferencePayment;
                newPayment[index] = index === 'checked' ? !newPayment.checked : payAmmount;
                setTransferencePayment({...newPayment});
                break;
            default:
                break;
        }
        const paymentArray = [];

        if(cashPayment.checked && cashPayment.ammount > 0){
            paymentArray.push(cashPayment);
        }
        if(bankDocument.checked && bankDocument.ammount > 0){
            paymentArray.push(bankDocument);
        }
        if(creditPayment.checked && creditPayment.ammount > 0){
            paymentArray.push(creditPayment);
        }
        if(transferencePayment.checked && transferencePayment.ammount > 0){
            paymentArray.push(transferencePayment);
        }
        updatePay(paymentArray);
    }

    const removeFile = () => {
        const newCar = car;
        newCar.circulationPermission.document.url = '';
        newCar.circulationPermission.document.name = '';
        setTempFile(' ');
        updateData(newCar);
    }
    
    return(
        <>
            <SubtitleDivider>Datos generales del vehículo</SubtitleDivider>
            {!loading && 
            <>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="car-plate">Patente</Label>
                            <Input type="text" id="car-plate" className="bg-white" value={ plate } disabled />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="car-brand">Marca</Label>
                            <Input type="text" id="car-brand" className="bg-white" value={ car?.brand } disabled/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="car-model">Modelo</Label>
                            <Input type="text" id="car-model" className="bg-white" value={ car?.model } disabled/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="car-year">Año</Label>
                            <Input type="text" id="car-year" className="bg-white" value={ car?.year } disabled/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="color-id">Color</Label>
                            <Input type="text" id="color-id" className="bg-white" value={ car?.color } onChange={ (el) => updateForm(el.target)} required disabled={car.color ? true : false} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="fuel-id">Combustible</Label>
                            <Input type="text" id="fuel-id" className="bg-white" value={ car?.fuel } onChange={ (el) => updateForm(el.target)} required disabled={car.fuel ? true : false} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="chassisNumber-id">Número de chasis</Label>
                            <Input type="text" id="chassisNumber-id" className="bg-white" value={ car?.chassisNumber } onChange={ (el) => updateForm(el.target)} disabled/>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="motorNumber-id">Número de motor</Label>
                            <Input type="text" id="motorNumber-id" className="bg-white" value={ car?.motorNumber } onChange={ (el) => updateForm(el.target)} disabled/>
                        </FormGroup>
                    </Col>
                </Row>
                <SubtitleDivider>Tasación del vehículo</SubtitleDivider>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="car-version">
                                Versión
                                <span id="car-version-tooltip" className='ml-2'><FontAwesomeIcon icon='question-circle' /> </span>
                                <UncontrolledTooltip placement="right" target="car-version-tooltip" className='tooltip-form-help'>
                                El impuesto a pagar se calculará en base al precio más alto entre la tasación del vehículo y el precio de venta ingresado 
                                </UncontrolledTooltip>
                            </Label>
                            <Input type="select" id="version-id" className="bg-white" value={ car?.version } onChange={ (el) => updateVersion(el.target)} disabled>
                                <option value='' disabled>Seleccione una versión</option>
                                {valuations.map((el, k)=>
                                    <option key={k} value={el.version}>{el.version}</option>
                                )}
                                <option value='otro'>Otro Modelo</option>
                                <option value='SC'>Sin codigo SII</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="SIICode-id">Codigo SII</Label>
                            <span id="sii-tooltip" className='ml-2'><FontAwesomeIcon icon='question-circle' /> </span>
                            <UncontrolledTooltip placement="right" target="sii-tooltip" className='tooltip-form-help'>
                                Ingresa el código de SII que aparece regularmente en el permiso de circulación.
                                <a href='https://static.autofact.cl/conces/transferencia/codigo_sii.png' target="_blank" className='underline-text-decoration text-light pl-1' rel="noreferrer">ver ejemplo</a>
                            </UncontrolledTooltip>
                            <Input type="text" id="SIICode-id" className="bg-white" value={ car?.SIICode } disabled/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="valuationPrice-id">Tasación del vehículo</Label>
                            <span id="tasacion-tooltip" className='ml-2'><FontAwesomeIcon icon='question-circle' /> </span>
                            <UncontrolledTooltip placement="right" target="tasacion-tooltip" className='tooltip-form-help'>
                                El impuesto a pagar se calculará en base al precio más alto entre la tasación del vehículo y el precio de venta ingresado 
                            </UncontrolledTooltip>
                            <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} id="valuationPrice-id" className="form-control bg-white" value={ car?.valuationPrice } onChange={ (el) => updateForm(el.target)} disabled />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="price-id">Precio de venta</Label>
                            <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} id="price-id" className="form-control bg-white" value={ car?.price } onChange={ (el) => updateForm(el.target)}
                            customInput={ Input }
                            disabled/>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="taxValue-id">Impuesto a pagar</Label>
                            <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} customInput={Input} id="taxValue-id" className="form-control bg-white" value={ car?.taxValue } onChange={ (el) => updateForm(el.target)} disabled/>
                        </FormGroup>
                    </Col>
                </Row>
                <SubtitleDivider>Forma de pago</SubtitleDivider>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for="paymentType-id">Seleccione la forma de pago</Label>
                            <Input type="select" id="paymentType-id" className="bg-white" value={car.paymentType || ''} onChange={(e)=> updateForm(e.target)} 
                                required
                            >
                                <option value='' disabled> Seleccione una forma de pago</option>
                                <option value="1">Efectivo</option>
                                <option value="3">Vale Vista</option>
                                <option value="11">Tarjeta bancaria (débito/crédito)</option>
                                <option value="8">Transferencia electrónica</option>
                                <option value="otro">Mix de formas de pago</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    {car?.paymentType === "otro" &&
                        <Col xs="4" className="d-flex align-items-center">
                            <h2> Valor a pagar {AmmountHelper.formatCurrency(car.price)}</h2>
                        </Col>
                    }
                </Row>
                {car?.paymentType === "otro" &&
                    <Row  className="mt-4">
                        <Col xs="4">
                            <FormGroup>
                                <Label>Efectivo</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend" >
                                    <InputGroupText className="custom-height">
                                        <Input addon type="checkbox" className="rounded"  id="cashPayment.checked" checked={cashPayment.checked} aria-label="Checkbox for following text input" onChange={ (e)=> updatePayment(e.target)} />
                                    </InputGroupText>
                                    </InputGroupAddon>
                                    <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} customInput={Input} placeholder="Ingrese monto" id="cashPayment.ammount" className="custom-height" value={cashPayment.ammount} onChange={ (e)=> updatePayment(e.target)} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label>Vale Vista</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="custom-height">
                                        <Input addon type="checkbox" className="rounded" id="bankDocument.checked" checked={bankDocument.checked} aria-label="Checkbox for following text input" onChange={ (e)=> updatePayment(e.target)} />
                                    </InputGroupText>
                                    </InputGroupAddon>
                                    <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} customInput={Input} placeholder="Ingrese monto" id="bankDocument.ammount" className="custom-height" value={bankDocument.ammount} onChange={ (e)=> updatePayment(e.target)} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label>Tarjeta bancaria (débito/crédito)</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="custom-height">
                                        <Input addon type="checkbox" className="rounded" id="creditPayment.checked" checked={creditPayment.checked} aria-label="Checkbox for following text input" onChange={ (e)=> updatePayment(e.target)}/>
                                    </InputGroupText>
                                    </InputGroupAddon>
                                    <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} customInput={Input} placeholder="Ingrese monto" id="creditPayment.ammount" className="custom-height" value={creditPayment.ammount} onChange={ (e)=> updatePayment(e.target)} />
                                </InputGroup>
                            </FormGroup> 
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label>Transferencia electrónica</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="custom-height">
                                        <Input addon type="checkbox" className="rounded" id="transferencePayment.checked" checked={transferencePayment.checked} onChange={ (e)=> updatePayment(e.target)} aria-label="Checkbox for following text input" />
                                    </InputGroupText>
                                    </InputGroupAddon>
                                    <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} customInput={Input} placeholder="Ingrese monto" id="transferencePayment.ammount" className="custom-height" value={transferencePayment.ammount} onChange={ (e)=> updatePayment(e.target)} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup className="bold">
                                <Label>Suma total</Label>
                                <InputGroup>
                                <NumericFormat thousandSeparator='.' decimalSeparator=',' prefix={'$'} customInput={Input} className="custom-height" value={paymentSum} onChange={ (e)=> updatePayment(e.target)} invalid={!isValidAmmount} disabled/>
                                    <FormFeedback> El precio del vehículo y la suma total no coinciden </FormFeedback>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                }
                <SubtitleDivider>Información del permiso de circulación</SubtitleDivider>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Ingresa fecha de expiración del permiso</Label>
                            <InputGroup>
                                <Input type="select" id="day" className="h-auto" value={expiration.day} onChange={ (e)=> updateExpiration(e.target)} required>
                                    <option value="" disabled>Mes expiración</option>
                                    <option value="-03-31">31 de Marzo</option>
                                    <option value="-08-31">31 de Agosto</option>
                                </Input>
                                <InputGroupAddon addonType="append" className="w-50">
                                    <Input type="select" id="year" placeholder="Año expiración" value={expiration.year}  onChange={ (e)=> updateExpiration(e.target)} required>
                                    <option value="" disabled >Seleccione un año</option>
                                    {yearList.map(year => (
                                        <option key={year} value={year}>
                                        {year}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="circulationPermission.comunaId">Ingresa comuna del permiso</Label>
                            <Input type="select" id="circulationPermission.comunaId" className="bg-white" value={ car?.circulationPermission?.comunaId } onChange={ (el) => updateForm(el.target)} required >
                                <option value="">Seleccione una comuna</option>
                                {communes.map((el, k)=>
                                    <option key={k} value={el.idcomuna}>{el.nombre}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="car-permlink">Ver permiso de circulación</Label>
                            {car?.circulationPermission.document.url ? 
                                <div className='d-flex flex-row align-items-center'>
                                    <a id="car-permlink" className="link-block" target="_blank" rel="noreferrer" href={car?.circulationPermission.document.url}>
                                        <AttachFileIcon />
                                        { FileHelper.getFileNameFromUrl(car?.circulationPermission.document.url)}
                                    </a>
                                    <FontAwesomeIcon icon="trash" color='red' className='pointer ml-2' onClick={ () => removeFile()}/>
                                </div>
                            :
                                <>
                                    <div className="d-flex">
                                        <CustomInput type="file" id="url" className="bg-white mr-2" label={tempFile} accept=".png, .jpg, .pdf" onChange={ e => upload(e.target)} invalid={invalidFile}/>
                                        { fileLoading && <i className='ml-auto pt-2 pr-2'><CircularProgress size={20}/></i>}
                                    </div>
                                    <FormText>{invalidFile && 'Seleccione uno de los '}Formatos permitidos: JPG, PNG, PDF</FormText>
                                </>
                            }
                        </FormGroup>
                    </Col> 
                </Row>
            </>}
        </>
    )
}

export default NotaryContractVehicleForm;