import { JSONPath } from 'jsonpath-plus'
import config from '../../config'
export const BUCKET = config.buckets.identity;

const STEP_STATUS_COMPLETED = 2;
const STEP_STATUS_ACTIVE = 1;
const STEP_STATUS_INACTIVE = 0;

export const STEP_NAMES = {
  PREVIEW_IDENTIY_CARD_FRONT: 'preview_identity_card_front',
  PREVIEW_CAPTURE_PHOTO: 'preview_capture_photo',
  HANDWRITE_SIGNATURE: 'handwritten_signature_capture',
  CAPTURE_VIDEO: 'capture_video',
  CAPTURE_VIDEO_NOTARY: 'capture_video_notary_declaration'
};

export const STEP_LABELS = [
  {
    label: 'Análisis de cédula',
    key: STEP_NAMES.PREVIEW_IDENTIY_CARD_FRONT
  },

  {
    label:'Reconocimiento facial',
    key: STEP_NAMES.PREVIEW_CAPTURE_PHOTO
  },
  {
    label:'Identificación de firma',
    key: STEP_NAMES.HANDWRITE_SIGNATURE
  },
  {
    label:'Video de consentimiento',
    key: STEP_NAMES.CAPTURE_VIDEO
  },
  {
    label:'Declaración ante notario',
    key: STEP_NAMES.CAPTURE_VIDEO_NOTARY
  },
];

export const allAvailableSteps = (data) => {
  return STEP_LABELS.filter((label) =>{
      return isAvailableStep(data, label.key)
  });
}

export const isActiveStep = (stepName, data) => {
  return data.stepsStatus[stepName] > STEP_STATUS_INACTIVE;
}
export const isCurrentStep = (stepName, data) => {
  return data.stepsStatus[stepName]  === STEP_STATUS_ACTIVE;
}
export const isCompletedStep = (stepName, data) => {
  return data.stepsStatus[stepName]  === STEP_STATUS_COMPLETED;
}

export const isAvailableStep = (data, stepName) =>{
  if(stepName === STEP_NAMES.CAPTURE_VIDEO && !data.captureVideo){
    return false;
  }
  if(stepName === STEP_NAMES.CAPTURE_VIDEO_NOTARY && !isCurrentStep( STEP_NAMES.CAPTURE_VIDEO_NOTARY, data) ) {
    return false;
  }
  return true;
}
const jsonPathFn = json => path => JSONPath({ path, json })[0] || null;

const identityImgV2 = (value) =>{
  return value ? `${BUCKET}/${value}` : null;
}

export const transformItem = data => {
  const jsonPath = jsonPathFn(data)
  const finished = jsonPath('$.client_finish');
  const status = jsonPath('$.status');
  const timestamp_main_token_expiration = jsonPath('$.timestamp_main_token_expiration');
  const utcTimestamp = new Date(new Date().getTime()).toISOString();
  const tokenExpired = utcTimestamp >= timestamp_main_token_expiration;

  const identityDataValues = {};
  for (const step of data.identity_steps) {
    if (step.identity_data) {
      for (const item of step.identity_data) {
        if (item.status > 0) {
          identityDataValues[item.type] = item.data;
        }
      }
    }
  }
  const stepsStatus = data.identity_steps.reduce((status, step) => {
    status[step.name] = step.status;
    return status;
  }, {});
  return {
    finished,
    status,
    code: data.code,
    name: jsonPath('$.identity_person.name'),
    lastName: jsonPath('$.identity_person.lastname'),
    sex: jsonPath('$.identity_person.sex'),
    bod: jsonPath('$.identity_person.born_date'),
    nationality: jsonPath('$.identity_person.nationality'),
    rut: jsonPath('$.identity_person.identity_code'),
    expirationDate: jsonPath('$.identity_person.expiration_date'),
    cardFront: identityImgV2(identityDataValues['IDENTITY_CARD_FRONT']),
    cardBack: identityImgV2(identityDataValues['IDENTITY_CARD_BACK']),
    facePhoto: identityImgV2(identityDataValues['FACE_PHOTO']),
    faceSimilarity: identityDataValues['FACIAL_PERCENT'] ?? 0,
    cardPhoto: identityImgV2(identityDataValues['IDENTITY_CARD_FACE_CROPPED']),
    cardSignature: identityImgV2(identityDataValues['IDENTITY_CARD_SIGNATURE_CROPPED']),
    handwrittenSignature: identityImgV2(identityDataValues['HANDWRITTEN_SIGNATURE']),
    video: identityDataValues['RECORDED_VIDEO'] ?? null,
    captureVideo: identityImgV2(identityDataValues['RECORDED_VIDEO_NOTARY_DECLARATION'] ?? null),
    timestamp: new Date(jsonPath('$.timestamp')),
    enableApprove: finished && status === 1,
    enableReject: status === 1,
    stepsStatus: stepsStatus,
    tokenExpired,
    id: data.id,
    externName: jsonPath('$.identity_person.extern_name'),
  }
}
