
const SubtitleDivider = (props) => {
    return(
        <div className='hr-container'>
            <span className='hr-text text-gray'>{ props.children }</span>
            <span className='hr' />
        </div>
    )
}

export default SubtitleDivider;