import React,{Component} from 'react';

class DashBoardStatisticsComponent extends Component{
    render() {
        return <div>
            <div>Estadisticas</div>
        </div>
    }
}

export default DashBoardStatisticsComponent;
