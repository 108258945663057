import React,{Component} from 'react';
import {Button, Col, Form, FormGroup, CustomInput, Label, Row, Input} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackendApiService from "../../services/BackendApiService";
import DashBoardRequestUpListComponent from "./DashBoardRequestUpListComponent";
import ModalConfirmComponent from "../modal-confirm/ModalConfirm";
import NavTabsSkeleton from "./NavTabsSkeleton";

class DashBoardRequestUpComponent extends Component{
    state = {
        file:null,
        fileName:null,
        firstRepertory:'',
        lastRepertory:'',
        inProgress:false,
        filePages: 0,
        showConfirmPagesDiference: false
    }
    inputFileRef = React.createRef();
    listComponentRef = React.createRef();

    handleChangeFile = (e) =>{
        console.log('llamado el cambio de fichero')
        console.log(e.target.files)
        const files = e.target.files || [];
        const file = files[0] || null;
        const fileName = file.name || '';
        this.calculatePdfPages(file)
        this.setState({
            file: file,
            fileName: fileName
        })
    }
    calculatePdfPages = (file) =>{
        const _this = this;
        if(file){
            const reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onloadend = function(){
                const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                _this.setState({filePages: count})
            }
        }else{
            this.setState({filePages: 0})
        }

    }
    handleChange = (e) =>{
       const state = Object.assign({}, this.state)
       state[e.target.id] = e.target.value;
       this.setState(state);
    }
    handleChangeUnit = (e) =>{
        this.setState({firstRepertory: e.target.value, lastRepertory: e.target.value});
     }
    isValid = () =>{
        const firstRepertory = parseInt(this.state.firstRepertory)
        const lastRepertory = parseInt(this.state.lastRepertory);
        if(
            this.state.fileName
            && firstRepertory > 0
            && lastRepertory > 0
            && firstRepertory <= lastRepertory
        ){
            return true;
        } else{
            return false;
        }
    }
    confirmDifferenceMessage = () =>{
        this.setState({showConfirmPagesDiference: false})
        this.uploadDocument();
    }
    cancelUpDocument = () => {
        this.setState({showConfirmPagesDiference: false})
    }
    confirmSubmit = async (e) =>{
        e.preventDefault();
        if(this.state.lastRepertory - this.state.firstRepertory < (this.state.filePages - 2)){
            this.setState({showConfirmPagesDiference: true})
        }else{
            this.uploadDocument();
        }
        
    }
    async uploadDocument(){
        this.setState({inProgress:true})
        try{
            const result = await BackendApiService.uploadRequest(this.state.firstRepertory, this.state.lastRepertory, this.state.file)
            if(result){
                this.props.showSuccesMessage('Solicitudes subidas con éxito');
                this.setState({file:null,
                    fileName:null,
                    firstRepertory:'',
                    lastRepertory:'',
                    inProgress:false,
                    filePages: 0
                })
                this.inputFileRef.current.value = null;
            }else{
                this.props.showErrorMessage('Ocurrio un error al subir ficheros');
                this.setState({inProgress:false})
            }
            this.listComponentRef.current.reloadData()

        }catch (e) {
            console.log(e);
            this.props.showErrorMessage('Ocurrio un error al subir ficheros');
            this.setState({inProgress:false})
        }
    }

    unitUpload = () => <Row className="field-row">
                        <FormGroup row>
                            <Col sm={12}>
                                <Label for="plate">Repertorio</Label>
                                <Input type="number" name="firstRepertory" id="firstRepertory"
                                    className="form-control-sm" value={this.state.firstRepertory}
                                    onChange={this.handleChangeUnit}>
                                </Input>
                            </Col>
                        </FormGroup>
                    </Row>;

    massiveUpload = () =>  <Row className="field-row">
                                <FormGroup row>
                                    <Col sm={6}>
                                        <Label for="plate">Repertorio Inicial</Label>
                                        <Input type="number" name="firstRepertory" id="firstRepertory"
                                            className="form-control-sm" value={this.state.firstRepertory}
                                            onChange={this.handleChange}>
                                        </Input>
                                    </Col>
                                    <Col sm={6}>
                                        <Label for="plate" >Repertorio Final</Label>
                                        <Input type="number" name="lastRepertory" id="lastRepertory"
                                            className="form-control-sm" value={this.state.lastRepertory}
                                            onChange={this.handleChange}>
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </Row>;
    
    tabsDefinition = () =>  {
            return { 
                massive: {
                    id: 'massiveUpload',
                    title: 'Carga Masiva',
                    component:this.massiveUpload
                },
                unit: {
                    id: 'unitUpload',
                    title: 'Carga Unitaria',
                    component:this.unitUpload
                },
        }
    };

    eraseRepertoryNumbers = () => {
        this.setState( { firstRepertory: '', lastRepertory: '' } );
    }


    render() {
        return <div>
            <div>Con esta herramienta puedes subir los archivos con las solicitudes de los contratos de Autofact</div>

           <div className={'d-flex'}>
               <Form className="request-up-form mt-5 col-md-5">
                <Row className="field-row">
                        <Col sm={12} className={'ml-0 pl-0'}>
                            <Label for="exampleCustomFileBrowser">Escoger archivo</Label>
                            <CustomInput
                                type="file"
                                id="exampleCustomFileBrowser"
                                name="customFile" bsSize="sm"
                                label={this.state.fileName} onChange={this.handleChangeFile}
                                innerRef={this.inputFileRef}

                            />
                        </Col>
                </Row>
                <div className='separation-gap'/>
                <NavTabsSkeleton tabsDefinition={this.tabsDefinition()}
                                 onSelectTab={this.eraseRepertoryNumbers}
                 />
                <Row className="field-row">
                        <Button className="btn btn-sm badge-primary font-weight-bold"
                                disabled={!this.isValid() || this.state.inProgress}
                                onClick={this.confirmSubmit}>
                            {this.state.inProgress ? <FontAwesomeIcon icon="spinner" spin/> :<FontAwesomeIcon icon="upload"/>}
                            {this.state.inProgress?' Subiendo solicitud':' Subir solicitud'}
                        </Button>
                </Row>
            </Form>
            <DashBoardRequestUpListComponent
                ref={this.listComponentRef}
            />
               {this.state.showConfirmPagesDiference && <ModalConfirmComponent
                message={`La cantidad de solicitudes que está cargando no coincide con el rango de repertorios indicado. Por favor revisa y corregir información`}
                confirmFunction={this.confirmDifferenceMessage}
                cancelFunction={this.cancelUpDocument}
                confirmText={"Aceptar y continuar"}
                />}
           </div>
        </div>
    }
}

export default DashBoardRequestUpComponent;
