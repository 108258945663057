import React, { Component } from 'react';
import { FormGroup, Label, Input, Row, Col, Button, Alert, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Switch } from '@material-ui/core';
import BackendApiService from "../../services/BackendApiService";
import { app } from '../../config';

const TIME_OUT_CLOSED_ALERT_MS = 3000;
class TelepresenceNotaryEnablementButton extends Component {
    intervalID = null;
    constructor(props) {
        super(props);
        this.state = {
            telepresenceEnabled: false,
            processCount: 0,
            minutesActive: 0,
            message: null,
            isMessageVisible: false,
            isSaving: false,
            isModalOpen: false,
        };
    }

    async componentDidMount() {
        await this.loadData();
        this.startInterval();
    }

    componentWillUnmount() {
        this.stopInterval();
    }

    handleTelepresenceChange = async (e) => {
        const isEnabled = e.target.checked;
        this.setState({ telepresenceEnabled: isEnabled });

        if (isEnabled) {
            this.toggleModal();
            return;
        }

        this.setState({
            processCount: 0,
            minutesActive: 0,
        }, async () => {
            const result = await this.updateNotaryTelepresenceAvailability({
                telepresenceEnabled: false,
                processCount: 0,
                minutesActive: 0,
            });

            if (result.error) {
                this.showErrorMessage('Error al guardar la selección.');
            } else {
                this.showSuccesMessage('Selección guardada con éxito.');
            }
        });
    }

    handleProcessCountChange = (e) => {
        this.setState({ processCount: e.target.value });
    }

    handleProcessMinutesActiveChange = (e) => {
        this.setState({ minutesActive: e.target.value });
    };

    onDismiss = () => {
        this.setState({ isMessageVisible: false });
    }

    showSuccesMessage = (message) => {
        this.setState({
            message: {
                success: true,
                text: message,
            },
            isMessageVisible: true
        });

        setTimeout(() => {
            this.setState({ isMessageVisible: false });
        }, TIME_OUT_CLOSED_ALERT_MS);
    }

    showErrorMessage = (message) => {
        this.setState({
            message: {
                success: false,
                text: message
            },
            isMessageVisible: true
        });

        setTimeout(() => {
            this.setState({ isMessageVisible: false });
        }, TIME_OUT_CLOSED_ALERT_MS);
    }

    updateNotaryTelepresenceAvailability = async (params) => {
        const response = {
            error: false,
            message: null,
        };
        try {
            const { telepresenceEnabled, processCount, minutesActive } = params;
            const result = await BackendApiService.updateNotaryTelepresenceAvailability({
                telepresenceEnabled,
                processCount: parseInt(processCount, 10),
                minutesActive,
            });

            if (result !== true) {
                response.error = true;
                response.message = 'No fue posible ejecutar la acción.';
            }
        } catch (error) {
            response.message = error.message;
            response.error = true;
        }

        return response;
    }

    loadData = async () => {
        const responseGetTelepresenceConfigNotary = await this.getTelepresenceConfigNotary();
        if (!responseGetTelepresenceConfigNotary || !Object.keys(responseGetTelepresenceConfigNotary).includes('status') || responseGetTelepresenceConfigNotary.status !== 'OK') {
            return;
        }
        const { data: getTelepresenceConfigNotary } = responseGetTelepresenceConfigNotary;
                const { status, capacity: processCount, minutes: minutesActive } = getTelepresenceConfigNotary;
        const telepresenceEnabled = parseInt(status, 10) === 1;
        this.setState({ telepresenceEnabled, processCount, minutesActive });
    }

    getTelepresenceConfigNotary = async () => {
        try {
            const result = await BackendApiService.getNotaryTelepresenceAvailability();
            return result;
        } catch (error) {
            console.error('getTelepresenceConfigNotary: ', error);
            return null;
        }
    }

    hasAvailabilityofAssignmentTime = async () => {
        const responseApi = await this.hasAvailabilityofAssignmentTimeApi();
        if (!responseApi || !Object.keys(responseApi).includes('status') || responseApi.status !== 'OK') {
            return false;
        }
        const { data: hasAvailabilityofAssignmentTime } = responseApi;

        return hasAvailabilityofAssignmentTime;
    }


    hasAvailabilityofAssignmentTimeApi = async () => {
        try {
            const result = await BackendApiService.hasAvailabilityofAssignmentTime();
            return result;
        } catch (error) {
            console.error('hasAvailabilityofAssignmentTime: ', error);
            return null;
        }
    }

    checkAvailability = async () => {
        const availability = await this.hasAvailabilityofAssignmentTime();
        const { hasAvailabilityofAssignmentTime } = availability;
        if (!hasAvailabilityofAssignmentTime && this.state.telepresenceEnabled) {
            await this.handleTelepresenceChange({ target: { checked: false } });
        }
    }

    toggleModal = (isCancelled) => {
        if (!this.state.isModalOpen) {
            this.stopInterval();
        } else {
            this.startInterval();
        }

        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen,
            telepresenceEnabled: isCancelled ? false : prevState.telepresenceEnabled
        }));
    }

    saveAndCloseModal = async () => {
        const { telepresenceEnabled, processCount, minutesActive } = this.state;

        const result = await this.updateNotaryTelepresenceAvailability({
            telepresenceEnabled,
            processCount,
            minutesActive,
        });

        if (result.error) {
            this.showErrorMessage('Error al guardar la selección.');
        } else {
            this.showSuccesMessage('Selección guardada con éxito.');
            this.toggleModal();
            this.setState({ telepresenceEnabled: true });
        }

        this.startInterval();
    }

    startInterval = () => {
        this.stopInterval();
        const { telepresence } = app;
        this.intervalID = setInterval(
            this.checkAvailability,
            parseInt(telepresence.timeCheckAvailabilityMilliseconds, 10)
        );
    }

    stopInterval = () => {
        clearInterval(this.intervalID);
        this.intervalID = null;
    }

    render() {
        let message = null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'}
                isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
        }
        return (
            <>
                {message}
                <Form className="notary-form">
                    <Row className="field-row">
                        <Col sm={12}>
                            <FormGroup tag="fieldset">
                                <legend style={{ fontSize: 'small' }}>¿Habilitar/Deshabilitar notaría para recibir procesos de Telepresencia?</legend>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormGroup inline>
                                        <Label>
                                            <Switch
                                                checked={this.state.telepresenceEnabled}
                                                onChange={this.handleTelepresenceChange}
                                                color="primary"
                                            />
                                            Habilitar
                                        </Label>
                                    </FormGroup>
                                    {this.state.telepresenceEnabled &&
                                        <>
                                            <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                                                <ModalHeader toggle={() => this.toggleModal(true)}>Configuración de Telepresencia</ModalHeader>
                                                <ModalBody>

                                                    <FormGroup>
                                                        <Label for="processCount" size="small">Capacidad Firmas</Label>
                                                        <Input type="number" id="processCount" value={this.state.processCount}
                                                            onChange={this.handleProcessCountChange}
                                                            min="0" step="1" style={{ width: '80px', marginLeft: '10px' }} bsSize="sm" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="minutesActive" style={{ marginLeft: '10px' }} size="small">Minutos Activo</Label>
                                                        <Input type="number" id="minutesActive" value={this.state.minutesActive}
                                                            onChange={this.handleProcessMinutesActiveChange}
                                                            min="0" step="1" style={{ width: '80px', marginLeft: '10px' }} bsSize="sm" />
                                                    </FormGroup>

                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="primary" onClick={this.saveAndCloseModal} disabled={this.state.isSaving} >
                                                        {this.state.isSaving ? 'Guardando...' : 'Guardar'}
                                                    </Button>{' '}
                                                    <Button color="secondary" onClick={() => this.toggleModal(true)} size="sm">Cancelar</Button>
                                                </ModalFooter>
                                            </Modal>
                                        </>
                                    }
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

export default TelepresenceNotaryEnablementButton;
