import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  CustomInput
} from 'reactstrap';

import BackendApiService from '../../services/BackendApiService';

class FirmasDocumentosUploadButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: null,
      inProgress: false,
      modal: false
    };
    this.inputFileRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeFile = (e) => {
    const files = e.target.files || [];
    const file = files[0] || null;
    const fileName = file.name || '';
    this.setState({
      file: file,
      fileName: fileName
    });
  };

  handleSubmit = async () => {
    this.setState({ inProgress: true, modal: false });
    try {
      const { subsidiary_id, code } = this.props.signDocumentData;
      const result = await BackendApiService.uploadUpdatedMandatePhysic(this.props.id, subsidiary_id, code, this.state.file);
      console.log(result);
      if (result) {
        console.log('Documento actualizado con éxito');
        this.setState({
          file: null,
          fileName: null,
          firstRepertory: '',
          lastRepertory: '',
          inProgress: false,
          filePages: 0
        });
        window.location.reload();
      } else {
        console.error('Ocurrio un error al actualizar el documento');
        this.setState({ inProgress: false });
      }

    } catch (e) {
      console.log(e);
      console.error('Ocurrio un error al  actualizar el documento');
      this.setState({ inProgress: false });
    }
  };

  render() {
    let message = null;

    return (
      <React.Fragment>
        <Button onClick={this.toggle} color='info' disabled={this.state.inProcess}>
          {this.state.inProgress ? 'Actualizando...' : 'Actualizar'}
          <i className='glyphicon glyphicon-remove-sign text-icon'/>
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          {message}
          <ModalHeader toggle={this.toggle}>Actualizar documento</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="exampleCustomFileBrowser">Escoger archivo</Label>
              <CustomInput
                type="file"
                id="exampleCustomFileBrowser"
                name="customFile" bsSize="sm"
                label={this.state.fileName} onChange={this.handleChangeFile}
                innerRef={this.inputFileRef}

              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={this.handleSubmit}>Actualizar</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default FirmasDocumentosUploadButton;
