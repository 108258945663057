import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import moment from 'moment';
import {PreAdmissionStatus} from '@autofact/operation-lib-model-notary-platform';
import DateRangePicker from 'react-bootstrap-daterangepicker';

class PreAdmissionListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilters: {
                PLATE: '',
                STATUS: '',
                REPERTORY: '',
                NUMBER: '',
                DATE_FROM: '',
                DATE_TO: '',
                DATE_RANGE: ''
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.onDateRangeSelected = this.onDateRangeSelected.bind(this);
    }

    handleFilterChange(event) {
        let newFilterData = {};
        newFilterData[event.target.id] = event.target.value;
        newFilterData = Object.assign(this.state.formFilters, newFilterData);
        this.setState({formFilters: newFilterData});
    }

    setFilters() {
        const filters = {};
        for (const filter in this.state.formFilters) {
            if (this.state.formFilters.hasOwnProperty(filter) && this.state.formFilters[filter]) {
                filters[filter] = this.state.formFilters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters() {
        const filters = {
            PLATE: '',
            STATUS: '',
            REPERTORY: '',
            NUMBER: '',
            DATE_FROM: '',
            DATE_TO: '',
            DATE_RANGE: ''
        };
        this.setState({formFilters: filters});
    }

    onDateRangeSelected(event, picker) {
        const filterChangeEvent = {
            target: {
                id: 'DATE_RANGE',
                value: `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`
            }
        };
        this.handleFilterChange(filterChangeEvent);

        filterChangeEvent.target.id = 'DATE_FROM';
        filterChangeEvent.target.value = picker.startDate.format('YYYY-MM-DD');
        this.handleFilterChange(filterChangeEvent);

        filterChangeEvent.target.id = 'DATE_TO';
        filterChangeEvent.target.value = picker.endDate.format('YYYY-MM-DD');
        this.handleFilterChange(filterChangeEvent);
    }

    render() {
        return (
            <Form className={'px-2'}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <DateRangePicker
                                onApply={this.onDateRangeSelected}
                                ranges={
                                    {
                                        'Hoy': [moment(), moment()],
                                        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                                        'Última semana': [moment().subtract(6, 'days'), moment()],
                                        'Últimas 2 semanas': [moment().subtract(13, 'days'), moment()],
                                        'Este mes': [moment().startOf('month'), moment().endOf('month')],
                                        'Mes anterior': [moment().subtract(1, 'month').startOf('month'),
                                            moment().subtract(1, 'month').endOf('month')]
                                    }
                                }
                                locale={{
                                    format: 'DD/MM/YYYY',
                                    applyLabel: 'Aplicar',
                                    cancelLabel: 'Limpiar',
                                    fromLabel: 'Desde',
                                    toLabel: 'Hasta',
                                    customRangeLabel: 'Seleccionar rango',
                                    daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                                    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                                        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre',
                                        'Diciembre'],
                                    firstDay: 1
                                }}
                                containerStyles={{display: 'block'}}
                            >
                                <Input type="text" placeholder="Rango de fecha" id="DATE_RANGE" className="form-control-sm"
                                       onChange={this.handleFilterChange}
                                       value={this.state.formFilters.DATE_RANGE}/>
                            </DateRangePicker>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text" placeholder="Número de repertorio" id="REPERTORY" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.REPERTORY}/>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text" placeholder="Patente" id="PLATE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.PLATE}/>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text" placeholder="Número de preingreso" id="NUMBER" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.NUMBER}/>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="STATUS" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.STATUS}>
                                <option value="">Estado</option>
                                {Object.keys(PreAdmissionStatus)
                                    .map((statusId, index, statusList) => {
                                        const status = PreAdmissionStatus[statusId];
                                        return (<option key={`type-option-${index}`}
                                                        value={status.id}>{status.label}</option>);
                                    })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <Button className="btn btn-sm btn-primary-darker" onClick={this.setFilters}>Aplicar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default PreAdmissionListFilter;
