const configJson = require('./config-imported');
const version = require('./version');

const endpoints = {
    backendApi: {
        url: configJson.REACT_APP_BACKEND_API_URL || process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:3010/'
    },
    autofactProApi: {
        url: configJson.REACT_APP_LOGIN_PRO_URL || process.env.REACT_APP_LOGIN_PRO_URL || 'http://pro.autofact.qa/',
        domain: configJson.REACT_APP_PRO_DOMAIN || process.env.REACT_APP_PRO_DOMAIN || '.pro.autofact.qa',
    },
    meeting :{
        url: configJson.MEET_RECORDING_URL || process.env.MEET_RECORDING_URL || 'https://pn5890y2ra.execute-api.us-east-1.amazonaws.com/qa/'
    },
    api_operation_internal: {
        url: configJson.API_OPERATION_INTERNAL || process.env.API_OPERATION_INTERNAL || 'https://x21se6qmsc.execute-api.us-east-1.amazonaws.com/latest/'
    }
};

const formats = {
    date: 'DD-MM-YYYY HH:mm:ss',
    shortDate: 'D-MM-YYYY'
};
const buckets = {
    identity: configJson.IDENTITY_BUCKET || process.env.IDENTITY_BUCKET || 'https://s3.amazonaws.com/docs.identity.qa'
};
const app = {
    version: version.version,
    notificationUrl: configJson.NOTIFICATION_SOUND_URL || process.env.NOTIFICATION_SOUND_URL || 'https://s3.amazonaws.com/doc.operaciones.autofactpro.com/STATICS/notification.mp3',
    timeToUpdateList: configJson.MS_TO_UPDATE_LIST || process.env.MS_TO_UPDATE_LIST || 15000,
    signTypesNotPhysic: configJson.SIGN_TYPES_NOT_PHYSIC || process.env.SIGN_TYPES_NOT_PHYSIC || '1,2',
    signTypesPhysic: configJson.SIGN_TYPES_PHYSIC || process.env.SIGN_TYPES_PHYSIC || '3',
    msToRequesCavStatus: configJson.MS_TO_REQUEST_CAV_STATUS || process.env.MS_TO_REQUEST_CAV_STATUS || 10000,
    cavDeliveredText: configJson.CAV_STATUS_DELIVERED || process.env.CAV_STATUS_DELIVERED || 'DELIVERED',
    contractType: configJson.CONTRACT_TYPE || process.env.CONTRACT_TYPE || 2,
    buyerType: {
        naturalPerson: configJson.NATURAL_PERSON_BUYER || process.env.NATURAL_PERSON_BUYER || 1,
        company: configJson.COMPANY_BUYER || process.env.COMPANY_BUYER || 2,
        buyFor: configJson.BUY_FOR_BUYER || process.env.BUY_FOR_BUYER || 3,
    },
    personType: {
        buyer: configJson.BUYER_TYPE || process.env.BUYER_TYPE || 'Buyer',
        buyerRepresentative: configJson.BUYER_REPRESENTATIVE_TYPE || process.env.BUYER_REPRESENTATIVE_TYPE || 'BuyerRepresentative',
        seller: configJson.SELLER_TYPE || process.env.SELLER_TYPE || 'Seller',
        sellerRepresentative: configJson.SELLER_REPRESENTATIVE_TYPE || process.env.SELLER_REPRESENTATIVE_TYPE || 'SellerRepresentative',
    },
    signProcess: {
        check_sign_interval: configJson.CHECK_SIGN_INTERVAL || 15000,
    },
    msToRequestTransference:  configJson.MS_TO_REQUEST_TRANSFERENCE || process.env.MS_TO_REQUEST_TRANSFERENCE || 30000,
    stevFee: configJson.STEV_FEE || process.env.STEV_FEE || 27540,
    notaryPaymentFee: configJson.NOTARY_PAYMENT_FEE || process.env.NOTARY_PAYMENT_FEE || 27540,
    expressTransfer: {
        textColorClass: configJson.EXPRESS_TRANSFERENCE_TEXT_COLOR_CLASS ?? 'green-ok-color',
    },
    telepresence: {
        timeCheckAvailabilityMilliseconds: configJson.TIME_CHECK_AVAILABILITY_MS || 30000,
        timeToReloadMultiMeeting: configJson.TIME_TO_RELOAD_MULTI_MEETING || 5000,
    },
}

//Development .env.local
//Production .env.production

module.exports = {
    app,
    endpoints,
    configJson,
    formats,
    buckets
};
