
const CheckStatus = ({children, actual, required}) => {
    return (
        actual !== null && required.includes(actual) ?
            children
            :
            []
    );
};

export default CheckStatus;
