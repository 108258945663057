import React, {useState, useEffect} from 'react'
import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TaxPaymentModal = ({
  isOpen,
  data,
  methods,
}) => {

    const { taxPaymentOnProgress } = data;
    const { closeModal } = methods;
    const [paySuccess, setPaySuccess] = useState(false);

    useEffect(()=>{
        if(!taxPaymentOnProgress) setPaySuccess(true);
        else setPaySuccess(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxPaymentOnProgress])

    const closeModalAction = () => {
        closeModal();
    }

    return (
        <Modal isOpen={isOpen} toggle={() => closeModalAction()} className='modal-contract-ok' centered>
        <div className="modal-content text-center">
                <div className="modal-header bg-primary-darker text-white">
                    <h2 className="modal-title">Pago de costos legales</h2>
                    <button type="button" className="close white-circle-close" onClick={() => closeModalAction()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='d-flex flex-column flex-wrap align-items-center medium-gap p-1'>
                        <FontAwesomeIcon icon='check-circle' className='green-ok-color' inverse={true} size='2x'/>
                        {paySuccess ?
                            <><h1 className='h1-title text-primary-darker'>El pago de impuestos del contrato ha finalizado exitosamente.</h1></> 
                        :
                            <>
                            <h1 className='h1-title text-primary-darker'>El contrato ya está en proceso de pago de impuestos.</h1>
                            <h2>Desde ahora puedes asignar repertorio</h2>
                            <div className="alert waiting-alert mb-5 p-4 w-100 text-center" role="alert">
                                <FontAwesomeIcon icon='exclamation-circle' className='mr-2' size='lg'/>
                                <span>Este proceso puede tardar entre 3 y 5 minutos.</span>
                            </div>
                            </>
                        }
                    </div>
                </div>
                <div className="justify-content-center mb-2">
                    <button className="btn btn-primary-dark bold mb-3" onClick={ () => closeModalAction()}>ACEPTAR</button>
                </div>
        </div>
        </Modal>
    )
}

export default TaxPaymentModal