import React, {Component} from 'react';
import {Row, Col, Input, Form, Button, UncontrolledAlert} from 'reactstrap';
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

import BackendApiService from "../../services/BackendApiService";
import SignDocumentStatus from '../../services/SignDocumentStatus';
import { app } from '../../config';
const SIGN_TYPE_PHYSIC = app.signTypesPhysic;
const SIGN_TYPE_NOT_PHYSIC = app.signTypesNotPhysic;
const SignStatus = {
    PENDING: {
        label: 'Pendiente'
    },
    PREPARING_SIGN: {
        label: 'Preparando firma'
    },
    ERROR_PREPARING_SIGN: {
        label: 'Error Preparando firma'
    },
    READY_FOR_SIGN: {
        label: 'Esperando firma'
    },
    SIGNING: {
        label: 'Firmando'
    },
    ERROR: {
        label: 'Ocurrió un error'
    },
    COMPLETING_SIGN: {
        label: 'Completando firma'
    },
    READY: {
        label: 'Lista'
    },
};

class DocumentSignMassiveSignForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            operationId: props.operationId,
            operationStatusData: null,
            signDocumentList: [],
            signDocumentSelected: {},
            signDocumentListStatus: {},
            message: null,
            loading: true,
            signing: false,
            signUrl: null,
            redirect: false
        };
        this.signDocumentSelected = this.signDocumentSelected.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    fetchData = async () => {
        let where = {
            status: SignDocumentStatus.NOTARIO.id,
        };

        if(this.props.scope) {

            const scopesFiltersMap = {
                not_transference: {
                    extern_source: 'not_transference'
                },
                transference: {
                    extern_source: 'transference',
                    process_type: SIGN_TYPE_NOT_PHYSIC,
                },
                transference_physic: {
                    process_type: SIGN_TYPE_PHYSIC,
                    extern_source: 'transference',
                }
            }
            const filters = scopesFiltersMap[this.props.scope] || {};
            where = {...where, ...filters};

        }

        const params = {
            per_page:50,
            where: JSON.stringify(where),
        };
        const signDocumentList = await BackendApiService.getFirmasDocumentosList(params);
        const signDocumentListStatus = {};
        for (const signDocument of signDocumentList.data) {
            signDocumentListStatus[signDocument.id] = SignStatus.PENDING;
        }
        let operationStatusData = null;
        if (this.state.operationId) {
            const operationsToComplete = [];
            operationStatusData = await BackendApiService.getSignOperationDocumentStatus(this.state.operationId);
            if (operationStatusData) {
                for (const documentData of Object.values(operationStatusData.documentStatus)) {
                    switch (documentData.statusData.status) {
                        case 'PENDING': {
                            signDocumentListStatus[documentData.id] = SignStatus.SIGNING;
                            break;
                        }
                        case 'SIGNED': {
                            signDocumentListStatus[documentData.id] = SignStatus.COMPLETING_SIGN;
                            operationsToComplete.push({
                                signDocumentId: documentData.id,
                                operationId: this.state.operationId
                            });
                            break;
                        }
                        default: {
                            signDocumentListStatus[documentData.id] = SignStatus.ERROR;
                            break;
                        }
                    }
                }
                if (operationsToComplete.length > 0) {
                    setTimeout(
                        () => {
                            this.processCompleteTransferSign(operationsToComplete);
                        },
                        500
                    );
                }
            }
            else {
                operationStatusData = -1;
            }
        }
        this.setState({loading: false, signDocumentList: signDocumentList.data, signDocumentListStatus, operationStatusData});
    };

    async processCompleteTransferSign(operationsToComplete) {
        this.setState({signing: true, message: null});
        let countOk = 0;
        for (const operation of operationsToComplete) {
            const completeResult = await BackendApiService.completeSignDocument(operation.signDocumentId, operation.operationId);
            if (completeResult) {
                countOk++;
                this.setSignDocumentStatus(operation.signDocumentId, SignStatus.READY);
            }
            else {
                this.setSignDocumentStatus(operation.signDocumentId, SignStatus.ERROR);
            }
        }
        let message = null;
        if (countOk === operationsToComplete.length) {
            this.setState({redirect: true});
            await BackendApiService.destroySignOperation(this.state.operationId);
            message = {
                color: 'success',
                text: 'Documentos firmados OK.'
            };
        }
        else {
            message = {
                color: 'warning',
                text: 'Documentos firmados parcialmente. revise el estado de los documentos.'
            };
        }
        this.setState({signing: false, message});
        return true;
    }

    signDocumentSelected(e, item) {
        const stateToChange = Object.assign({}, this.state.signDocumentSelected);
        if (e.target.checked) {
            stateToChange[item.id] = item;
        } else {
            delete stateToChange[item.id];
        }
        this.setState({signDocumentSelected: stateToChange});
    }

    handlerClearSelected = (e) => {
        e.preventDefault();
        this.setState({signDocumentSelected: {}});
    };

    handlerSelectedAll = (e) => {
        const signDocumentSelected = {};
        if (e.target.checked) {
            for (const signDocument of this.state.signDocumentList) {
                signDocumentSelected[signDocument.id] = signDocument;
            }
        }
        this.setState({signDocumentSelected});
    };

    setSignDocumentStatus(signDocumentId, status) {
        const signDocumentListStatus = this.state.signDocumentListStatus;
        signDocumentListStatus[signDocumentId] = status;
        this.setState({signDocumentListStatus});
    }

    async handleSignButtonClick() {
        const signDocumentListStatus = {};
        for (const signDocument of this.state.signDocumentList) {
            signDocumentListStatus[signDocument.id] = SignStatus.PENDING;
        }
        let message = null;
        this.setState({signing: true, signDocumentListStatus, message});
        try {
            const documentDataList = [];
            const signDocumentValues = Object.values(this.state.signDocumentSelected);
            signDocumentValues.forEach(signDocumentData=>{
                this.setSignDocumentStatus(signDocumentData.id, SignStatus.PREPARING_SIGN);
            })

            const requestSignPromises = signDocumentValues.map(signDocumentData=>{
                return BackendApiService.requestSignDataForDocument(signDocumentData.id)
            });

            const requestSignResult = await Promise.allSettled(requestSignPromises);
            requestSignResult.forEach((signDataResponse, index)=>{
                console.log(signDataResponse);
                const signDocumentData = signDocumentValues[index];
                if(signDataResponse.status === 'fulfilled') {
                    const signData = signDataResponse.value;
                    if (signData
                      && signData.fileHeadData
                      && signData.fileHeadData.Metadata
                      && signData.fileHeadData.Metadata.sha1
                      && signData.ticketData) {
                        documentDataList.push(signData);
                        this.setSignDocumentStatus(signDocumentData.id, SignStatus.READY_FOR_SIGN);
                    }
                    else {
                        console.error(signDocumentData.id, signData);
                        this.setSignDocumentStatus(signDocumentData.id, SignStatus.ERROR_PREPARING_SIGN);
                    }
                }else{
                    console.error(signDocumentData.id, signDataResponse.reason);
                    this.setSignDocumentStatus(signDocumentData.id, SignStatus.ERROR_PREPARING_SIGN);
                }
            })
            if (documentDataList.length > 0) {
                const BASE_URL = `${window.location.protocol}//${window.location.host}/`;
                const urlCallback = `${BASE_URL}firma-documentos/firma-masiva-estado`;
                const signOperation = await BackendApiService.requestNewSignDocumentOperation(documentDataList, urlCallback, urlCallback);
                if (signOperation) {
                    return this.setState({signing: false, signUrl: signOperation.linkOperation});
                }
                else {
                    message = {
                        color: 'danger',
                        text: 'Error generando firma.'
                    };
                }
            }
            else {
                message = {
                    color: 'warning',
                    text: 'No hay documentos para firmar.'
                };
            }
        }
        catch (error) {
            message = {
                color: 'danger',
                text: 'Error generando firma.'
            };
            console.error(error);
        }
        this.setState({signing: false, message});
    }

    render() {
        if (this.state.signUrl) {
            return (<Redirect to={{
                pathname: '/redireccionando',
                state: {to: this.state.signUrl}
            }}/>);
        }
        if (this.state.loading) {
            return (
                <div className="loading-overlay">
                    <LoadingOverlay
                        className="loading"
                        active={true}
                        spinner
                        fadeSpeed={100}
                        text='Cargando listado de documentos...'
                        />
                </div>
            );
        }
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/firma-documentos",
                state: {tab: 'signed'}
            }} />
        }
        let {signDocumentList} = this.state;
        if (this.state.operationStatusData === -1) {
            return (<div>Operacion de firma invalida</div>);
        }
        if (this.state.operationStatusData) {
            signDocumentList = signDocumentList.filter(document => this.state.operationStatusData.documentStatus.hasOwnProperty(document.id));
        }
        const signDocumentMap = signDocumentList.map((signDocument, index) => {
            const selected = this.state.signDocumentSelected.hasOwnProperty(signDocument.id);
            const background = index % 2 === 0 ? 'background' : '';
            const marked = selected ? 'background-selected' : '';
            return <Row key={index} className={`data-row field-row ml-0 ${background} ${marked}`}>
                <Col sm={1}>
                    <Input type="checkbox"
                           className="massive-checkbox form-control-sm"
                           disabled={this.state.signing}
                           checked={selected}
                           value={signDocument.id}
                           onChange={(e) => this.signDocumentSelected(e, signDocument)}
                    />
                </Col>
                <Col sm={2}>
                    <span className="bold">{signDocument.id}</span>
                </Col>
                <Col sm={3}>
                    <span className="transference-status transference-status-pendiente">{this.state.signDocumentListStatus[signDocument.id].label}</span>
                </Col>
            </Row>
        });

        return (
            <div>
                {this.state.message ?
                    <UncontrolledAlert color={this.state.message.color}>
                        {this.state.message.text}
                    </UncontrolledAlert>
                    :<div/>}
                <Form className="massive-view transference-massive-sign">
                    <Row className="field-headers field-row ml-0">
                        <Col sm={1}>
                            <div className="massive-checkbox">
                                <Input type="checkbox"
                                    className="form-control-sm"
                                    disabled={this.state.signing}
                                    checked={this.state.signDocumentList.length === Object.keys(this.state.signDocumentSelected).length}
                                    onChange={this.handlerSelectedAll}
                                />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <span className="bold">ID</span>
                        </Col>
                        <Col sm={3}>
                            <span className="bold">Estado</span>
                        </Col>
                    </Row>
                    {signDocumentMap}
                    {this.state.operationId ? <div />:
                        <Row className="botton-row">
                            <Col sm={3}>
                                <Button
                                    disabled={this.state.signing}
                                    className="btn btn-sm btn-primary form-check-input mt-0"
                                    onClick={this.handlerClearSelected}>
                                    Borrar seleccionados
                                </Button>
                            </Col>
                            <Col sm={9} className="text-right">
                                {this.state.signing ? '':
                                    <Link
                                        disabled={this.state.signing}
                                        className="btn btn-sm btn-primary mr-2"
                                        to="/transferencias">
                                        Cancelar
                                    </Link>
                                }
                                <Button
                                    disabled={this.state.signing}
                                    className="btn btn-sm  btn-primary-darker"
                                    onClick={() => {this.handleSignButtonClick()}}>
                                    Firmar documentos seleccionados
                                </Button>
                            </Col>
                        </Row>
                    }
                </Form>

            </div>
        );
    }
}

export default DocumentSignMassiveSignForm;
