import React, {Component} from 'react';
import DataTable from '../data-table/DataTable';
import moment from 'moment-timezone';
import {formats} from "../../config";
import {Link} from 'react-router-dom';
import BackendApiService from "../../services/BackendApiService";
import {TransferenceStatus} from '@autofact/operation-lib-model-notary-platform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import TransferenceRepertoryModal from '../repertory-modal/TransferenceRepertoryModal';
import contractHeaderSettings from '../../helpers/contractHeaderSettings';

class WaitingContractList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();

        this.state = {
            waitingContractList : [],
            repertoryModalOpen: false,
            loadingRepertory: false,
            currentRepertory: '',
            currentCode: ''
        }
        this.fetchData = this.fetchData.bind(this);
        this.setCurrentRepertory  = this.setCurrentRepertory.bind(this);
        this.openRepertoryModal = this.openRepertoryModal.bind(this);
        this.closeRepertoryModal = this.closeRepertoryModal.bind(this);
        this.assignRepertory = this.assignRepertory.bind(this);
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    async fetchData(paginate){

        const today = moment().format('MM-DD-YYYY');
        const filters =  {
            SPECIFIC_STATUS: [
                TransferenceStatus.CLIENT_SIGNATURE.id,
                TransferenceStatus.TAKE_APPROVAL.id,
                TransferenceStatus.STATUS_TAX_IN_PROCESS.id,
                TransferenceStatus.TAX.id,
                TransferenceStatus.TAX_PAYED.id,
                TransferenceStatus.REPERTORY.id,
                TransferenceStatus.PACKING.id,
                TransferenceStatus.NOTARY.id,
                TransferenceStatus.INGRESADO_STEV.id
            ],
            TRANSFERENCE_DATE_FROM: today,
            TRANSFERENCE_DATE_TO: today,
            IS_FROM_NOTARY: true,
        };
        const params = { where: JSON.stringify(filters), ...paginate};
        const waitingContractList = await BackendApiService.getTransferenceList(params);
        this.setState({params, waitingContractList});
        return waitingContractList;
    }

    setCurrentRepertory(e){
        const repertorio = e.target.value
        this.setState({currentRepertory: repertorio});
    }

    openRepertoryModal(code, repertory){
        this.setState({currentRepertory: repertory || ''})
        this.setState({currentCode: code});
        this.setState({repertoryModalOpen: true});
    }

    closeRepertoryModal(){
        this.setState({repertoryModalOpen: false});
    }

    assignRepertory = async () => {
        const repertory = this.state.currentRepertory;
        const codigo = this.state.currentCode;
        try{
          this.setState({ loadingRepertory: true});
          const response = await BackendApiService.assignSimpleRepertory(codigo, repertory);
          if(response.countFail !== 0){
            this.setState({ loadingRepertory: false});
            alert('Error asignando repertorio');
          }else{
            this.setState({ loadingRepertory: false});
            this.closeRepertoryModal();
          }
          this.reloadData();
        }catch(error){
            this.setState({ loadingRepertory: false});
            alert('Error asignando repertorio');
          console.error(error);
        }
      }

    render() {
        const Actions = ( { row } ) => {
            const status =  row.estado;
            const code =  row.codigo;
            const repertory = row.numero_repertorio;
            return (
                <>
                    {(status === TransferenceStatus.REPERTORY.id && !repertory) &&
                        <button className="btn btn-primary-dark btn-sm py-0 font-sm mr-3 bold" onClick={()=>this.openRepertoryModal(code, repertory)}>Asignar repertorio</button>
                    }
                    <Link
                    className="text-primary-dark bolder py-0 h3"
                    to={`notaria/ver/${row.codigo}`}>
                        Ver contrato
                    </Link>
                </>
            )
        }
        const columns = [
            {
                Header: 'Patente',
                accessor: 'patente',
                width: 100
            },
            {
                Header: 'Marca/Modelo/Año',
                accessor: 'description',
                minWidth: 100,
                Cell: row => {
                    const r = row.original;
                    return (<p><b> {r.marca} - </b> {r.modelo} - <b> {r.year} </b></p>)
                }
            },
            {
                Header: 'Repertorio',
                accessor: 'numero_repertorio',
                width: 100
            },
            {
                Header: 'Fecha creación',
                accessor: 'timestamp',
                width: 150,
                Cell: row => {
                    let date = moment(row.original.timestamp);
                    return date.utc().format(formats.date);
                }
            },
            {
                Header: 'Estado',
                accessor: 'status',
                headerClassName: 'text-center',
                Cell: row => {
                    const status = row.original.estado;
                    let headerSetting;
                    if([6,27,9,11,20].includes(status)){
                        headerSetting = contractHeaderSettings(27);
                    }else{
                        headerSetting = contractHeaderSettings(status);
                    }
                    return(
                       <div className={`text-center ${headerSetting.cssClass}`}>
                            <FontAwesomeIcon icon='exclamation-circle' className='mr-2'/>
                            {headerSetting.label}
                            { headerSetting.cssClass === 'tag tag-tax' && <CircularProgress className='ml-3' size={13} />}
                        </div>
                    )
                }
            },
            {
                Header: 'Acciones',
                accessor: 'actions',
                width: 320,
                Cell: row => (
                   <Actions row={row.original} />
                )
            },
        ];
        return (
            <>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                    fetchDataCallback={this.fetchData}
                />
                <TransferenceRepertoryModal
                    open={this.state.repertoryModalOpen}
                    title={"ASIGNAR REPERTORIO"}
                    bodyText={"Ingresa el número de repertorio para este contrato"}
                    cancelHandler={this.closeRepertoryModal}
                    confirmHandler={this.assignRepertory}
                    inProcess={this.state.loadingRepertory}
                    repertory={this.state.currentRepertory}
                    handlerChange={this.setCurrentRepertory}
                    className={"repertory-modal"}
                />
            </>

        );
    }
}

export default WaitingContractList;
