import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, FormGroup, Label, Input, Col, CustomInput, FormText, FormFeedback } from 'reactstrap';
import SubtitleDivider from '../subtitle-divider/SubtitleDivider';
import rutHelper from '../../helpers/rutHelper';
import BackendApiService from '../../services/BackendApiService';
import { CircularProgress } from "@material-ui/core"
import FileHelper from '../../helpers/FileHelper';
import AttachFile from '@material-ui/icons/AttachFile';
import { PatternFormat, NumericFormat } from 'react-number-format'
import {TransferenceDocumentType} from '@autofact/operation-lib-model-notary-platform';

const {REPRESENTED_BUYER_IDENTITY_CARD, REPRESENTED_SELLER_IDENTITY_CARD, SELLER_IDENTITY_CARD, BUYER_IDENTITY_CARD} = TransferenceDocumentType;

const NotaryContractNaturalPersonForm = ({ person, type, number, regions, updatePerson, uploadFile, isContractUpdate, hasRepresentatives }) => {
    const personType = type || 'vendedor';
    const subtype = 'persona';
    //const docTypeId = type === 'vendedor' ? REPRESENTED_SELLER_IDENTITY_CARD.id : REPRESENTED_BUYER_IDENTITY_CARD.id;

    const [communes, setCommunes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [identityFile, setIdentityFile] = useState({})
    const [fileLoading, setFileLoading] = useState(false);
    const [tempFile, setTempFile] = useState(' ');
    const [invalidFile, setInvalidFile] = useState(false);
    const [countries, setCountries] = useState([]);
    const [docTypeId, setDocTypeId] = useState();

    useEffect(()=>{

        const chooseDocType = () => {
            if (personType === 'vendedor' && hasRepresentatives) {
                setDocTypeId(REPRESENTED_SELLER_IDENTITY_CARD.id);
            } else if (personType === 'vendedor' && !hasRepresentatives) {
                setDocTypeId(SELLER_IDENTITY_CARD.id);
            } else if (personType === 'comprador' && hasRepresentatives) {
                setDocTypeId(REPRESENTED_BUYER_IDENTITY_CARD.id);
            } else if (personType === 'comprador' && !hasRepresentatives) {
                setDocTypeId(BUYER_IDENTITY_CARD.id);
            }
        }

        const personData = async() =>{
            let persona = person;
            if (!persona.name) persona.name = person.fullName;
            if(persona.address.regionId){
                loadCommunes(persona.address.regionId);
            }

            if(persona.phone && persona.phone.toString().startsWith('56')) {
                persona.phone = persona.phone.toString().slice(2);
            };

            updatePerson(persona, type, subtype, number);
            setLoading(false);
        }
        const personIdentity = ()=>{
            let identity = person?.documents ? person.documents[0]: null;
            identity = identity ?? {
                name: '',
                typeId: docTypeId,
                url: '',
                identityCode: person.identityCode
            };
            setIdentityFile(identity);
        };

        const countriesData = async() =>{
            const response = await BackendApiService.getAllCountries();
            setCountries(response);
        }
        countriesData();
        chooseDocType();
        personData();
        personIdentity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        const communeData = async() =>{
            const response = await BackendApiService.getCommuneListByRegion(person.address?.regionId);
            setCommunes(response);
        }
        communeData();
    }, [person.address?.regionId]);

    const loadCommunes = async(regionId) => {
        const data = await BackendApiService.getCommuneListByRegion(regionId);
        setCommunes(data);
    }

    const updateForm = el => {
        const newPersona = person;
        let index = el.id.split('-')[0];
        if(index.substring(0,7) === "address"){
            const subIndex = index.split('.')[1];
            const address = newPersona.address;
            address[subIndex] = el.value;
        } else{
            newPersona[index] = el.value;
        }

        if(index === "name" || index === "fatherLastName" || index === "motherLastName"){
            if(index === "name") {
                newPersona.fullName = `${el.value} ${newPersona.fatherLastName} ${newPersona.motherLastName}`;
            }
            if(index === "fatherLastName") {
                newPersona.fullName = `${newPersona.name} ${el.value} ${newPersona.motherLastName}`;
            }
            if(index === "motherLastName") {
                newPersona.fullName = `${newPersona.name} ${newPersona.fatherLastName} ${el.value}`;
            }
        }

        updatePerson(newPersona, type, subtype, number);
    };

    const uploadIdentityFile = async(el) => {
        const files = el.files;
        const file = files[0] || null;
        if(file?.type && FileHelper.allowedExtensions(file.type)){
            setInvalidFile(false);
            setTempFile(file?.name);
            setFileLoading(true);
            let url = '';
            let fileName = '';
            url = await uploadFile(file, `cedula-${type}`);
            fileName = FileHelper.getFileNameFromUrl(url);
            const identity = {
                name: fileName,
                typeId: docTypeId,
                url,
                identityCode: person.identityCode
            };
            setIdentityFile(identity);
            const newPersona = person;
            if(newPersona.documents === undefined) newPersona.documents = [];
            const index = newPersona?.documents?.findIndex( element => element.typeId === docTypeId);
            if(index !== -1){
                newPersona.documents[index] = identity;
            }else{
                newPersona.documents.push(identity);
            }
            updatePerson(newPersona, type, subtype, number);
            setFileLoading(false);
        }else{
            setInvalidFile(true)
        }
    }

    const removeFile = () =>{

        const newPersona = person;
        const newIdentity = {
            name: '',
            typeId: docTypeId,
            url: '',
            identityCode: person.identityCode
        };
        setIdentityFile(newIdentity);
        setTempFile(' ');
        newPersona.documents = []
        updatePerson(newPersona, type, subtype, number);
    }

    const validNationality = countries?.some((el) => el.iso_alfa_3 === person.nationality);

    return(
        <>
            <div className=' d-flex align-items-center subtitle-box-step-validation px-2 mb-2'>
                <div className='d-flex justify-content-center'>
                    <span className='font-weight-bold my-1'><FontAwesomeIcon icon={'user'} size='lg' color='gray' /></span>
                </div>
                <span className='h2 dark-color font-weight-bold ml-2 mb-0 text-capitalize'>{ personType } {number + 1}</span>
                { loading && <i className='ml-auto pt-2 pr-2'><CircularProgress size={20}/></i>}
            </div>
            <SubtitleDivider>Información personal del { personType }</SubtitleDivider>
            { !loading &&
                <>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`identityCode-${person.identityCode}`}>Rut del { personType }</Label>
                            <Input type="text" id={`identityCode-${person.identityCode}`} className="bg-white"
                                value={ rutHelper.toDotedRut(person.identityCode) }
                                onChange={(e)=> updateForm(e.target)}
                                disabled={ (personType === "vendedor" ? true : false) || (personType === "comprador" && isContractUpdate) }
                                invalid={!rutHelper.validateRut(person.identityCode)}
                                required
                            />
                            <FormFeedback>Ingrese un RUT válido</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`power-${person.identityCode}`}>Fotocopia cédula {type} (opcional)</Label>
                            {identityFile.url ?
                                <div className='d-flex flex-row align-items-center'>
                                    <a id="car-permlink" className="link-block" target="_blank" rel="noreferrer" href={identityFile.url}>
                                        <AttachFile />
                                        { FileHelper.getFileNameFromUrl(identityFile.url)}
                                    </a>
                                    <FontAwesomeIcon icon="trash" color='red' className='pointer ml-2' onClick={ () => removeFile()}/>
                                </div>
                            :
                                <>
                                    <div className="d-flex">
                                        <CustomInput type="file" name="file" id={`sii-${person?.identityCode}`} className="bg-white mr-2" label={tempFile} accept=".png, .jpg, .pdf" onChange={ e => uploadIdentityFile(e.target)} invalid={invalidFile}/>
                                        { fileLoading && <i className='ml-auto pt-2 pr-2'><CircularProgress size={20}/></i>}
                                    </div>
                                    <FormText> {invalidFile && 'Seleccione uno de los'} Formatos permitidos: JPG, PNG, PDF</FormText>
                                </>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for={`name-${person.identityCode}`}>Nombres del { personType }</Label>
                            { number === 0 ?
                                <Input type="text" id={`name-${person.identityCode}`} className="bg-white" value={person.name} onChange={(e)=> updateForm(e.target)} required  disabled={ personType === "vendedor" && isContractUpdate }/>
                            :
                                <Input type="text" id={`name-${person.identityCode}`} className="bg-white" value={person.name} onChange={(e)=> updateForm(e.target)} required disabled={ personType === "vendedor" && isContractUpdate }/>
                            }
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for={`fatherLastName-${person.identityCode}`}>Apellido paterno del { personType }</Label>
                            <Input type="text" id={`fatherLastName-${person.identityCode}`} className="bg-white" value={person.fatherLastName } onChange={(e)=> updateForm(e.target)} required disabled={ personType === "vendedor" && isContractUpdate }/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for={`motherLastName-${person.identityCode}`}>Apellido materno del { personType }</Label>
                            <Input type="text" id={`motherLastName-${person.identityCode}`} className="bg-white" value={person.motherLastName } onChange={(e)=> updateForm(e.target)} required disabled={ personType === "vendedor" && isContractUpdate }/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`nationality-${person.identityCode}`}>Nacionalidad</Label>
                            <Input type="select" id={`nationality-${person.identityCode}`} className="bg-white" value={validNationality? person.nationality : ''} onChange={(e)=> updateForm(e.target)} required>
                                <option value=''disabled>seleccione un país</option>
                                {countries?.map((el, key) =>
                                    <option value={el.iso_alfa_3} key={key}>{el.nombre}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`sex-${person.identityCode}`}>Sexo</Label>
                            <Input type="select" id={`sex-${person.identityCode}`} className="bg-white" value={person.sex } onChange={(e)=> updateForm(e.target)} required>
                                <option value='' disabled>Seleccione una opción</option>
                                <option value='F'>Femenino</option>
                                <option value='M'>Masculino</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`maritalStatus-${person.identityCode}`}>Estado Civil</Label>
                            <Input type="text" id={`maritalStatus-${person.identityCode}`} className="bg-white" value={person.maritalStatus } onChange={(e)=> updateForm(e.target)} required/>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`occupation-${person.identityCode}`}>Actividad o profesión</Label>
                            <Input type="text" id={`occupation-${person.identityCode}`} className="bg-white" value={person.occupation } onChange={(e)=> updateForm(e.target)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <SubtitleDivider>Dirección del { personType }</SubtitleDivider>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`address.regionId-${person.identityCode}`}>Región</Label>
                            <Input type="select" id={`address.regionId-${person.identityCode}`} className="bg-white" value={person.address?.regionId} onChange={(e) => updateForm(e.target)} required>
                                <option value='' disabled>seleccione una región</option>
                                {regions?.map((el, key) =>
                                    <option value={el.id} key={key}>{el.nombre}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`address.comunaId-${person.identityCode}`}>Comuna</Label>
                            <Input type="select" id={`address.comunaId-${person.identityCode}`} className="bg-white" value={person.address?.comunaId} onChange={(e)=> updateForm(e.target)} required>
                                <option value=''disabled>seleccione una comuna</option>
                                {communes?.map((el, key) =>
                                    <option value={el.idcomuna} key={key}>{el.nombre}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for={`address.street-${person.identityCode}`}>Nombre de la calle</Label>
                            <Input type="text" id={`address.street-${person.identityCode}`} className="bg-white" value={person.address?.street} onChange={(e)=> updateForm(e.target)} required/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for={`address.number-${person.identityCode}`}>Número de la calle</Label>
                            <NumericFormat allowNegative={false} id={`address.number-${person.identityCode}`} className="form-control bg-white" min="1" value={person.address?.number} onChange={(e)=> updateForm(e.target)} required/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for={`address.rest-${person.identityCode}`}>Datos adicionales de la dirección (opcional)</Label>
                            <Input type="text" id={`address.rest-${person.identityCode}`} className="bg-white" value={person.address?.rest} onChange={(e)=> updateForm(e.target)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <SubtitleDivider>Datos de contacto del { personType }</SubtitleDivider>
                <Row>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`email-${person.identityCode}`}>Correo electrónico</Label>
                            <Input type="email" id={`email-${person.identityCode}`} className="bg-white" value={person?.email} onChange={(e)=> updateForm(e.target)} required/>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <FormGroup>
                            <Label for={`phone-${person.identityCode}`}>Teléfono</Label>
                            <PatternFormat pattern='\D([+56]\d[2-9])(\d{4})(\d{4})\D*' format="+56#########" allowEmptyFormatting  type="phone" title='número de teléfono' id={`phone-${person.identityCode}`} className="form-control bg-white" value={person?.phone} onChange={(e)=> updateForm(e.target)} required/>
                        </FormGroup>
                    </Col>
                </Row>
                </>
            }
        </>
    )
}

export default NotaryContractNaturalPersonForm;
