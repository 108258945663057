
class PeopleHelper {

  static filterNoEmptyObjectData(object){
      try {
        const filteredObject = Object.keys(object).reduce((filteredData, key) => {
          if (object[key] !== "") {
            filteredData[key] = object[key];
          }
          return filteredData;
        }, {});

        return filteredObject;
      } catch (error) {
          return object;
      }

  }

}

export default PeopleHelper;