import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormGroup, Input, Label, Button, Row, FormText } from 'reactstrap'
import rutHelper from '../../../helpers/rutHelper';

const ClientsRut = ({
  ruts,
  updateRuts,
  updateBuyerIsCompany,
  buyerCompany
}) => {

  const [isCompany, setIsCompany] = useState(buyerCompany);

  const addNewRut = () => {
    updateRuts([...ruts, { identityCode: ""}])
  }

  const changeRutValue = (index, value) => {
    const newRuts = ruts;
    const valid = rutHelper.validateRut(value);
    newRuts[index].identityCode = value;
    newRuts[index].valid = valid;
    updateRuts(ruts);
  }

  const deleteRut = () => {
    let newRuts = ruts;
    newRuts.pop();
    updateRuts(newRuts);
  }

  const changeIsCompany = () => {
    setIsCompany(!isCompany);
    updateBuyerIsCompany(!isCompany)
    !isCompany && updateRuts([{ identityCode: ""}])
  }

  return (
    <div className='mb-5'>
      <div className=' d-flex align-items-center title-box-step-validation px-2 mb-3'>
        <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
          <span className='font-weight-bold my-1'>2</span>
        </div>
        <span className='h2 dark-color font-weight-bolder ml-2 mb-0'>Consulta sobre deuda de pensión (Compradores/personas naturales)</span>
      </div>
      <div className='d-flex flex-column mb-3'>
        <p className='h4 dark-color font-weight-bolder mb-4'>Si es empresa marca esta opción</p>
        <FormGroup check className="mb-2">
          <Label check>
            <Input type="checkbox" checked={isCompany} onChange={ ()=> changeIsCompany() }/>
            Empresa (Recuerda que si es empresa no debes consultar pensión)
          </Label>
      </FormGroup>
      </div>
      {ruts.map((item, index, arrRuts) => {
        return(
          <Row key={`row-rut-${index}`}>
            <FormGroup className="col-lg-3 col-md-4 col-sm-12" >
              <Label>{`Ingresa rut de comprador ${index!== 0? index+1 : ''} *`}</Label>
              <Input 
                type="input"
                name="inputRut" 
                className="form-control rut-imput"
                minLength="8" maxLength="9" required
                onChange={(e) => changeRutValue(index, e.target.value)}
                value={item.identityCode}
                pattern="[0-9kK]{0,9}"
                invalid={ !item.valid && !isCompany }
                disabled={isCompany}
              />
              <FormText color="muted">
                Ingresa rut sin puntos ni guión
              </FormText>
            </FormGroup>
            {
              index === arrRuts.length-1 &&
              <>
                {
                  arrRuts.length > 1  &&
                  <FormGroup className="col-auto my-auto">
                    <Button className="btn btn-outline-danger border-0 font-weight-bolder" onClick={() => deleteRut()} disabled={isCompany}> <FontAwesomeIcon icon={"trash"} /> Eliminar </Button>
                  </FormGroup>
                }
                <FormGroup className="col-auto my-auto">
                  <Button className="btn btn-outline-primary-dark font-weight-bolder" onClick={() => addNewRut()} disabled={isCompany}> <FontAwesomeIcon icon={"plus"} /> Agregar otro comprador </Button>
                </FormGroup>
              </>
            }
          </Row>
      )})}
    </div>
  )
}

export default ClientsRut