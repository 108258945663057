import React, {Component} from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formats} from '../../config';

class DocumentSelector extends Component{
    render() {
        const documentsRender = [];
        this.props.documentList.filter(signDocument => signDocument.document.state === 1).forEach(signDocument => {
            const document = signDocument.document;

            const documentTitle = document.name;
            documentsRender.push(<li className={'nav-item document-item-wrapper'} key={`document-item-${document.id}`}>
                <a href={document.path} onClick={(event) => {
                    event.preventDefault();
                    if (this.props.onDocumentSelect) {
                        this.props.onDocumentSelect(document);
                    }
                }}>
                    <div className="document-icon"><FontAwesomeIcon icon="file-pdf"/></div>
                    <div className={'document-title'}>{documentTitle}</div>
                    <div className={'document-date'}>{moment(document.documentModification).format(formats.date)}</div>
                </a>
            </li>);
        });
        return (<div>
            <ul className="transference-document-list">
                {documentsRender}
            </ul>
        </div>);
    }
}

export default DocumentSelector;
