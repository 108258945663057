import React from 'react';
import { CircularProgress } from "@material-ui/core";
import {Row, Col} from 'reactstrap';

const TaxPaymentProgressNotify = () => {


    return (
        <div>
            <Row className='alert waiting-alert p-4 my-3 mx-1'>
                <Col sm={10} className='text-center'>
                    <div className='d-flex flex-column align-items-center mt-1'>
                        <span className='bold h2'> El pago de costos legales está en proceso.</span>
                        <span className='h3 mt-2'> Este proceso puede tardar entre 3 y 5 minutos...</span>
                    </div>
                </Col>
                <Col sm={2}>
                    <CircularProgress size={50} />
                </Col>
            </Row>
        </div>
    )
}

export default TaxPaymentProgressNotify;