import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EXTERNAL_PROCESS = {
    MENU: 'Inicio de proceso o revisión de estado.',
    SERVER_ERROR: 'Error de servidor.',
    LOADING: 'Obteniendo datos...',
    INITIATED: 'Proceso iniciado con éxito.',
    WAITING_REPERTORIES: 'En espera de aceptación de la solicitud de repertorios en la plataforma del proveedor.',
    REJECTED_REQUEST_REPERTORIES: 'Solicitud de repertorios rechazada.',
    FINISHED: 'Proceso finalizado.',
    MASSIVE_TRANSFER_ASSIGN_ERROR: 'Error en asignación masiva en sistema Autofact.',
    PROVIDER_ERROR: 'Error del proveedor al enviar solicitud de repertorios.',
    DEFAULT: 'Error inesperado.',
    ASSIGN_LOADING: 'Asignando repertorio sistema autofact...',
}

const ICON_PROCESS = {
    LOADING :<FontAwesomeIcon icon='spinner' className='icon' spin/>,
    WAITING_REPERTORIES: <FontAwesomeIcon icon='times-circle' className='icon'/>,
    DEFAULT: <div/>
}



class AssignMassiveRepertoryModal extends Component {

    modalLabel = () => {
        const processLabel = this.props.process || 'DEFAULT';
        return EXTERNAL_PROCESS[processLabel];
    }

    icon = () =>{ 
        const processLabel = this.props.process || 'DEFAULT';
        return ICON_PROCESS[processLabel] || ICON_PROCESS['DEFAULT'] ;
    }
      
    renderButton = () => {
                               if(this.props.process !== 'LOADING' && this.props.process !== 'MENU'){
                               return this.props.okButton
                               
                               }else if(this.props.process === 'MENU'){
                                        return this.props.menu
                               }else{
                                return <div></div>;
                               }
    }
    
    render(){ 
        return (
            <Modal isOpen={this.props.open} >
                <ModalHeader>{this.props.title}</ModalHeader>
                <ModalBody>
                    <span>{this.modalLabel()}</span>
                    <span>{this.icon()}</span>
                </ModalBody>
                <ModalFooter>
                    {this.renderButton()}
                </ModalFooter>
            </Modal>
        );
    }
}

export default AssignMassiveRepertoryModal;