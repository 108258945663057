import React, { useState } from 'react'
import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { app }from '../../../config'
import BackendApiService from '../../../services/BackendApiService';
import { CircularProgress } from "@material-ui/core";

const LegalCostsModal = ({
  isOpen,
  methods,
  data
}) => {

  const { codigo, taxValue } = data;
  const { closeThis, startTaxPayment, getTransference, openTaxPaymentModal } = methods;
  const total = Number(taxValue) + Number(app.notaryPaymentFee);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [loading, setLoading] = useState(false);

  const showSecondStep = () => {
    setIsSecondStep(true);
  };

  const payAction = async () => {
    try {
      setLoading(true);
      const response = await BackendApiService.approveTransfer(codigo);
      if(response?.status === 'SUCCESS'){
        await getTransference(codigo);
        setLoading(false);
        startTaxPayment();
        closeModalAction();
        openTaxPaymentModal();
      } else {
        throw new Error("Couldn't send transfer to approve");
      }
    } catch (error) {
      setLoading(false);
    }

  }

  const closeModalAction = () => {
    setIsSecondStep(false);
    closeThis();
  }

  return (
    <Modal isOpen={isOpen} size={'lg'} toggle={() => (loading ? null : closeModalAction())} centered>
      <div className="modal-content text-center">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Pago de costos legales</h2>
          <button type="button" className="close white-circle-close" onClick={() => closeModalAction()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    
          <div className="modal-body">
            <div className='m-4'>
              {
                isSecondStep?
                  <h1 className="text-20 orange-color">Realizarás el pago de los siguientes costos legales. </h1>
                :
                  <>
                    <span className='text-secondary bold'>Iniciarás el pago del impuesto del arancel de registro civil.</span>
                    <h1 className="text-20 orange-color">¿El cliente ya realizó el pago de honorarios e impuestos en caja?</h1>
                  </>
              }
            </div>
            <div className="alert waiting-alert mb-5" role="alert">
              <div className='row'>
                <div className='ml-auto'>
                  <FontAwesomeIcon icon='exclamation-circle' size='lg'/>
                </div>
                <div className='col-10 mr-auto'>
                  <span className='bold'>Importante:</span> El pago se realizará de manera automática y es irreversible. En caso de error este monto no podrá ser reembolsable.
                </div>
              </div>
            </div>
            { isSecondStep &&
              <div className='d-flex justify-content-center mb-4'>
                <div className='payment-resume-box'>
                  <span className='text-20 bold'>Resumen de pagos</span>
                  <div className='d-flex w-100 justify-content-between text-18'>
                    <span>Impuesto compraventa:</span>
                    <span>{`$${Intl.NumberFormat('es-ES').format(taxValue)}`}</span>
                  </div>
                  <div className='d-flex w-100 justify-content-between text-18'>
                    <span>Arancel Registro Civil:</span>
                    <span>{`$${Intl.NumberFormat('es-ES').format(app.notaryPaymentFee)}`}</span>
                  </div>
                  <div className='d-flex w-100 justify-content-between text-20 bold'>
                    <span>Total:</span>
                    <span>{`$${Intl.NumberFormat('es-ES').format(total)}`}</span>
                  </div>
                </div>

              </div>
            }
          </div>
        <div className="modal-footer justify-content-center mb-2">
          <button className="btn btn-lg btn-outline-primary-dark px-5" onClick={() => closeModalAction()} disabled={loading} >{isSecondStep? 'CANCELAR' : 'AÚN NO, CANCELAR'}</button>
          {
            isSecondStep ?
              <button className="btn btn-primary-dark" onClick={() => payAction()} disabled={loading}>{loading? <>PAGANDO COSTOS LEGALES...<CircularProgress className='ml-3 text-white' size={18} /></> : 'PAGAR COSTOS LEGALES'}</button>
            :
              <button className="btn btn-primary-dark" onClick={() => showSecondStep()} disabled={loading} > {loading? "INGRESANDO A PAGO DE IMPUESTOS" : "SI, PUEDO CONTINUAR A PAGO DE IMPUESTOS"} </button>

          }
        </div>
      </div>
    </Modal>
  )
}

export default LegalCostsModal