import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

const Activity = ({transferenceActivity}) => {

  const concatTextNodes = (node, index) => {
    if (node.type === 'text') {
      return convertNodeToElement(node, index, (textNode) => {
        return <React.Fragment key={index}>{textNode.data} </React.Fragment>
      })
    }
  }
  

  return (
    <div className='documents-pannel'>
     {
       transferenceActivity.map(activity => {
        return (
          <div className='d-flex flex-column align-items-start mb-2' key={`document-item-${activity.idtransferencia_activity}`}>
            <span className='text-16 bold'><FontAwesomeIcon icon={'flag'} /> {activity.titulo || 'Actividad no especificada'} </span>
            <span className='text-11 mb-2'> {activity.timestamp } </span>
            <span className='text-14'>{ReactHtmlParser(activity.descripcion || 'Sin descripción', { transform: concatTextNodes })}</span>
          </div>
        )
       })  
     }
    </div>
  )
}

export default Activity