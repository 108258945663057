import { useEffect, useState } from 'react'
import { Alert, Row, Col} from 'reactstrap'
import { CircularProgress} from "@material-ui/core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AlertCAVRequest = ({
  success
}) => {

  const [keepOpen, setKeepOpen] = useState(true);

  useEffect(() => {
    if(success){
      setTimeout(() => {
        setKeepOpen(false);
      }, 5000);
    }
  }, [success])

  return (
    <Alert  className={ `text-center text-dark ${success? "success": "waiting"}-alert w-100`} isOpen={keepOpen}>
      {
        success ?
          <div>
            <FontAwesomeIcon icon={"check-circle"}/>
            <span className='font-weight-bold ml-3'>El certificado de anotaciones vigentes (CAV) está listo. Puedes continuar con el proceso.</span>
          </div>
        :
          <Row className={"justify-content-end"}>
            <Col md={8}>
              <p>
                <span className='font-weight-bolder'> El Certificado de Anotaciones Vigentes (CAV) se está generando. </span> <br/>
                <span className='font-weight-bold'> Espera unos minutos... </span><br/>
                <span className='font-weight-normal'> Mientras puedes avanzar ingresado los datos a continuación. </span>
              </p>
            </Col>
            <Col md={2} className="my-auto">
              <CircularProgress size={50} className='text-primary-dark' />
            </Col>
          </Row>
      }
    </Alert>
  )
}

export default AlertCAVRequest