import React, { Component } from 'react';
import { Row, Col, Input, Form, Button, UncontrolledAlert } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BackendApiService from "../../services/BackendApiService";
import { TransferenceStatus } from '@autofact/operation-lib-model-notary-platform';

const SignStatus = {
    PENDING: {
        label: 'Pendiente'
    },
    PREPARING_SIGN: {
        label: 'Preparando firma'
    },
    ERROR_PREPARING_SIGN: {
        label: 'Error Preparando firma'
    },
    READY_FOR_SIGN: {
        label: 'Esperando firma'
    },
    SIGNING: {
        label: 'Firmando'
    },
    ERROR: {
        label: 'Ocurrió un error'
    },
    COMPLETING_SIGN: {
        label: 'Completando firma'
    },
    READY: {
        label: 'Lista'
    },
};

class TransferenceSignatureMassiveRepresentationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            operationId: props.operationId,
            operationStatusData: null,
            transferenceList: [],
            transferenceSelected: {},
            transferenceListStatus: {},
            message: null,
            loading: true,
            signing: false,
            signUrl: null,
            redirect: false
        };
        this.transferenceSelected = this.transferenceSelected.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    fetchData = async () => {
        const params = {
            where: JSON.stringify({
                STATUS: TransferenceStatus.STATUS_ADVANCED_SIGNATURE_REPRESENTATIVE.id,
            }),
            per_page: -1
        };
        const transferenceList = await BackendApiService.getTransferenceList(params);
        console.log('transferenceList', transferenceList);
        const transferenceListStatus = {};
        for (const transference of transferenceList.data) {
            transferenceListStatus[transference.idtransferencia] = SignStatus.PENDING;
        }
        let operationStatusData = null;
        console.log('this.state.operationId', this.state.operationId);
        if (this.state.operationId) {
            const operationsToComplete = [];
            operationStatusData = await BackendApiService.getSignOperationStatus(this.state.operationId);
            console.log('operationStatusData', operationStatusData);
            if (operationStatusData) {
                for (const transferenceData of Object.values(operationStatusData.transferenceStatus)) {
                    switch (transferenceData.statusData.status) {
                        case 'PENDING': {
                            transferenceListStatus[transferenceData.transferenceId] = SignStatus.SIGNING;
                            break;
                        }
                        case 'SIGNED': {
                            transferenceListStatus[transferenceData.transferenceId] = SignStatus.COMPLETING_SIGN;
                            operationsToComplete.push({
                                transferenceId: transferenceData.transferenceId,
                                transferenceCode: transferenceData.transferenceCode,
                                operationId: this.state.operationId
                            });
                            break;
                        }
                        default: {
                            transferenceListStatus[transferenceData.transferenceId] = SignStatus.ERROR;
                            break;
                        }
                    }
                }
                if (operationsToComplete.length > 0) {
                    setTimeout(
                        () => {
                            this.processCompleteTransferSign(operationsToComplete);
                        },
                        500
                    );
                }
            }
            else {
                operationStatusData = -1;
            }
        }
        this.setState({ loading: false, transferenceList: transferenceList.data, transferenceListStatus, operationStatusData });
    };

    async processCompleteTransferSign(operationsToComplete) {
        this.setState({ signing: true, message: null });
        let countOk = 0;
        for (const operation of operationsToComplete) {
            const completeResult = await BackendApiService.completeSignTransference(operation.transferenceCode, operation.operationId, true);
            if (completeResult) {
                countOk++;
                this.setTransferenceStatus(operation.transferenceId, SignStatus.READY);
            }
            else {
                this.setTransferenceStatus(operation.transferenceId, SignStatus.ERROR);
            }
        }
        let message = null;
        if (countOk === operationsToComplete.length) {
            this.setState({redirect: true});
            await BackendApiService.destroySignOperation(this.state.operationId);
            message = {
                color: 'success',
                text: 'Documentos firmados OK.'
            };
        }
        else {
            message = {
                color: 'warning',
                text: 'Documentos firmados parcialmente. revise el estado de los documentos.'
            };
        }
        this.setState({ signing: false, message });
        return true;
    }

    transferenceSelected(e, item) {
        const stateToChange = Object.assign({}, this.state.transferenceSelected);
        if (e.target.checked) {
            stateToChange[e.target.value] = item;
        } else {
            delete stateToChange[e.target.value];
        }
        this.setState({ transferenceSelected: stateToChange });
    }

    handlerClearSelected = (e) => {
        e.preventDefault();
        this.setState({ transferenceSelected: {} });
    };

    handlerSelectedAll = (e) => {
        const transferenceSelected = {};
        if (e.target.checked) {
            for (const transfer of this.state.transferenceList) {
                transferenceSelected[transfer.codigo] = true;
            }
        }
        this.setState({ transferenceSelected });
    };

    setTransferenceStatus(transferenceId, status) {
        const transferenceListStatus = this.state.transferenceListStatus;
        transferenceListStatus[transferenceId] = status;
        this.setState({ transferenceListStatus });
    }

    async handleSignButtonClick() {
        const transferenceListStatus = {};
        for (const transference of this.state.transferenceList) {
            transferenceListStatus[transference.idtransferencia] = SignStatus.PENDING;
        }
        let message = null;
        this.setState({ signing: true, transferenceListStatus, message });
        try {
            const documentDataList = [];
            for (const transferenceCode of Object.keys(this.state.transferenceSelected)) {
                const transferenceData = this.state.transferenceList.find(transference => transference.codigo === transferenceCode);
                try {
                    this.setTransferenceStatus(transferenceData.idtransferencia, SignStatus.PREPARING_SIGN);
                    const signData = await BackendApiService.requestSignDataForTransference(transferenceCode, true);
                    console.log('signData', signData);
                    if (signData
                        && signData.fileHeadData
                        && signData.fileHeadData.Metadata
                        && signData.fileHeadData.Metadata.sha1
                        && signData.fileHeadData.Metadata.pages
                        && signData.ticketData) {
                        documentDataList.push(signData);
                        this.setTransferenceStatus(transferenceData.idtransferencia, SignStatus.READY_FOR_SIGN);
                    }
                    else {
                        console.error(transferenceCode, signData);
                        this.setTransferenceStatus(transferenceData.idtransferencia, SignStatus.ERROR_PREPARING_SIGN);
                    }
                } catch (error) {
                    this.setTransferenceStatus(transferenceData.idtransferencia, SignStatus.ERROR_PREPARING_SIGN);
                }
            }

            console.log('documentDataList', documentDataList);
            if (documentDataList.length > 0) {
                const BASE_URL = `${window.location.protocol}//${window.location.host}/`;
                const urlCallback = `${BASE_URL}transferencias/firma-masiva-representacion-estado`;
                const isRepresentativeSignature = true;
                const signOperation = await BackendApiService.requestNewSignOperation(documentDataList, urlCallback, urlCallback, isRepresentativeSignature);
                if (signOperation) {
                    return this.setState({ signing: false, signUrl: signOperation.linkOperation });
                }
                else {
                    message = {
                        color: 'danger',
                        text: 'Error generando firma.'
                    };
                }
            } else {
                message = {
                    color: 'warning',
                    text: 'No hay documentos para firmar.'
                };
            }
        }
        catch (error) {
            message = {
                color: 'danger',
                text: 'Error generando firma.'
            };
            console.error(error);
        }
        this.setState({ signing: false, message });
    }

    render() {
        console.log('this.props representative', this.props);
        if (this.state.signUrl) {
            return (<Redirect to={{
                pathname: '/redireccionando',
                state: { to: this.state.signUrl }
            }} />);
        }
        if (this.state.loading) {
            return (
                <div className="loading-overlay">
                    <LoadingOverlay
                        className="loading"
                        active={true}
                        spinner
                        fadeSpeed={100}
                        text='Cargando listado de transferencias...'
                    />
                </div>
            );
        }
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/transferencias",
                state: { tab: 'signed' }
            }} />
        }
        let { transferenceList } = this.state;
        if (this.state.operationStatusData === -1) {
            return (<div>Operacion de firma invalida</div>);
        }
        if (this.state.operationStatusData) {
            transferenceList = transferenceList.filter(transference => this.state.operationStatusData.transferenceStatus.hasOwnProperty(transference.idtransferencia));
        }
        const transferenceMap = transferenceList.map((transference, index) => {
            const selected = this.state.transferenceSelected.hasOwnProperty(transference.codigo);
            const background = index % 2 === 0 ? 'background' : '';
            const marked = selected ? 'background-selected' : '';
            return <Row key={index} className={`data-row field-row ml-0 ${background} ${marked}`}>
                <Col sm={1}>
                    <Input type="checkbox"
                        className="massive-checkbox form-control-sm"
                        disabled={this.state.signing}
                        checked={selected}
                        value={transference.codigo}
                        onChange={(e) => this.transferenceSelected(e, transference)}
                    />
                </Col>
                <Col sm={2}>
                    <span className="bold">{transference.patente}</span>
                </Col>
                <Col sm={3}>
                    {transference.numero_repertorio}
                </Col>
                <Col sm={3}>
                    <span className="transference-status transference-status-pendiente">{this.state.transferenceListStatus[transference.idtransferencia].label}</span>
                </Col>
            </Row>
        });

        return (
            <div>
                {this.state.message ?
                    <UncontrolledAlert color={this.state.message.color}>
                        {this.state.message.text}
                    </UncontrolledAlert>
                    : <div />}
                <Form className="massive-view transference-massive-sign">
                    <Row className="field-headers field-row ml-0">
                        <Col sm={1}>
                            <div className="massive-checkbox">
                                <Input type="checkbox"
                                    className="form-control-sm"
                                    disabled={this.state.signing}
                                    checked={this.state.transferenceList.length === Object.keys(this.state.transferenceSelected).length}
                                    onChange={this.handlerSelectedAll}
                                />
                            </div>
                        </Col>
                        <Col sm={2}>
                            <span className="bold">Patente</span>
                        </Col>
                        <Col sm={3}>
                            <span className="bold">Repertorio</span>
                        </Col>
                        <Col sm={3}>
                            <span className="bold">Estado</span>
                        </Col>
                    </Row>
                    {transferenceMap}
                    {this.state.operationId ? <div /> :
                        <Row className="botton-row">
                            <Col sm={3}>
                                <Button
                                    disabled={this.state.signing}
                                    className="btn btn-sm btn-primary form-check-input mt-0"
                                    onClick={this.handlerClearSelected}>
                                    Borrar seleccionados
                                </Button>
                            </Col>
                            <Col sm={9} className="text-right">
                                {this.state.signing ? '' :
                                    <Link
                                        disabled={this.state.signing}
                                        className="btn btn-sm btn-primary mr-2"
                                        to="/transferencias">
                                        Cancelar
                                    </Link>
                                }
                                <Button
                                    disabled={this.state.signing}
                                    className="btn btn-sm  btn-primary-darker"
                                    onClick={() => { this.handleSignButtonClick() }}>
                                    Firmar contratos seleccionados
                                </Button>
                            </Col>
                        </Row>
                    }
                </Form>

            </div>
        );
    }
}

export default TransferenceSignatureMassiveRepresentationForm;
