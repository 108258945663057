const NOTARY_CONFIG_AUTOFACT_REPRESENTATION_NAME = 'autofact_representation';

const NOTARY_CONFIG_AUTOFACT_REPRESENTATION_VALUE = {
    ENABLED: '1',
    DISABLED: '0',
};

const NOTARY_CONFIG_AUTOFACT_REPRESENTATION_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
};

module.exports = {
    NOTARY_CONFIG_AUTOFACT_REPRESENTATION_NAME,
    NOTARY_CONFIG_AUTOFACT_REPRESENTATION_VALUE,
    NOTARY_CONFIG_AUTOFACT_REPRESENTATION_STATUS,
};