import { useEffect, useState } from 'react'
import { CheckCircleOutlineOutlined, DeleteOutline } from '@material-ui/icons';
import rutHelper from '../../helpers/rutHelper';


const SignActions = ({person, index, actions}) => {

  const [roleDescription, setRoleDescription] = useState('');

  useEffect(() => {

    const { indexByRole, rol, es_representante, legalRepresentative } = person;

    let representative = '';
    let roleText = '';
    
    if (rol === 0) {
      roleText = 'comprador';
      if (es_representante === 1) {
        representative = 'representante';
      }
    } else if (rol === 1) {
      roleText = 'vendedor';
      if (es_representante === 1) {
        representative = 'representante';
      }
    }
    
    const repDesc = es_representante === 1 && legalRepresentative ? ' legal' : '';
    
    setRoleDescription(`${representative}${repDesc} ${roleText} ${indexByRole === -1 ? '' : indexByRole + 1}`);
    
  }, [person])

    const {openRemoveSignature, openSignModal} = actions;
    return (
      <div className='d-flex flex-column px-4 py-4 sign-box' key={`sign-box-${index}`}>
        <div className='row'>
          <div className='col d-flex flex-column'>
            <span className='text-18 bold'>Firma {roleDescription} </span>
            <span className='mb-2 text-18 bold'>{rutHelper.toDotedRut(person.rut)}</span>
          </div>
          {person.audit &&
            <div className='col-auto'>
              <CheckCircleOutlineOutlined fontSize={'large'} className='green-ok-color' />
            </div>
          }

        </div>
        {person.audit ?
        <div className='d-flex flex-column text-center'>
          <span className='text-14 bold green-ok-color'>Firma exitosa</span>
          <button className="btn btn-link delete-sign-btn mt-2" onClick={() => openRemoveSignature(person.rut, person.rol)}>
            <DeleteOutline />
            <span>Eliminar firma</span>
          </button>
        </div>
          :
          <button className='btn btn-primary-dark bold' onClick={() => openSignModal({ ...person, roleDescription }, index + 1)}>INICIAR FIRMA</button>
        }
      </div>
    )
  }

  export default SignActions;