import React, {Component} from 'react';
import {Button, Collapse, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {Link,withRouter} from "react-router-dom";
import PreAdmissionListContainer from "../../components/pre-admission-list-container/PreAdmissionListContainer";
import PreAdmissionListFilter from "../../components/pre-admission-list-filter/PreAdmissionListFilter";
import TransferenceListFilter from "../../components/transference-list-filter/TransferenceListFilter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TransferenceStatus} from '@autofact/operation-lib-model-notary-platform';
import moment from 'moment';


import FilterIcon from '../../theme/assets/filter_conces.svg';

const tabsDefinition = {
    preAdmision: {
        id: 'preAdmision',
        title: 'Vista de preingresos',
        filters: {}
    },
    forPreAdmission: {
        id: 'forPreAdmission',
        title: 'Contratos por pre ingresar',
        filters: {STATUS: TransferenceStatus.NOTARIZED.id}
    },
    forSend: {
        id: 'forSend',
        title: 'Contratos por enviar al Registro Civil',
        filters: {
            STATUS: [TransferenceStatus.PRE_TRANSFERRED.id, TransferenceStatus.MANUAL.id].join(','),
            PREADMISSION_DATE_START: moment().format('YYYY-MM-DD'),
            PREADMISSION_DATE_END: moment().format('YYYY-MM-DD'),
            PREADMISSION_DATE_RANGE: moment().format('DD/MM/YYYY - DD/MM/YYYY')
        }
    },
    fullPreadmission: {
        id: 'fullPreadmission',
        title: 'Consultar todos',
        filters: {WITH_PREADMISION: true}
    }
};
class PreAdmissionList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: tabsDefinition.preAdmision.id,
            filtersCollapsed: true,
            listFilters: {
                preAdmision: Object.assign({}, tabsDefinition.preAdmision.filters),
                forPreAdmission: Object.assign({}, tabsDefinition.forPreAdmission.filters),
                forSend: Object.assign({}, tabsDefinition.forSend.filters),
                fullPreadmission: Object.assign({}, tabsDefinition.fullPreadmission.filters)
            },
            recordCount: {
                preAdmision: 0,
                forPreAdmission: 0,
                forSend: 0,
                fullPreadmission: 0
            }
        };
        this.toggleFilters = this.toggleFilters.bind(this);
        this.setFiltersCallback = this.setFiltersCallback.bind(this);
        this.buildFilters = this.buildFilters.bind(this);
        this.setToTabforSend = this.setToTabforSend.bind(this);
        this.currentTableRef = React.createRef();
        this.filterComponentRef = React.createRef();
    }
    setToTabforSend(){
        this.toggleTab(tabsDefinition.forSend.id)
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            const listFilters = this.state.listFilters;
            listFilters[tab] = tabsDefinition[tab].filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
            this.filterComponentRef.current.resetFilters();
        }
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({activeTab: this.props.location.state.tab})
        }
        const recordCount = {};
        Object.keys(tabsDefinition).forEach(tabKey => {
            recordCount[tabKey] = 0;
        });
        this.setState({recordCount});
    }
    toggleFilters() {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    buildFilters(tab, filters = null) {
        const where = filters || this.state.listFilters[tab];
        return {where: JSON.stringify(where)};
    }

    setFiltersCallback(filters) {
        const tab = this.state.activeTab;
        const listFilters = this.state.listFilters;
        for (const filterKey in tabsDefinition[tab].filters) {
            if (tabsDefinition[tab].filters.hasOwnProperty(filterKey)) {
                if (filters.hasOwnProperty(filterKey)) {
                    const filterValue = tabsDefinition[tab].filters[filterKey].toString().split(',').filter(filterValue => filterValue.toString() === filters[filterKey].toString());
                    filters[filterKey] = filterValue.join(',');
                }
                else {
                    filters[filterKey] = tabsDefinition[tab].filters[filterKey];
                }
            }
        }
        listFilters[tab] = filters;
        this.setState({listFilters});
        this.currentTableRef.current.refreshFilters(this.buildFilters(tab, listFilters[tab]));
    }

    render() {
        const activeTab = this.state.activeTab;
        return (
            <div>
                <div className="page-title">
                    <FontAwesomeIcon className="icon-title" icon="file-signature" />
                    <h2 className="text-title">Preingreso</h2>
                </div>
                <Link className="btn btn-sm btn-primary-dark" to={'preingreso/registrar'}>
                    Registrar preingreso
                </Link>
                <Nav tabs className="tabs-dark">
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <NavItem key={`tab-nav-item-${index}`}>
                                <NavLink
                                    key={`tab-nav-${index}`}
                                    className={activeTab === tab.id ? 'active' : ''}
                                    onClick={() => this.toggleTab(tab.id)}
                                >
                                    {tab.title} ({this.state.recordCount[tab.id]})
                                </NavLink>
                            </NavItem>
                        );
                    })}
                    <NavItem className="nav-button">
                        <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters}>
                            <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                        </Button>
                    </NavItem>
                </Nav>

                <Collapse isOpen={!this.state.filtersCollapsed}>
                    <div className="filter-form-wrapper">
                        {activeTab === 'preAdmision' && <PreAdmissionListFilter
                            ref={this.filterComponentRef}
                            defaultFilters={tabsDefinition[this.state.activeTab].filters}
                            activeTab={this.state.activeTab}
                            setFiltersCallback={this.setFiltersCallback}
                        />}
                        {activeTab !== 'preAdmision' && <TransferenceListFilter
                            ref={this.filterComponentRef}
                            defaultFilters={tabsDefinition[this.state.activeTab].filters}
                            activeTab={this.state.activeTab}
                            setFiltersCallback={this.setFiltersCallback}
                        />}
                    </div>
                </Collapse>
                <TabContent activeTab={activeTab}>
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                                {activeTab === tab.id ?
                                    <PreAdmissionListContainer
                                        id={tab.id}
                                        tab={tab.id}
                                        ref={this.currentTableRef}
                                        key={`tab-list-${index}`}
                                        setToTabforSend={this.setToTabforSend}
                                        updateCount={count => {
                                            const recordCountState = this.state.recordCount;
                                            recordCountState[tab.id] = count;
                                            this.setState({recordCount: recordCountState});
                                        }}
                                        filters={this.buildFilters(tab.id)}
                                    />
                                    :
                                    <div key={`tab-loading-${index}`}>Cargando</div>
                                }
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        );
    }
}

export default withRouter(PreAdmissionList);
