import React, {Component} from 'react';
import LoadingOverlay from 'react-loading-overlay';

class RedirectExternal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: this.props.location.state && this.props.location.state.to ? this.props.location.state.to : null
        }
    }
    render() {
        if (!this.state.redirect) {
            return (
                <div className="loading-overlay">
                    <LoadingOverlay
                        className="loading"
                        active={true}
                        spinner
                        fadeSpeed={100}
                        text='Redireccionando...'
                        />
                </div>
                );
        }
        else {
            window.location = this.state.redirect;
            return (
                <div className="loading-overlay">
                    <LoadingOverlay
                        className="loading"
                        active={true}
                        spinner
                        fadeSpeed={100}
                        text='Redireccionando...'
                        />
                </div>
                );
        }
    }
}

export default RedirectExternal;
