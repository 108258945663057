import React from 'react'
import { Modal, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalRemoveRepresentative = (props) => {
  
  const { isOpen, removeRepresentative, loading, close, representative } = props.data;

  const onRemoveClick = () => {
    loading.setLoading(true);
    removeRepresentative(representative);
  }

  return (
    <Modal isOpen={isOpen} toggle={close} size='md' centered>
      <div className="modal-content modal-remove-representative">
        <div className="modal-header bg-primary-darker text-white">
          <h2 className="modal-title">Eliminar representante</h2>
          <button type="button" className="close white-circle-close" onClick={() => close()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <FontAwesomeIcon icon="exclamation-circle" className='orange-color'/>
          <h1 className="modal-title orange-color">¿Deseas eliminar este representante?</h1>
          <p className='text-secondary mt-1 text-16'>Esta acción no se puede deshacer.</p>
        </div>
        <div className="modal-footer justify-content-center mt-2">
          <Button className="btn-outline-primary-dark" size="sm" onClick={() => close()} >CANCELAR</Button>{' '}
          <Button color="primary-dark" size="sm" onClick={() => onRemoveClick()} >
          {loading.loading? [
                    <span key='span-k-1' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>,
                    <span key='span-k-2' className='ml-2'> Cargando...</span>
                  ] :
                    <span>SI, ELIMINAR REPRESENTANTE</span>
                  }
            
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalRemoveRepresentative