import DateHelper from "../../helpers/DateHelper";
import rutHelper from "../../helpers/rutHelper";

const SellerInformation = (props) => {

  const { sellerData } = props;
  const { sellers } = sellerData;

  return (
      <div className='mx-2 mt-4'>
        <div className='d-flex align-items-center gray-light-bg'>
          <div className='d-flex justify-content-center rounded-circle yellow-number-icon'>
            <span className='font-weight-bold my-1'>1</span>
          </div>
          <span className='dark-color font-weight-bolder ml-2 text-20'>Información del vendedor</span>
        </div>
        <div className='mt-5'>
          {
            sellers.map((seller, index) => {
              return (
                <div key={`seller-${index}`} className='mt-4 seller-box gray-light-bg'>
                  <p className='text-secondary font-weight-bold text-14'>Vendedor {index + 1}</p>
                  <div className='row text-16'>
                    <div className='col-lg-6 col-sm-12'>
                      <p> <strong>Rut: </strong> {rutHelper.toDotedRut(seller.identityCode)}</p>
                      <p> <strong>Nombre: </strong> {seller.fullName}</p>
                      <p> <strong>Fec. Adquisición: </strong> {DateHelper.arrayToDateES(seller.acquisitionDate)}</p>
                    </div>
                    <div className='col-auto'>
                      <p> <strong>Repertorio: </strong>{seller.repertoire}</p>
                      <p> <strong>Número: </strong>{seller.number}</p>
                      <p> <strong>De fecha: </strong>{DateHelper.arrayToDateES(seller.registrationDate)}</p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
  )
}

export default SellerInformation