import React, {useState, useEffect} from 'react';
import { Button, Alert } from 'reactstrap';
import {Stepper, Step, StepLabel, CircularProgress} from "@material-ui/core";
import { withRouter } from 'react-router';
import LoadingOverlay from 'react-loading-overlay';
import './NotaryBuySellProcess.style.css';
import InformationStep from '../../components/notary-buy-sell-stepper/InformationStep';
import { app } from '../../config';
import BackendApiService from '../../services/BackendApiService';
import ValidationResult from '../../components/notary-validation-result/ValidationResult';
import AlertCAVConditions from '../../components/notary-information-validation/alert-cav-conditions/AlertCAVConditions';
import rutHelper from '../../helpers/rutHelper';
import ContractCreationStep from '../../components/notary-buy-sell-stepper/ContractCreationStep';
import ContractFillStep from '../../components/notary-buy-sell-stepper/ContractFillStep';
import { SaveOutlined, ArrowBack } from '@mui/icons-material';
import { merge } from 'lodash';
import AuthService from '../../services/AuthService';
import AmmountHelper from '../../helpers/AmmountHelper';
import moment from 'moment';
import ModalSaveContractForm from '../../components/modal-save-contract-form/ModalSaveContractForm';

const steps = [
  "Validación de información",
  "Resultado de validación",
  "Creación de contrato",
  "Completar contrato",
  "Revisión y firma de contrato",
  "Pago de Impuestos",
  "Repertorio",
  "Firma Notario",
  "Ingreso a Reg.Civil"
];

const APROBADO = 3;
const FIRMA_CLIENTE = 10;

const NotaryBuySellProcess = (props) => {

  const [ticket, setTicket] = useState('');
  const [cavCode, setCavCode] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [cavModal, setCavModal] = useState(false);
  const [aceptedCondition, setAceptedCondition] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [updatedData, setUpdatedData] = useState(false);
  const [validationForm, setValidationForm] = useState({
    domainLimitation: null,
    annotation: null,
    theftCharge: null,
    fines: null,
    foodDebt:  {
      people: [],
      companies: []
    },
    cavStatus: false,
    peopleExtras: {
      people: {
        sellers: {},
        buyers: {
          buyerType: null,
        }
      }
    }
  });
  const [updateLoading, setUpdateLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [contractForm, setContractForm] = useState({
    type: parseInt(app.contractType, 10),
    plate: '',
    societyId: 1,
    vehicle:{
      SIICode: '',
      circulationPermission: {
        expirationDate: '',
        comunaId: '',
        document:{
          typeId: 5,
          url: '',
          name: ''
        }
      },
    },
    people:{
      sellers: {
        representatives: [],
        sellers:[]
      },
      buyers: {
        representatives: [],
        buyers: [
          {
            identityCode: "",
            isCompany: false,
          }
        ]
      }
    },
    payment: []
  });
  const [isAnUpdate, setIsAnUpdate] = useState(null);
  const [isValidForm, setIsValidForm] = useState(true);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [saveFormStatus, setSaveFormStatus] = useState('');
  const [sellerIsCompany, setSellerIsCompany] = useState(false);

  const [buyerIsCompany, setBuyerIsCompany] = useState(false);
  const [formErrorText, setFormErrorText] = useState('');

  useEffect(() => {

    const getPretransferData = async (code) => {
      try {
        const apiResponse = await BackendApiService.getPretransfer(code);
        if(!apiResponse){
          throw new Error('');
        }

        const currentData = apiResponse[0];
        switch (currentData.step_id) {
          case 0:
              if(!currentData.transference_object?.contractForm){
                const authData = await AuthService.getAuthData();
                const {subsidiaryId, userId} = authData.company;
                const newContractForm = contractForm;
                const societyIdResponse = await BackendApiService.getSocietyByUserAndSubsidiary(userId, subsidiaryId);
                if(societyIdResponse?.idpersona){
                  newContractForm.societyId = societyIdResponse.idpersona;
                  setContractForm(newContractForm);
                }
              } else {
                setContractForm(currentData.transference_object.contractForm);
              }

              if(currentData.transference_object?.validationForm){
                setValidationForm(currentData.transference_object.validationForm);
                setBuyerIsCompany(currentData.transference_object.validationForm?.buyerCompany || false);
              }

              if(currentData.transference_object?.cav_ticket){
                setCavCode(currentData.cav_code);
              }


              break;

          case 1:
          case 2:
              setCavCode(currentData.cav_code);
              setContractForm(currentData.transference_object.contractForm);
              setValidationForm(currentData.transference_object.validationForm);
            break;

          default:
              setCavCode(currentData.cav_code);
              setContractForm(currentData.transference_object.contractForm);
              setValidationForm(currentData.transference_object.validationForm);
            break;
        }
        setTicket(apiResponse[0].transference_object?.cav_ticket || apiResponse[0].cav_code);
        setActiveStep(apiResponse[0].step_id);
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
      }

    }

    const { pathname } = props.location;
    const segments = pathname.split('/');
    const action = segments[2];

    if(action === 'modificar-compraventa'){
      const pretransferFromStorage = JSON.parse(sessionStorage.getItem('pretransfer'));
      if(pretransferFromStorage){
        setContractForm(pretransferFromStorage.contractForm);
        setValidationForm(pretransferFromStorage.validationForm);
        setCavCode(pretransferFromStorage.cavCode);
        setTicket(pretransferFromStorage.ticket);
        setActiveStep(pretransferFromStorage.activeStep);
        if((pretransferFromStorage.activeStep !== 2 && pretransferFromStorage.activeStep !== 3) || !pretransferFromStorage.contractForm) {
          props.history.replace('/notaria');
        }
      } else {
        console.log('no se encontro el objeto');
        props.history.replace('/notaria');
      }
      setPageLoading(false);
    }

    if (action === 'nueva-compraventa'){
      getPretransferData(props.match.params.code);
    }

    setIsAnUpdate(action === 'modificar-compraventa');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.match.params.code, props.location])

  useEffect(() => {
    if (updatedData) {
      setUpdatedData(false);
    }
  }, [updatedData])


  const stepContent = (step) => {
    const steps = {
      0: <InformationStep
          data={{
            ticket,
            validationForm,
            contractForm,
            code: props.match.params.code,
          }}
          updateCavCode={ {cavCode, setCavCode} }
          updateData={ updateDataCallback }
          updatePretransfer={ updatePretransfer }
          updateBuyerIsCompany={ updateBuyerIsCompany }
        />,
      1: <ValidationResult resultData={{
          ticket: ticket,
          validationForm: validationForm,
          contractForm: contractForm
          }}
        />,
      2: <ContractCreationStep
          data={{
            ticket,
            validationForm,
            contractForm,
            sellerIsCompany
          }}
          changeSellerCompanyState={selleIsCompanyStateChange}
          updateData={ updateDataCallback }
          updatePretransfer={ updatePretransfer }
          isContractUpdate={ isAnUpdate }
          modifyPerson={ createOrUpdatePerson }
        />,
      3: <ContractFillStep
          data={ {contractForm, code: props.match.params.code, isContractUpdate: isAnUpdate} }
          updateData={ updateDataCallback }
          createOrUpdatetransfer={createOrUpdateTransference}
        />,
      4: <>Paso {activeStep +1}</>,
      5: <>Paso {activeStep +1}</>,
      6: <>Paso {activeStep +1}</>,
      7: <>Paso {activeStep +1}</>
    }
    return(steps[step])
  }

  const updateStoredPretransfer = (nextStep = null) => {
    const sessionPretransfer = JSON.parse(sessionStorage.getItem('pretransfer'));
    sessionPretransfer.activeStep = nextStep || sessionPretransfer.activeStep;
    sessionPretransfer.cavCode = cavCode;
    sessionPretransfer.validationForm = validationForm;
    sessionPretransfer.contractForm = contractForm;
    sessionPretransfer.ticket = ticket;
    sessionStorage.setItem("pretransfer", JSON.stringify(sessionPretransfer));
  }

  const updateDataCallback = (validation, contract) => {
      validation ??  setValidationForm( current => (merge({ ...current, ...validation })));
      contract ?? setContractForm( current => (merge({ ...current, contract })));
      setUpdatedData(true);
  }

  const updateBuyerIsCompany = (isCompany) => {
    setBuyerIsCompany(isCompany);
    const newValidationForm = validationForm;
    newValidationForm.buyerCompany = isCompany;
    setValidationForm(newValidationForm);
  }
  
  function processBuyersSellers(persons) {
    const ruts = [];
    const companiesRut = [];
  
    persons.forEach(item => {
      const rutValue = item.identityCode.slice(0, -1);

      if (parseInt(rutValue) < 50000000) {
        ruts.push(item.identityCode);
      } else {
        companiesRut.push(item.identityCode);
      }
    });
  
    return { ruts, companiesRut };
  }

  const fillPeopleInformation = (dbPeople, contractFormPeople, isSeller = false) => {

    let newPeopleArray = [];
    contractFormPeople.forEach(person => {
      const existingPerson = dbPeople.find(toCompare => toCompare.identityCode.toUpperCase() === person.identityCode.toUpperCase());
      if(existingPerson){
        existingPerson.documents = [];

        if(person.identityCode) existingPerson.identityCode = person.identityCode;
        if(person.acquisitionDate) existingPerson.acquisitionDate = person.acquisitionDate;
        if(person.registrationDate) existingPerson.registrationDate = person.registrationDate;
        if(person.number)  existingPerson.number = person.number;
        if(person.repertoire) existingPerson.repertoire = person.repertoire;
        
        newPeopleArray.push(existingPerson);
      }
      const isCompany = parseInt(person.identityCode.slice(0, -1)) >= 50000000;

      if (!existingPerson && person.identityCode !== '') {
        if(!isCompany || (isSeller && isCompany)){
          const newPerson = {
            identityCode: person.identityCode,
            name: '',
            secondName: '',
            fullName: '',
            fatherLastName: '',
            motherLastName: '',
            sex: isCompany? 'E' : '',
            phone: '',
            email: '',
            nationality: '',
            maritalStatus: '',
            occupation: '',
            address: {
                street: '',
                number: '',
                rest: '',
                regionId: '',
                comunaId: ''
            },
            documents: [],
            ...person.identityCode && { identityCode: person.identityCode },
            ...person.acquisitionDate && { acquisitionDate: person.acquisitionDate },
            ...person.registrationDate && { registrationDate: person.registrationDate },
            ...person.number && { number: person.number },
            ...person.repertoire && { repertoire: person.repertoire }
          };
          newPeopleArray.push(newPerson);
        }

      }
    });

    return newPeopleArray;
    
  }

  const getfoodDebt = async () => {
    setPageLoading(true);

    const buyers = processBuyersSellers(contractForm.people.buyers.buyers);
    const sellers = processBuyersSellers(contractForm.people.sellers.sellers);
    
    const ruts = [...buyers.ruts, ...sellers.ruts];
    const companiesRut = [...buyers.companiesRut, ...sellers.companiesRut];

    const result = await BackendApiService.getPensionDebtor(ruts);
    if(result){
      const newValidationForm = validationForm;
      newValidationForm.foodDebt = {people: result, companies: companiesRut};
      setValidationForm(newValidationForm);
    }
    setPageLoading(false);
  }

  const aceptCondition = () => {
    setAceptedCondition(true);
    setCavModal(false);
    updateTransferOnStepChange(1);
    setActiveStep(activeStep + 1);
  }

  const updatePretransfer = async(finalUpdate = false, buttonGuardar = false) => {
    try {
      setUpdateLoading(true);
      if(isAnUpdate && !finalUpdate){
        updateStoredPretransfer();
      } else{
        const resp = await BackendApiService.updateNotaryTransfer(props.match.params.code, {
          cav_code: cavCode,
          transference_object: JSON.stringify(
            {
              cav_ticket: ticket,
              validationForm,
              contractForm
            }
          )
        })

        if(!resp && buttonGuardar){
          throw new Error("Couldn't save pretransfer data");
        }
      }
      
      setUpdateLoading(false);
      if(buttonGuardar){
        showModalSaveContractForm('ok');
      }

    } catch (error) {
      console.log(error.message);
      setUpdateLoading(false);
      if(buttonGuardar){
        showModalSaveContractForm('error');
      }
    }

  }

  const executeAction = (step) => {
    const actions = {
      0 : async () => {
        await getfoodDebt();
        updateTransferOnStepChange(0);
      },
      1: () => {
        if(!aceptedCondition){
          setCavModal(true)
          return false;
        }
        return true;
      },
      2: () => {
        updateTransferOnStepChange(2)
        return true;
      },
      3:() => {
        return true;
      }
    };
    return actions[step]();
  }

  const checkStep = (step) => {
    const check = {
      0: (()=>{
        const { buyers } = contractForm.people.buyers;
        const { SIICode, price, circulationPermission } = contractForm.vehicle;
        const rutsList = buyers.map( el => el.identityCode);
        const invalidsRut =  buyerIsCompany ? false : rutHelper.checkRutList(rutsList);
        const validDate =  moment(circulationPermission.expirationDate,'YYYY-MM-DD', true).isValid();
        const buyersVerification = buyerIsCompany ? true : buyers.length !== 0;
        return !(SIICode && price && validDate && circulationPermission.comunaId && buyersVerification && !invalidsRut);
      })(),
      1: null,
      2: (() => {

        const { buyers, representatives } = contractForm.people.buyers;
        const sellerRep = contractForm.people.sellers.representatives;

        const { buyerType } = validationForm.peopleExtras.people.buyers;
        const requestedPeopleInputs = ["identityCode", "name", "fatherLastName", "fatherLastName"];
        const requestedCompanyInputs = ["identityCode", "name"];
        
        const personHasEmptyValues = Object.values(contractForm.people.buyers).some(arr =>
          arr.some(item => requestedPeopleInputs.some(field => !item[field]))
          );
          
        const companyHasEmptyValues = Object.values(contractForm.people.buyers).some(arr =>
          arr.some(item => requestedCompanyInputs.some(field => !item[field]))
          );
            
        if(sellerIsCompany && !sellerRep.length){
          return true;
        }
        
        switch (buyerType) {
          case app.buyerType.naturalPerson:
            if(buyers.length && !personHasEmptyValues){
              return false;
            }
            return true;
          case app.buyerType.buyFor:
            if(buyers.length && representatives.length && !personHasEmptyValues){
              return false;
            }
            return true;

          case app.buyerType.company:
            if(buyers.length && representatives.length && !companyHasEmptyValues){
              return false;
            }
            return true;

            default:
              return true;
        }


      })()
    }
    return check[step];
  }

  const nextStep = () => {
    const nextStep = executeAction(activeStep);
    if(nextStep){
      setActiveStep(activeStep + 1);
    }
  }

  const closeCAVConditionsModal = () => setCavModal(false);

  const updateTransferOnStepChange = async (currStep) => {

    if(isAnUpdate){
      updateStoredPretransfer(3);
      return;
    }

    switch (currStep) {
      case 0:
        {
          const resp = await BackendApiService.updateNotaryTransfer(props.match.params.code, {
            step_id: currStep + 1,
            cav_code: cavCode,
            transference_object: JSON.stringify(
              {
                cav_ticket: ticket,
                validationForm,
                contractForm
              }
            )
          })
          return resp;
        }

      case 1: {

        try {
          setPageLoading(true);
          let ruts = [];
          const newContract = contractForm;

          const processedBuyers = processBuyersSellers(contractForm.people.buyers.buyers);
          const processedSellers = processBuyersSellers(contractForm.people.sellers.sellers);

          ruts = [...ruts, ...processedBuyers.ruts, ...processedSellers.ruts, ...processedSellers.companiesRut];
          let peopleResponse = [];

          if(ruts.length){
            peopleResponse = await BackendApiService.getPeople(ruts);
          }
          
          const buyers = fillPeopleInformation(peopleResponse, newContract.people.buyers.buyers);
          const sellers = fillPeopleInformation(peopleResponse, newContract.people.sellers.sellers, true);

          newContract.people.buyers.buyers = buyers;
          newContract.people.sellers.sellers = sellers;

 
          const resp = await BackendApiService.updateNotaryTransfer(props.match.params.code, {
            step_id: currStep + 1,
            transference_object: JSON.stringify(
              {
                cav_ticket: ticket,
                validationForm,
                contractForm: newContract
              }
            )
          })

          return resp;
        } catch (error) {
          console.log('error while getting people')
          console.log(error.message)
        }
        finally{
          setPageLoading(false);
        }
        
        break;

      }

      case 2:
      {
        const resp = await BackendApiService.updateNotaryTransfer(props.match.params.code, {
          step_id: currStep + 1,
          cav_code: cavCode,
          transference_object: JSON.stringify(
            {
              cav_ticket: ticket,
              validationForm,
              contractForm
            }
          )
        })
        return resp;
      }

      default:
        updatePretransfer()
        break;
    }
  }

  const createOrUpdateTransference = async(e) => {
    try {
      e.preventDefault()
      setCreateLoading(true);
      updatePretransfer();
      const data = await AuthService.getAuthData();
      const contractTosend = contractForm;
      contractTosend.companyId = data.company.companyId;
      contractTosend.subsidiaryId = data.company.subsidiaryId;
      contractTosend.userId = data.company.userId;

      const accumPayment = contractTosend.payment.reduce((total, item) => total + item.ammount, 0);
      if(accumPayment !== AmmountHelper.toNumber(contractTosend.vehicle?.price)){
        setCreateLoading(false);
        setFormErrorText('El precio del vehículo y la suma total a pagar no coinciden');
        setIsValidForm(false);
        throw new Error('Precio ingresado es distinto al precio de venta del vehículo');
      }

      contractTosend.people.buyers.buyers.forEach((item, index) => {
        item.identityCode = rutHelper.toCleanRut(item.identityCode);
        contractTosend.people.buyers.buyers[index] = item;

        const { name, secondName, fatherLastName, motherLastName } = contractTosend.people.buyers.buyers[index];
        contractTosend.people.buyers.buyers[index].fullName = `${name}${secondName ? ` ${secondName}` : ''}${fatherLastName ? ` ${fatherLastName}` : ''}${motherLastName ? ` ${motherLastName}` : ''}`;
        
        if(!checkFields(item, true)){
          setFormErrorText('Por favor cargue todos los documentos');
          setCreateLoading(false);
          throw new Error('Faltan archivos');
        }
        if(contractTosend.people.buyers.buyers[0]?.companyInDay === 1){
          contractTosend.people.buyers.representatives[index].notary.name = 'EMPRESA EN UN DÍA';
          contractTosend.people.buyers.representatives[index].notary.comunaId = '66';
        }
      });

      contractTosend.people.buyers.representatives.forEach((item, index) => {
        item.identityCode = rutHelper.toCleanRut(item.identityCode);
        contractTosend.people.buyers.representatives[index] = item;
        const { name, secondName, fatherLastName, motherLastName } = contractTosend.people.buyers.representatives[index];
        contractTosend.people.buyers.representatives[index].fullName = `${name}${secondName ? ` ${secondName}` : ''}${fatherLastName ? ` ${fatherLastName}` : ''}${motherLastName ? ` ${motherLastName}` : ''}`;
        const naturalRepresentative = item?.notary ? false : true;
        if(!naturalRepresentative && !checkFields(item)){
          setFormErrorText('Por favor cargue todos los documentos');
          setCreateLoading(false);
          throw new Error('Faltan archivos');
        }
      });

      contractTosend.people.sellers.sellers.forEach((item, index) => {
        item.identityCode = rutHelper.toCleanRut(item.identityCode);
        contractTosend.people.sellers.sellers[index] = item;
        const { name, secondName, fatherLastName, motherLastName } = contractTosend.people.sellers.sellers[index];
        contractTosend.people.sellers.sellers[index].fullName = `${name}${secondName ? ` ${secondName}` : ''}${fatherLastName ? ` ${fatherLastName}` : ''}${motherLastName ? ` ${motherLastName}` : ''}`;
        
        if(!checkFields(item, true)){
          setFormErrorText('Por favor cargue todos los documentos');
          setCreateLoading(false);
          throw new Error('Faltan archivos');
        }
      });

      contractTosend.people.sellers.representatives.forEach((item, index) => {
        item.identityCode = rutHelper.toCleanRut(item.identityCode);
        contractTosend.people.sellers.representatives[index] = item;
        const { name, secondName, fatherLastName, motherLastName } = contractTosend.people.sellers.representatives[index];
        contractTosend.people.sellers.representatives[index].fullName = `${name}${secondName ? ` ${secondName}` : ''}${fatherLastName ? ` ${fatherLastName}` : ''}${motherLastName ? ` ${motherLastName}` : ''}`;

        if(!checkFields(item)){
          setFormErrorText('Por favor cargue todos los documentos');
          setCreateLoading(false);
          throw new Error('Faltan archivos');
        }
        if(contractTosend.people.sellers.sellers[0]?.companyInDay === 1){
          contractTosend.people.sellers.representatives[index].notary.name = 'EMPRESA EN UN DÍA';
          contractTosend.people.sellers.representatives[index].notary.comunaId = '66';
        }
      });
      
      if(!contractTosend.vehicle.circulationPermission.document.url){
        setFormErrorText('Por favor cargue todos los documentos');
        setCreateLoading(false);
        setIsValidForm(false);
        throw new Error('Faltan archivos');
      }
      contractTosend.vehicle.price = AmmountHelper.toNumber(contractTosend.vehicle.price);
      contractTosend.vehicle.taxValue = AmmountHelper.toNumber(contractTosend.vehicle.taxValue);
      contractTosend.vehicle.valuationPrice = AmmountHelper.toNumber(contractTosend.vehicle.valuationPrice);
      contractTosend.vehicle.invoice = AmmountHelper.toNumber(contractTosend.vehicle.valuationPrice);
      contractTosend.vehicle.mileage = 10;
      
      if(isAnUpdate){
        const errorMessage = 'ocurrio un error al actualizar contrato';
        const pretransferFromStorage = JSON.parse(sessionStorage.getItem('pretransfer'));
        const { transferCode } = pretransferFromStorage;
        const removePersonResponse = await BackendApiService.removeTransferBuyer(transferCode, contractTosend.userId );

        if(removePersonResponse?.status !== APROBADO){
          const msg = removePersonResponse?.message || errorMessage;
          alert(msg)
          throw new Error(msg);
        }
        const addPersonResponse = await BackendApiService.addTransferBuyer(transferCode, contractTosend);
        if(addPersonResponse?.status !== FIRMA_CLIENTE){
          const msg = addPersonResponse?.message || errorMessage;
          alert(msg)
          throw new Error(msg);
        }
        const response = await BackendApiService.updateTransfer(transferCode, contractTosend);
        if(response?.status === 'OK'){
          updatePretransfer(true);
          setCreateLoading(false);
          redirectToTransfer();
        } else {
          alert(response?.message || errorMessage);
        }
      }
      else {
        const pretransferCode = props.match.params.code;        

        if(contractTosend.people.sellers.sellers[0]?.sex.toLocaleUpperCase() === "E"){
          await createOrUpdatePerson(contractTosend.people.sellers.sellers[0]);
        }

        if(contractTosend.people.buyers.buyers[0]?.sex.toLocaleUpperCase() === "E"){
          await createOrUpdatePerson(contractTosend.people.buyers.buyers[0]);
        }

        const response = await BackendApiService.createTransference(pretransferCode, contractTosend);
        if(response.status === 'OK'){
          const { code } = response.data.data;

          setCreateLoading(false);
          props.history.replace(`/notaria/ver/${code}`);
        }else{
          alert(response?.message || 'ocurrio un error al generar contrato');
        }
        setCreateLoading(false);
      }
      setCreateLoading(false);
    } catch (error) {
      setCreateLoading(false);
      console.log(error)
      return false;
    }
  }

  const checkFields = (item, skip = false) => {
    try {
      if(item.sex !== "E" && skip){
        return true;
      }
      const docValidation =   item.documents?.length > 0 && item.documents.every((element) => element.url !== '');
      setIsValidForm(docValidation);
      return docValidation;
    } catch (error) {
      return false;
    }
  }

  const disabled = checkStep(activeStep);

  const redirectToTransfer = (wasCanceled = false) => {
    const pretransferFromStorage = JSON.parse(sessionStorage.getItem('pretransfer'));

    if(wasCanceled){
      if(pretransferFromStorage.buyersBeforeUpdate?.buyers){
        contractForm.people.buyers.buyers.filter(async (actualBuyer) => {
          const oldBuyer = pretransferFromStorage.buyersBeforeUpdate.buyers.find((oldBuyer) => oldBuyer.identityCode === actualBuyer.identityCode);
          await createOrUpdatePerson(oldBuyer);
        })
      }
    }

    if(pretransferFromStorage.transferPath){
      const pathname = pretransferFromStorage.transferPath;
      sessionStorage.removeItem('pretransfer')
      props.history.replace(pathname);
    }
  };

  const showModalSaveContractForm = (formStatus) => {
    setSaveFormStatus(formStatus);
    setOpenModalSave(true);
  };
  
  const closeModalSaveContractForm = () => {
    setOpenModalSave(false);
  };
  
  const createOrUpdatePerson = async (person) => {
    const resp = await BackendApiService.createOrUpdatePerson(person);
    return resp;
  }
  
  const selleIsCompanyStateChange = (isCompany) => setSellerIsCompany(isCompany);

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep} className="w-100 buy-sell-stepper">
        {
          steps.map((item, index) => {
            return(
              <Step key={`step-${index}`}>
                <StepLabel>{item}</StepLabel>
              </Step>
            );
          })
        }
      </Stepper>
      { pageLoading ?
        (
          <div className="loading-overlay">
            <LoadingOverlay
              className="loading"
              active={pageLoading}
              spinner
              fadeSpeed={100}
              text='Cargando información...'
            />
          </div>
        ) :
        (
          <div className='step-content'>
            {activeStep === steps.length ? (<>Pantalla final</>):(stepContent(activeStep))}
          </div>
        )
      }
      {activeStep === 3 ?
        <div className='d-flex flex-row justify-content-center'>
          <Button color="primary-dark" outline className="mr-2 font-weight-bold" onClick={()=> setActiveStep(curr => (curr - 1))}><ArrowBack /> Volver</Button>
          {!isAnUpdate ?
            <>
              <Button color="primary-dark" outline className="mr-2 font-weight-bold" onClick={()=> updatePretransfer(false, true)}>
                {updateLoading ?
                  <><CircularProgress size={20} className="text-light mr-1"/> Guardando ... </>
                  :
                  <><SaveOutlined/> Guardar</>
                }
              </Button>
                <Button type="button" id="submit-transfer-button" color="primary-dark" className="font-weight-bold" disabled={createLoading} >
                {createLoading ?
                  <><CircularProgress size={20} className="text-light mr-1" /> Creando ... </>
                  :
                  <>Crear contrato</>
                }
              </Button>
            </>
            :
            <>
              <Button color="primary-dark" outline className="mr-2 font-weight-bold" onClick={()=> redirectToTransfer(true)}>
                Cancelar, volver a visualizar contrato
              </Button>
              <Button type="button" id="submit-transfer-button" color="primary-dark" className="font-weight-bold" disabled={createLoading} >
              {createLoading ?
                <><CircularProgress size={20} className="text-light mr-1" /> Actualizando ... </>
                :
                <>Modificar</>
              }
              </Button>
            </>
          }
              <Alert color="danger" className="alert-bottom" isOpen={!isValidForm} toggle={()=>setIsValidForm(!isValidForm)}>
                {formErrorText}
              </Alert>
        </div>
        :
        <Button
          className='btn btn-primary-dark float-right mt-4'
          onClick={nextStep}
          disabled={disabled}
        >
          {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
        </Button>
      }

      <ModalSaveContractForm data={{modalIsOpen: openModalSave, status: saveFormStatus}} func={{closeModal: closeModalSaveContractForm}} />


      <AlertCAVConditions
          history={props.history}
          isOpen={cavModal}
          closeModal={closeCAVConditionsModal}
          aceptCondition={ aceptCondition }
          validations={ validationForm }
          pretransferCode={props.match.params.code}
        />
    </div>
  )
}

export default withRouter(NotaryBuySellProcess)
