import React, {Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';


class NavTabsSkeleton extends Component{
    constructor(props) {
        super(props);

        this.firstKey = (Object.keys(this.props.tabsDefinition)||[false])[0];
        this.state = {
            activeTab: (this.firstKey  && this.props.tabsDefinition) && this.props.tabsDefinition[this.firstKey] && this.props.tabsDefinition[this.firstKey].id ,
        };
    }

    async componentDidMount() {
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        if(this.props.onSelectTab){
            this.props.onSelectTab();
        }
    }

    render() {
        const activeTab = this.state.activeTab;
        const tabsDefinition = this.props.tabsDefinition;
        return <div className={'mb-5'}>
             <Nav className="tabs-button">
            {Object.keys(tabsDefinition).map((key, index) => {
                const tab = tabsDefinition[key];
                return (
                    <NavItem key={`tab-nav-item-${index}`}>
                        <NavLink
                            key={`tab-nav-${index}`}
                            className={activeTab === tab.id ? 'active' : ''}
                            onClick={() => this.toggleTab(tab.id)}
                        >
                            {tab.title}
                        </NavLink>
                    </NavItem>
                );
            })}
        </Nav>
        <TabContent activeTab={activeTab}>
            {Object.keys(tabsDefinition).map((key, index) => {
                const tab = tabsDefinition[key];
                return (
                    <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                        {activeTab === tab.id ?
                            <tab.component
                                id={tab.id}
                                tab={tab.id}
                                key={`tab-list-${index}`}
                            />
                            :
                            <div key={`tab-loading-${index}`}>Cargando</div>
                        }
                    </TabPane>
                );
            })}
        </TabContent>
        </div>
    }
}

export default NavTabsSkeleton;
