import React, { Component } from 'react';
import { ButtonGroup,Button,  Card, CardBody } from 'reactstrap';

import { TransferenceStatus, getTransferenceStatusFromId, Permissions, TransferenceDocumentType } from '@autofact/operation-lib-model-notary-platform';
import CheckAccess from '../check-access/CheckAccess';
import CheckStatus from '../check-status/CheckStatus';

import TransferenceApproveButton from '../transference-actions/TransferenceApproveButton';
import TransferenceRejectButton from '../transference-actions/TransferenceRejectButton';
import TransferenceRegisterPreAdmissionButton from '../transference-actions/TransferenceRegisterPreAdmissionButton';
import TransferenceSignButton from "../transference-actions/TransferenceSignButton";
import TransferenceRepertoryButton from "../transference-actions/TransferenceRepertoryButton";
import _ from 'lodash'
import TransferenceIdentityButton from '../transference-actions/TransferenceIdentityButton';

class TransferenceActionsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transferenceData: this.props.transferenceData
        };
    }

    render() {
        const preAdmision  = this.props.transferenceData.preAdmission || {};
        const preAdmisionDoc = _.find(this.props.transferenceData.documents,document=>{
            return document.tipo === TransferenceDocumentType.PRE_ADMISSION.id && document.estado > 0
        })
        return (<Card>
            <CardBody>

                <CheckAccess permission={Permissions.TRANSFERENCE_SEE}>
                    <TransferenceIdentityButton code={this.props.transferenceData.codigo} />
                </CheckAccess>

                <CheckAccess permission={Permissions.TRANSFERENCE_REPERTORY_ADD}>
                    <CheckStatus actual={getTransferenceStatusFromId(this.props.transferenceData.estado).id} required={[TransferenceStatus.REPERTORY.id]}>
                        <TransferenceRepertoryButton code={this.props.transferenceData.codigo} repertory={this.props.transferenceData.numero_repertorio} />
                    </CheckStatus>
                </CheckAccess>

                <CheckAccess permission={Permissions.TRANSFERENCE_REPERTORY_EDIT}>
                    <CheckStatus actual={getTransferenceStatusFromId(this.props.transferenceData.estado).id} required={[TransferenceStatus.NOTARY.id]}>
                        <TransferenceRepertoryButton editButton code={this.props.transferenceData.codigo}  repertory={this.props.transferenceData.numero_repertorio}/>
                    </CheckStatus>
                </CheckAccess>

                <ButtonGroup>
                    <CheckAccess permission={Permissions.TRANSFERENCE_APPROVE}>
                        <CheckStatus actual={getTransferenceStatusFromId(this.props.transferenceData.estado).id} required={[TransferenceStatus.NOTARY_APPROVAL.id]}>
                            <TransferenceApproveButton code={this.props.transferenceData.codigo} />
                        </CheckStatus>
                    </CheckAccess>

                    <CheckAccess permission={Permissions.TRANSFERENCE_REJECT}>
                        <CheckStatus actual={getTransferenceStatusFromId(this.props.transferenceData.estado).id} required={[TransferenceStatus.NOTARY.id, TransferenceStatus.REPERTORY.id, TransferenceStatus.NOTARY_APPROVAL.id]}>
                            <TransferenceRejectButton code={this.props.transferenceData.codigo} />
                        </CheckStatus>
                    </CheckAccess>
                    <CheckAccess permission={Permissions.PRE_ADMISSION_CREATE}>
                        <CheckStatus actual={getTransferenceStatusFromId(this.props.transferenceData.estado).id} required={[TransferenceStatus.NOTARIZED.id]}>
                            {!preAdmision.idpreingreso &&  <TransferenceRegisterPreAdmissionButton
                                code={this.props.transferenceData.codigo}
                                showSuccesMessage={this.props.showSuccesMessage}
                                showErrorMessage={this.props.showErrorMessage}
                            />}
                            {preAdmision.idpreingreso &&  preAdmisionDoc && <Button onClick={(e)=>this.props.showPreAdmisionDocument(preAdmisionDoc)} color='primary'>
                                Ver Preingreso
                            </Button>}
                        </CheckStatus>
                    </CheckAccess>
                    <CheckAccess permission={Permissions.TRANSFERENCE_SIGN}>
                        <CheckStatus actual={getTransferenceStatusFromId(this.props.transferenceData.estado).id} required={[TransferenceStatus.NOTARY.id]}>
                            <TransferenceSignButton code={this.props.transferenceData.codigo} />
                        </CheckStatus>
                    </CheckAccess>
                </ButtonGroup>
            </CardBody>
        </Card>);
    }
}

export default TransferenceActionsContainer;
