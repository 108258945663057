import React, {Component} from 'react';
import {withRouter} from 'react-router';
import TransferenceDetailContainer from "../../components/transference-detail-container/TransferenceDetailContainer";

class TransferenceDetail extends Component {

    render() {
        return(
            <div>
                <TransferenceDetailContainer code={this.props.match.params.code}/>
            </div>
        );
    }
}

export default withRouter(TransferenceDetail);
