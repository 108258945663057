import React, {Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, Alert} from 'reactstrap';
import FirmasDocumentosListContainer from '../../components/firmas-documentos-list-container/FirmasDocumentosListContainer';
import FirmasDocumentosListFilter from '../../components/firmas-documentos-list-filter/FirmasDocumentosListFilter';
import {Permissions} from '@autofact/operation-lib-model-notary-platform';
import  SignDocumentStatus from '../../services/SignDocumentStatus'
import {Link, withRouter} from "react-router-dom";
import _ from 'lodash';

import FilterIcon from '../../theme/assets/filter_conces.svg';
import CheckAccess from "../../components/check-access/CheckAccess";
import {app} from '../../config';
import ModalConfirm from '../../components/modal-confirm/ModalConfirm';
import BackendApiService from '../../services/BackendApiService';

const SIGN_TYPE_PHISIC = app.signTypesPhysic;
const tabsDefinition = {
    forLoad: {
        id: 'forLoad',
        title: 'Mandatos Por cargar',
        filters: {
            extern_source: 'transference',
            process_type: SIGN_TYPE_PHISIC,
            status: [SignDocumentStatus.APROBACION_NOTARIO.id].join(','),
            include: 'SignStatus,Subsidiary,SignConfig',
            signConfigWhere: { name: 'notary_need_upload', value: 1 },

        }
    },
    forApproval: {
        id: 'forApproval',
        title: 'Mandatos Por aprobar',
        filters: {
            extern_source: 'transference',
            status: SignDocumentStatus.APROBACION_NOTARIO.id,
            process_type: SIGN_TYPE_PHISIC,
            signConfigWhere: { name: 'notary_need_upload', value: 2},
            include: 'SignStatus,Subsidiary,SignConfig'
        }
    },
    forSign: {
        id: 'forSign',
        title: 'Mandatos Por firmar',
        filters: {
            extern_source: 'transference',
            process_type: SIGN_TYPE_PHISIC,
            status: [SignDocumentStatus.NOTARIO.id].join(',')
        }
    },
    signed: {
        id: 'signed',
        title: 'Firmados',
        filters: {
            extern_source: 'transference',
            process_type: SIGN_TYPE_PHISIC,
            status: [SignDocumentStatus.LEGALIZADO.id].join(',')
        }
    },
    full: {
        id: 'full',
        title: 'Consultar todos',
        filters: {
            extern_source: 'transference',
            process_type: SIGN_TYPE_PHISIC,
            status:[SignDocumentStatus.APROBACION_NOTARIO.id, SignDocumentStatus.NOTARIO.id, SignDocumentStatus.LEGALIZADO.id].join(',')
        }
    }
};
const MASSIVE_APPROVAL_SEND_COUNT = 10;

class FirmaDocumentosFisicosList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabsDefinition.forLoad.id,
            filtersCollapsed: true,
            isMessageVisible:false,
            message:{},
            listFilters: {
                forApproval: Object.assign({}, tabsDefinition.forApproval.filters),
                forLoad: Object.assign({}, tabsDefinition.forLoad.filters),
                forSign: Object.assign({}, tabsDefinition.forSign.filters),
                signed: Object.assign({}, tabsDefinition.signed.filters),
                full: Object.assign({}, tabsDefinition.full.filters)
            },
            recordCount: {
                forApproval: 0,
                forLoad: 0,
                forSign: 0,
                signed: 0,
                full: 0
            },
            selectedMandatesIds: [],
            openMassiveApprovalConfirmModal: false,
            approving: false,
            approvingData: {},
            massiveApprovalFinished: false
        };
        this.toggleFilters = this.toggleFilters.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.showSuccesMessage = this.showSuccesMessage.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.showDetailsInTabGeneric = this.showDetailsInTabGeneric.bind(this);
        this.buildFilters = this.buildFilters.bind(this);
        this.setFiltersCallback = this.setFiltersCallback.bind(this);
        this.currentTableRef = React.createRef();
        this.filterComponentRef = React.createRef();
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            const listFilters = this.state.listFilters;
            listFilters[tab] = tabsDefinition[tab].filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
            this.filterComponentRef.current.resetFilters();
        }
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({activeTab: this.props.location.state.tab})
        }
        const recordCount = {};
        Object.keys(tabsDefinition).forEach(tabKey => {
            recordCount[tabKey] = 0;
        });
        this.setState({recordCount});
    }

    toggleFilters() {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    buildFilters(tab, filters = null) {
        const where = filters || this.state.listFilters[tab];
        return {where : JSON.stringify(where)};
    }

    setFiltersCallback(filters) {
        const tab = this.state.activeTab;
        const listFilters = this.state.listFilters;
        for (const filterKey in tabsDefinition[tab].filters) {
            if (tabsDefinition[tab].filters.hasOwnProperty(filterKey)) {
                if (filters.hasOwnProperty(filterKey)) {
                    const filterValue = tabsDefinition[tab].filters[filterKey].toString().split(',').filter(filterValue => filterValue.toString() === filters[filterKey].toString());
                    filters[filterKey] = filterValue.join(',');
                }
                else {
                    filters[filterKey] = tabsDefinition[tab].filters[filterKey];
                }
            }
        }
        listFilters[tab] = filters;
        this.setState({listFilters});
        this.currentTableRef.current.refreshFilters(this.buildFilters(tab, listFilters[tab]));
    }

    showDetailsInTabGeneric(filters){
        console.log(`called ${JSON.stringify(filters)}`)
            const tab = tabsDefinition.full.id;
            const listFilters = Object.assign({},this.state.listFilters);
            listFilters[tab] = filters;
            this.setState({
                activeTab: tab,
                listFilters,
                filtersCollapsed: true
            });
        setTimeout(() => {
            this.setFiltersCallback(listFilters[tab]);
        }, 100);


    }

    onDismiss() {
        this.setState({isMessageVisible: false});
    }

    showSuccesMessage(message) {
        this.setState({
            message: {
                success: true,
                text: message
            }, isMessageVisible: true
        })
    }

    showErrorMessage(message) {
        this.setState({
            message: {
                success: false,
                text: message
            }, isMessageVisible: true
        })
    }
    // mandates massive approval
    handleSelectedCallback = (selectedIds) => {
        this.setState({ selectedMandatesIds: selectedIds });
    }
    handleModalStatus = (status = false) => {
        this.setState({openMassiveApprovalConfirmModal: status});
    }
    renderModalConfirmMassiveApproval() {
        return this.state.openMassiveApprovalConfirmModal &&
            <ModalConfirm
                confirmFunction={this.confirmMassiveApproval}
                cancelFunction={() => this.handleModalStatus()}
                messageType="info"
                message={`Se enviarán a aprobar ${this.state.selectedMandatesIds.length} mandatos. Desea continuar?`}
            />
    }
    confirmMassiveApproval = async () => {
        this.handleModalStatus();
        const approvingData = {...this.state.approvingData};
        this.state.selectedMandatesIds.forEach(id => { approvingData[id] = 'pending'; })
        this.setState({approving: true, approvingData}, this.manageApprovalProcess);
    }
    manageApprovalProcess = async () => {
        const mandateIds = [...this.state.selectedMandatesIds];
        for (const portion of _.chunk(mandateIds.sort((a, b) => b - a), MASSIVE_APPROVAL_SEND_COUNT)) {
            await this.sendToApprove(portion);
        }
        this.setState({approving: false, approvingData: {}, massiveApprovalFinished: true});
        this.currentTableRef.current.refreshFilters(this.buildFilters(this.state.activeTab));
        setTimeout(() => {
            this.setState({ massiveApprovalFinished: false, selectedMandatesIds: [] });
        }, 4000);
    }
    sendToApprove = (portion) => {
        const promises = [];
        const approvingData = {...this.state.approvingData};
        for (const mandateId of portion) {
            promises.push(
                BackendApiService.approveFirmasDocumentos(mandateId)
                    .then(response => { approvingData[mandateId] = 'success'; })
                    .catch(error => { approvingData[mandateId] = 'failed'; })
                    .finally(() => { this.setState({approvingData}) })
            );
            approvingData[mandateId] = 'inProgress';
        }
        this.setState({approvingData});
        return Promise.all(promises);
    }

    render() {
        const activeTab = this.state.activeTab;
        const listMenu = [];
        switch (activeTab) {
            case 'forSign': {
                listMenu.push(
                    <CheckAccess key={`massive-sign`} permission={Permissions.TRANSFERENCE_SIGN}>
                        <Link className="btn btn-sm btn-primary-dark mr-2"
                              to={`firma-documentos/firma-masiva/transference_physic`}>Firma masiva</Link>
                    </CheckAccess>);
                break;
            }
            case 'forApproval': {
                listMenu.push(
                    <CheckAccess key={'massive-approval'} permission={Permissions.TRANSFERENCE_APPROVE}>
                        <Button disabled={!this.state.selectedMandatesIds.length || this.state.approving} className="btn btn-sm btn-primary-dark mr-2"
                                onClick={() => this.handleModalStatus(true)}>
                            {this.state.approving ? `Aprobando ${this.state.selectedMandatesIds.length} mandatos ...` : 'Aprobación masiva'}
                        </Button>
                    </CheckAccess>);
                break;
            }
            default: {
                break;
            }
        }
        let message = null;
        if (this.state.isMessageVisible) {
            message = <Alert className="mt-2" color={this.state.message.success ? 'success' : 'danger'}
                             isOpen={this.state.isMessageVisible} toggle={this.onDismiss}>
                {this.state.message.text}
            </Alert>
        }
        return (
            <div>
                {message}
                {this.renderModalConfirmMassiveApproval()}
                <Nav tabs className="tabs-dark">
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <NavItem key={`tab-nav-item-${index}`}>
                                <NavLink
                                    key={`tab-nav-${index}`}
                                    className={activeTab === tab.id ? 'active' : ''}
                                    onClick={() => this.toggleTab(tab.id)}
                                >
                                    {tab.title} ({this.state.recordCount[tab.id]})
                                </NavLink>
                            </NavItem>
                        );
                    })}
                    <NavItem className="nav-button">
                        {listMenu}
                        <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters} disabled={this.state.approving}>
                            <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                        </Button>
                    </NavItem>
                </Nav>
                <Collapse isOpen={!this.state.filtersCollapsed}>
                    <div className="filter-form-wrapper">
                        <FirmasDocumentosListFilter ref={this.filterComponentRef}
                                                defaultFilters={tabsDefinition[this.state.activeTab].filters}
                                                activeTab={this.state.activeTab}
                                                setFiltersCallback={this.setFiltersCallback}/>
                    </div>
                </Collapse>
                <TabContent activeTab={activeTab}>
                    {Object.keys(tabsDefinition).map((key, index) => {
                        const tab = tabsDefinition[key];
                        return (
                            <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                                {activeTab === tab.id ?
                                    <FirmasDocumentosListContainer
                                        id={tab.id}
                                        tab={tab.id}
                                        ref={this.currentTableRef}
                                        key={`tab-list-${index}`}
                                        updateCount={count => {
                                            const recordCountState = this.state.recordCount;
                                            recordCountState[tab.id] = count;
                                            this.setState({recordCount: recordCountState});
                                        }}
                                        filters={this.buildFilters(tab.id)}
                                        selectedCallback={this.handleSelectedCallback}
                                        approving={this.state.approving}
                                        approvingData={this.state.approvingData}
                                        massiveApprovalFinished={this.state.massiveApprovalFinished}
                                    />
                                    :
                                    <div key={`tab-loading-${index}`}>Cargando</div>
                                }
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        );
    }

}

export default withRouter(FirmaDocumentosFisicosList);
