import { TransferenceStatus, getTransferenceRequestStatusFromId } from '@autofact/operation-lib-model-notary-platform';


const contractHeaderSettings = (status) => {

    const defaultLabel  = getTransferenceRequestStatusFromId(status)?.label || '';

    let header = {
        title: 'Nuevo contrato de compraventa',
        label: defaultLabel.toLowerCase(),
        cssClass: 'tag',
        icon: 'exclamation-circle'
    }

    if(status === TransferenceStatus.CLIENT_SIGNATURE.id){
      header = {
        title: 'Vista previa para revisión y firma de contrato',
        label: 'En revisión / Firma clientes',
        cssClass: 'tag tag-revision',
        icon: 'pen'
      }
    }

    if([TransferenceStatus.TAKE_APPROVAL.id, TransferenceStatus.APPROVAL.id].includes(status)){
      header = {
        title: 'Pago de impuestos y asignación de repertorio',
        label: 'En espera de pago de impuestos',
        cssClass: 'tag tag-revision',
        icon: 'exclamation-circle'
      }
    }

    if([TransferenceStatus.TAX.id, TransferenceStatus.STATUS_TAX_IN_PROCESS.id, TransferenceStatus.TAX_PAYED.id, TransferenceStatus.TAX_PAYED.id].includes(status)){
      header = {
        title: 'Pago de impuestos y asignación de repertorio',
        label: 'En pago de impuestos',
        cssClass: 'tag tag-tax',
        icon: 'exclamation-circle'
      }
    }

    if(status === TransferenceStatus.REPERTORY.id){
      header = {
        title: 'Pago de impuestos y asignación de repertorio',
        label: 'En espera de asignación de repertorio',
        cssClass: 'tag tag-revision',
        icon: 'exclamation-circle'
      }
    }

    if(status === TransferenceStatus.PACKING.id){
      header = {
        title: 'Pago de impuestos y asignación de repertorio',
        label: 'Consolidando',
        cssClass: 'tag tag-revision',
        icon: 'exclamation-circle'
      }
    }

    if(status === TransferenceStatus.NOTARY.id){
      header = {
        title: 'Pago de impuestos y asignación de repertorio',
        label: 'En espera de Firma Notario',
        cssClass: 'tag tag-sign',
        icon: 'exclamation-circle'
      }
    }

    if(status === TransferenceStatus.INGRESADO_STEV.id){
      header = {
        title: 'En ingreso a Registro Civil',
        label: 'En ingreso a Reg.Civil',
        cssClass: 'tag tag-rc',
        icon: 'file-alt'
      }
    }
    if(status === TransferenceStatus.TRANSFERRED.id){
      header = {
        title: 'Transferido',
        label: 'Transferido',
        cssClass: 'tag tag-transferred',
        icon: 'check-circle'
      }
    }
    if(status === TransferenceStatus.REJECTED.id){
      header = {
        title: 'Rechazado',
        label: 'Rechazado',
        cssClass: 'tag tag-rejected',
        icon: 'times-circle'
      }
    }

    return header;
  }

  export default contractHeaderSettings
